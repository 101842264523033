import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { setTimber } from '@appSrc/actions/configuratorActions';

import { itemToInput } from '@components/forms/GridForm';

import Card from '../Card';
import MaterialSelector from '../MaterialSelector';
import Section, { InputContainer } from '../Section';
import { DoubleInputs } from '../layouts';

export const SILL_TIMBER_KEY = 'sill_timber';

const Sill = ({ recordId = null, timberItems = [] }) => {
  const methods = useFormContext();
  let state = useSelector((state) => {
    // @ts-ignore
    return { timber: state.configuratorForms.timber[SILL_TIMBER_KEY] };
  });
  const dispatch = useDispatch();

  const handleTimberSelection = (item) => {
    // console.log('Selected: ', item);
    dispatch(setTimber(SILL_TIMBER_KEY, item));
  };

  const renderCard = (item) => {
    if (item != null) {
      return (
        <Card
          key="a1"
          defaultOpen={false}
          title={item['name']}
          attributes={[{ label: 'Type', value: item['timber_type'] }]}
          narrowStyle
        />
      );
    } else {
      return <Card key="a2" defaultOpen={false} blank={true} narrowStyle />;
    }
  };

  return (
    <Section title={'Sill'}>
      <InputContainer className="flex flex-row space-x-12 w-full" key="sill-form">
        <InputContainer className="flex flex-col space-y-2">
          <span className="pl-16">Dimensions</span>
          <InputContainer className="flex flex-row -space-x-16">
            {itemToInput(
              {
                label: 'Width',
                name: 'sill[width]',
                type: 'number',
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
            {itemToInput(
              {
                label: 'Height',
                name: 'sill[height]',
                type: 'number',
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
          </InputContainer>
        </InputContainer>
        <InputContainer className="flex flex-col space-y-2">
          <span className="h-6 w-1"></span>
          <InputContainer className="flex flex-row -space-x-16">
            {itemToInput(
              {
                label: 'Projection',
                name: 'sill[projection]',
                type: 'number',
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
            {itemToInput(
              {
                label: 'Horns length',
                name: 'sill[horns]',
                type: 'number',
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
            {itemToInput(
              {
                label: 'Groove depth',
                name: 'sill[groove_depth]',
                error: { required: 'Please enter groove depth' },
                type: 'number',
                step: 1,
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
          </InputContainer>
        </InputContainer>
        <InputContainer className="flex space-x-4" style={{}}>
          <div className="self-center " style={{ minWidth: 215 }}>
            {renderCard(state.timber)}
          </div>
          <div className="self-center w-full">
            {/* @ts-ignore */}
            <MaterialSelector
              modalButtonText="Sill Timber"
              title="Sill Timber"
              items={timberItems}
              onItemSelect={handleTimberSelection}
              typeOfMaterial={'timberType'}
            />
          </div>
        </InputContainer>
      </InputContainer>
      <InputContainer>
        {DoubleInputs({
          methods,
          first: {
            label: 'Sill angle',
            name: 'sill[angle]',
            type: 'number',
            defaultValue: '',
            inputClass: 'w-3/5',
          },
          second: {
            label: 'Bottom rail angle',
            name: 'sash[bottom_rail][angle]',
            type: 'number',
            defaultValue: '',
            inputClass: 'w-3/5',
          },
          distance: 12,
        })}
      </InputContainer>
      {state.timber ? (
        <InputContainer className="flex flex-row space-x-2 w-full" key="sill-timb-comp">
          {itemToInput(
            {
              label: 'Sill timber composition',
              name: 'sill[timber_composition]',
              error: { required: 'Please select timber composition for sill' },
              orientation: 'vertical',
              type: 'radio',
              options: [
                { label: 'Solid', value: 'solid' },
                { label: 'Engineered', value: 'engineered' },
              ],
              defaultValue: '',
              inputClass: 'w-2/4',
            },
            false,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer>
      ) : (
        <span key="empty"></span>
      )}
    </Section>
  );
};

export default Sill;
