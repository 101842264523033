export const SET_CONFIG_KEY = 'SET_CONFIG_KEY';
export const SET_CONFIG = 'SET_CONFIG';

export function setToLoading() {
  return {
    type: SET_CONFIG_KEY,
    key: 'loading',
    value: true
  };
}

export function loadingFinished() {
  return {
    type: SET_CONFIG_KEY,
    key: 'loading',
    value: false
  };
}
