import React from 'react';

type Props = {
  inline: boolean;
  label: string;
  name: string;
  style: string;
  placeholder: string;
  icon: JSX.Element;
  min: number;
  disabled: boolean;
};

const RenderLabel = ({ children, icon }) => {
  if (icon) {
    return (
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">{icon}</span>
        {children}
      </div>
    );
  }

  return children;
};

export const NumberInput = React.forwardRef(
  (
    { inline = false, name = 'name', style = '', placeholder = '', icon, ...otherProps }: Props,
    ref
  ) => {
    const inputStyle = icon ? 'rounded-md pl-8 ' + style : style;

    return (
      <RenderLabel icon={icon}>
        <input
          name={name}
          type="number"
          className={inputStyle}
          placeholder={placeholder}
          autoComplete="off"
          ref={ref}
          {...otherProps}
        />
      </RenderLabel>
    );
  }
);
