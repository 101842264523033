import React from 'react';

import { ISection } from '@stores/SectionStore';

import ArcRect from '@components/configurators/Casement/ArcRect';

type MullionProps = {
  section: ISection;
  lineProps?: any;
};

const DEFAULT_SHAPE_PROPS = {
  closed: true,
  stroke: 'black',
  strokeWidth: 4,
  opacity: 1,
  fill: 'white',
};

function isAround(a, b, tolerance = 12) {
  return Math.abs(a - b) < tolerance;
}

export default function Mullion({ section, lineProps = DEFAULT_SHAPE_PROPS }: MullionProps) {
  // DEBUG
  // console.log('Mullion ORIGIN: ', section.origin);
  // console.log('Mullion REBATE SIZE: ', {
  //   with: section.store.rebate.width,
  //   height: section.size.height,
  // });
  // DEBUG-END
  // console.log('Mullion PARENT', section.parent.sectionType);
  let yOffset = 0;
  let heightOffset = 0;
  if (section.isHeadNeighbour) {
    heightOffset = section.store.rebate.width;
  }
  if (section.parent.sectionType === 'root') {
    yOffset = section.store.sill.width - section.store.visibleSillHeight;
    heightOffset += yOffset;
  }

  return (
    <>
      <ArcRect {...lineProps} section={section} />
      <ArcRect
        {...lineProps}
        section={section}
        x={section.store.visibleMullionWidth}
        y={section.origin.y + yOffset}
        altSize={{ width: section.store.rebate.width, height: section.size.height - heightOffset }}
      />
      <ArcRect
        {...lineProps}
        section={section}
        x={-section.store.rebate.width}
        y={section.origin.y + yOffset}
        altSize={{ width: section.store.rebate.width, height: section.size.height - heightOffset }}
      />
    </>
  );
}
