import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FaPercentage } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

import DebugConsole from '@appSrc/components/DebugOptions/DebugConsole';

import { Alert } from '@components/alerts';

import { updateSelectionKeys } from '@utils/formHelpers';

import '../../../css/react-notifications.css';
import ContextForm from '../ContextForm';
import { NumberInput } from '../numberInput';
import InsulatorVariants from './InsulatorVariants';

const howSuppliersSell = (item, alerts, register, errors, control, quantityType) => {
  return (
    <div className="form-element">
      {item.label && <div className="form-label required">{item.label}</div>}
      <div className="flex items-start justify-start flex-col">
        <label className="flex items-center justify-start space-x-2">
          <input
            type="radio"
            value="length"
            name="supplier_quantity_type"
            ref={register({ required: true })}
            className={`form-radio h-4 w-4 ${
              errors['supplier_quantity_type'] ? 'text-red-500' : ''
            }`}
          />
          <span className={`${errors['supplier_quantity_type'] ? 'text-red-500' : ''}`}>
            Length
          </span>
          {/* @ts-ignore */}
          {quantityType === 'length' && (
            <Controller
              as={NumberInput}
              name="supplier_quantity"
              style={`form-textarea ${
                errors['supplier_quantity'] ? 'border border-red-500' : ''
              } w-32`}
              placeholder="Metres"
              icon={null}
              control={control}
              rules={{ required: 'Please enter how many metres are in a single item' }}
              defaultValue={''}
              /* @ts-ignore */
              step={0.01}
              min={0}
            />
          )}
          {!alerts && errors['supplier_quantity'] && (
            <div className="form-error">{errors['supplier_quantity'].message}</div>
          )}
        </label>
        <label className="flex items-center justify-start space-x-2">
          <input
            type="radio"
            value="unit"
            name="supplier_quantity_type"
            ref={register({ required: true })}
            className={`form-radio h-4 w-4 ${
              errors['supplier_quantity_type'] ? 'text-red-500' : ''
            }`}
          />
          <span className={`${errors['supplier_quantity_type'] ? 'text-red-500' : ''}`}>Unit</span>
        </label>
      </div>
    </div>
  );
};

const quantityAndPrice = (item, alerts, register, errors, control) => {
  return (
    <div className="form-element flex items-start justify-start flex-col">
      <div
        className="form-element flex items-start justify-start flex-row space-x-2"
        style={{ flexDirection: 'row', marginBottom: 0 }}
      >
        <div className="form-element" style={{ marginBottom: 0 }}>
          <div className="form-label required">Quantity</div>
          {/* @ts-ignore */}
          <Controller
            as={NumberInput}
            name="quantity"
            style={`form-textarea ${errors['quantity'] ? 'border border-red-500' : ''} w-24`}
            placeholder=""
            icon={null}
            control={control}
            rules={{ required: 'Please enter what quantity the price coresponds to.' }}
            min={0}
            defaultValue={''}
          />
        </div>
      </div>
      {!alerts && errors['quantity'] && (
        <div className="form-error">{errors['quantity'].message}</div>
      )}
      {!alerts && errors['price'] && <div className="form-error">{errors['price'].message}</div>}
    </div>
  );
};

const MODE_HANDLER = {
  edit: API.updateHardwareItem,
  default: API.createHardwareItem,
};

const PRODUCT_RANGES = [
  { label: 'Box Sash', value: 'box_sash' },
  { label: 'Casement', value: 'casement' },
  { label: 'Doors', value: 'doors' },
  { label: 'All', value: 'all' },
];

export const PRODUCT_RANGES_MAP = {
  box_sash: { label: 'Box Sash', value: 'box_sash' },
  casement: { label: 'Casement', value: 'casement' },
  doors: { label: 'Doors', value: 'doors' },
  all: { label: 'All', value: 'all' },
};

const InsulatorForm = ({
  message = null,
  formId,
  recordId = null,
  mode = 'default',
  newFormConfig = null,
  category = 'all',
}) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm({
    defaultValues: {
      product_category: [PRODUCT_RANGES_MAP[category]],
    },
  });
  const state = useSelector((state) => {
    return {
      // @ts-ignore
      file: state.forms['product_image' + recordId],
      // @ts-ignore
      supplierOptions: state.forms.suppliers,
    };
  });

  const quantityType = methods.watch('supplier_quantity_type');

  useEffect(() => {
    if (recordId == null) return;
    API.getHardware(recordId).then((data) => {
      const supplier = state.supplierOptions.find(
        (option) => Number(option.value) === data['supplier_id']
      );

      setDefaultValues(data);
      reset({
        ...data,
        supplier,
        product_category: data['product_category'] || [PRODUCT_RANGES_MAP[category]],
        variants: reMapVariants(data['variants']),
      });
    });
  }, [reset, recordId, category]);

  const reMapVariants = (variants) => {
    const remappedVariants = Object.assign({}, variants);
    Object.keys(remappedVariants).forEach((key) => {
      const hardwareOption = newFormConfig.finishes.find(
        (option) => Number(option.value) === remappedVariants[key]['hardware_finish_id']
      );
      remappedVariants[key] = {
        // @ts-ignore
        ...remappedVariants[key],
        hardware_finish_id: hardwareOption,
      };
    });
    return remappedVariants;
  };

  const handleVariants = (variants) => {
    return Object.entries(variants).map(([key, value]) => {
      return {
        // @ts-ignore
        ...value,
        hardware_finish_id: value['hardware_finish_id'].value,
      };
    });
  };

  const onSubmitFn = (data) => {
    // console.log('Data', data);
    // console.log('Submit count', methods.formState.submitCount);

    const imageParams = state.file ? { product_image: state.file } : {};
    let { ...filteredData } = data;
    updateSelectionKeys(data, 'product_category');
    // console.log('DATA', data);
    MODE_HANDLER[mode || 'default'](
      API.toFormData({
        ...filteredData,
        supplier: filteredData.supplier.value,
        variants: JSON.stringify(handleVariants(data.variants)),
        product_category: data['product_category'].join(','),
        ...imageParams,
      }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Image',
          error: {},
          name: 'product_image',
          type: 'image',
          recordId,
          files: state.file,
          defaultValue: defaultValues['product_image'],
        },
      ],
    },
    {
      config: ['3/4', 'full', '3/4'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Name',
          error: { required: 'Please enter a hardware product name' },
          name: 'name',
          type: 'text',
          placeholder: 'Polished brass hinge',
          inlineClass: 'w-1/3',
        },
        {
          label: 'Description',
          error: { required: 'Please enter item description' },
          name: 'description',
          type: 'textarea',
          placeholder: 'Standard polish brass butt hinge',
          inlineClass: 'min-w-26',
        },
        {
          label: 'Product URL',
          error: {},
          name: 'product_url',
          type: 'text',
          placeholder: '',
        },
      ],
    },
    {
      config: ['full', '', ''],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Product range',
          error: { required: 'Please select product range(s)' },
          name: 'product_category',
          options: PRODUCT_RANGES,
          type: 'multiSelect',
          inlineClass: 'w-1/4',
        },
        {
          label: 'Supplier',
          error: { required: 'Please select a supplier' },
          name: 'supplier',
          options: state.supplierOptions,
          type: 'combobox',
          inlineClass: 'w-1/4',
        },
        {
          name: 'hardware_type',
          type: 'hidden',
          error: {},
          defaultValue: 'INSULATOR',
        },
      ],
    },
    {
      config: ['full', 'full', 'full'],
      style: { orientation: 'column' },
      name: 'quantityAndPrice',
      items: [
        {
          label: 'How the supplier sells',
          error: { required: 'You must select at least one option' },
          type: 'custom',
          // @ts-ignore
          component: (...props) => howSuppliersSell(...props, quantityType),
        },
        {
          error: { required: 'Please enter quantity and price' },
          name: '',
          type: 'custom',
          component: quantityAndPrice,
        },
        {
          label: 'VAT',
          error: { required: 'Please enter valid percentage value' },
          name: 'vat_rate',
          type: 'number',
          defaultValue: 20,
          icon: <FaPercentage />,
          inputClass: 'w-32',
          min: 0,
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formId={formId}
            formStyle="flex flex-row"
          >
            <InsulatorVariants
              methods={methods}
              hardwareFinishes={(newFormConfig && newFormConfig.finishes) || []}
              dataKeys={
                (defaultValues['variants'] && Object.keys(defaultValues['variants'])) || null
              }
            />
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default InsulatorForm;
