import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FaPercentage } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

import '@appSrc/css/react-notifications.css';

import DebugConsole from '@components/DebugOptions/DebugConsole';
import { Alert } from '@components/alerts';
import TableInputs from '@components/configurators/TableInputs';
import ContextForm from '@components/forms/ContextForm';
import { CurrencyInput } from '@components/forms/currencyInput';
import { NumberInput } from '@components/forms/numberInput';

import { updateSelectionKeys, omit } from '@utils/formHelpers';

const priceAndVAT = (_item, alerts, _register, errors, control) => {
  return (
    <div className="form-element flex items-start justify-start flex-col space-x-2">
      <div className="form-element required" style={{ marginLeft: '0rem' }}>
        <div className="form-label required">
          Price m
          <span className="text-2xl" style={{ lineHeight: '0.5rem' }}>
            &#178;
          </span>
        </div>
        {/* @ts-ignore */}
        <Controller
          as={CurrencyInput}
          name="price"
          style={`form-textarea ${errors['price'] ? 'border border-red-500' : ''} w-40`}
          placeholder={'30.02'}
          control={control}
          rules={{ required: 'Please enter price' }}
          defaultValue={''}
        />
      </div>
      <div className="form-element required" style={{ marginLeft: '0rem' }}>
        <div className="form-label required">VAT</div>
        {/* @ts-ignore */}
        <Controller
          as={NumberInput}
          name="vat_rate"
          style={`form-textarea ${errors['vat_rate'] ? 'border border-red-500' : ''} w-24`}
          placeholder=""
          icon={<FaPercentage />}
          control={control}
          rules={{ required: 'Please a valid percentage value.' }}
          defaultValue={20}
          min={0}
        />
      </div>
      {!alerts && errors['price'] && <div className="form-error">{errors['price'].message}</div>}
      {!alerts && errors['price_below'] && (
        <div className="form-error">{errors['price_below'].message}</div>
      )}
      {!alerts && errors['vat_rate'] && (
        <div className="form-error">{errors['vat_rate'].message}</div>
      )}
    </div>
  );
};

const MODE_HANDLER = {
  edit: API.updateGlazingUnitItem,
  default: API.createGlazingUnitItem,
};

const GlazingUnitsForm = ({ message = null, formId, recordId = null, mode = 'default' }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm();
  const state = useSelector((state: { forms: any; glazingUnitForms: any }) => {
    return {
      supplierOptions: state.forms.suppliers,
      formFields: state.glazingUnitForms,
    };
  });

  useEffect(() => {
    if (recordId == null) return;
    if (typeof state.supplierOptions === 'undefined') return;

    API.getGlazingUnitItem(recordId).then((data) => {
      reset(data);
    });
  }, [reset, recordId, state.supplierOptions]);

  const onSubmitFn = (data) => {
    console.log('Data', data);
    console.log('Submit count', methods.formState.submitCount);

    const isDoubleGlazed = data.glazing_type === 'double';

    updateSelectionKeys(data, 'inner_pane_thickness');
    updateSelectionKeys(data, 'inner_pane_type');
    if (isDoubleGlazed) {
      updateSelectionKeys(data, 'integrated_bar_color');
      updateSelectionKeys(data, 'integrated_bar_thickness');
      updateSelectionKeys(data, 'integrated_bar_type');
      updateSelectionKeys(data, 'integrated_bar_width');
      updateSelectionKeys(data, 'outer_pane_thickness');
      updateSelectionKeys(data, 'outer_pane_type');
      updateSelectionKeys(data, 'spacer_color');
      updateSelectionKeys(data, 'spacer_gas');
      updateSelectionKeys(data, 'spacer_thickness');
      updateSelectionKeys(data, 'spacer_type');
    } else {
      data = omit(data, [
        'integrated_bar_color',
        'integrated_bar_thickness',
        'integrated_bar_type',
        'integrated_bar_width',
        'outer_pane_thickness',
        'outer_pane_type',
        'spacer_color',
        'spacer_gas',
        'spacer_thickness',
        'spacer_type',
      ]);
    }
    updateSelectionKeys(data, 'supplier');
    MODE_HANDLER[mode || 'default'](
      API.toFormData({
        ...data,
        u_value: data.u_value ? data.u_value : '',
        vat_rate: (Number(data['vat_rate']) / 100).toFixed(2),
        supplier_id: data.supplier,
        double_glazed: isDoubleGlazed,
      }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['full', '3/4', '3/4'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Configuration',
          error: { required: 'Please select one option' },
          name: 'glazing_type',
          type: 'radio',
          options: [
            { label: 'Single', value: 'single' },
            { label: 'Double', value: 'double' },
          ],
          defaultValue: 'double',
        },
        {
          label: 'Supplier',
          error: {},
          name: 'supplier',
          options: state.supplierOptions,
          type: 'combobox',
          inlineClass: 'w-1/4',
        },
        {
          label: 'U-value',
          error: {},
          name: 'u_value',
          type: 'number',
          step: 0.01,
          defaultValue: '',
        },
      ],
    },
    {
      config: ['full  mt-16'],
      style: { orientation: 'column' },
      items: [
        {
          error: { required: 'Please enter price and vat' },
          name: '',
          type: 'custom',
          /* @ts-ignore */
          component: priceAndVAT,
        },
      ],
    },
  ];

  const columns = [
    { name: 'Thickness' },
    { name: 'Width' },
    { name: 'Type' },
    { name: 'Toughened' },
    { name: 'Colour' },
    { name: 'Gas' },
  ];

  const paneTicknessOptions = [
    { label: 4, value: 4 },
    { label: 6, value: 6 },
    { label: 6.4, value: 6.4 },
    { label: 6.8, value: 6.8 },
  ];

  const spacerTicknessOptions = [
    { label: 4, value: 4 },
    { label: 6, value: 6 },
    { label: 8, value: 8 },
    { label: 10, value: 10 },
    { label: 12, value: 12 },
    { label: 14, value: 14 },
    { label: 16, value: 16 },
  ];

  const integratedBarWidthOptions = [
    { label: 14, value: 14 },
    { label: 16, value: 16 },
    { label: 18, value: 18 },
    { label: 20, value: 20 },
  ];

  const paneTypeOptions = [
    { label: 'Low "E"', value: 'Low "E"' },
    { label: 'Laminated', value: 'Laminated' },
    { label: 'Accoustic', value: 'Accoustic' },
    { label: 'Obscure', value: 'Obscure' },
  ];

  const spacerTypeOptions = [
    { label: 'Warm edge', value: 'Warm edge' },
    { label: 'Swiss', value: 'Swiss' },
    { label: 'Aluminium', value: 'Aluminium' },
  ];

  const integratedBarTypeOptions = [
    { label: 'Duplex', value: 'Duplex' },
    { label: 'Box', value: 'Box' },
  ];

  const colorOptions = [
    { label: 'White', value: 'White' },
    { label: 'Black', value: 'Black' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Grey', value: 'Grey' },
  ];

  const gasOptions = [
    { label: 'Argon', value: 'Argon' },
    { label: 'Xenon', value: 'Xenon' },
    { label: 'Krypton', value: 'Krypton' },
    { label: 'Air', value: 'Air' },
  ];

  const rows = [
    {
      name: 'Inner pane',
      inputs: [
        {
          name: 'inner_pane_thickness',
          type: 'combobox',
          options: paneTicknessOptions,
          defaultValue: paneTicknessOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        { disabled: true, key: 'dis1' },
        {
          name: 'inner_pane_type',
          type: 'comboMultiSelect',
          options: paneTypeOptions,
          defaultValue: paneTypeOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          cellStyle: {
            minWidth: 'none',
            maxWidth: 200,
          },
          inputClass: 'w-full',
        },
        { name: 'inner_pane_toughened', wrapperClass: 'justify-center' },
        { disabled: true, key: 'dis12' },
        { disabled: true, key: 'dis2' },
      ],
    },
    {
      name: 'Spacer',
      inputs: [
        {
          name: 'spacer_thickness',
          type: 'combobox',
          options: spacerTicknessOptions,
          defaultValue: spacerTicknessOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        { disabled: true, key: 'dis3' },
        {
          name: 'spacer_type',
          type: 'combobox',
          options: spacerTypeOptions,
          defaultValue: spacerTypeOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        { disabled: true, key: 'dis4' },
        {
          name: 'spacer_color',
          type: 'combobox',
          options: colorOptions,
          defaultValue: colorOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          cellStyle: {
            minWidth: 0,
            maxWidth: 150,
          },
          inputClass: 'w-full',
        },
        {
          name: 'spacer_gas',
          type: 'combobox',
          options: gasOptions,
          defaultValue: gasOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          cellStyle: {
            minWidth: 0,
            maxWidth: 150,
          },
          inputClass: 'w-full',
        },
      ],
    },
    {
      name: 'Outer pane',
      inputs: [
        {
          name: 'outer_pane_thickness',
          type: 'combobox',
          defaultValue: paneTicknessOptions[0],
          options: paneTicknessOptions,
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        { disabled: true, key: 'dis5' },
        {
          name: 'outer_pane_type',
          type: 'comboMultiSelect',
          options: paneTypeOptions,
          defaultValue: paneTypeOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        { name: 'outer_pane_toughened', wrapperClass: 'justify-center' },
        { disabled: true, key: 'dis11' },
        { disabled: true, key: 'dis6' },
      ],
    },
    {
      name: 'Integrated bars',
      inputs: [
        {
          name: 'integrated_bar_thickness',
          type: 'combobox',
          options: spacerTicknessOptions,
          defaultValue: spacerTicknessOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        {
          name: 'integrated_bar_width',
          type: 'combobox',
          options: integratedBarWidthOptions,
          defaultValue: integratedBarWidthOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        {
          name: 'integrated_bar_type',
          type: 'combobox',
          options: integratedBarTypeOptions,
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          inputClass: 'w-full',
        },
        { disabled: true, key: 'dis7' },
        {
          name: 'integrated_bar_color',
          type: 'combobox',
          options: colorOptions,
          defaultValue: colorOptions[0],
          wrapperStyles: {
            minWidth: 'none',
            marginBottom: 0,
          },
          cellStyle: {
            minWidth: 0,
            maxWidth: 150,
          },
          inputClass: 'w-full',
        },
        { disabled: true, key: 'dis8' },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formId={formId}
            formStyle="flex flex-row"
          >
            <TableInputs methods={methods} columns={columns} rows={rows} />
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default GlazingUnitsForm;
