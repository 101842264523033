// @ts-nocheck
import API from 'apiv1';
import Fuse from 'fuse.js';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import styled from 'styled-components';
import tw from 'twin.macro';

import '@appSrc/css/react-notifications.css';

import DebugConsole from '@components/DebugOptions/DebugConsole';
import Card from '@components/configurators/Card';
import { itemToInput } from '@components/forms/GridForm';
import SwitchInput from '@components/forms/Switch';
import { NumberInput } from '@components/forms/numberInput';
import DataModal from '@components/modals/DataModal';

const BoxSashHardwareSetForm = ({
  formId,
  recordId = null,
  boxSashSet = null,
  mode = 'default',
  hardware,
  weightSuppliers,
}) => {
  const [data, onSubmit] = useState(null);
  const [pulleyItem, setPulleyItem] = useState(null);
  const [pulleyItemQty, setPulleyItemQty] = useState(0);
  const [liftItemQty, setLiftItemQty] = useState(0);
  const [liftItem, setLiftItem] = useState(null);
  const [handleItemQty, setHandleItemQty] = useState(0);
  const [handleItem, setHandleItem] = useState(null);
  const [fitchFastenerItemQty, setFitchFastenerItemQty] = useState(0);
  const [fitchFastenerItem, setFitchFastenerItem] = useState(null);
  const [ventLockItemQty, setVentLockItemQty] = useState(0);
  const [ventLockItem, setVentLockItem] = useState(null);
  const [cordItem, setCordItem] = useState(null);
  const [sealItem, setSealItem] = useState(null);
  const [weightSupplier, setWeightSupplier] = useState('');
  const [springs, setSprings] = useState(false);
  const [miscItems, setMiscItems] = useState([]);
  const [submitForm, setSubmitForm] = useState(false);

  const { reset, ...methods } = useForm();
  const [query, setQuery] = useState(null);
  const [items, setItems] = useState([]);

  const MODE_HANDLER = {
    edit: API.updateHardwareSet,
    default: API.createHardwareSet,
  };

  const stateToParams = () => {
    let formData = {};

    if (pulleyItem) {
      formData['pulley'] = { id: pulleyItem.id, quantity: pulleyItemQty };
    }
    if (liftItem) {
      formData['lift'] = { id: liftItem.id, quantity: liftItemQty };
    }
    if (handleItem) {
      formData['handle'] = { id: handleItem.id, quantity: handleItemQty };
    }
    if (fitchFastenerItem) {
      formData['fitch_fastener'] = { id: fitchFastenerItem.id, quantity: fitchFastenerItemQty };
    }
    if (ventLockItem) {
      formData['vent_lock'] = { id: ventLockItem.id, quantity: ventLockItemQty };
    }
    if (cordItem) {
      formData['cord'] = { id: cordItem.id, quantity: 1 };
    }
    if (sealItem) {
      formData['seal'] = { id: sealItem.id, quantity: 1 };
    }
    if (weightSupplier && weightSupplier['value']) {
      formData['weight'] = { id: weightSupplier['value'] };
    }

    formData['name'] = methods.watch('name');
    formData['springs'] = springs;

    return formData;
  };

  useEffect(() => {
    if (!submitForm) return;

    MODE_HANDLER[mode || 'default'](
      API.toFormData({ data: JSON.stringify(stateToParams()) }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  }, [submitForm]);

  useEffect(() => {
    if (mode !== 'edit') return;

    if (boxSashSet['pulley']) setPulleyItem(boxSashSet['pulley']['hardware']);
    if (boxSashSet['lift']) setLiftItem(boxSashSet['lift']['hardware']);
    if (boxSashSet['handle']) setHandleItem(boxSashSet['handle']['hardware']);
    if (boxSashSet['fitch_fastener'])
      setFitchFastenerItem(boxSashSet['fitch_fastener']['hardware']);
    if (boxSashSet['vent_lock']) setVentLockItem(boxSashSet['vent_lock']['hardware']);
    if (boxSashSet['cord']) setCordItem(boxSashSet['cord']['hardware']);
    if (boxSashSet['seal']) setSealItem(boxSashSet['seal']['hardware_variant']);
    setWeightSupplier(boxSashSet['weight']);
    reset({ name: boxSashSet['name'] });
  }, [boxSashSet, mode]);

  const options = {
    includeScore: true,
    keys: ['name', 'finish', 'certificates', 'supplier'],
  };

  const fuse = new Fuse(hardware, options);

  const handleSearch = (q) => {
    return fuse.search(q);
  };

  const handleSelection = (item, itemSetter) => {
    itemSetter(item);
  };

  const renderCard = (item) => {
    if (item) {
      return (
        <Card
          defaultOpen={false}
          title={[item['name'], item['finish'], item['supplier']].join(' - ')}
          narrowStyle
        />
      );
    } else {
      return <Card defaultOpen={false} blank={true} narrowStyle />;
    }
  };

  const dataModal = (itemSetter) => {
    const columns = [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Finish',
        accessor: 'finish',
      },
      {
        title: 'Certificates',
        accessor: 'certificates',
      },
      {
        title: 'Supplier',
        accessor: 'supplier',
      },
    ];

    return [
      <DataModal
        key="ai1"
        modalButtonText="Select"
        buttonClass="whitespace-nowrap"
        title="Select Hardware"
        columns={columns}
        data={query ? handleSearch(query).map((r) => r.item) : hardware}
        handleSearch={setQuery}
        onSelect={(item) => handleSelection(item, itemSetter)}
      />,
    ];
  };

  const renderSection = (title, item, qty, qtyChangeHandler, dataModal) => {
    return (
      <CardContainer>
        <SectionContainer className={'w-5/6'} minWidth={500}>
          <div className="form-label">{title}</div>
          {renderCard(item)}
        </SectionContainer>
        {qtyChangeHandler && (
          <SectionContainer maxWidth={50}>
            <div className="form-label">Qty</div>
            <NumberInput
              name={''}
              // @ts-ignore
              value={qty}
              // @ts-ignore
              onChange={qtyChangeHandler}
              min={0}
              step={0.1}
              className="form-textarea"
            />
          </SectionContainer>
        )}
        <ButtonsContainer>{dataModal}</ButtonsContainer>
      </CardContainer>
    );
  };

  const renderWeightsInput = () => {
    return (
      <CardContainer>
        <SectionContainer className={'w-5/6'} minWidth={500}>
          <div className="form-label">Weight Supplier</div>
          <Select
            options={weightSuppliers}
            menuPortalTarget={document.body}
            value={weightSupplier}
            onChange={setWeightSupplier}
            styles={{
              control: (styles) => ({
                ...styles,
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                appearance: 'none',
                backgroundColor: '#fff',
                borderColor: '#e2e8f0',
                borderWidth: '1px',
                borderRadius: '0.675rem',
                lineHeight: 1.5,
                fontSize: '0.825rem',
                width: '100%',
              }),
              valueContainer: (styles) => ({
                ...styles,
                paddingTop: '0.25rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.25rem',
                paddingLeft: '0.5rem',
                borderRadius: '0.675rem',
              }),
              menu: (styles) => ({
                ...styles,
                paddingTop: '0.25rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.25rem',
                paddingLeft: '0.5rem',
                borderRadius: '0.675rem',
                zIndex: 9999,
              }),
              menuPortal: (styles) => ({
                ...styles,
                zIndex: 9990,
              }),
            }}
          />
        </SectionContainer>
      </CardContainer>
    );
  };

  const handleSubmtiForm = async () => {
    const result = await methods.trigger();
    if (result) setSubmitForm(true);
  };

  return (
    <>
      <div className="flex flex-row">
        <FormProvider {...methods} reset={reset}>
          <form
            onSubmit={methods.handleSubmit(handleSubmtiForm)}
            className="form flex flex-col w-full"
            id={formId || null}
          >
            <Container>
              <SwitchInput
                initialState={springs}
                leftLabel="Weights & Cords"
                rightLabel="Springs"
                onChange={setSprings}
              />
              <CardContainer>
                <SectionContainer minWidth={350}>
                  {itemToInput(
                    {
                      label: 'Name',
                      name: 'name',
                      error: { required: 'Please werite a name' },
                      type: 'text',
                      defaultValue: '',
                    },
                    null,
                    methods.register,
                    methods.errors,
                    methods.control
                  )}
                </SectionContainer>
              </CardContainer>
              {/* PULLEY */}
              {!springs &&
                renderSection('Pulley', pulleyItem, pulleyItemQty, null, dataModal(setPulleyItem))}
              {renderSection('Lift', liftItem, liftItemQty, null, dataModal(setLiftItem))}
              {renderSection('Handle', handleItem, handleItemQty, null, dataModal(setHandleItem))}
              {renderSection(
                'Fitch Fastener',
                fitchFastenerItem,
                fitchFastenerItemQty,
                null,
                dataModal(setFitchFastenerItem)
              )}
              {renderSection(
                'Vent Lock',
                ventLockItem,
                ventLockItemQty,
                null,
                dataModal(setVentLockItem)
              )}
              {renderSection('Seal', sealItem, 1, null, dataModal(setSealItem))}
              {!springs && renderSection('Cord', cordItem, 1, null, dataModal(setCordItem))}
              {!springs && renderWeightsInput()}
              {/* {renderSection('Misc', pulleyItem, pulleyItemQty, setPulleyItemQty, dataModal())} */}
            </Container>
          </form>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

const Container = styled.div`
  ${tw`flex flex-col w-full`}
`;

const CardContainer = styled.div`
  ${tw`flex flex-row mt-6 space-x-6 w-5/6`}
`;

const SectionContainer = styled.div`
  ${tw`flex flex-col`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-col space-y-2 self-center mt-4`}
`;
export default BoxSashHardwareSetForm;
