import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert } from '../alerts';
import Grid from './Grid';
import SwitchInput from './Switch';
import { itemToInput } from './GridForm';

type Props = {
  gridItems: any;
  onSubmit: any;
  alerts?: any;
  formStyle?: any;
  switchOptions?: any;
  formId?: string;
  children?: JSX.Element[] | JSX.Element;
};

const ContextForm = ({
  gridItems,
  onSubmit,
  alerts,
  formStyle,
  switchOptions,
  formId,
  children,
}: Props) => {
  const { handleSubmit, errors, register, control } = useFormContext();

  const registerItemAlerts = (items) => {
    return (
      alerts &&
      items.map((item, i) => {
        if (!errors[item.name]) return null;
        let msg = errors[item.name].message;
        if (msg.length === 0) msg = `${item.label} is required`;
        return (
          <div className="flex flex-col w-full" key={String(item.name) + i}>
            {errors[item.name] && (
              <div className="mb-2" key={i}>
                <Alert color="bg-transparent border-red-500 text-red-500" borderLeft raised>
                  {msg}
                </Alert>
              </div>
            )}
          </div>
        );
      })
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form flex flex-col w-full"
      id={formId || null}
    >
      {gridItems.map((gridConfig) => registerItemAlerts(gridConfig.items))}
      {switchOptions && (
        <div className="w-full">
          <SwitchInput {...switchOptions} />
        </div>
      )}
      <div className={`w-full ${formStyle}`}>
        {gridItems.map((gridConfig, i) => (
          <Grid
            config={gridConfig.config}
            gridStyle={gridConfig.style}
            key={i}
            lastItem={gridItems.length === i + 1}
          >
            {gridConfig.items.map((item) => itemToInput(item, alerts, register, errors, control))}
          </Grid>
        ))}
      </div>
      {children && <div className="w-full flex flex-col">{children}</div>}
      {!formId && (
        <input
          type="submit"
          className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
        />
      )}
    </form>
  );
};

export default ContextForm;
