import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useSelector, shallowEqual } from 'react-redux';
import { Provider } from 'react-redux';

import { store, initStore } from '@appSrc/store';

import { Alert } from '@components/alerts';
import SocialMedia from '@components/login-1/social-media';
import Footer from '@components/login-2/footer';
import Logo from '@components/login-2/logo';
import Text from '@components/login-2/text';
// import { Link } from 'react-router-dom';
import Login from '@components/sample-forms/login';

const Page = () => {
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  let { name } = { ...config };
  return (
    <div className="w-full flex flex-row h-screen overflow-hidden">
      <div className="hidden lg:flex lg:flex-col w-1/2 text-white p-8 items-start justify-between relative bg-1">
        <Logo />
        <Text />
        <Footer />
      </div>
      <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
        <p className="text-2xl font-bold text-blue-500 mb-4">Login to {name}</p>
        <div className="w-full mb-4">
          {/* <Alert
            color="bg-blue-500 text-white"
            icon={<FiAlertCircle className="mr-2 stroke-current h-4 w-4" />}
          >
            This is an important alert. Check it out!
          </Alert> */}
        </div>
        <Login />
        {/* <SocialMedia /> */}
        {/* <div className="flex flex-row w-full">
          <span className="text-secondary mr-1">New user?</span>
          <span>
            <a className="link" href="/create-account">
              Create account here
            </a>
          </span>
        </div> */}
        <div className="w-full">
          <span>
            Did you forget your password? Contact us at{' '}
            <a href="mailto:support@siliconorigami.com">support@siliconorigami.com</a>
            {/* <a className="link" href="/forgot-password">
              Forgot password?
            </a> */}
          </span>
        </div>
      </div>
    </div>
  );
};

const LoginPage = () => {
  initStore({
    config: {
      name: 'Radiko',
      description: 'Platform for joinery professionals',
      url: 'siliconorigami.com',
      layout: 'layout-1',
      collapsed: false,
      rightSidebar: false,
      backdrop: false,
    },
  });
  return (
    <Provider store={store}>
      <Page />
    </Provider>
  );
};

export default LoginPage;
