import React from 'react';

const RadiusArc = ({ size = 4 }) => {
  return (
    <svg
      id="radius"
      enable-background="new 0 0 300 300"
      // height="512"
      viewBox="0 0 300 300"
      // width="512"
      className={`w-${size} h-${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m150.484 157.516c-1.562-1.562-4.094-1.562-5.656 0l-2.828 2.828-2.828-2.828c-1.562-1.562-4.094-1.562-5.656 0s-1.562 4.094 0 5.656l2.828 2.828-2.828 2.828c-1.562 1.562-1.562 4.094 0 5.656.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l2.828-2.828 2.828 2.828c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172c1.562-1.562 1.562-4.094 0-5.656l-2.828-2.828 2.828-2.828c1.563-1.563 1.563-4.094 0-5.656z" />
        <circle cx="202" cy="146" r="4" />
        <circle cx="250" cy="194" r="4" />
        <path d="m251.777 38h-27.555c-5.636 0-10.222 4.586-10.222 10.223v9.777h-128v-9.777c0-5.637-4.586-10.223-10.223-10.223h-27.554c-5.637 0-10.223 4.586-10.223 10.223v27.555c0 5.636 4.586 10.222 10.223 10.222h9.777v128h-9.777c-5.637 0-10.223 4.586-10.223 10.223v27.555c0 5.636 4.586 10.222 10.223 10.222h27.555c5.636 0 10.222-4.586 10.222-10.223v-9.777h120.568c1.789 6.882 7.999 12 15.432 12 8.822 0 16-7.176 16-16s-7.178-16-16-16c-7.433 0-13.643 5.118-15.432 12h-81.258c64.104-20.477 111.309-78.258 116.232-148h10.235c5.637 0 10.223-4.586 10.223-10.223v-27.554c0-5.637-4.586-10.223-10.223-10.223zm-29.777 192c4.412 0 8 3.59 8 8s-3.588 8-8 8-8-3.59-8-8 3.588-8 8-8zm-176-154.223v-27.554c0-1.227.998-2.223 2.223-2.223h27.555c1.224 0 2.222.996 2.222 2.223v27.555c0 1.226-.998 2.222-2.223 2.222h-27.554c-1.225 0-2.223-.996-2.223-2.223zm32 176c0 1.227-.998 2.223-2.223 2.223h-27.554c-1.225 0-2.223-.996-2.223-2.223v-27.555c0-1.227.998-2.223 2.223-2.223h27.555c1.225 0 2.223.996 2.223 2.223v27.555zm8-18.259v-9.295c0-5.637-4.586-10.223-10.223-10.223h-9.777v-128h9.777c5.637 0 10.223-4.586 10.223-10.223v-9.777h128v9.777c0 5.637 4.586 10.223 10.223 10.223h9.294c-5.848 78.939-68.591 141.669-147.517 147.518zm168-157.741c0 1.227-.998 2.223-2.223 2.223h-27.555c-1.225 0-2.223-.996-2.223-2.223v-27.554c0-1.227.998-2.223 2.223-2.223h27.555c1.225 0 2.223.996 2.223 2.223z" />
      </g>
    </svg>
  );
};

export default RadiusArc;
