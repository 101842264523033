import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosEye, IoIosEyeOff, IoMdCloseCircleOutline } from 'react-icons/io';

import { NumberInput } from '@components/forms/numberInput';
import DataModal from '@components/modals/DataModal';
import Card from './Card';

const Item = ({ item, isFirst = false, onRemove, ...props }) => {
  const [visible, setVisible] = useState(true);
  const [qty, setQty] = useState(1);

  const handleQtyChange = (event) => {
    setQty(event.target.value);
  };

  return (
    <Container className="flex flex-row space-x-2 w-full">
      <div onClick={() => setVisible(!visible)} className="self-center cursor-pointer">
        {visible ? <IoIosEye size={24} /> : <IoIosEyeOff size={24} />}
      </div>

      <Card {...item} defaultOpen={false} narrowStyle={true} />

      <QauntityContainer
        className={`form-element ${isFirst ? '-mt-8' : ''}`}
        style={{ marginBottom: 0 }}
      >
        {isFirst && <div className="form-label">Qty</div>}
        {/* @ts-ignore */}
        <NumberInput
          name={''}
          value={qty}
          onChange={handleQtyChange}
          min={0}
          step={0.1}
          className="form-textarea"
        />
      </QauntityContainer>
      <span className="self-center self-center ml-4">
        <IoMdCloseCircleOutline
          size={24}
          color={'red'}
          className="cursor-pointer"
          onClick={(_) => onRemove(item)}
        />
      </span>
    </Container>
  );
};

const ConfigItemsList = ({
  buttons = [],
  items = [],
  itemTransform = (i) => i,
  onRemove = (_) => {},
}) => {
  return (
    <Container className="flex flex-row space-x-12">
      <ItemsContainer className="flex flex-col w-5/6 space-y-6">
        {items.map((item, index) => (
          <Item
            item={itemTransform(item)}
            isFirst={index === 0}
            key={item.title}
            onRemove={onRemove}
          />
        ))}
      </ItemsContainer>

      <ButtonsContainer className="flex flex-col space-y-2 self-center">{buttons}</ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 50%;
`;
const ItemsContainer = styled.div``;
const ButtonsContainer = styled.div``;
const QauntityContainer = styled.div`
  max-width: 50px;
`;

export default ConfigItemsList;
