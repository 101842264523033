import API from 'apiv1';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GrAddCircle } from 'react-icons/gr';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';

import { setFormData } from '@appSrc/actions/configuratorActions';

import { itemToInput } from '@components/forms/GridForm';

import UUID from '@utils/uuid';

import Section from '../Section';

const handleSubmit = async (data) => {
  try {
    await API.createCasement(API.toFormData({ data: JSON.stringify({ data: data }) }));
    NotificationManager.success('Success');
  } catch (err) {
    NotificationManager.error(err.message);
  }
};

export const reMapVariants = (variants, sites = []) => {
  const remappedVariants = Object.assign({}, variants);
  Object.keys(remappedVariants).forEach((key) => {
    const siteOption = sites.find((option) => option.value === remappedVariants[key]['site']);
    remappedVariants[key] = {
      // @ts-ignore
      ...remappedVariants[key],
      site: siteOption,
    };
  });
  return remappedVariants;
};

export const handleVariants = (variants) => {
  return Object.entries(variants).map(([key, value]) => {
    return {
      // @ts-ignore
      ...value,
      site: value['site'].value,
    };
  });
};

type Props = {
  data?: any;
  availableSiteOptions: any;
};

const FormFields = ({ data, availableSiteOptions }: Props) => {
  const [variants, setVariants] = useState([UUID()]);
  const [sites, setSites] = useState(availableSiteOptions);
  const { reset, ...methods } = useFormContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = methods.watch();
    dispatch(setFormData('casementProductItemData', data));
  }, [methods.watch]);

  const handleAddVariant = () => {
    setVariants([...variants, UUID()]);
  };

  const handleRemoveVariant = (variantUUID) => {
    setVariants(variants.filter((variant) => variant !== variantUUID));
  };

  return (
    <div className="flex flex-col w-full">
      <form
        onSubmit={methods.handleSubmit((data) => {
          handleSubmit(data);
        })}
        id="casement-form"
      >
        <Section title={'Project Items'}>
          <div className={'flex flex-col space-y-6'} style={{ maxWidth: '992px' }}>
            {variants.map((variantUUID, index) => {
              const last = variants.length - 1 === index;
              const first = index === 0;
              return (
                <div className={`flex flex-row`} key={variantUUID}>
                  <div className="flex flex-row space-x-12">
                    {itemToInput(
                      {
                        label: 'Reference',
                        name: `product_items[${variantUUID}][reference]`,
                        type: 'text',
                        error: { required: 'Please provide a reference' },
                        defaultValue: '',
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                    {itemToInput(
                      {
                        label: 'Site',
                        name: `product_items[${variantUUID}][site]`,
                        type: 'combobox',
                        error: { required: 'Please select or enter a site' },
                        options: sites,
                        // defaultValue: '',
                        placeholder: '',
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                    {itemToInput(
                      {
                        label: 'Quantity',
                        name: `product_items[${variantUUID}][quantity]`,
                        type: 'text',
                        error: { required: 'Please enter a quantity' },
                        defaultValue: '',
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                  </div>
                  {!(first && last) && (
                    <span className="position-center self-center ml-4">
                      <IoMdCloseCircleOutline
                        size={24}
                        color={'red'}
                        style={{ marginTop: '0.5rem' }}
                        className="cursor-pointer"
                        onClick={(_) => handleRemoveVariant(variantUUID)}
                      />
                    </span>
                  )}
                  {last && (
                    <span className="position-center self-center ml-4">
                      <GrAddCircle
                        size={24}
                        color={'green'}
                        style={{ marginTop: '0.5rem' }}
                        className="cursor-pointer"
                        onClick={handleAddVariant}
                      />
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </Section>
      </form>
    </div>
  );
};

export default FormFields;
