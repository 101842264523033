export type Point = {
  x: number;
  y: number;
};

export type Size = {
  width: number;
  height: number;
};

export function pythagaros(distanceA: number, radius: number): number {
  return Math.sqrt(radius ** 2 - distanceA ** 2);
}

export function bezierCurveApproximatedArc(
  startPoint: Point,
  endPoint: Point,
  center: Point,
  radius: number
): Point[] {
  // ax = x1 - xc
  // ay = y1 - yc
  // bx = x4 - xc
  // by = y4 - yc
  // q1 = ax * ax + ay * ay
  // q2 = q1 + ax * bx + ay * by
  // k2 = (4/3) * (sqrt(2 * q1 * q2) - q2) / (ax * by - ay * bx)

  // x2 = xc + ax - k2 * ay
  // y2 = yc + ay + k2 * ax
  // x3 = xc + bx + k2 * by
  // y3 = yc + by - k2 * bx

  const ax = startPoint.x - center.x;
  const ay = startPoint.y - center.y;
  const bx = endPoint.x - center.x;
  const by = endPoint.y - center.y;
  const q1 = ax * ax + ay * ay;
  const q2 = q1 + ax * bx + ay * by;
  const k2 = ((4 / 3) * (Math.sqrt(2 * q1 * q2) - q2)) / (ax * by - ay * bx);

  const x2 = center.x + ax - k2 * ay;
  const y2 = center.y + ay + k2 * ax;
  const x3 = center.x + bx + k2 * by;
  const y3 = center.y + by - k2 * bx;

  return [startPoint, { x: x2, y: y2 }, { x: x3, y: y3 }, endPoint];
}

export function radiusFromSagittaAndChord(sagitta: number, chord: number): number {
  const l = chord / 2;
  return (sagitta ** 2 + l ** 2) / (2 * sagitta);
}

export function sagittaFromRadiusAndChord(radius: number, chord: number): number {
  const l = chord / 2;
  return radius + Math.sqrt(radius ** 2 - l ** 2);
}

export function bezierArcPoints(
  start: Point,
  end: Point,
  height: number,
  center: Point,
  radius: number,
  clockwise = true
): Point[][] {
  const midPoint = { x: end.x / 2, y: height };

  // Part 1
  const arcPoints = bezierCurveApproximatedArc(start, midPoint, center, radius);
  // Part 2
  const arcPoints2 = bezierCurveApproximatedArc(midPoint, end, center, radius);

  const transformArcPoints = [];
  const transformArcPoints2 = [];

  if (clockwise) {
    arcPoints.slice(1).map(({ x, y }) => transformArcPoints.push(Math.abs(x), y));
    arcPoints2.slice(1).map(({ x, y }) => transformArcPoints2.push(Math.abs(x), y));
  } else {
    arcPoints
      .reverse()
      .slice(1)
      .map(({ x, y }) => transformArcPoints2.push(x, y));
    arcPoints2
      .reverse()
      .slice(1)
      .map(({ x, y }) => transformArcPoints.push(x, y));
  }

  return [transformArcPoints, transformArcPoints2];
}
