import React, { useEffect } from 'react';
import { Bars } from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector, shallowEqual } from 'react-redux';

const DefaultLoader = () => {
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  const { loading } = { ...config };

  if (!loading) return <></>;

  return (
    <div className="fixed w-full h-screen bg-orange-M100 bg-opacity-25 cursor-wait flex justify-center items-center">
      <Bars
        color="#58244b"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </div>
  );
};

export default DefaultLoader;
