import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';

import BoxedLayout from '@appSrc/layouts/content/Boxed';
import { store } from '@appSrc/store';

import BoxSash from '@components/configurators/BoxSash';
import Widget from '@components/widget';

const Page = ({
  materials,
  rfq,
  projectId,
  boxSashConfig = null,
  sites = [],
  recordId = null,
  mode = 'edit',
  redirectToUrl,
  productItemId,
}) => {
  const [data, setData] = useState({});

  return (
    <>
      <Widget
        title="New Product Item"
        breadcrumbs={[{ title: 'Projects' }, { title: rfq.name, link: `/projects/${projectId}` }]}
      >
        <BoxSash
          materials={materials}
          boxSashConfig={boxSashConfig}
          recordId={recordId}
          availableSiteOptions={sites.map((site) => ({ label: site, value: site }))}
          mode={mode}
          rfqID={rfq.id}
          productItemID={productItemId}
          context="productItems"
          redirectToURL={redirectToUrl}
        />
      </Widget>
    </>
  );
};

const EditProductItemsPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default EditProductItemsPage;
