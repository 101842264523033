import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';

import '../../css/react-notifications.css';
import { Alert } from '../alerts';
import ContextForm from './ContextForm';

const MODE_HANDLER = {
  edit: API.updateSupplier,
  default: API.createSupplier,
};

const SupplierForm = ({ message = null, formId, recordId = null, mode = 'default' }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm();

  useEffect(() => {
    if (recordId == null) return;
    API.getSupplier(recordId).then((data) => {
      reset({ ...data });
    });
  }, [reset, recordId]);

  const onSubmitFn = (data) => {
    console.debug('Data', data);

    MODE_HANDLER[mode || 'default'](API.toFormData({ ...data }), recordId)
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const glassSupplier = methods.watch('glass_supplier');

  const glassSupplierFields = [
    {
      label: 'Price per bar m2',
      error: {},
      name: 'price_per_bar',
      type: 'currency',
      defaultValue: '',
    },
    {
      label: 'Price for bars Grid m2',
      error: {},
      name: 'price_per_crossover',
      type: 'currency',
      defaultValue: '',
    },
    {
      label: 'Mimumum glass order value m2',
      error: { required: 'Please enter a minimum order value in m2' },
      name: 'minimum_order_value',
      type: 'number',
      defaultValue: '',
    },
  ];

  let items = [
    {
      config: ['full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Supplier Name',
          error: { required: 'Please enter a supplier name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
        {
          label: '',
          error: {},
          name: 'glass_supplier',
          type: 'checkbox',
          options: [{ label: 'Glass Supplier', value: 'true' }],
          defaultValue: '',
        },
      ],
    },
  ];

  if (glassSupplier) {
    items[0].config = ['full', 'full', 'full', 'full', 'full'];
    // @ts-ignore
    items[0].items.push(...glassSupplierFields);
  }

  return (
    <div className="flex flex-row" style={{ minWidth: 223 }}>
      {data && message && (
        <div className="w-full mb-4">
          <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
            {message}
          </Alert>
        </div>
      )}
      <FormProvider {...methods} reset={reset}>
        <ContextForm
          formId={formId}
          gridItems={items}
          onSubmit={(data) => {
            onSubmit(data);
            onSubmitFn(data);
          }}
          formStyle="flex flex-row"
        />
      </FormProvider>
    </div>
  );
};

export default SupplierForm;
