import React from 'react';
import { Line, Shape } from 'react-konva';

import { bezierArcPoints } from '@utils/geometry';

const ArchedTopMoulding = ({ section, _upperY, lowerY, radius, center }) => {
  const lowerArcRadius = radius - section.store.sash_moulding.width;

  const [lowerArcPointsP1, lowerArcPointsP2] = bezierArcPoints(
    { x: section.store['stiles'].width, y: lowerY },
    { x: section.size.width - section.store['stiles'].width, y: lowerY },
    section.size.height - section.store['top_rail'].width,
    center,
    lowerArcRadius
  );

  const mouldingLineProps = {
    closed: false,
    stroke: 'black',
    strokeWidth: 4,
    // fill: 'none',
    opacity: 1,
  };

  return (
    <Shape
      {...mouldingLineProps}
      {...section.size}
      sceneFunc={(ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(section.store['stiles'].width, lowerY);
        // @ts-ignore
        ctx.bezierCurveTo(...lowerArcPointsP1);
        // @ts-ignore
        ctx.bezierCurveTo(...lowerArcPointsP2);
        // Konva specific method
        ctx.fillStrokeShape(shape);
      }}
    />
  );
};

const SashMoulding = ({ section, topMoulding = {} }) => {
  const { internalSize, internalOrigin } = section;
  const mouldingWidth = section.store.sash_moulding.width;
  const mouldingHorizontalLength = internalSize.width + mouldingWidth * 2;
  const mouldingInnerHorizontalLength = internalSize.width;
  const mouldingInnerVerticalLength =
    (topMoulding['lowerY'] &&
      topMoulding['lowerY'] - section.store.bottom_rail.width + mouldingWidth) ||
    internalSize.height + mouldingWidth;
  const mouldingVerticalLength =
    (topMoulding['upperY'] &&
      topMoulding['upperY'] - section.store.bottom_rail.width + mouldingWidth) ||
    internalSize.height + mouldingWidth * 2;
  const mouldingOrigin = {
    x: internalOrigin.x - mouldingWidth,
    y: internalOrigin.y - mouldingWidth,
  };
  const rightMouldingOrigin = {
    x: internalOrigin.x + internalSize.width,
    y: internalOrigin.y - mouldingWidth,
  };
  const topMouldingOrigin = {
    x: internalOrigin.x - mouldingWidth,
    y: internalOrigin.y + internalSize.height,
  };

  const mouldingLineProps = {
    stroke: 'black',
    strokeWidth: 4,
    fill: 'white',
    opacity: 1,
    closed: true,
  };

  return (
    <>
      {/* Left moulding */}
      <Line
        {...mouldingLineProps}
        {...mouldingOrigin}
        points={[
          0,
          0,
          mouldingWidth,
          mouldingWidth,
          mouldingWidth,
          mouldingInnerVerticalLength,
          0,
          mouldingVerticalLength,
          0,
          0,
        ]}
      />
      {/* Right moulding */}
      <Line
        {...mouldingLineProps}
        {...rightMouldingOrigin}
        points={[
          0,
          mouldingWidth,
          0,
          mouldingInnerVerticalLength,
          mouldingWidth,
          mouldingVerticalLength,
          mouldingWidth,
          0,
          0,
          mouldingWidth,
        ]}
      />
      {/* Bottom moulding */}
      <Line
        {...mouldingLineProps}
        {...mouldingOrigin}
        points={[
          0,
          0,
          mouldingWidth,
          mouldingWidth,
          mouldingInnerHorizontalLength + mouldingWidth,
          mouldingWidth,
          mouldingHorizontalLength,
          0,
          0,
          0,
        ]}
      />
      {/* Top moulding */}
      {!section.isHeadNeighbour || section.store.headStyle === 'standard' ? (
        <Line
          {...mouldingLineProps}
          {...topMouldingOrigin}
          points={[
            mouldingWidth,
            0,
            mouldingWidth + mouldingInnerHorizontalLength,
            0,
            mouldingHorizontalLength,
            mouldingWidth,
            0,
            mouldingWidth,
            mouldingWidth,
            0,
          ]}
        />
      ) : (
        // @ts-ignore
        <ArchedTopMoulding section={section} {...topMoulding} />
      )}
    </>
  );
};

export default SashMoulding;
