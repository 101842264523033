export const METRIC_SIZE = 80;
export const BASE_FONTSIZE = 38; // In pixels
export const DIMENSION_LINE_DISTANCE = 50;
export const EXTENSION_LINE_LENGTH = 60;

export const LEVELS = {
  1: EXTENSION_LINE_LENGTH * 1,
  2: EXTENSION_LINE_LENGTH * 2,
  3: EXTENSION_LINE_LENGTH * 3,
  4: EXTENSION_LINE_LENGTH * 4,
  5: EXTENSION_LINE_LENGTH * 5,
  6: EXTENSION_LINE_LENGTH * 6,
  7: EXTENSION_LINE_LENGTH * 7,
};

export function round(number, precision = 2) {
  const factor = Math.pow(10, precision);
  return Math.round((number + Number.EPSILON) * factor) / factor;
}

export function calculateLevelLength(level) {
  return LEVELS[level] + EXTENSION_LINE_LENGTH + 20;
}

export function calculateFontSize(canvasScale) {
  return BASE_FONTSIZE * (1 + canvasScale);
}

export function isSmallDimension(dimension) {
  return String(dimension).split('.')[0].length <= 2;
}

export function getTextWidth(text, scaleFactor = 1, letterSpacing = 2) {
  const specialCharCount = String(text).match(/[\.]/g) ? String(text).match(/[\.]/g).length : 0;
  return (
    ((String(text).length - specialCharCount + specialCharCount / 2) / 2) *
    (BASE_FONTSIZE * scaleFactor + letterSpacing)
  );
}
