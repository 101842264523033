import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import ButtonIcon from '@components/buttons/ButtonIcon';
import ButtonLink from '@components/buttons/ButtonLink';
import Datatable, { TruncatedCell } from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import BOMItemForm from '@components/forms/BOMItemForm';
import SimpleTooltip from '@components/tooltips/SimpleTooltip';
import Widget from '@components/widget';

import { store } from '../store';

const mockData = {
  // 'Ironmongery Direct': [
  //   {
  //     reference_number: 1,
  //     name: 'Sample 1',
  //     product_code: 'SDHYGub13-SDA',
  //     quantity: 2,
  //     cost_per_item: '£12',
  //     total_cost: '£24',
  //   },
  // ],
};

const Page = ({ billOfMaterials }) => {
  const columns = [
    {
      Header: 'Ref No.',
      accessor: 'reference_number',
    },
    {
      Header: 'Product name',
      accessor: 'name',
      Cell: ({ value, ...props }) => {
        if (!props.row.original['note'] || props.row.original['note'] === '')
          return <span>{value}</span>;

        return (
          <SimpleTooltip
            usePortal={true}
            hideArrow={false}
            portalContainer={document.getElementById('portal-container')}
            trigger="hover"
            placement="top"
            tooltip={<span>{props.row.original['note']}</span>}
          >
            <span className="">*{value}</span>
          </SimpleTooltip>
        );
      },
    },
    {
      Header: 'Product code',
      accessor: 'product_code',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      Cell: ({ value, ...props }) => {
        return (
          <div className="flex flex-row">
            <span className="justify-center self-center mr-2">{value}</span>
            {RecordActions(props.row.original['id'], BOMItemForm, null)}
          </div>
        );
      },
    },
    {
      Header: 'Unit',
      accessor: 'unit',
    },
    {
      Header: (_props) => <div className="text-right w-full">Cost per unit</div>,
      accessor: 'cost_per_item',
      Cell: ({ value }) => <div className="text-right w-full">{value}</div>,
    },
    {
      Header: (_props) => <div className="text-right w-full">Total cost</div>,
      accessor: 'total_cost',
      Cell: ({ value }) => <div className="text-right w-full">{value}</div>,
    },
  ];

  function addUrlParam(category) {
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('category', category);
    return currentUrl.toString();
  }

  return (
    <Widget
      title="Bill of Materials"
      breadcrumbs={[
        { title: 'Projects' },
        {
          title: billOfMaterials['project_name'],
          link: `/projects/${billOfMaterials['project_id']}`,
        },
        {
          title: 'Bill Of Materials',
          current: true,
        },
      ]}
    >
      <div className="flex flex-col w-full">
        <div className="self-center py-6">
          <h5>{billOfMaterials['project_name']}</h5>
        </div>
        <div className="flex flex-col lg:flex-row lg:flex-wrap items-start lg:items-center content-between justify-center md:space-x-2 xxl:space-x-4 my-6">
          <div className="flex flex-col">
            <ButtonLink
              buttonStyle="light-primary"
              css={css(tw`mb-2`)}
              href={addUrlParam('hardware')}
            >
              Hardware & Ironmongery
            </ButtonLink>
            <span className="text-center w-full mt-2">
              {billOfMaterials['item_categories']['hardware']}
            </span>
          </div>

          <div className="flex flex-col">
            <ButtonLink
              buttonStyle="light-primary"
              css={css(tw`mb-2`)}
              href={addUrlParam('glazing')}
            >
              Glass & Glazing
            </ButtonLink>
            <span className="text-center w-full mt-2">
              {billOfMaterials['item_categories']['glazing']}
            </span>
          </div>

          <div className="flex flex-col">
            <ButtonLink
              buttonStyle="light-primary"
              css={css(tw`mb-2`)}
              href={addUrlParam('finish')}
            >
              Finish
            </ButtonLink>
            <span className="text-center w-full mt-2">
              {billOfMaterials['item_categories']['finish']}
            </span>
          </div>

          <div className="flex flex-col">
            <ButtonLink
              buttonStyle="light-primary"
              css={css(tw`mb-2`)}
              href={addUrlParam('timber')}
            >
              Timber
            </ButtonLink>
            <span className="text-center w-full mt-2">
              {billOfMaterials['item_categories']['timber']}
            </span>
          </div>

          <div className="flex flex-col">
            <ButtonLink
              buttonStyle="light-primary"
              css={css(tw`mb-2`)}
              href={addUrlParam('moulding')}
            >
              Moulding
            </ButtonLink>
            <span className="text-center w-full mt-2">
              {billOfMaterials['item_categories']['moulding']}
            </span>
          </div>

          <div className="flex flex-col">
            <ButtonLink
              buttonStyle="light-primary"
              css={css(tw`mb-2`)}
              href={addUrlParam('miscellenous')}
            >
              Miscellenous
            </ButtonLink>
            <span className="text-center w-full mt-2">
              {billOfMaterials['item_categories']['miscellenous']}
            </span>
          </div>
        </div>
        <div className="self-center mb-6">
          <strong>Total: {billOfMaterials['item_categories']['total']}</strong>
        </div>

        {Object.entries(
          billOfMaterials['bom_items'].length === 0 ? mockData : billOfMaterials['bom_items']
        ).map(([key, value]) => (
          <SectionContainer key={key}>
            <div className="mb-4 font-semibold text-lg">{key}</div>
            {/* @ts-ignore */}
            <Datatable columns={columns} data={value} />
          </SectionContainer>
        ))}
      </div>
    </Widget>
  );
};

const SectionContainer = styled.div``;

const BillOfMaterialsPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default BillOfMaterialsPage;
