import React, { useState } from 'react';
import Fuse from 'fuse.js';

import DataModal from '@components/modals/DataModal';

const MATERIAL_TABLE_CONFIG = {
  timberType: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Density',
        accessor: 'density',
      },
      {
        title: 'Type',
        accessor: 'type',
      },
    ],
    searchFields: ['name'],
  },
};

const MaterialSelector = ({
  items = [],
  typeOfMaterial,
  onSearch = (_) => {},
  onItemSelect = (_) => {},
  ...dataModalProps
}) => {
  const [query, setQuery] = useState(null);
  const materialConfig = MATERIAL_TABLE_CONFIG[typeOfMaterial];
  const options = {
    includeScore: true,
    keys: materialConfig.searchFields,
  };

  const fuse = new Fuse(items, options);

  const handleSearch = (q) => {
    onSearch(q);
    return fuse.search(q);
  };

  const handleItemSelection = (item) => {
    onItemSelect(item);
  };

  return (
    // @ts-ignore
    <DataModal
      {...dataModalProps}
      columns={materialConfig.columns}
      data={query ? handleSearch(query).map((r) => r.item) : items}
      handleSearch={setQuery}
      onSelect={handleItemSelection}
    />
  );
};

export default MaterialSelector;
