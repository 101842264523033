import React from 'react';
import { useFormContext } from 'react-hook-form';

import { itemToInput } from '@components/forms/GridForm';

import Section, { InputContainer } from '../Section';

const ProductInformation = ({ recordId, imageFile }) => {
  const methods = useFormContext();

  return (
    <Section title={'Product information'}>
      <InputContainer className="flex flex-row space-x-12">
        {/* <InputContainer className="flex">
          {itemToInput(
            {
              label: 'Image',
              name: 'product_image',
              type: 'image',
              recordId,
              defaultValue: '',
              files: imageFile,
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer> */}
        <InputContainer className="flex flex-col">
          {itemToInput(
            {
              label: 'Name',
              name: 'name',
              type: 'text',
              defaultValue: '',
              disabled: false,
              placeholder: '',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer>
        <InputContainer className="flex flex-col">
          {itemToInput(
            {
              label: 'Loading type',
              name: 'spring',
              type: 'newSelect',
              options: [
                { label: 'Weights & Cords', value: 'false' },
                { label: 'Springs', value: 'true' },
              ],
              defaultValue: { label: 'Weights & Cords', value: 'false' },
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
          {itemToInput(
            {
              label: 'Type of Sash',
              name: 'type_of_sash',
              type: 'newSelect',
              error: { required: 'Please select type of sash' },
              options: [
                { label: 'Single', value: 'single' },
                { label: 'Sashes only', value: 'sash_only' },
                { label: 'Double', value: 'double' },
                // { label: 'Triple', value: 'triple' },
              ],
              defaultValue: { label: 'Single', value: 'single' },
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer>
        <InputContainer className="flex flex-col">
          {itemToInput(
            {
              label: 'Part of bay',
              name: 'part_of_bay',
              type: 'checkbox',
              options: [{ label: 'Part of bay', value: 'true' }],
              defaultValue: '',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default ProductInformation;
