import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { setTimber } from '@appSrc/actions/configuratorActions';

import { itemToInput } from '@components/forms/GridForm';
import SwitchInput from '@components/forms/Switch';

import Card from '../Card';
import MaterialSelector from '../MaterialSelector';
import Section, { InputContainer } from '../Section';

export const FRAME_TIMBER_KEY = 'frame_timber';

const Frame = ({
  recordId,
  isFabrication = false,
  isTriple = false,
  isDouble,
  timberItems = [],
}) => {
  const [fabricationSizes, setFabricationSizes] = useState(isFabrication);
  const methods = useFormContext();
  const timber = useSelector((state) => {
    // @ts-ignore
    return state.configuratorForms.timber[FRAME_TIMBER_KEY];
  });
  const dispatch = useDispatch();

  const handleTimberSelection = (item) => {
    dispatch(setTimber(FRAME_TIMBER_KEY, item));
  };

  const archType = methods.watch('frame[arch_rise_type]');

  if (archType && archType !== 'none') {
    if (fabricationSizes) setFabricationSizes(false);
  }

  const sashStileThickness = methods.watch('sash[stiles][thickness]');
  const sillWidth = methods.watch('sill[width]');
  const outerLiningThickness = methods.watch('frame[outer_lining][vertical][thickness]');

  let staffBeadWidth = 0;
  if (sashStileThickness && sillWidth && outerLiningThickness) {
    staffBeadWidth = sillWidth - outerLiningThickness - sashStileThickness * 2 - 16;
  }

  const renderSizes = () => {
    const target = fabricationSizes
      ? { label: 'Fabrication', inputName: 'fabrication' }
      : { label: 'Brick Opening', inputName: 'brick_opening' };
    return (
      <>
        <span className="pl-10">{target.label}</span>
        <InputContainer className="flex flex-row -space-x-16">
          {itemToInput(
            {
              label: 'Width',
              name: `frame[${target.inputName}][width]`,
              type: 'number',
              defaultValue: '',
              inputClass: 'w-2/4',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
          {itemToInput(
            {
              label: 'Height',
              name: `frame[${target.inputName}][height]`,
              type: 'number',
              defaultValue: '',
              inputClass: 'w-2/4',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer>
        {itemToInput(
          {
            label: 'Arch type',
            name: 'frame[arch_rise_type]',
            type: 'radio',
            options: [
              { label: 'No arch', value: 'none', checked: true },
              { label: 'Fully arched head', value: 'fullyArched' },
              { label: 'Visible only head arch', value: 'visibleArch' },
            ],
            defaultValue: '',
            orientation: 'vertical',
            inputClass: 'w-2/4',
          },
          null,
          methods.register,
          methods.errors,
          methods.control
        )}
        {archType &&
          archType !== 'none' && [
            itemToInput(
              {
                label: 'Arch rise (if any)',
                name: 'frame[arch_rise]',
                type: 'number',
                step: 1,
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            ),
            // itemToInput(
            //   {
            //     label: 'Brick Opening Width',
            //     name: 'frame[brick_opening][width]',
            //     type: 'number',
            //     step: 1,
            //     defaultValue: '',
            //     inputClass: 'w-2/4',
            //   },
            //   null,
            //   methods.register,
            //   methods.errors,
            //   methods.control
            // ),
          ]}
      </>
    );
  };

  return (
    <Section title={'Frame'}>
      <InputContainer className="flex flex-row space-x-12 w-full">
        <InputContainer className="flex flex-col space-y-12">
          <InputContainer className="flex flex-row space-x-12">
            <InputContainer className="flex flex-col space-y-2">
              <SwitchInput
                initialState={fabricationSizes}
                leftLabel="Fabrication Size"
                onChange={setFabricationSizes}
              />
              {renderSizes()}
            </InputContainer>
          </InputContainer>

          <InputContainer className="flex flex-row">
            <InputContainer className="flex flex-col space-y-2">
              <span className="pl-16">Jamb</span>
              <InputContainer className="flex flex-row -space-x-16">
                {itemToInput(
                  {
                    label: 'Thickness',
                    name: 'frame[jamb][thickness]',
                    type: 'number',
                    defaultValue: '',
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Width',
                    name: 'frame[jamb][width]',
                    type: 'number',
                    defaultValue: '',
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </InputContainer>
            </InputContainer>
            <InputContainer className="flex flex-col space-y-2">
              <span className="pl-16">Head</span>
              <InputContainer className="flex flex-row -space-x-16">
                {itemToInput(
                  {
                    label: 'Thickness',
                    name: 'frame[head][thickness]',
                    type: 'number',
                    defaultValue: '',
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Width',
                    name: 'frame[head][width]',
                    type: 'number',
                    defaultValue: '',
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </InputContainer>
            </InputContainer>
          </InputContainer>

          <InputContainer className="flex flex-row space-x-20">
            <InputContainer className="flex flex-col space-y-2">
              <span className="">Outer lining</span>
              <InputContainer className="flex flex-row -space-x-16">
                <table>
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-solid border-b border-r"></th>
                      <th
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ width: '100px' }}
                      >
                        Thickness
                      </th>
                      <th
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        Width
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-4 border-solid border-b border-r">Vertical</td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ width: '100px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[outer_lining][vertical][thickness]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[outer_lining][vertical][width]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-solid border-b border-r">Head</td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ width: '100px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[outer_lining][head][thickness]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[outer_lining][head][width]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </InputContainer>
            </InputContainer>

            {(isTriple || isDouble) && (
              <InputContainer className="flex flex-col space-y-2">
                <span className="">Mullion Outside lining</span>
                <InputContainer className="flex flex-row -space-x-16">
                  <table>
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-solid border-b border-r"></th>
                        <th
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ width: '100px' }}
                        >
                          Thickness
                        </th>
                        <th
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          Width
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-4 border-solid border-b border-r">Vertical</td>
                        <td
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ width: '100px' }}
                        >
                          {itemToInput(
                            {
                              label: null,
                              name: 'frame[mullion_out][thickness]',
                              error: { required: 'Please enter outside mullion thickness' },
                              type: 'number',
                              defaultValue: '',
                              inputClass: 'w-full',
                              wrapperStyles: {
                                marginBottom: 0,
                              },
                            },
                            null,
                            methods.register,
                            methods.errors,
                            methods.control
                          )}
                        </td>
                        <td
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          {itemToInput(
                            {
                              label: null,
                              name: 'frame[mullion_out][width]',
                              error: { required: 'Please enter outside mullion width' },
                              type: 'number',
                              defaultValue: '',
                              inputClass: 'w-full',
                              wrapperStyles: {
                                marginBottom: 0,
                              },
                            },
                            null,
                            methods.register,
                            methods.errors,
                            methods.control
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </InputContainer>
              </InputContainer>
            )}

            {(isTriple || isDouble) && (
              <InputContainer className="flex flex-col space-y-2">
                <span className="">Mullion Inside lining</span>
                <InputContainer className="flex flex-row -space-x-16">
                  <table>
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-solid border-b border-r"></th>
                        <th
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ width: '100px' }}
                        >
                          Thickness
                        </th>
                        <th
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          Width
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-4 border-solid border-b border-r">Vertical</td>
                        <td
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ width: '100px' }}
                        >
                          {itemToInput(
                            {
                              label: null,
                              name: 'frame[mullion_in][thickness]',
                              error: { required: 'Please enter inside mullion thickness' },
                              type: 'number',
                              defaultValue: '',
                              inputClass: 'w-full',
                              wrapperStyles: {
                                marginBottom: 0,
                              },
                            },
                            null,
                            methods.register,
                            methods.errors,
                            methods.control
                          )}
                        </td>
                        <td
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          {itemToInput(
                            {
                              label: null,
                              name: 'frame[mullion_in][width]',
                              error: { required: 'Please enter inside mullion width' },
                              type: 'number',
                              defaultValue: '',
                              inputClass: 'w-full',
                              wrapperStyles: {
                                marginBottom: 0,
                              },
                            },
                            null,
                            methods.register,
                            methods.errors,
                            methods.control
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </InputContainer>
              </InputContainer>
            )}

            <InputContainer className="flex flex-col space-y-2">
              <span className="">Inner lining</span>
              <InputContainer className="flex flex-row">
                <table>
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-solid border-b border-r"></th>
                      <th
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ width: '100px' }}
                      >
                        Thickness
                      </th>
                      <th
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        Width
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-4 border-solid border-b border-r">Vertical</td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ width: '100px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[inner_lining][vertical][thickness]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[inner_lining][vertical][width]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-solid border-b border-r">Head</td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ width: '100px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[inner_lining][head][thickness]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[inner_lining][head][width]',
                            type: 'number',
                            defaultValue: '',
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </InputContainer>
            </InputContainer>

            {!fabricationSizes && (
              <InputContainer className="flex flex-col space-y-2">
                <span className="">Visible Lining</span>
                <InputContainer className="flex flex-row">
                  <table>
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-solid border-b border-r"></th>
                        <th
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          Width
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-4 border-solid border-b border-r">Vertical</td>
                        <td
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          {itemToInput(
                            {
                              label: null,
                              name: 'frame[visible_lining][vertical][width]',
                              type: 'number',
                              defaultValue: 15,
                              inputClass: 'w-full',
                              wrapperStyles: {
                                marginBottom: 0,
                              },
                            },
                            null,
                            methods.register,
                            methods.errors,
                            methods.control
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-4 border-solid border-b border-r">Head</td>
                        <td
                          className="py-2 px-4 border-solid border-b border-r"
                          style={{ minWidth: '100px', maxWidth: '110px' }}
                        >
                          {itemToInput(
                            {
                              label: null,
                              name: 'frame[visible_lining][head][width]',
                              type: 'number',
                              defaultValue: 15,
                              inputClass: 'w-full',
                              wrapperStyles: {
                                marginBottom: 0,
                              },
                            },
                            null,
                            methods.register,
                            methods.errors,
                            methods.control
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </InputContainer>
              </InputContainer>
            )}
          </InputContainer>
          <InputContainer>
            <InputContainer className="flex flex-col space-y-2">
              <span className="">Beads</span>
              <InputContainer className="flex flex-row">
                <table>
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-solid border-b border-r"></th>
                      <th
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        Thickness
                      </th>
                      <th
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        Width
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-4 border-solid border-b border-r">Parting Bead</td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[parting_bead][thickness]',
                            type: 'number',
                            defaultValue: 8,
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[parting_bead][width]',
                            type: 'number',
                            defaultValue: 26,
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-solid border-b border-r">Staff Bead</td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[staff_bead][thickness]',
                            type: 'number',
                            defaultValue: 15,
                            inputClass: 'w-full',
                            wrapperStyles: {
                              marginBottom: 0,
                            },
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                      <td
                        className="py-2 px-4 border-solid border-b border-r"
                        style={{ minWidth: '100px', maxWidth: '110px' }}
                      >
                        {itemToInput(
                          {
                            label: null,
                            name: 'frame[staff_bead][width]',
                            type: 'number',
                            static: true,
                            value: staffBeadWidth,
                          },
                          null,
                          methods.register,
                          methods.errors,
                          methods.control
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </InputContainer>
            </InputContainer>
          </InputContainer>
          <InputContainer className="flex space-x-4" style={{}}>
            <div className="self-center " style={{ minWidth: 215 }}>
              {timber ? (
                <Card
                  narrowStyle
                  defaultOpen={false}
                  title={timber['name']}
                  attributes={[{ label: 'Type', value: timber['type'] }]}
                />
              ) : (
                <Card defaultOpen={false} blank={true} narrowStyle />
              )}
            </div>
            <div className="self-center w-full">
              {/* @ts-ignore */}
              <MaterialSelector
                modalButtonText="Select Sash Timber"
                title="Sash Timber"
                items={timberItems}
                onItemSelect={handleTimberSelection}
                typeOfMaterial={'timberType'}
              />
            </div>
          </InputContainer>
          {timber && (
            <InputContainer className="flex flex-row space-x-2 w-full">
              {itemToInput(
                {
                  label: 'Frame timber composition',
                  name: 'frame[timber_composition]',
                  error: { required: 'Please select timber composition for frame' },
                  orientation: 'vertical',
                  type: 'radio',
                  options: [
                    { label: 'Solid', value: 'solid' },
                    { label: 'Engineered', value: 'engineered' },
                  ],
                  defaultValue: '',
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </InputContainer>
          )}
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default Frame;
