import React from 'react';
import { GrAddCircle } from 'react-icons/gr';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import uuid from '@utils/uuid';

const addItemIds = (items: any[] | null): Array<any | null> => {
  return (items || []).map((item) => ({ ...item, itemID: uuid() }));
};

const DynamicList = ({
  items,
  ItemComponent,
  onChange,
  uniqueKey = 'itemID',
  onItemRemove = (itemID) => {},
}) => {
  function onAdd() {
    onChange([...items, { [uniqueKey]: uuid() }]);
  }

  function onDelete(itemID) {
    onChange(items.filter((item) => item[uniqueKey] !== itemID));
    onItemRemove(itemID);
  }

  return (
    <div className="flex flex-col w-full min-w-full">
      {items.map((item, index) => (
        <div className="flex flex-row items-center min-w-full mb-2" key={item[uniqueKey]}>
          <ItemComponent item={item} position={index} />
          <span className="inline-flex self-center pl-2">
            <IoMdCloseCircleOutline
              size={24}
              color={'red'}
              className="cursor-pointer"
              onClick={(_) => onDelete(item[uniqueKey])}
            />
          </span>
        </div>
      ))}
      <span className="inline-flex self-center pl-2 mr-auto w-full">
        <GrAddCircle size={24} color={'green'} className="cursor-pointer" onClick={onAdd} />
      </span>
    </div>
  );
};

export { addItemIds };
export default DynamicList;
