import API from 'apiv1';
import { observer } from 'mobx-react';
import { isAlive, onSnapshot } from 'mobx-state-tree';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';
import Button from '@appSrc/components/buttons/ButtonLink';
import { hardwareSetToFormParams } from '@appSrc/stores/sectionUtilities';

import DataModal from '@components/DataModal';
import Card from '@components/configurators/Card';
import CheckBox from '@components/forms/controls/CheckBox';

import { round } from '@utils/dimensionHelpers';

const SectionEditor = ({ section, selected = false, dispatch, editorState, productData }) => {
  if (!isAlive(section)) return null;

  const materialCard = (material) => {
    if (material) {
      return <Card defaultOpen={false} title={material['product_name']} narrowStyle={true} />;
    }

    return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
  };

  return (
    <div
      className={`p-6 border border-l-8 rounded-metronic ${
        selected ? 'border-deep-purple-M100' : 'border-blue-M500'
      }`}
    >
      {process.env.NODE_ENV === 'development' && (
        <>
          ID: {section.id}
          <br />
          Type: {section.sectionType}
          <br />
          isHeadNeighbour: {String(section.isHeadNeighbour)}
          <br />
          hasBars: {String(section.hasBars)}
          <br />
          isOnTopOfTransom: {String(section.isOnTopOfTransom)}
          <br />
          usingStickOnBars: {String(section.usingStickOnBars)}
          <br />
          {!section.usingStickOnBars && (
            <div>
              panes:
              <pre>{JSON.stringify(section.trueBarGlassPanes, null, 2)}</pre>
              <br />
            </div>
          )}
          dimensions:
          <pre>{JSON.stringify(section.dimensions, null, 2)}</pre>
          <br />
          sashGaps:
          <pre>{JSON.stringify(section.sash_gaps, null, 2)}</pre>
          <br />
          {/* barOrigins:
          <pre>{JSON.stringify(section.barOrigins(), null, 2)}</pre> */}
          <br />
          <div>
            relativePosition: <pre>{JSON.stringify(section.relativePosition)}</pre>
            <br />
          </div>
        </>
      )}
      <br />
      {['transom', 'mullion'].includes(section.sectionType) && (
        <p>
          <b>Surface area:</b> {Math.round(section.surfaceArea * 1000) / 1000} m<sup>2</sup>
        </p>
      )}
      {['transom'].includes(section.sectionType) && (
        <CheckBox
          name={`sectionEditors.${section.id}.transomRainDrip`}
          label=""
          wrapperClass="mt-2"
          orientation="vertical"
          controlled={true}
          onChange={(value, e) => {
            if (value === 'true') {
              section.setHasRainDrip(e.target.checked);
            } else {
              section.setHasRainDrip(!e.target.checked);
            }
          }}
          options={[
            {
              label: 'Rain drip',
              value: 'true',
              checked: section.hasRainDrip,
            },
          ]}
        />
      )}
      {['glazing'].includes(section.sectionType) && (
        <p>
          <b>Glass area:</b> {Math.round(section.glassArea * 1000) / 1000} m<sup>2</sup>
          <br />
          <b>Glass size:</b> {round(section.glassSize[0]?.width)}x
          {round(section.glassSize[0]?.height)}
        </p>
      )}
      {['leaf', 'dummy', 'fixed'].includes(section.sectionType) && (
        <>
          <p>
            <b>Surface area:</b> {Math.round(section.surfaceArea * 1000) / 1000} m<sup>2</sup>
          </p>
          <br />
          <p>
            <b>Glass area:</b> {Math.round(section.glassArea * 1000) / 1000} m<sup>2</sup>
          </p>
          <p>
            <b>Glass size:</b> {round(section.glassSize[0]?.width)}x
            {round(section.glassSize[0]?.height)}
          </p>
          <br />
          <span className="mb-2 block">Glazing Unit</span>
          <div className="flex flex-row space-x-2 sm:w-full md:w-full xxl:w-1/12">
            {materialCard(editorState && editorState.glazingUnit)}
            <div className="flex flex-col space-y-2 self-center">
              <DataModal
                data={productData.glazingUnits}
                materialKey="glazingUnit"
                onItemSelect={(glazingUnit) => {
                  dispatch(setFormData(`sectionEditors.${section.id}.glazingUnit`, glazingUnit));
                  return true;
                }}
              />
            </div>
          </div>
          {['dummy', 'leaf'].includes(section.sectionType) && (
            <>
              <span className="mb-2 mt-2 block">Sash Gaps</span>
              <div className="flex flex-row space-x-2 w-full">
                <div className="flex flex-row space-x-2 self-center">
                  <span className="self-center">Top</span>
                  <input
                    className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900 w-1/3 xxl:w-1/12"
                    type="number"
                    value={section.sashGapTop}
                    onChange={(e) => section.setSashGaps({ top: Number(e.target.value) })}
                  />
                  <span className="self-center">Left</span>
                  <input
                    className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900 w-1/3 xxl:w-1/12"
                    type="number"
                    value={section.sashGapLeft}
                    onChange={(e) => section.setSashGaps({ left: Number(e.target.value) })}
                  />
                  <span className="self-center">Bottom</span>
                  <input
                    className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900 w-1/3 xxl:w-1/12"
                    type="number"
                    value={section.sashGapBottom}
                    onChange={(e) => section.setSashGaps({ bottom: Number(e.target.value) })}
                  />
                  <span className="self-center">Right</span>
                  <input
                    className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900 w-1/3 xxl:w-1/12"
                    type="number"
                    value={section.sashGapRight}
                    onChange={(e) => section.setSashGaps({ right: Number(e.target.value) })}
                  />
                </div>
              </div>
            </>
          )}
          <CheckBox
            name={`sectionEditors.${section.id}.usingStickOnBars`}
            label="Bars"
            wrapperClass="mt-2"
            orientation="vertical"
            controlled={true}
            onChange={(value, e) => {
              if (value === 'stickOnBars') {
                section.setStickOnBars(e.target.checked);
              } else {
                section.setStickOnBars(!e.target.checked);
              }
            }}
            options={[
              {
                label: 'Stick-on bars',
                value: 'stickOnBars',
                checked: section.usingStickOnBars,
              },
              {
                label: 'True bars',
                value: 'trueBars',
                checked: !section.usingStickOnBars,
              },
            ]}
          />
          {['leaf', 'dummy', 'fixed'].includes(section.sectionType) && (
            <CheckBox
              name="hardware"
              label="Hardware"
              wrapperClass="mt-2"
              orientation="vertical"
              onChange={(value, e) =>
                dispatch(setFormData(`sectionEditors.${section.id}.${value}`, e.target.checked))
              }
              options={[
                {
                  label: 'Has shootbolt',
                  value: 'hasShootbolt',
                  checked: editorState && editorState.hasShootbolt,
                },
                {
                  label: 'Has trickle vent',
                  value: 'hasTrickleVent',
                  checked: editorState && editorState.hasTrickleVent,
                },
                {
                  label: 'Has brake',
                  value: 'hasBrake',
                  checked: editorState && editorState.hasBrake,
                },
                {
                  label: 'Has extension set',
                  value: 'hasExtensionSet',
                  checked: editorState && editorState.hasExtensionSet,
                },
                {
                  label: 'Has lockable stay pin',
                  value: 'hasLockableStayPin',
                  checked: editorState && editorState.hasLockableStayPin,
                },
                {
                  label: 'Leaf has seal',
                  value: 'hasSeal',
                  checked: editorState && editorState.hasSeal,
                },
              ]}
            />
          )}
        </>
      )}
    </div>
  );
};

type State = {
  sectionEditors: any;
};

const SectionEditors = ({ store }) => {
  const dispatch = useDispatch();
  const [sections, setSections] = React.useState([]);
  const [currentSection, setCurrentSection] = React.useState(null);
  const state = useSelector((state: State) => {
    return {
      // @ts-ignore
      sectionEditors: state.configuratorForms.sectionEditors,
      // @ts-ignore
      casementData: state.configuratorForms.casementData,
      // @ts-ignore
      timberSummary: state.configuratorForms.timberSummary,
      // @ts-ignore
      labourSummary: state.configuratorForms.labourSummary,
      // @ts-ignore
      glassSummary: state.configuratorForms.glassSummary,
      // @ts-ignore
      hardwareSummary: state.configuratorForms.hardwareSummary,
      // @ts-ignore
      hardwareSet: state.configuratorForms.hardwareSet,
      // @ts-ignore
      finishSystem: state.configuratorForms.finishSystem,
      // @ts-ignore
      hardware_finish_id: state.configuratorForms.casementData.hardware_finish_id,
      // @ts-ignore
      trickle_vent_finish_id: state.configuratorForms.casementData.trickle_vent_finish_id,
      // @ts-ignore
      shootbolt_finish_id: state.configuratorForms.casementData.shootbolt_finish_id,
      // @ts-ignore
      productData: state.configData,
    };
  });

  function getChildren(section, childSections = []) {
    if (!section || !isAlive(section)) return;

    if (!['root', 'blank'].includes(section.sectionType)) {
      if (section.id !== store.selectedSectionId) {
        childSections.push(section);
      } else {
        setCurrentSection(section);
      }
    }

    if (section.sections.length !== 0) {
      section.sections.forEach((s) => getChildren(s, childSections));
    }

    return childSections;
  }

  React.useEffect(() => {
    return onSnapshot(store, (_snapshot) => {
      setSections(getChildren(store.sections[0], []));
    });
  }, []);

  const requestForTimberSummary = () => {
    const formData = API.toFormData({
      data: JSON.stringify({
        timber: {
          frame_timber_id: state.casementData.frame_timber_id,
          leaf_timber_id: state.casementData.leaf_timber_id,
          sill_timber_id: state.casementData.sill_timber_id,
          frame_engineered: state.casementData.frame_engineered,
          sill_engineered: state.casementData.sill_engineered,
          leaf_engineered: state.casementData.leaf_engineered,
        },
        timber_items: store.allTimber,
      }),
    });

    API.getCasementTimberSummary(formData)
      .then((response) => {
        dispatch(setFormData('timberSummary', response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const requestForGlassSummary = () => {
    let glazingData = [];

    glazingData = store.glassSummary.map((glass) => ({
      ...glass,
      glazing_unit_id:
        state.sectionEditors[glass.sectionID] &&
        state.sectionEditors[glass.sectionID].glazingUnit.id,
    }));

    const formData = API.toFormData({
      data: JSON.stringify({
        glass_summary: glazingData,
      }),
    });

    API.getCasementGlassSummary(formData)
      .then((response) => {
        dispatch(setFormData('glassSummary', response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const requestForHardwareSummary = () => {
    let hardwareData = store.hardwareSummary;
    hardwareData.sections = hardwareData.sections.map((section) => {
      const { sectionID, ...rest } = section;

      const editorState = state.sectionEditors[sectionID];
      if (!editorState) return section;

      return {
        ...rest,
        restrictor_quantity: editorState.restrictorQuantity,
        has_shootbolt: editorState.hasShootbolt,
        has_trickle_vent: editorState.hasTrickleVent,
        has_brake: editorState.hasBrake,
        has_extension_set: editorState.hasExtensionSet,
        has_lockable_stay_pin: editorState.hasLockableStayPin,
        has_seal: editorState.hasSeal,
      };
    });

    const hardwareFinishes = {};
    if (state.hardware_finish_id) {
      // @ts-ignore
      hardwareFinishes.hardware_finish_id = state.hardware_finish_id.value;
    }
    if (state.trickle_vent_finish_id) {
      // @ts-ignore
      hardwareFinishes.trickle_vent_finish_id = state.trickle_vent_finish_id.value;
    }
    if (state.shootbolt_finish_id) {
      // @ts-ignore
      hardwareFinishes.shootbolt_finish_id = state.shootbolt_finish_id.value;
    }

    const formData = API.toFormData({
      data: JSON.stringify({
        hardware_set: { ...hardwareSetToFormParams(state.hardwareSet), ...hardwareFinishes },
        hardware_summary: hardwareData,
      }),
    });

    API.getCasementHardwareSummary(formData)
      .then((response) => {
        dispatch(setFormData('hardwareSummary', response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const requestForLabourSummary = () => {
    console.debug('requestForLabourSummary', store.statistics);
    const formData = API.toFormData({
      data: JSON.stringify({
        ...store.statistics,
        is_dual_colour: state.finishSystem && state.finishSystem.dual_finish,
      }),
    });

    API.getCasementLabourSummary(formData)
      .then((response) => {
        dispatch(setFormData('labourSummary', response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div
        className={'p-6 border border-l-8 rounded-metronic border-red-M100'}
        style={{ width: 400 }}
      >
        <br />
        <p>
          <b>Total Surface area:</b> {Math.round(store.totalSurfaceArea * 1000) / 1000} m
          <sup>2</sup>
        </p>
        <div className="flex flex-col">
          <Button as="button" onClick={requestForTimberSummary} className="my-2 w-5/12">
            Request timber
          </Button>
          <p>
            <b>Timber Price:</b> {state.timberSummary.total_price || '£0.0'}
          </p>
          <table className="table w-full">
            <thead>
              <tr>
                <th>Timber</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(state.timberSummary.timber_summary || {}).map(([key, value]: any) => {
                return (
                  <tr key={key}>
                    <td>{value.timber_name}</td>
                    <td>{Math.round(value.quantity * 1000) / 1000}</td>
                    <td>{value.unit}</td>
                    <td>{value.total_price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* LABOUR */}
        <div className="flex flex-col">
          <Button as="button" onClick={requestForLabourSummary} className="my-2 w-5/12">
            Request labour
          </Button>
          <p>
            <b>Labour Cost:</b> {state.labourSummary.total_cost || '£0.0'}
          </p>
          <p>
            <b>Labour Price:</b> {state.labourSummary.total_price || '£0.0'}
          </p>
          <table className="table w-full">
            <thead>
              <tr>
                <th>Category</th>
                <th>Time</th>
                <th>Cost</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(state.labourSummary || {}).map(([key, value]: any) => {
                if (typeof value !== 'object') return null;
                return (
                  <tr key={key}>
                    <td>{value.description}</td>
                    <td>{Math.round(value.duration * 1000) / 1000}</td>
                    <td>{value.cost}</td>
                    <td>{value.price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Glazing */}
        <div className="flex flex-col">
          <Button as="button" onClick={requestForGlassSummary} className="my-2 w-5/12">
            Request glass
          </Button>
          <p>
            <b>Glazing Cost:</b> {state.glassSummary.total_price || '£0.0'}
          </p>
          <table className="table w-full">
            <thead>
              <tr>
                <th>Glass Unit</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {(state.glassSummary.glass_summary || []).map((value: any, i) => {
                if (typeof value !== 'object') return null;
                return (
                  <tr key={`${value.glazing_unit}${i}`}>
                    <td>{value.glazing_unit}</td>
                    <td>{value.total_price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Hardware */}
        <div className="flex flex-col">
          <Button as="button" onClick={requestForHardwareSummary} className="my-2 w-5/12">
            Request hardware
          </Button>
          <p>
            <b>Hardware Cost:</b> {state.hardwareSummary.total_cost || '£0.0'}
          </p>
          <table className="table w-full">
            <thead>
              <tr>
                <th>Hardware</th>
                <th>Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {(state.hardwareSummary.hardware_summary || []).map((value: any, i) => {
                if (typeof value !== 'object') return null;
                return (
                  <tr key={`${value.hardware_name}${i}`}>
                    <td>{value.hardware_name}</td>
                    <td>{value.quantity}</td>
                    <td>{value.total_cost}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {currentSection && isAlive(currentSection) && (
        <SectionEditor
          key={currentSection.id}
          section={currentSection}
          editorState={state.sectionEditors[currentSection.id]}
          productData={state.productData}
          selected
          dispatch={dispatch}
        />
      )}
      {sections.map((section) => (
        <SectionEditor
          key={section.id}
          editorState={state.sectionEditors[section.id]}
          productData={state.productData}
          section={section}
          dispatch={dispatch}
        />
      ))}
    </div>
  );
};

export default SectionEditors;
