import API from 'apiv1';
import React, { useState } from 'react';

interface Props {
  projectID: number;
  rfqID: number;
  onCustomFormRequest?: Function;
  onClose?: Function;
}

const ProductSelector = ({
  projectID,
  rfqID,
  onCustomFormRequest,
  onClose,
}: Props): JSX.Element => {
  const [productCategory, setProductCategory] = useState(null);
  const [products, setProducts] = useState([]);

  const renderProductCategories = () => {
    return (
      <div className="flex flex-row space-x-6 justify-center">
        <div
          onClick={() => setProductCategory('box_sash')}
          className="py-12 px-8 bg-blue-M100 font-bold rounded-metronic cursor-pointer"
        >
          Box Sash
        </div>
        <div
          onClick={() => setProductCategory('casement')}
          className="py-12 px-8 bg-blue-M100 font-bold rounded-metronic cursor-pointer"
        >
          Casement
        </div>
        <div
          onClick={() => {
            onCustomFormRequest(true);
            onClose && onClose();
          }}
          className="py-12 px-8 bg-blue-M100 font-bold rounded-metronic cursor-pointer"
        >
          Custom
        </div>
      </div>
    );
  };

  const loadProducts = (category) => {
    if (products.length === 0) {
      API.getProducts({ category: category, projectID })
        .then((data) => {
          // console.log(data);
          setProducts(data);
        })
        .catch(console.error);
    }
  };

  const renderProducts = () => {
    loadProducts(productCategory);

    return (
      <div className="flex flex-row flex-wrap" style={{ maxWidth: 1250 }}>
        {products.map((product) => (
          <div
            key={product.id}
            className="flex flex-col mr-2 cursor-pointer sm:w-1/2 md:w-1/3 lg:w-1/3"
            style={{ maxWidth: 400 }}
            onClick={() => {
              window.location.href = `/rfqs/${rfqID}/product_items/${productCategory}/new?base=${product.id}`;
            }}
          >
            <img
              style={{ maxWidth: 400, maxHeight: 500, height: 'auto' }}
              src={product['frame_diagram']}
              alt={product.name}
            />
            <div className="self-center text-ellipsis">{product.name}</div>
          </div>
        ))}
        <div
          className="flex flex-col mr-2 cursor-pointer"
          onClick={() =>
            (window.location.href = `/rfqs/${rfqID}/product_items/${productCategory}/new`)
          }
        >
          <div
            style={{ width: 400, height: 500 }}
            className="bg-blue-M100 flex justify-center items-center font-bold"
          >
            Add New
          </div>
          <div className="self-center">Create New</div>
        </div>
      </div>
    );
  };

  const renderStep = () => {
    if (productCategory === null) {
      return renderProductCategories();
    }

    // if (products.length > 1) {
    return renderProducts();
    // }
  };

  return <div>{renderStep()}</div>;
};

export default ProductSelector;
