import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { selectMaterial, removeSelectedMaterial } from '@appSrc/actions/materialSelectionActions';

import DataModal from '@components/DataModal';
import DynamicList, { addItemIds } from '@components/DynamicList';
import Card from '@components/configurators/Card';

import '../../css/react-notifications.css';
import DebugConsole from '../DebugOptions/DebugConsole';
import { Alert } from '../alerts';
import ContextForm from './ContextForm';

const MODE_HANDLER = {
  edit: API.updateFinishSystem,
  default: API.createFinishSystem,
};

const SelectInput = ({ value, inputOptions, onChange, onBlur = () => {} }) => {
  const handleValueChange = (option) => {
    if (onChange) onChange(option);
  };

  return (
    <Select
      defaultValue={inputOptions.defaultValue}
      options={inputOptions.options}
      name={inputOptions.name}
      menuPortalTarget={document.body}
      className={'theme-multi-select' + (inputOptions.className || '')}
      onChange={handleValueChange}
      value={value}
      onBlur={onBlur}
      styles={{
        control: (styles) => ({
          ...styles,
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          backgroundColor: '#fff',
          borderColor: '#e2e8f0',
          borderWidth: '1px',
          borderRadius: '0.675rem',
          lineHeight: 1.5,
          fontSize: '0.825rem',
          width: '100%',
        }),
        valueContainer: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
        }),
        menu: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
          zIndex: 9999,
        }),
        menuPortal: (styles) => ({
          ...styles,
          zIndex: 9990,
        }),
      }}
    />
  );
};

const MaterialCard = ({ item }) => {
  if (item['name']) {
    return (
      <Card defaultOpen={false} title={[item['name'], item['code']].join(' - ')} narrowStyle />
    );
  }

  return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
};

const Item = ({
  item,
  position,
  timberFinishItems = [],
  selectedMaterial = {},
  handleSelection = (item, itemID) => true,
  handleLayerSelect = (option, itemID) => {},
}) => {
  let inputItem = {
    label: position === 0 && 'Layer',
    name: `finish_system[item][${item.itemID}][layer]`,
    error: { required: 'Please select a layer or remove line item.' },
    type: 'newSelect',
    options: [
      { label: 'Impregnant', value: 'impregnant' },
      { label: 'Primer', value: 'primer' },
      { label: 'Top coat', value: 'top_coat' },
      { label: 'Oil', value: 'oil' },
    ],
    inputClass: 'mb-0',
    wrapperClassName: position === 0 && 'dynamic-action--padding',
    defaultValue: '',
    className: '',
  };

  return (
    <div
      className={`inline-flex flush--bottom space-x-2 ${
        position === 0 && 'dynamic-action-button--padding dynamic-action--padding'
      }`}
    >
      <div
        key={inputItem.name}
        className={`form-element ${position === 0 && 'dynamic-action--padding'}`}
        style={{ minWidth: '300px' }}
      >
        {inputItem.label && <div className="form-label">{inputItem.label}</div>}

        {
          <SelectInput
            value={item.categoryRaw}
            inputOptions={inputItem}
            onChange={(option) => handleLayerSelect(option, item.itemID)}
          />
        }
      </div>
      <div style={{ marginTop: position === 0 && '1.8rem', minWidth: 250 }}>
        <MaterialCard item={selectedMaterial} />
      </div>
      <DataModal
        data={timberFinishItems}
        materialKey="timberFinish"
        onItemSelect={(material) => handleSelection(material, item.itemID)}
      />
    </div>
  );
};

const FinishSystemForm = ({
  message = null,
  recordId = null,
  timberFinishItems = [],
  formId,
  mode = 'default',
}) => {
  const [data, onSubmit] = useState(null);
  const [layers, setLayers] = useState(addItemIds([{}]));
  const { reset, ...methods } = useForm();
  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return {
      selectedMaterials: state.selectedMaterials.finishSystem || {},
    };
  });

  useEffect(() => {
    if (recordId == null) return;

    API.getFinishSystem(recordId).then((finishSystem) => {
      let newLayers = addItemIds(finishSystem.data);
      setLayers(newLayers);
      newLayers.forEach((layer) =>
        handleSelection(
          finishSystem['timber_finishes'].find(
            (finish) => finish['id'] === layer['timber_finish_id']
          ),
          layer.itemID
        )
      );

      reset({ name: finishSystem.name });
    });
  }, [reset, recordId]);

  const onSubmitFn = (formData) => {
    console.log('Form data: ', formData);
    console.log('State data: ', state.selectedMaterials);
    let finalLayers = [...layers];
    Object.keys(state.selectedMaterials).forEach((layerID) => {
      let index = finalLayers.findIndex((layer) => layer.itemID === layerID);
      if (index < 0) return;
      let layer = finalLayers[index];
      layer['timber_finish_id'] = state.selectedMaterials[layerID]['id'];
      finalLayers.splice(index, 1, layer);
    });
    console.log('Final state?: ', { name: formData['name'], data: finalLayers });
    MODE_HANDLER[mode || 'default'](
      API.toFormData({ name: formData['name'], data: JSON.stringify(finalLayers) }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  function handleSelectionRemove(itemID) {
    dispatch(removeSelectedMaterial('finishSystem', itemID));
  }

  function handleSelection(item, itemID) {
    dispatch(selectMaterial('finishSystem', itemID, item));
    return true;
  }

  function handleLayerSelect(option, itemID) {
    let layer = layers.find((layer) => layer.itemID === itemID);
    let layerIndex = layers.findIndex((layer) => layer.itemID === itemID);
    layer.category = option.value;
    layer.categoryRaw = option;
    let newLayers = [...layers];
    newLayers.splice(layerIndex, 1, layer);
    setLayers(newLayers);
  }

  let items = [
    {
      config: ['2/4'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Name',
          error: { required: 'Please enter system name' },
          name: 'name',
          type: 'text',
          placeholder: 'e.g. Sikkens',
          defaultValue: '',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-col"
            formId={formId}
          >
            <DynamicList
              items={layers}
              ItemComponent={(props) => (
                <Item
                  {...props}
                  selectedMaterial={state.selectedMaterials[props.item['itemID']]}
                  timberFinishItems={timberFinishItems}
                  handleSelection={handleSelection}
                  handleLayerSelect={handleLayerSelect}
                />
              )}
              onChange={setLayers}
              onItemRemove={handleSelectionRemove}
            />
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default FinishSystemForm;
