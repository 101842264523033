import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import '../../../css/react-notifications.css';

import { update } from '@utils/formHelpers';
import ContextForm from '../ContextForm';
import { itemToInput } from '@components/forms/GridForm';
import Button from '@components/buttons/ButtonLink';
import API from 'apiv1';
import DebugConsole from '@appSrc/components/DebugOptions/DebugConsole';

const TermsAndConditionsForm = ({ business, businessID }) => {
  const [data, onSubmit] = useState(null);
  const { terms_and_conditions_file, ...businessData } = business;
  const { reset, ...methods } = useForm({ defaultValues: businessData });
  const state = useSelector((state) => {
    return {
      file: state.forms['company_logo' + businessID],
    };
  });

  console.log(methods.watch());

  const onSubmitFn = (data) => {
    const imageParams = state.file ? { company_logo: state.file } : {};

    // Serialize the address as the API expects at as JSON
    if (data['registered_address']) {
      update(data, 'registered_address', (address) => JSON.stringify(address));
    }

    // Serialize the address as the API expects at as JSON
    if (data['terms_and_conditions_file'] && data['terms_and_conditions_file'].length > 0) {
      update(data, 'terms_and_conditions_file', (fileList) => fileList[0]);
    }

    API.updateBusiness(
      API.toFormData({
        ...data,
        ...imageParams,
      }),
      businessID
    )
      .then((resp) => {
        NotificationManager.success(
          'Account details have been updated successfully',
          'Update Successful',
          40000
        );
        // window.location.reload()
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const items = [
    // {
    //   config: ['full', 'full'],
    //   style: { orientation: 'column' },
    //   items: [
    //     {
    //       label: 'Link to T&C',
    //       error: {},
    //       name: 'terms_and_conditions_url',
    //       type: 'text',
    //       inlineClass: 'w-1/3',
    //     },
    //     {
    //       label: 'Upload PDF T&C',
    //       error: {},
    //       name: 'terms_and_conditions_file',
    //       type: 'file',
    //       multiple: false,
    //       inlineClass: 'w-1/3',
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div className="flex flex-row">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            formId={'tandc'}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          >
            <div className="max-w-sm">
              {itemToInput(
                {
                  label: 'Link to T&C',
                  error: {},
                  name: 'terms_and_conditions_url',
                  type: 'text',
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {terms_and_conditions_file && (
                <div className="mb-2">
                  <strong>Currently Uploaded: </strong>
                  <a href={terms_and_conditions_file.url}>{terms_and_conditions_file.name}</a>
                </div>
              )}
              {itemToInput(
                {
                  label: 'Upload PDF T&C',
                  error: {},
                  name: 'terms_and_conditions_file',
                  type: 'file',
                  multiple: false,
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </div>

            <div style={{ maxWidth: '140px' }}>
              <Button as="button" type="submit">
                Update T&C
              </Button>
            </div>
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default TermsAndConditionsForm;
