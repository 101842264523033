export const CASEMENT_HARDWARE_SET_ITEMS = [
  'weatherseal',
  'hinge',
  'top_hung_hinge',
  'espag_handle',
  'espag',
  'top_hung_espag',
  'keeps',
  'stay',
  'handle',
  'restrictor',
  'shootbolt',
  'flushbolt',
  'trickle_vent',
  'brake',
  'extension_set',
  'lockable_stay_pins',
];

export default {
  CASEMENT_HARDWARE_SET_ITEMS,
};
