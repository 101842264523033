import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import '../../css/react-notifications.css';

import ContextForm from './ContextForm';
import { Alert } from '../alerts';
import API from 'apiv1';

const MODE_HANDLER = {
  edit: API.updateBOMItem,
};

const BOMItemForm = ({ formId, recordId = null, mode = 'edit', quantity }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm({ defaultValues: { quantity } });

  const onSubmitFn = (data) => {
    // NotificationManager.success('Success message', 'Title here');
    MODE_HANDLER[mode](API.toFormData({ ...data }), recordId)
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['full'],
      // style: { orientation: 'column' },
      items: [
        {
          label: 'Quantity',
          error: { required: 'Quantity is required' },
          name: 'quantity',
          type: 'number',
          step: 1,
          defaultValue: '',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            formId={formId}
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          />
        </FormProvider>
      </div>
      <pre>{JSON.stringify(data)}</pre>
    </>
  );
};

export default BOMItemForm;
