import { destroy } from './helpers';

export const deleteMouldingsAndTrimmingsItem = (id) => {
  return destroy(`/api/v1/mouldings/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteMiscItem = (id) => {
  return destroy(`/api/v1/miscelleneous/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteTimberFinishItem = (id) => {
  return destroy(`/api/v1/timber_finishes/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteGlazingUnitItem = (id) => {
  return destroy(`/api/v1/glazing_units/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteTimberItem = (id) => {
  return destroy(`/api/v1/timber_items/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteHardwareItem = (id) => {
  return destroy(`/api/v1/hardwares/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteClient = (id) => {
  return destroy(`/api/v1/clients/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteProject = (id) => {
  return destroy(`/api/v1/projects/${id}`).then((resp) => {
    return resp;
  });
};
export const deleteSupplier = (id) => {
  return destroy(`/api/v1/suppliers/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteTimberType = (id) => {
  return destroy(`/api/v1/timber_types/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteHardwareSet = (id) => {
  return destroy(`/api/v1/box_sash_hardware_sets/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteCasementHardwareSet = (id) => {
  return destroy(`/api/v1/casement_hardware_sets/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteBoxSashConfig = (id) => {
  return destroy(`/api/v1/box_sash_configs/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteProductItem = (id) => {
  return destroy(`/api/v1/products/items/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteCasement = (id) => {
  return destroy(`/api/v1/casements/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteFinishSystem = (id) => {
  return destroy(`/api/v1/finish_systems/${id}`).then((resp) => {
    return resp;
  });
};

export const deleteAttachment = (id) => {
  return destroy(`/api/v1/attachments/${id}`).then((resp) => {
    return resp;
  });
};
