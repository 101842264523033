import { ISection } from './SectionStore';

export function toMeters(value: number): number {
  return Math.round(value * 1e-3 * 1000) / 1000;
}

export function hardwareSetToFormParams(hardwareSet) {
  if (!hardwareSet) return {};

  const items = {};
  Object.entries(hardwareSet.materials).forEach(([key, value]) => {
    // @ts-ignore
    items[key] = { id: value.id };
  });

  return {
    parent_id: hardwareSet['parent_id'] || hardwareSet.id,
    items: items,
  };
}

export function finishSystemToFormParams(finishSystem) {
  if (!finishSystem) return {};

  const items = [];
  finishSystem.timber_finishes.forEach((timberFinish) => {
    items.push({ id: timberFinish.id });
  });

  const params = {
    parent_id: finishSystem['parent_id'] || finishSystem.id,
    timber_finishes: items,
  };

  if (finishSystem.dual_finish && finishSystem.outer_frame['timber_finish']) {
    // @ts-ignore
    params.outer_frame = {
      id: finishSystem.outer_frame.timber_finish.id,
    };
  } else {
    // @ts-ignore
    params.outer_frame = {};
  }

  return params;
}

export function sectionEditorsToFormParams(sectionEditors) {
  if (!sectionEditors) return [];

  const items = [];
  Object.entries(sectionEditors).forEach(([key, value]) => {
    items.push({
      section_id: key,
      // @ts-ignore
      glazing_unit_id: value.glazingUnit && value.glazingUnit.id,
    });
  });

  return items;
}

export function equalHorizontalSplitInternalSection(section: ISection, options: any): any[] {
  const {
    splitWidth,
    splitCount,
    separatorType,
    productType = 'glazing',
    separatorIncMoulding = false,
  } = options;
  const {
    internalSize: { width, height },
    internalOrigin,
    absoluteOrigin,
  } = section;

  let newSections: any[] = [];
  let splitInserted = 0;

  const newSectionWidth = (width - splitWidth * splitCount) / (splitCount + 1);

  for (let i = 0; i < splitCount + 1; i++) {
    newSections.push({
      sectionType: productType,
      origin: {
        x: internalOrigin.x + (newSectionWidth * i + splitWidth * i),
        y: internalOrigin.y,
      },
      absoluteOrigin: {
        x: absoluteOrigin.x + (internalOrigin.x + (newSectionWidth * i + splitWidth * i)),
        y: absoluteOrigin.y,
      },
      size: {
        width: newSectionWidth,
        height,
      },
    });

    if (splitInserted !== splitCount) {
      const mouldingWidth = separatorIncMoulding ? section.store.sash_moulding.width : 0;
      newSections.push({
        sectionType: separatorType,
        origin: {
          x: internalOrigin.x + (newSectionWidth * (i + 1) + splitWidth * i) + mouldingWidth,
          y: internalOrigin.y - mouldingWidth,
        },
        absoluteOrigin: {
          x:
            absoluteOrigin.x +
            (internalOrigin.x + (newSectionWidth * (i + 1) + splitWidth * i)) +
            mouldingWidth,
          y: absoluteOrigin.y - mouldingWidth,
        },
        size: {
          width: splitWidth - mouldingWidth * 2,
          height: height + mouldingWidth * 2,
        },
      });

      splitInserted += 1;
    }
  }

  return newSections;
}
