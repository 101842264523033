import { observer, inject } from 'mobx-react';
import { isAlive } from 'mobx-state-tree';
import React from 'react';
import { Group, Line, Rect, Stage, Layer } from 'react-konva';

import SectionView from './SectionView';

type Props = {
  casementStore?: any;
  registerSashRef?: Function;
};

type State = {
  section: any;
};

class SectionsView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { section: this.props.casementStore.sections[0] };
    // @ts-ignore
    this.group = null;
  }

  handleClick = (e) => {
    const firstSection = e.target.findAncestor('.section');
    // @ts-ignore
    if (firstSection === this.group && this.state.section.sections.length <= 0) {
      this.props.casementStore.setCurrentSection(this.state.section.id);
    }
  };

  render() {
    if (
      this.state.section === undefined ||
      this.state.section === null ||
      !isAlive(this.state.section)
    )
      return <></>;

    // const isSelected = this.props.casementStore.selectedSectionId === this.state.section.id;
    // console.log(
    //   'View Section ID: ',
    //   this.props.casementStore.currentSection && this.props.casementStore.currentSection.id
    // );

    return (
      <Group
        // @ts-ignore
        ref={(node) => (this.group = node)}
        onClick={this.handleClick}
        {...this.state.section.size}
        {...this.state.section.absoluteOrigin}
        name="section"
      >
        {/* Selector for when pane is empty */}
        {/* <Rect {...this.state.section.size} fill="white" opacity={0.1} /> */}
        {/* {isSelected && <Rect {...this.state.section.size} fill="green" opacity={0.3} />} */}
        <SectionView
          key={this.state.section.id}
          store={this.props.casementStore}
          section={this.state.section}
        />
      </Group>
    );
  }
}

export default inject('casementStore')(observer(SectionsView));
