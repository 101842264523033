import React from 'react';

import { ISection } from '@stores/SectionStore';

import ArcRect from '@components/configurators/Casement/ArcRect';

type TransomProps = {
  section: ISection;
  lineProps?: any;
};

const DEFAULT_SHAPE_PROPS = {
  closed: true,
  stroke: 'black',
  strokeWidth: 4,
  opacity: 1,
  fill: 'white',
};

const Transom = ({ section, lineProps = DEFAULT_SHAPE_PROPS }: TransomProps) => {
  let xOffset = section.store.rebate.width;
  let widthOffset = 2 * section.store.rebate.width;
  let [rainDripWidth, leftOffset] = section.rainDripWidth;

  return (
    <>
      <ArcRect {...lineProps} section={section} />
      <ArcRect
        {...lineProps}
        section={section}
        x={section.origin.x + xOffset}
        y={section.store.visibleTransomWidth}
        altSize={{ width: section.size.width - widthOffset, height: section.store.rebate.width }}
      />
      {section.hasRainDrip && (
        <ArcRect
          {...lineProps}
          section={section}
          x={section.origin.x - leftOffset}
          y={section.store.visibleTransomWidth - 3}
          altSize={{ width: rainDripWidth, height: -section.store.transomRainDrip.width }}
        />
      )}
      <ArcRect
        {...lineProps}
        section={section}
        x={section.origin.x + xOffset}
        y={-section.store.rebate.width}
        altSize={{ width: section.size.width - widthOffset, height: section.store.rebate.width }}
      />
    </>
  );
};

export default Transom;
