import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Layouts from './layouts';
import { store, initStore } from './store';

const Wrapper = ({ children }) => {
  return <Layouts>{children}</Layouts>;
};

export default function appLayout({ appVersion }) {
  initStore({
    config: {
      name: 'Radiko',
      description: 'Platform for joinery professionals',
      url: 'siliconorigami.com',
      layout: 'layout-1',
      version: appVersion,
      collapsed: false,
      rightSidebar: false,
      backdrop: false,
      loading: false,
    },
  });
  return (
    <Provider store={store}>
      <Router>
        <Wrapper />
      </Router>
    </Provider>
  );
}
