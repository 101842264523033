import axios from 'axios';

export const toFormData = (object) => {
  const formaData = Object.keys(object).reduce((formData, key) => {
    // if (typeof object[key] === "object") {
    //   formData.append(key, object[key]);
    // } else if (Array.isArray(object[key])) {
    //   formData.append(key, object[key]);
    // } else {
    formData.append(key, object[key]);
    // }
    return formData;
  }, new FormData());
  return formaData;
};

export const applicationHeaders = {
  // 'Content-Type': 'application/json',
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
};

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // @ts-ignore
    config.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]')?.content;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const post = async (url, body, headers = {}) => {
  return await axios.post(url, body, {
    headers: { ...applicationHeaders, ...headers },
    withCredentials: true,
  });
};

export const put = async (url, body) => {
  return await axios.put(url, body, {
    headers: { ...applicationHeaders },
    withCredentials: true,
  });
};

export const destroy = async (url) => {
  return await axios.delete(url, {
    headers: { ...applicationHeaders },
    withCredentials: true,
  });
};

export const get = async (url, params = {}) => {
  const resp = await axios.get(url, {
    params: params,
    withCredentials: true,
  });
  return resp.data;
};
