import { observer, inject } from 'mobx-react';
import { destroy } from 'mobx-state-tree';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import Button from '@components/buttons/ButtonLink';
import CheckBox from '@components/forms/controls/CheckBox';

import { handleCaseArchLarge } from './testCases';

const SelectInput = ({ value, inputOptions, onChange, onBlur = () => {} }) => {
  const handleValueChange = (option) => {
    if (onChange) onChange(option);
  };

  return (
    <Select
      defaultValue={inputOptions.defaultValue}
      options={inputOptions.options}
      isOptionDisabled={(option) => option.disabled}
      name={inputOptions.name}
      menuPortalTarget={document.body}
      className={'theme-multi-select' + (inputOptions.className || '')}
      onChange={handleValueChange}
      value={value}
      onBlur={onBlur}
      styles={{
        control: (styles) => ({
          ...styles,
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          backgroundColor: '#fff',
          borderColor: '#e2e8f0',
          borderWidth: '1px',
          borderRadius: '0.675rem',
          lineHeight: 1.5,
          fontSize: '0.825rem',
          width: '100%',
        }),
        valueContainer: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
        }),
        menu: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
          zIndex: 10,
        }),
        menuPortal: (styles) => ({
          ...styles,
          zIndex: 8,
        }),
      }}
    />
  );
};

const SectionControls = observer(({ store }) => {
  const [numberOfMullions, setNumberOfMullions] = useState(1);
  const [numberOfTransom, setNumberOfTransom] = useState(1);
  const [transomDrop, setTransomDrop] = useState('');
  const [mullionDistance, setMullionDistance] = useState('');
  const [falseMullionSectionSize, setFalseMullionSectionSize] = useState('');
  const [midRailDrop, setMidRailDrop] = useState('');
  const [numberOfFalseMullions, setNumberOfFalseMullions] = useState(1);
  const [headStyle, setHeadStyle] = useState();
  const [leafOpening, setLeafOpening] = useState({});
  const [transomBalanceTraget, setTransomBalanceTraget] = useState(null);
  const [barsBalanceTarget, setBarsBalanceTarget] = useState(null);
  const [georgianBars, setGeorgianBars] = useState({ vertical: 2, horizontal: 2 });
  let inputItem = {
    label: 'Leaf opening',
    name: 'leaf_opening',
    error: { required: 'Please select opening for the currently selected leaf' },
    type: 'newSelect',
    options: [
      { label: 'Side-Hung Left', value: 'side_hung_left' },
      { label: 'Side-Hung Right', value: 'side_hung_right' },
      { label: 'Top-Hung', value: 'top_hung' },
      { label: 'Tilt & Turn Left', value: 'tilt_and_turn_left' },
      { label: 'Tilt & Turn Right', value: 'tilt_and_turn_right' },
      { label: 'Awning', value: 'awning' },
    ],
    inputClass: 'mb-0',
    defaultValue: '',
    className: 'w-12',
  };
  let headStyleInputItem = {
    label: 'Head Style',
    name: 'head_style',
    error: { required: 'Please select a head style' },
    type: 'newSelect',
    options: [
      { label: 'Standard', value: 'standard' },
      { label: 'Head-Only Arch', value: 'head_only_arch', disabled: true },
      { label: 'Full Arch', value: 'full_arch', disabled: true },
    ],
    inputClass: 'mb-0',
    defaultValue: '',
    className: 'w-12',
  };

  useEffect(() => {
    if (transomBalanceTraget === null) return;
    if (!store.currentSection) return;
    if (transomBalanceTraget === store.currentSection.id) return;

    if (store.currentSection.sectionType !== 'transom') {
      setTransomBalanceTraget(null);
    }

    const targetSection = store.findSection(transomBalanceTraget);
    targetSection.setTransomBalanceTarget(store.currentSection);
    setTransomBalanceTraget(null);
  }, [transomBalanceTraget, store.currentSection]);

  // TODO: Merge this with the above
  useEffect(() => {
    if (barsBalanceTarget === null) return;
    if (!store.currentSection) return;
    if (barsBalanceTarget === store.currentSection.id) return;

    const targetSection = store.findSection(barsBalanceTarget);
    console.log(
      'Settings Balance Target: ',
      targetSection.setBarsBalanceTarget(store.currentSection)
    );
    setBarsBalanceTarget(null);
  }, [barsBalanceTarget, store.currentSection]);

  const currentSection = store.currentSection;

  return (
    <div className="flex flex-row pt-2" style={{ minHeight: 80, minWidth: 1 }}>
      <div className="mr-2">
        <Button as="button" onClick={() => store.toggleSashesOnly()}>
          {store.sashesOnly ? 'Add Frame' : 'Sashes Only'}
        </Button>
      </div>

      <div className="mr-2">
        <Button as="button" onClick={() => store.resetCasement()}>
          Reset
        </Button>
      </div>
      {currentSection &&
        ['node', 'rootAncestor', 'blank', 'root'].includes(currentSection.sectionType) && (
          <>
            <div className="space-x-2">
              <Button
                as="button"
                onClick={() => currentSection.splitViaMullion(numberOfMullions)}
                disabled={!(currentSection && currentSection.id)}
              >
                Add mullion
              </Button>
              <input
                className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
                type="number"
                min={1}
                step={1}
                max={7}
                value={numberOfMullions}
                onChange={(e) => setNumberOfMullions(Number(e.target.value))}
              />
            </div>
            <div className="ml-4 space-x-2">
              <Button
                as="button"
                onClick={() => currentSection.splitViaTransom(numberOfTransom)}
                disabled={!(currentSection && currentSection.id)}
              >
                Add transom
              </Button>
              <input
                className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
                type="number"
                min={1}
                step={1}
                max={7}
                value={numberOfTransom}
                onChange={(e) => setNumberOfTransom(Number(e.target.value))}
              />
            </div>
            <div className="ml-4 space-x-2">
              <Button
                as="button"
                onClick={() => currentSection.setFalseTransom(transomDrop)}
                disabled={!(currentSection && currentSection.id)}
              >
                Set False Transom
              </Button>
              <input
                className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
                type="number"
                min={1}
                step={1}
                max={5000}
                value={transomDrop}
                // @ts-ignore
                onChange={(e) => setTransomDrop(Number(e.target.value))}
              />
            </div>
            <div className="ml-4">
              <Button
                as="button"
                onClick={() => currentSection.setFalseMullion(numberOfFalseMullions)}
                disabled={!(currentSection && currentSection.id)}
              >
                Set False Mullions
              </Button>
              <input
                className="rounded-md py-1 ml-2 focus:outline-none focus:bg-white focus:text-gray-900"
                type="number"
                min={1}
                step={1}
                max={20}
                value={numberOfFalseMullions}
                onChange={(e) => setNumberOfFalseMullions(Number(e.target.value))}
              />
            </div>
          </>
        )}
      {/* <div className="ml-4">
        <Button as="button" onClick={() => handleCaseArchLarge(store)}>
          Arc 1000
        </Button>
      </div> */}
      {currentSection && ['leaf', 'dummy', 'fixed'].includes(currentSection.sectionType) && (
        <>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.setGeorgianBars(georgianBars)}>
              Set Georgian Bars
            </Button>
          </div>
          <div className="ml-4 flex flex-row" style={{ minWidth: '200px' }}>
            <div className="flex flex-col">
              <span>Vertical</span>
              <input
                className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
                type="number"
                min={1}
                step={1}
                max={7}
                value={georgianBars.vertical}
                onChange={(e) =>
                  setGeorgianBars({ ...georgianBars, vertical: Number(e.target.value) })
                }
              />
            </div>

            <div className="ml-4 flex flex-col">
              <span>Horizontal</span>
              <input
                className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
                type="number"
                min={1}
                step={1}
                max={7}
                value={georgianBars.horizontal}
                onChange={(e) =>
                  setGeorgianBars({ ...georgianBars, horizontal: Number(e.target.value) })
                }
              />
            </div>
          </div>
        </>
      )}
      {store.selectedSectionId === 'head' && (
        <>
          <div className="ml-4" style={{ minWidth: '200px' }}>
            <SelectInput
              value={headStyle}
              inputOptions={headStyleInputItem}
              onChange={(option) => {
                store.setHeadStyle(option.value);
                setHeadStyle(option);
              }}
            />
          </div>
          <div className="ml-4" style={{ minWidth: '200px' }}>
            <CheckBox
              name={`sectionControls.${store.id}.headRainDrip`}
              label=""
              wrapperClass="mt-2"
              orientation="vertical"
              controlled={true}
              onChange={(value, e) => {
                if (value === 'true') {
                  store.setHasHeadRainDrip(e.target.checked);
                } else {
                  store.setHasHeadRainDrip(!e.target.checked);
                }
              }}
              options={[
                {
                  label: 'Rain drip',
                  value: 'true',
                  checked: store.hasHeadRainDrip,
                },
              ]}
            />
          </div>
        </>
      )}
      {/* Transom */}
      {currentSection && currentSection.sectionType === 'transom' && (
        <>
          <div className="ml-4 space-x-2">
            <Button as="button" onClick={() => currentSection.setTransomDrop(transomDrop)}>
              Set transom drop
            </Button>
            <input
              className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
              type="number"
              min={0}
              step={1}
              max={5000}
              value={transomDrop}
              // @ts-ignore
              onChange={(e) => setTransomDrop(Number(e.target.value))}
            />
          </div>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.deleteSection()}>
              Delete transom
            </Button>
          </div>
        </>
      )}
      {/* Mullion */}
      {currentSection && currentSection.sectionType === 'mullion' && (
        <>
          <div className="ml-4 space-x-2">
            <Button as="button" onClick={() => currentSection.setMullionDistance(mullionDistance)}>
              Set mullion distance
            </Button>
            <input
              className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
              type="number"
              min={0}
              step={1}
              max={5000}
              value={mullionDistance}
              // @ts-ignore
              onChange={(e) => setMullionDistance(Number(e.target.value))}
            />
          </div>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.deleteSection()}>
              Delete mullion
            </Button>
          </div>
        </>
      )}
      {/* False Mullion/French */}
      {currentSection && currentSection.leafStyle === 'frenchLeaf' && !currentSection.isLast && (
        <>
          <div className="ml-4 space-x-2">
            <Button
              as="button"
              onClick={() => currentSection.setFalseMullionSectionSize(falseMullionSectionSize)}
            >
              Set section width
            </Button>
            <input
              className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
              type="number"
              min={0}
              step={1}
              max={5000}
              value={falseMullionSectionSize}
              // @ts-ignore
              onChange={(e) => setFalseMullionSectionSize(Number(e.target.value))}
            />
          </div>
        </>
      )}
      {currentSection && ['blank', 'root'].includes(currentSection.sectionType) && (
        <>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.makeLeaf()}>
              Leaf
            </Button>
          </div>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.makeFixed()}>
              Fixed
            </Button>
          </div>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.makeDummy()}>
              Dummy
            </Button>
          </div>
        </>
      )}
      {currentSection && ['frenchLeaf'].includes(currentSection.leafStyle) && (
        <>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.removeFalseMullion()}>
              Remove False Mullion
            </Button>
          </div>
        </>
      )}
      {currentSection && ['leaf', 'fixed', 'dummy'].includes(currentSection.sectionType) && (
        <>
          <div className="ml-4">
            <Button as="button" onClick={() => currentSection.clearType()}>
              Clear
            </Button>
          </div>
          {!currentSection.hasMuntin && (
            <div className="ml-4">
              <Button as="button" onClick={() => currentSection.addMuntin()}>
                Add Muntin
              </Button>
            </div>
          )}
          {currentSection.hasMuntin && (
            <div className="ml-4">
              <Button as="button" onClick={() => currentSection.removeMuntin()}>
                Remove Muntin
              </Button>
            </div>
          )}
          {currentSection.hasMidRail && (
            <div className="ml-4">
              <Button as="button" onClick={() => currentSection.removeMidRail()}>
                Remove Mid Rail
              </Button>
            </div>
          )}
          <div className="ml-4 space-x-2">
            <Button as="button" onClick={() => currentSection.setMidRail(midRailDrop)}>
              Add Mid Rail
            </Button>
            <input
              className="rounded-md py-1 focus:outline-none focus:bg-white focus:text-gray-900"
              type="number"
              min={1}
              step={1}
              max={5000}
              value={midRailDrop}
              // @ts-ignore
              onChange={(e) => setMidRailDrop(Number(e.target.value))}
            />
          </div>
          {currentSection.hasBars && (
            <div className="ml-4">
              <Button as="button" onClick={() => setTransomBalanceTraget(currentSection.id)}>
                Balance bars with transom
              </Button>
              {currentSection.transomBalanceTarget && (
                <Button
                  className="ml-4"
                  as="button"
                  onClick={() => currentSection.removeTransomBalanceTarget()}
                >
                  Clear bars balance
                </Button>
              )}
              <Button
                className="ml-4"
                as="button"
                onClick={() => setBarsBalanceTarget(currentSection.id)}
              >
                Balance bars
              </Button>
              {currentSection.barsBalanceTarget && (
                <Button
                  className="ml-4"
                  as="button"
                  onClick={() => currentSection.removeBarsBalanceTarget()}
                >
                  Clear bars balance
                </Button>
              )}
            </div>
          )}
        </>
      )}
      {currentSection && ['leaf'].includes(currentSection.sectionType) && (
        <div className="ml-4" style={{ minWidth: '200px' }}>
          <SelectInput
            value={leafOpening}
            inputOptions={inputItem}
            onChange={(option) => {
              currentSection.setLeafOpening(option.value);
              setLeafOpening(option);
            }}
          />
        </div>
      )}
    </div>
  );
});

export default SectionControls;
