import React from 'react';

// Config should consist of array of strings denoting the proportions for each child element
// in the order they were provided e.g. ["1/2", "1/2"] for two children.

type Props = {
  config: string[];
  children: JSX.Element[];
  gridStyle: any;
  lastItem?: boolean;
};

const Grid = ({ config, children, gridStyle, lastItem }: Props) => {
  const childrenLength = children.length;
  if (childrenLength !== config.length) {
    console.error(
      `Config out of bounds: ${config.length} present but ${childrenLength} children provided`
    );
  }

  let columnStyle;

  const marginLeft = lastItem ? '' : 'mr-16';

  switch (gridStyle && gridStyle.orientation) {
    case 'column':
      columnStyle = 'flex-col ' + marginLeft;
      break;
    case 'column-reverse':
      columnStyle = 'flex-col flex-col-reverse ' + marginLeft;
      break;

    default:
      break;
  }

  return (
    // <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
    <div className={`-mx-3 ${columnStyle} md:flex mb-6`}>
      {children.map((child: JSX.Element, index: number) => {
        const spacingStyle = index < childrenLength ? ' mb-6 md:mb-0' : '';
        return (
          <div
            className={`sm:w-full w-${config[index]} px-3 ${spacingStyle}`}
            key={config[index] + index}
          >
            {child}
          </div>
        );
      })}
    </div>
    // </div>
  );
};

export default Grid;
