import API from 'apiv1';
import React, { useEffect, useState } from 'react';
import { FaPoundSign, FaPercentage } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';

import { addImage } from '@appSrc/actions/formActions';

import Button from '@components/buttons/ButtonLink';
import MediaFile from '@components/forms/MediaFile';
import UploadFile from '@components/forms/UploadFile';

const Custom = ({ id = null, rfqID = null }) => {
  const [productRef, setProductRef] = useState('');
  const [site, setSite] = useState('');
  const [description, setDescription] = useState('');
  const [unitPrice, setUnitPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [priority, setPriority] = useState(0);
  const dispatch = useDispatch();

  const state = useSelector((state) => {
    return {
      // @ts-ignore
      file: state.forms['custom_product_image' + id],
    };
  });

  useEffect(() => {
    if (id == null) return;

    API.getProductItem(id).then((data) => {
      console.log('Data:', data);
      setProductRef(data['reference']);
      setSite(data['site'].value);
      setDescription(data['description']);
      setUnitPrice(data['unit_price_override']);
      setQuantity(data['quantity']);
      setDiscount(data['discount'] * 100);
      setPriority(data['priority']);
      if (data['diagram']) {
        dispatch(addImage('custom_product_image' + id, { url: data['diagram'] }));
      }
    });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = API.toFormData({
      data: JSON.stringify({
        rfq_id: rfqID,
        description,
        priority,
        unit_price: unitPrice,
        quantity,
        discount: discount / 100,
        site,
        ref: productRef,
        delete_diagram: state.file ? false : true,
      }),
      diagram: state.file || null,
    });
    try {
      if (id == null) {
        await API.createProductItem(data);
      } else {
        await API.updateProductItem(data, id);
      }
      NotificationManager.success('Product Item Saved');
      window.location.reload();
    } catch (err) {
      NotificationManager.error(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxHeight: 800 }}>
      <div className={'form-element'}>
        <div className="font-semibold text-lg mb-4">Product item</div>
        <div className={`flex items-start justify-start flex-col`}>
          <div className={'form-label'}>Ref</div>
          <div className="relative mb-2">
            <input
              type="text"
              autoComplete="off"
              value={productRef}
              className={`rounded-md focus:outline-none focus:bg-white focus:text-gray-900 `}
              onChange={(e) => setProductRef(e.target.value)}
            />
          </div>
          <div className={'form-label'}>Site</div>
          <div className="relative mb-2">
            <input
              type="text"
              autoComplete="off"
              value={site}
              className={`rounded-md focus:outline-none focus:bg-white focus:text-gray-900 `}
              onChange={(e) => setSite(e.target.value)}
            />
          </div>
          <div className={'form-label'}>Unit price</div>
          <div className="relative mb-2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <FaPoundSign />
            </span>
            <input
              type="number"
              step={0.01}
              autoComplete="off"
              value={unitPrice}
              className={`rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900 `}
              onChange={(e) => setUnitPrice(Number(e.target.value))}
            />
          </div>
          <div className={'form-label'}>Quantity</div>
          <div className="relative mb-2">
            <input
              type="number"
              step={0.01}
              autoComplete="off"
              value={quantity}
              className={`rounded-md focus:outline-none focus:bg-white focus:text-gray-900 `}
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
          </div>
          <div className={'form-label'}>Discount</div>
          <div className="relative mb-2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <FaPercentage />
            </span>
            <input
              type="number"
              step={0.01}
              autoComplete="off"
              value={discount}
              className={`rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900 `}
              onChange={(e) => setDiscount(Number(e.target.value))}
            />
          </div>
          <div className={'form-element'}>
            <div className={'form-label'}>Product Image</div>
            <div className={'flex items-start justify-start flex-col'}>
              <div className="form-element">
                <MediaFile
                  defaultValue={state.file}
                  file={state.file}
                  storeKey={'custom_product_image' + id}
                />
                <div className="flex items-center">
                  <UploadFile item={{ name: 'custom_product_image', recordId: id }} />
                </div>
              </div>
            </div>
          </div>
          <div className={'form-element'}>
            <div className={'form-label'}>Priority</div>
            <div className={'flex items-start justify-start flex-col'}>
              <input
                type="number"
                step={1}
                min={0}
                value={priority}
                className={'rounded-md pr-8 focus:outline-none focus:bg-white focus:text-gray-900'}
                onChange={(e) => setPriority(Number(e.target.value))}
              />
            </div>
          </div>
          <div className={'form-label'}>Description</div>
          <div className={`flex items-start justify-start flex-col`}>
            <textarea
              rows={6}
              className="form-textarea rounded-md focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent md:w-full"
              style={{ minWidth: 600 }}
              value={description || ''}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="pb-4">
        <Button as="button" type="submit">
          {id ? 'Update' : 'Create'}
        </Button>
      </div>
    </form>
  );
};

export default Custom;
