import React, { Component } from 'react';

type Option = {
  label: string;
  value: any;
  checked: boolean;
  disabled?: boolean;
};

type Props = {
  name?: string;
  label?: string;
  orientation?: 'vertical' | 'horizontal';
  wrapperClass?: string;
  required?: boolean;
  controlled?: boolean;
  options: Option[];
  onChange: (a: any, e?: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckBox = (props: Props) => {
  return (
    <div className={`form-element ${props.wrapperClass || ''}`} key={props.name}>
      {props.label && (
        <span className={'form-label' + (props.required ? ' required' : '')}>{props.label}</span>
      )}
      <div
        className={`flex ${
          props.orientation === 'vertical'
            ? 'items-start justify-start flex-col'
            : 'items-center justify-start space-x-2'
        }`}
      >
        {props.options.map((option, index) => (
          <label
            className="flex items-center justify-start space-x-2"
            key={option.label + option.value}
          >
            {props.controlled ? (
              <input
                className="form-checkbox h-4 w-4"
                type="checkbox"
                name={props.name}
                value={option.value}
                checked={option.checked}
                onChange={(e) => props.onChange(option.value, e)}
              />
            ) : (
              <input
                className="form-checkbox h-4 w-4"
                type="checkbox"
                name={props.name}
                value={option.value}
                defaultChecked={option.checked}
                onChange={(e) => props.onChange(option.value, e)}
              />
            )}
            <span>{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CheckBox;
