export const SELECT_MATERIAL = 'SELECT_MATERIAL';
export const REMOVE_SELECTED_MATERIAL = 'REMOVE_SELECTED_MATERIAL';

export function selectMaterial(namespace, key, item) {
  return {
    type: SELECT_MATERIAL,
    payload: { namespace, key, item },
  };
}

export function removeSelectedMaterial(namespace, key) {
  return {
    type: REMOVE_SELECTED_MATERIAL,
    payload: { namespace, key },
  };
}
