import API from 'apiv1';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import tw from 'twin.macro';

import '@appSrc/css/react-notifications.css';

import Button from '@components/buttons/ButtonLink';
import { itemToInput } from '@components/forms/GridForm';

import { update } from '@utils/formHelpers';

import DebugConsole from '../DebugOptions/DebugConsole';
import ContextForm from './ContextForm';

const decimalToPercent = (data) => {
  const newObj = {};
  Object.keys(data).forEach(
    (key) =>
      (newObj[key] = { ...data[key], rate: Math.round(Number(data[key].rate) * 100 * 100) / 100 })
  );
  return newObj;
};

const percentToDecimal = (data) => {
  const newObj = { size: [] };
  Object.keys(data).forEach(
    (key) =>
      key !== 'size' &&
      (newObj[key] = { ...data[key], rate: Math.round(Number(data[key].rate)) / 100 })
  );
  Object.keys(data.size).forEach((key) =>
    newObj.size.push({
      ...data.size[key],
      key,
      rate: Math.round(Number(data.size[key].rate)) / 100,
    })
  );
  return newObj;
};

const CasementProductionForm = ({ productOperationSummary }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm();

  React.useEffect(() => {
    reset({
      ...productOperationSummary.data,
      load_rates: decimalToPercent(productOperationSummary.data.load_rates),
    });
  }, [productOperationSummary]);

  const onSubmitFn = (data) => {
    update(data, 'load_rates', percentToDecimal);
    API.updateCasementProduction(
      API.toFormData({
        data: JSON.stringify(data),
      }),
      productOperationSummary.id
    )
      .then((resp) => {
        NotificationManager.success(
          'Operations Summary details have been updated successfully',
          'Update Successful',
          40000
        );
        // window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const frameItems = [
    {
      itemName: 'Sill',
      name: 'frame[sill][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Sill projection',
      name: 'frame[sill_projection][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Jamb (single)',
      name: 'frame[jamb][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Transom',
      name: 'frame[transom][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Mullion',
      name: 'frame[mullion][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Rain drip /m',
      name: 'frame[rain_drip][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Frame assembling per point',
      name: 'frame[frame_assembling_per_point][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Finish /m',
      name: 'frame[frame_finish][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Sealing /m',
      name: 'frame[sealing_frame][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
  ];

  const sashItems = [
    {
      itemName: 'Leaf',
      name: 'sashes[leaf][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Dummy',
      name: 'sashes[dummy][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Hinging',
      name: 'sashes[hinging][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Locks',
      name: 'sashes[locks][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Sash assembling per point',
      name: 'sashes[sash_assembling_per_point][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Finish /m',
      name: 'sashes[sash_finish][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Assembling leaf into frame',
      name: 'sashes[assembling_leaf_into_frame][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Assembling dummy into frame',
      name: 'sashes[assembling_dummy_into_frame][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Sealing sash /m',
      name: 'sashes[sealing_sash][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Fixing stay',
      name: 'sashes[fixing_stay][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Fixing fasteners',
      name: 'sashes[fixing_fastener][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Fixing child restrictors',
      name: 'sashes[fixing_child_restrictor][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
  ];

  const dimensionRates = productOperationSummary['data']
    ? productOperationSummary.data['load_rates']['size'].map((config) => ({
        itemName: config.label,
        name: `load_rates[size][${config.key}][rate]`,
        defaultValue: Number(config.rate) * 100,
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      }))
    : [];

  let archRates = [];
  let finishRates = [];
  let uniqueFeature = [];
  if (productOperationSummary['data']) {
    let smallArchconfig = productOperationSummary.data['load_rates']['small_arch'];
    let semiCircleConfig = productOperationSummary.data['load_rates']['semi_circle_arch'];
    console.log(smallArchconfig);
    archRates = [
      {
        itemName: smallArchconfig.label,
        name: `load_rates[small_arch][rate]`,
        defaultValue: '',
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      },
      {
        itemName: semiCircleConfig.label,
        name: `load_rates[semi_circle_arch][rate]`,
        defaultValue: '',
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      },
    ];

    let dualFinishConfig = productOperationSummary.data['load_rates']['dual_finish'];
    finishRates = [
      {
        itemName: dualFinishConfig.label,
        name: `load_rates[dual_finish][rate]`,
        defaultValue: '',
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      },
    ];

    uniqueFeature = [
      {
        itemName: 'Bay',
        name: 'load_rates[bay][rate]',
        type: 'number',
        defaultValue: '',
        inlineClass: 'w-1/3',
      },
    ];
  }

  const glazingItems = [
    {
      itemName: 'Assembling per pane',
      name: 'glazing[glazing_per_pane][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Georgian Bar',
      name: 'glazing[georgian_bar][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'True Bar',
      name: 'glazing[true_bar][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
  ];

  const packagingItems = [
    {
      itemName: 'Packaging',
      name: 'delivery_preparation[packaging][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Loading',
      name: 'delivery_preparation[loading][duration]',
      type: 'number',
      defaultValue: '',
      inlineClass: 'w-1/3',
    },
  ];

  function renderOpsSection(heading, items, unit = null) {
    return (
      <>
        <h5 className="mb-4 mt-4">{heading}</h5>
        <Table className="table-auto">
          <thead>
            <tr>
              <th className="text-left" style={{ maxWidth: '300px', width: '300px' }}>
                Item
              </th>
              <th className="text-left" style={{ maxWidth: '100px', width: '100px' }}>
                {unit || 'Minutes'}
              </th>
              <th></th>
            </tr>
          </thead>
          <TBody>
            {items.map((item) => (
              <tr>
                <td role="cell">{item.itemName}</td>
                <td role="cell">
                  {itemToInput(item, null, methods.register, methods.errors, methods.control)}
                </td>
                <td></td>
              </tr>
            ))}
          </TBody>
        </Table>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-row w-full">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={[]}
            formId={'business'}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          >
            <div className="max-w-md">
              {renderOpsSection('Frame', frameItems)}
              {renderOpsSection('Sashes', sashItems)}
              {renderOpsSection('Glazing', glazingItems)}
              {renderOpsSection('Packaging & Miscellenous', packagingItems)}
              {renderOpsSection('Unique Feature load rates', uniqueFeature, 'Rate (%)')}
              {renderOpsSection('Dimension load rates', dimensionRates, 'Rate (%)')}
              {renderOpsSection('Arch load rates', archRates, 'Rate (%)')}
              {renderOpsSection('Finish load rates', finishRates, 'Rate (%)')}
            </div>

            <div className="mt-6" style={{ maxWidth: '140px' }}>
              <Button as="button" type="submit" wrap={false}>
                Update Settings
              </Button>
            </div>
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

const Table = styled.table`
  ${tw`table-auto`}
  td[role="cell"] {
    ${tw`border border-black px-2 py-2`}
  }
`;

const TBody = styled.tbody`
  tr {
    td {
      .form-element {
        ${tw`mb-0`}
      }

      input {
        ${tw`border-0 m-0 focus:(ring-inset ring-opacity-100)`}
      }
    }
  }
`;
export default CasementProductionForm;
