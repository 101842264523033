import React, { useState } from 'react';
import { Provider } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';
import { store } from '@appSrc/store';

import BoxSash from '@components/configurators/BoxSash';
import Widget from '@components/widget';

const Page = ({
  materials,
  rfq,
  projectId,
  boxSashConfig = null,
  sites = [],
  recordId = null,
  mode = 'default',
}) => {
  const [data, setData] = useState({});

  return (
    <Widget
      title="New Product Item"
      breadcrumbs={[{ title: 'Projects' }, { title: rfq.name, link: `/projects/${projectId}` }]}
    >
      <BoxSash
        materials={materials}
        boxSashConfig={boxSashConfig}
        recordId={recordId}
        availableSiteOptions={sites.map((site) => ({ label: site, value: site }))}
        mode={mode}
        rfqID={rfq.id}
        context="productItems"
        redirectToURL={`/projects/${projectId}`}
      />
    </Widget>
  );
};

const NewProductItemsPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default NewProductItemsPage;
