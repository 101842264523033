import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IoPlaySkipBack, IoPlaySkipForward } from 'react-icons/io5';
import { MdFastRewind, MdFastForward } from 'react-icons/md';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useFlexLayout,
} from 'react-table';
import styled, { css } from 'styled-components';

import DebugConsole from '@appSrc/components/DebugOptions/DebugConsole';

// import { PageWithText } from '../pagination';

export { default as TruncatedCell } from './TruncatedCell';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} className="form-checkbox h-4 w-4" />;
});

function generatePageNumbers(count, current) {
  let pageCountArray = [];

  var shownPageNumbers = 3;

  for (var i = current; i <= count; i++) {
    i = Math.min(current, count - (shownPageNumbers - 1));

    if (pageCountArray.length < shownPageNumbers) {
      pageCountArray.push(i);
    } else {
      pageCountArray.push('...');
      pageCountArray.push(count);
      break;
    }
  }

  return pageCountArray;
}

function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
  if (length > total) length = total;

  let start = current - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + total - length);

  return Array.from({ length: length }, (el, i) => start + i);
}

type Cell = {
  Header: ((_) => JSX.Element) | string;
  accessor: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  Cell?: (_) => JSX.Element | string | number;
};

interface DatatableProps {
  columns: Cell[];
  data: any[];
  onRowClick?: (_) => void;
}

const Datatable = ({ columns, data, onRowClick }: DatatableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </>
          ),
        },
        ...columns,
      ]);
    }
  );

  const firstPage = pageIndex === 0;
  const lastPage = pageIndex === pageCount;

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="table w-full">
        <thead>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    css={
                      index === 0
                        ? `flex: 20 0 auto !important;
                        width: 20px !important;
                        box-sizing: border-box !important;
                        min-width: 0 !important;`
                        : ''
                    }
                  >
                    <div className="flex flex-row items-center justify-start">
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {column.isSorted && (
                        <span className="ml-auto">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FiChevronDown className="stroke-current text-2xs" />
                            ) : (
                              <FiChevronUp className="stroke-current text-2xs" />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row.original)}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      css={
                        j === 0
                          ? `flex: 20 0 auto !important;
                          width: 20px !important;
                          box-sizing: border-box !important;
                          min-width: 0 !important;
                          display: flex;
                          align-items: center;`
                          : `display: flex;
                          align-items: center;`
                      }
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex flex-row items-center justify-between my-4">
        {pageCount > 1 && (
          <div className="flex flex-wrap items-center justify-start pagination">
            <div
              onClick={() => gotoPage(0)}
              className={`flex items-center font-bold cursor-pointer text-grey-550 bg-blue-grey-70 ${
                firstPage ? 'link-disable' : 'hover:bg-deep-purple-M500 hover:text-white'
              }`}
              style={{
                backgroundColor: '#ecf1f6',
                height: '2.25rem',
                maxWidth: '2.25rem',
                minWidth: '2.25rem',
                padding: '0.5rem',
                fontSize: '0.9rem',
                lineHeight: '1rem',
                borderRadius: '0.6rem',
                color: '#7e8299',
                marginRight: '5px',
              }}
            >
              <MdFastRewind size={24} />
            </div>
            <div
              onClick={() => previousPage()}
              className={`flex items-center font-bold cursor-pointer text-grey-550 bg-blue-grey-70 ${
                firstPage ? 'link-disable' : 'hover:bg-deep-purple-M500 hover:text-white'
              }`}
              style={{
                height: '2.25rem',
                maxWidth: '2.25rem',
                minWidth: '2.25rem',
                padding: '0.5rem',
                fontSize: '0.9rem',
                lineHeight: '1rem',
                borderRadius: '0.6rem',
                marginRight: '5px',
              }}
            >
              <IoPlaySkipBack size={16} />
            </div>
            {getPagingRange(pageIndex + 1, { min: 1, total: pageCount, length: 5 }).map(
              (pageNumber) => (
                <div
                  key={pageNumber}
                  onClick={() => gotoPage(pageNumber - 1)}
                  className={`flex items-center cursor-pointer text-3xs justify-center ${
                    pageIndex + 1 !== pageNumber
                      ? 'hover:bg-deep-purple-M500 hover:text-white text-black'
                      : 'text-white bg-deep-purple-M500'
                  }`}
                  style={{
                    height: '2.25rem',
                    minWidth: '2.25rem',
                    padding: '0.5rem',
                    fontSize: '0.9rem',
                    lineHeight: '1rem',
                    borderRadius: '0.6rem',
                    marginRight: '5px',
                  }}
                >
                  <span className="block">{pageNumber}</span>
                </div>
              )
            )}
            <div
              onClick={() => nextPage()}
              className={`flex items-center font-bold cursor-pointer text-grey-550 bg-blue-grey-70 ${
                lastPage ? 'link-disable' : 'hover:bg-deep-purple-M500 hover:text-white'
              }`}
              style={{
                height: '2.25rem',
                maxWidth: '2.25rem',
                minWidth: '2.25rem',
                padding: '0.5rem',
                fontSize: '0.9rem',
                lineHeight: '1rem',
                borderRadius: '0.6rem',
                marginRight: '5px',
              }}
            >
              <IoPlaySkipForward size={16} />
            </div>

            <div
              onClick={() => gotoPage(pageCount - 1)}
              className={`flex items-center font-bold cursor-pointer text-grey-550 bg-blue-grey-70 ${
                lastPage ? 'link-disable' : 'hover:bg-deep-purple-M500 hover:text-white'
              }`}
              style={{
                height: '2.25rem',
                maxWidth: '2.25rem',
                minWidth: '2.25rem',
                padding: '0.5rem',
                fontSize: '0.9rem',
                lineHeight: '1rem',
                borderRadius: '0.6rem',
                marginRight: '5px',
              }}
            >
              <MdFastForward size={24} />
            </div>
          </div>
        )}
        <span>
          Page{' '}
          <b>
            {pageIndex + 1} of {pageOptions.length}
          </b>{' '}
        </span>

        <select
          className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <DebugConsole
        data={data.filter((_item, index) =>
          Object.keys(selectedRowIds)
            .map((i) => parseInt(i, 10))
            .includes(index)
        )}
      />
    </>
  );
};

export default Datatable;
