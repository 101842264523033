import { observer, inject } from 'mobx-react';
import React from 'react';
import { Group, Line, Arc, Shape, Rect } from 'react-konva';

import { pythagaros, bezierArcPoints } from '@utils/geometry';

type FrameProps = {
  casementStore: any;
};

type FrameState = {};

type Sizes = {
  width: number;
  height: number;
};

type HeadOnlyArchProps = {
  jamb: Sizes;
  head: Sizes;
  width: number;
  height: number;
  rise: number;
  radius: number;
};

const HeadOnlyArch = ({ jamb, head, width, rise, radius, ...shapeProps }: HeadOnlyArchProps) => {
  const yCenter = rise - radius;
  const upperArcCenter = { x: width / 2, y: yCenter };
  const yDistance = pythagaros(width / 2 - jamb.width, radius);
  const y = yCenter + yDistance;

  const [arcPointsP1, arcPointsP2] = bezierArcPoints(
    { x: jamb.width, y: y },
    { x: width - jamb.width, y: y },
    rise,
    upperArcCenter,
    radius
  );

  return (
    <Shape
      {...shapeProps}
      width={width}
      sceneFunc={(ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(jamb.width, y);
        // @ts-ignore
        ctx.bezierCurveTo(...arcPointsP1);
        // @ts-ignore
        ctx.bezierCurveTo(...arcPointsP2);
        ctx.lineTo(width, 0);
        ctx.lineTo(width, head.width + rise);
        ctx.lineTo(0, head.width + rise);
        ctx.lineTo(0, 0);
        // ctx.fillStyle = 'white';
        // ctx.fill();
        ctx.closePath();

        // Konva specific method
        ctx.fillStrokeShape(shape);
      }}
    />
  );
};

const FullyArched = ({
  jamb,
  head,
  width,
  rise,
  radius,
  height,
  ...shapeProps
}: HeadOnlyArchProps) => {
  // 1. Find the y coordinates at start (0) and end (width) of the arch
  // 2. Find bezier anchors. Inverse and draw them on the canvas.
  // 3. Likely will need to move 1. out as it is needed for the jambs.

  const yCenter = rise - radius;
  const upperArcCenter = { x: width / 2, y: yCenter };
  const yDistance = pythagaros(width / 2, radius);
  const y = yCenter + yDistance;

  // Draw the arc in two parts when it is a semi-circle.
  const [upperArcPointsP1, upperArcPointsP2] = bezierArcPoints(
    { x: 0, y: y },
    { x: width, y: y },
    rise,
    upperArcCenter,
    radius,
    false
  );

  const lowerArcRise = rise - head.width;
  const lowerArcRadius = radius - head.width;
  const lowerYCenter = lowerArcRise - lowerArcRadius;
  const lowerArcCenter = { x: width / 2, y: lowerYCenter };
  const lowerYDistance = pythagaros((width - jamb.width * 2) / 2, lowerArcRadius);
  const lowerY = lowerYCenter + lowerYDistance;

  const [lowerArcPointsP1, lowerArcPointsP2] = bezierArcPoints(
    { x: jamb.width, y: lowerY },
    { x: width - jamb.width, y: lowerY },
    lowerArcRise,
    lowerArcCenter,
    lowerArcRadius,
    true // Clockwise
  );

  return (
    <Shape
      {...shapeProps}
      width={width}
      height={height}
      closed={false}
      sceneFunc={(ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(jamb.width, lowerY);
        // FIXME: This seems to be a hack that needs to be investigated and if not in use and not needed it needs to be removed.
        // @ts-ignore
        if (width / 2 === radius && 1 === 2) {
          ctx.arc(lowerArcCenter.x, lowerArcCenter.y, lowerArcRadius, 0, Math.PI, false);
          ctx.moveTo(width - jamb.width, lowerY);
        } else {
          // @ts-ignore
          ctx.bezierCurveTo(...lowerArcPointsP1);
          // @ts-ignore
          ctx.bezierCurveTo(...lowerArcPointsP2);
        }

        ctx.lineTo(width, y);

        // FIXME: This seems to be a hack that needs to be investigated and if not in use and not needed it needs to be removed.
        // @ts-ignore
        if (width / 2 === radius && 1 === 2) {
          ctx.arc(upperArcCenter.x, upperArcCenter.y, radius, 0, Math.PI, false);
          ctx.moveTo(0, y);
          ctx.lineTo(jamb.width, lowerY);
        } else {
          // @ts-ignore
          ctx.bezierCurveTo(...upperArcPointsP1);
          // @ts-ignore
          ctx.bezierCurveTo(...upperArcPointsP2);
        }
        // ctx.lineTo(width, head.width + rise);
        // ctx.lineTo(0, head.width + rise);
        // ctx.lineTo(0, 0);
        // ctx.closePath();

        // Konva specific method
        ctx.fillStrokeShape(shape);
      }}
    />
  );
};

class Frame extends React.Component<FrameProps, FrameState> {
  renderHead() {
    const { casementStore } = this.props;
    const { headStyle, selectedSectionId, finalSize, arcRise, arcRadius } = casementStore;
    const headOffset = finalSize.height - casementStore.visibleHeadWidth;

    const headLineProps = {
      closed: true,
      stroke: 'black',
      strokeWidth: 4,
      fill: selectedSectionId === 'head' ? 'green' : 'white',
      opacity: selectedSectionId === 'head' ? 1 : 1,
    };

    if (headStyle === 'standard') {
      return (
        <>
          <Line
            {...headLineProps}
            onClick={() => casementStore.setCurrentSection('head')}
            onMouseEnter={(e) => (e.target.getStage().container().style.cursor = 'pointer')}
            onMouseLeave={(e) => (e.target.getStage().container().style.cursor = 'default')}
            points={[
              0,
              headOffset,
              finalSize.width,
              headOffset,
              finalSize.width,
              finalSize.height,
              0,
              finalSize.height,
            ]}
          />
          {casementStore.hasHeadRainDrip && (
            <Rect
              {...headLineProps}
              onClick={() => casementStore.setCurrentSection('head')}
              x={0}
              y={finalSize.height}
              width={finalSize.width}
              height={-casementStore.headRainDrip.width}
            />
          )}
          {/* Rebate */}
          <Rect
            {...headLineProps}
            x={casementStore.visibleJambWidth}
            y={headOffset}
            width={casementStore.visiualOpeningSizes.width}
            height={-casementStore.rebate.width}
          />
        </>
      );
    }

    if (headStyle === 'head_only_arch') {
      const headArcProps = {
        jamb: { width: casementStore.visibleJambWidth },
        head: { width: casementStore.visibleHeadWidth },
        width: finalSize.width,
        rise: arcRise,
        radius: arcRadius,

        onClick: () => casementStore.setCurrentSection('head'),
        fillEnabled: true,
        closed: true,
        stroke: 'black',
        strokeWidth: 4,
        fill: selectedSectionId === 'head' ? 'green' : 'white',
        opacity: 1,
        x: 0,
        y: finalSize.height - (casementStore.visibleHeadWidth + arcRise),
        height: casementStore.visibleHeadWidth + arcRise,
      };
      // @ts-ignore
      return <HeadOnlyArch {...headArcProps} />;
    }

    if (headStyle === 'full_arch') {
      const headArcProps = {
        jamb: { width: casementStore.visibleJambWidth },
        head: { width: casementStore.visibleHeadWidth },
        width: finalSize.width,
        rise: arcRise,
        radius: arcRadius,

        onClick: () => casementStore.setCurrentSection('head'),
        // fillEnabled: true,
        // closed: true,
        stroke: 'black',
        strokeWidth: 4,
        fill: selectedSectionId === 'head' ? 'green' : 'white',
        opacity: 1,
        x: 0,
        y: finalSize.height - arcRise,
        height: casementStore.visibleHeadWidth + arcRise,
      };
      // @ts-ignore
      return <FullyArched {...headArcProps} />;
    }
  }

  render() {
    const lineProps = {
      closed: true,
      stroke: 'black',
      strokeWidth: 4,
      fill: 'white',
    };
    const { casementStore } = this.props;
    const [sillPoints, sillLinePoints] = casementStore.sillPoints;
    const leftJambPoints = casementStore.leftJambPoints;
    const rightJambPoints = casementStore.rightJambPoints;

    return (
      <Group>
        {/* Sill */}
        <Line {...lineProps} points={sillPoints} />
        <Line stroke="black" strokeWidth={1} points={sillLinePoints} />
        <Rect
          {...lineProps}
          x={casementStore.visibleJambWidth}
          y={casementStore.visibleSillHeight}
          width={casementStore.visiualOpeningSizes.width}
          height={casementStore.sill.width - casementStore.visibleSillHeight}
        />
        {/* Jambs */}
        <Line {...lineProps} points={leftJambPoints} />
        <Rect
          {...lineProps}
          x={casementStore.visibleJambWidth}
          y={casementStore.sill.width}
          width={casementStore.rebate.width}
          height={casementStore.visiualOpeningSizes.height}
        />
        <Line {...lineProps} points={rightJambPoints} />
        <Rect
          {...lineProps}
          x={casementStore.visibleJambWidth + casementStore.visiualOpeningSizes.width}
          y={casementStore.sill.width}
          width={-casementStore.rebate.width}
          height={casementStore.visiualOpeningSizes.height}
        />
        {/* Head */}
        {this.renderHead()}
      </Group>
    );
  }
}

export default inject('casementStore')(observer(Frame));
