import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../store';

import Datatable, { TruncatedCell } from '@components/datatable';
import Button from '@components/buttons/ButtonLink';
import RecordActions from '@components/datatable/RecordActions';
import Widget from '@components/widget';
import DrawingInstructionsForm from '@components/forms/DrawingInstructionsForm';
import BoxedLayout from '@appSrc/layouts/content/Boxed';

const Page = ({ drawingInstructions }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, ...props }) => (
        <a className="text-blue" href={`/drawing_editor/${props.row.original['id']}`}>
          {value}
        </a>
      ),
    },
    {
      Header: 'Box Sash Config',
      accessor: 'config_name',
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => RecordActions(value, DrawingInstructionsForm, null),
    },
  ];

  return (
    <Widget
      title="Clients"
      right={
        <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
          <Button as="a" href={`/drawing_instructions/new`}>
            New Drawing
          </Button>
        </div>
      }
    >
      <Datatable columns={columns} data={drawingInstructions} />
    </Widget>
  );
};

const DrawingInstructionsPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default DrawingInstructionsPage;
