import React from 'react';

interface Breadcrumb {
  title: string;
  link?: string;
  current?: boolean;
}
interface WidgetProps {
  title?: string;
  description?: string;
  right?: JSX.Element;
  borderless?: boolean;
  children: JSX.Element | JSX.Element[];
  breadcrumbs?: Breadcrumb[];
}

const Widget = ({
  title = null,
  description = null,
  right = null,
  borderless = true,
  children,
  breadcrumbs = [],
}: WidgetProps): JSX.Element => {
  return (
    <div
      className={`card card-custom w-full px-4 py-4 mb-4 rounded-lg bg-white border border-none dark:bg-dark-95 dark:border-dark-90 overflow-x-auto ${
        borderless ? '' : 'shadow-defined-outline'
      }`}
    >
      {(title || description || right) && (
        <div className="flex flex-row items-center justify-between mb-6">
          <div className="flex flex-col px-2">
            <div className="flex items-center flex-wrap mr-1">
              <div className="flex items-baseline flex-wrap mr-5">
                <div className="font-semi-bold font-medium text-lg text-dark-200 my-1 mr-5">
                  {title}
                </div>

                {breadcrumbs.length !== 0 && (
                  <div className="flex flex-row breadcrumb breadcrumb-transparent breadcrumb-dot p-0 my-2 text-xs space-x-2">
                    {breadcrumbs.map((breadcrumb) => (
                      <div className="breadcrumb-item text-grey-500">
                        {breadcrumb['link'] && (
                          <a href={breadcrumb['link']} className=" text-grey-500">
                            {breadcrumb.title}
                          </a>
                        )}

                        {!breadcrumb['link'] && (
                          <span
                            className={
                              breadcrumb['current'] ? 'text-deep-purple-M100' : 'text-grey-500'
                            }
                          >
                            {breadcrumb.title}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-2 text-sm text-dark-200 text-opacity-50 font-bold">
              {description}
            </div>
          </div>
          {right}
        </div>
      )}
      {children}
    </div>
  );
};

export default Widget;
