// @ts-nocheck
import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import styled from 'styled-components';
import tw from 'twin.macro';

import '@appSrc/css/react-notifications.css';

import SelectorModal from '@components/DataModal';
import DebugConsole from '@components/DebugOptions/DebugConsole';
import Card from '@components/configurators/Card';
import { itemToInput } from '@components/forms/GridForm';
import { NumberInput } from '@components/forms/numberInput';

const FORM_DEFAULTS = {
  name: '',
  category: { label: 'Traditional', value: 'traditional' },
  sub_category: { label: 'Flush', value: 'flush' },
};

const DEFAULT_MATERIALS = {
  weatherseal: { id: null, item: null, quantity: 0 },
  hinge: { id: null, item: null, quantity: 0 },
  top_hung_hinge: { id: null, item: null, quantity: 0 },
  espag_handle: { id: null, item: null, quantity: 0 },
  espag: { id: null, item: null, quantity: 0 },
  top_hung_espag: { id: null, item: null, quantity: 0 },
  keeps: { id: null, item: null, quantity: 0 },
  stay: { id: null, item: null, quantity: 0 },
  handle: { id: null, item: null, quantity: 0 },
  restrictor: { id: null, item: null, quantity: 0 },
  shootbolt: { id: null, item: null, quantity: 0 },
  flushbolt: { id: null, item: null, quantity: 0 },
  trickle_vent: { id: null, item: null, quantity: 0 },
  brake: { id: null, item: null, quantity: 0 },
  extension_set: { id: null, item: null, quantity: 0 },
  lockable_stay_pins: { id: null, item: null, quantity: 0 },
};

const MODE_HANDLER = {
  edit: API.updateCasementHardwareSet,
  default: API.createCasementHardwareSet,
};

const CasementHardwareSetForm = ({
  formId,
  recordId = null,
  hardwareSet = null,
  mode = 'default',
  hardware,
}) => {
  const [data, onSubmit] = useState(null);
  const [materials, setMaterials] = useState(DEFAULT_MATERIALS);

  const { reset, ...methods } = useForm({ defaultValues: FORM_DEFAULTS });

  const handleMaterialQuantityChange = (materialKey, value) => {
    setMaterials({ ...materials, [materialKey]: { ...materials[materialKey], quantity: value } });
  };

  const handleMaterialsChange = (materialKey, value: any) => {
    if (value) {
      setMaterials({
        ...materials,
        [materialKey]: { ...materials[materialKey], id: value.id, item: value },
      });
      return true;
    }

    setMaterials({
      ...materials,
      [materialKey]: { ...materials[materialKey], id: null, item: null, quantity: 0 },
    });
  };

  const handleSubmtiForm = async () => {
    const result = await methods.trigger();

    if (result) {
      const { name, category, sub_category } = methods.getValues();

      const formData = {
        name: name,
        category: category.value,
        sub_category: sub_category.value,
      };

      Object.entries(materials).forEach(([key, value]) => {
        formData[key] = { id: value.id, quantity: value.quantity };
      });

      MODE_HANDLER[mode || 'default'](API.toFormData({ data: JSON.stringify(formData) }), recordId)
        .then((resp) => window.location.reload())
        .catch(() =>
          NotificationManager.error(
            'There was an error while submitting your form, our team has been informed of this error.',
            'Submission Error',
            40000
          )
        );
    }
  };

  useEffect(() => {
    if (mode !== 'edit') return;
    if (recordId == null) return;

    API.getCasementHardwareSet(recordId).then((data) => {
      reset({
        name: data['name'],
        category: data['category'],
        sub_category: data['sub_category'],
      });

      setMaterials(data['materials']);
    });
  }, [recordId, mode]);

  const renderCard = (item) => {
    if (item) {
      return (
        <Card
          defaultOpen={false}
          title={[item['name'], item['finish'], item['supplier']].join(' - ')}
          narrowStyle
        />
      );
    } else {
      return <Card defaultOpen={false} blank={true} narrowStyle />;
    }
  };

  const renderSection = (title, name, hasQuantity = false) => {
    return (
      <CardContainer>
        <SectionContainer className={'w-5/6'} minWidth={500}>
          <div className="form-label">{title}</div>
          {renderCard(materials[name].item)}
        </SectionContainer>
        {hasQuantity && (
          <SectionContainer maxWidth={50}>
            <div className="form-label">Qty</div>
            <NumberInput
              name={''}
              value={materials[name].quantity}
              onChange={(e) => handleMaterialQuantityChange(name, Number(e.target.value))}
              min={0}
              step={0.1}
              className="form-textarea"
            />
          </SectionContainer>
        )}
        <ButtonsContainer>
          <SelectorModal
            materialKey="hardware"
            data={
              name === 'weatherseal'
                ? hardware.filter((h) => h['hardware_type'] === 'INSULATOR')
                : hardware.filter((h) => h['hardware_type'] !== 'INSULATOR')
            }
            clearable={true}
            onItemSelect={(item) => handleMaterialsChange(name, item)}
            handleClear={(_item) => handleMaterialsChange(name, null)}
          />
        </ButtonsContainer>
      </CardContainer>
    );
  };

  return (
    <div className="flex flex-row">
      <FormProvider {...methods} reset={reset}>
        <form
          onSubmit={methods.handleSubmit(handleSubmtiForm)}
          className="form flex flex-col w-full"
          id={formId || null}
        >
          <Container>
            <CardContainer>
              <SectionContainer minWidth={350}>
                {itemToInput(
                  {
                    label: 'Name',
                    name: 'name',
                    error: { required: 'Please werite a name' },
                    type: 'text',
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Category',
                    name: 'category',
                    error: { required: 'Please select a casement category' },
                    type: 'newSelect',
                    options: [
                      { label: 'Traditional', value: 'traditional' },
                      { label: 'Performance', value: 'performance' },
                    ],
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Sub-category',
                    name: 'sub_category',
                    error: { required: 'Please select a casement sub-category' },
                    type: 'newSelect',
                    options: [
                      { label: 'Flush', value: 'flush' },
                      { label: 'Stormproof', value: 'stormproof' },
                    ],
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </SectionContainer>
            </CardContainer>
            {renderSection('Weatherseal', 'weatherseal')}
            {renderSection('Hinge', 'hinge')}
            {renderSection('Top hung hinge', 'top_hung_hinge')}
            {renderSection('Espag handle', 'espag_handle')}
            {renderSection('Espag', 'espag')}
            {renderSection('Top hung espag', 'top_hung_espag')}
            {renderSection('Keeps', 'keeps')}
            {renderSection('Stay', 'stay')}
            {renderSection('Handle', 'handle')}
            {renderSection('Restrictor', 'restrictor')}
            {renderSection('Shootbolt', 'shootbolt')}
            {renderSection('Flushbolt', 'flushbolt')}
            {renderSection('Trickle vent', 'trickle_vent')}
            {renderSection('Brake', 'brake')}
            {renderSection('Extension set', 'extension_set')}
            {renderSection('Lockable stay pins', 'lockable_stay_pins')}
          </Container>
        </form>
      </FormProvider>
      <DebugConsole data={data} />
    </div>
  );
};

const Container = styled.div`
  ${tw`flex flex-col w-full`}
`;

const CardContainer = styled.div`
  ${tw`flex flex-row mt-6 space-x-6 w-5/6`}
`;

const SectionContainer = styled.div`
  ${tw`flex flex-col`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-col space-y-2 self-center mt-4`}
`;

export default CasementHardwareSetForm;
