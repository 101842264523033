import React from 'react';
import { ImCross } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { removeFile } from '../../actions/formActions';

const MediaFiles = ({ file, storeKey, defaultValue }) => {
  const dispatch = useDispatch();

  function removeSelectedFile() {
    if (file.id) {
      file._destroy = true;
    } else {
      dispatch(removeFile(storeKey));
    }
  }

  const filePresent = (!(file == undefined) && !file._destroy) || defaultValue;

  const encodeFile = () => {
    if (typeof file === 'undefined') return defaultValue;

    if (file.url) {
      return file.url;
    } else {
      return URL.createObjectURL(file);
    }
  };

  return (
    <FilesContainer>
      {filePresent ? (
        <FileWrapper>
          <Media src={encodeFile()} />
          <Remove onClick={removeSelectedFile}>
            <ImCross className="text-white" />
          </Remove>
        </FileWrapper>
      ) : (
        <FileWrapper>
          <DummyMedia className="flex items-center justify-center">Image</DummyMedia>
        </FileWrapper>
      )}
    </FilesContainer>
  );
};

const Remove = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #202641;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
`;

const Media = styled.img`
  max-width: 200px;
  max-height: 200px;
  background-color: #f0f2f6;
`;

const DummyMedia = styled.div`
  width: 200px;
  height: 200px;
  background-color: #f0f2f6;
  border-radius: 2px;
`;

const FileWrapper = styled.div`
  position: relative;
  margin-right: 12px;

  &:hover ${Remove} {
    visibility: visible;
  }
`;

const FilesContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding-top: 10px;
  img {
    object-fit: cover;
  }
`;

export default MediaFiles;
