import { Provider } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React from 'react';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import createStore from '@stores/CasementStore';

import Canvas from '@components/configurators/Casement/Canvas';
import Widget from '@components/widget';

const Page = ({ recordId = null, snapshot }) => {
  return (
    <Widget title="New Casement">
      <Provider casementStore={createStore(snapshot)}>
        <Canvas id={recordId} />
      </Provider>
    </Widget>
  );
};

const RenderCasement = (props) => {
  return BoxedLayout(<Page {...props} />);
};

export default RenderCasement;
