import _ from 'lodash';

import {
  SET_CONFIG_DATA
} from '../actions/configuratorActions';

export default function configurationData(
  state = {
    timber: [],
    glazingUnits: [],
    finishSystems: [],
    finishes: [],
  },
  action
) {
  switch (action.type) {
    case SET_CONFIG_DATA: {
      const { keyPath, data } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, keyPath, data);
    }
    default:
      return state;
  }
}
