import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export const ComboMultiSelect = (props) => {
  const { className, ...otherProps } = props;
  return (
    <CreatableSelect
      isMulti
      isClearable
      isSearchable
      menuPortalTarget={document.body}
      className={`theme-multi-select  min-w-full ${className}`}
      styles={{
        control: (styles) => ({
          ...styles,
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          backgroundColor: '#fff',
          borderColor: '#e2e8f0',
          borderWidth: '1px',
          borderRadius: '0.675rem',
          lineHeight: 1.5,
          fontSize: '0.825rem',
          width: '100%',
        }),
        valueContainer: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
          borderWidth: 0,
        }),
        menu: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
          zIndex: 9999,
        }),
        menuPortal: (styles) => ({
          ...styles,
          zIndex: 9990,
        }),
        //   container: (styles) => ({ ...styles, backgroundColor: 'green' }),
      }}
      {...otherProps}
    />
  );
};

const MultiSelect = (props) => {
  const { className, ...otherProps } = props;
  return (
    <Select
      isMulti
      isClearable
      isSearchable
      menuPortalTarget={document.body}
      className={`theme-multi-select  min-w-full ${className}`}
      styles={{
        control: (styles) => ({
          ...styles,
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          backgroundColor: '#fff',
          borderColor: '#e2e8f0',
          borderWidth: '1px',
          borderRadius: '0.675rem',
          lineHeight: 1.5,
          fontSize: '0.825rem',
          width: '100%',
        }),
        valueContainer: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
          borderWidth: 0,
        }),
        menu: (styles) => ({
          ...styles,
          paddingTop: '0.25rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.25rem',
          paddingLeft: '0.5rem',
          borderRadius: '0.675rem',
          zIndex: 9999,
        }),
        menuPortal: (styles) => ({
          ...styles,
          zIndex: 9990,
        }),
        //   container: (styles) => ({ ...styles, backgroundColor: 'green' }),
      }}
      {...otherProps}
    />
  );
};

export default MultiSelect;
