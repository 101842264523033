export const ADD_IMAGE = 'ADD_IMAGE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_CLIENTS = 'SET_CLIENTS';

export function addImage(key, file) {
  return {
    type: ADD_IMAGE,
    payload: { key, file },
  };
}

export function removeFile(key) {
  return {
    type: REMOVE_FILE,
    payload: { key },
  };
}

export function setSuppliers(data) {
  return {
    type: SET_SUPPLIERS,
    payload: data,
  };
}

export function setClients(data) {
  return {
    type: SET_CLIENTS,
    payload: data,
  };
}
