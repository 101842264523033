import Fuse from 'fuse.js';
import React, { useState } from 'react';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import DataModal from '@components/modals/DataModal';

import ConfigItemsList from '../ConfigItemsList';
import Section, { InputContainer } from '../Section';

const MouldingsAndTrimmings = ({ recordId = [], mouldings = [] }) => {
  const [query, setQuery] = useState(null);
  const [items, setItems] = useState([]);
  const options = {
    includeScore: true,
    keys: ['name', 'finish', 'supplier'],
  };

  const fuse = new Fuse(mouldings, options);

  const handleSearch = (q) => {
    return fuse.search(q);
  };

  const handleSelection = (item) => {
    console.log(`Selected: `, item);
    setItems([...items, item]);
  };

  const handleRemoval = (item) => {
    setItems([...items.filter((e) => e.name !== item.name)]);
  };

  const itemTransform = (item) => {
    return { ...item, title: item.name + ' - ' + item.finish + ' - ' + item.dimensions };
  };

  const columns = [
    {
      title: 'Name',
      accessor: 'name',
    },
    {
      title: 'Dimensions',
      accessor: 'dimensions',
    },
    {
      title: 'Finish',
      accessor: 'finish',
    },
    {
      title: 'Supplier',
      accessor: 'supplier',
    },
  ];

  const buttons = [
    <DataModal
      key="am1"
      modalButtonText="Add Mouldings"
      buttonClass="whitespace-nowrap"
      columns={columns}
      data={query ? handleSearch(query).map((r) => r.item) : mouldings}
      handleSearch={setQuery}
      onSelect={handleSelection}
    />,
  ];

  return (
    <Section title={'Mouldings & Trimmings'}>
      <InputContainer className="flex flex-row space-x-12 w-full">
        <InputContainer className="flex flex-col space-y-2 w-full">
          <span className="pl-16">Select trimmings, mouldings and architraves</span>
          <InputContainer className="flex flex-row w-full">
            <ConfigItemsList
              buttons={buttons}
              items={items}
              onRemove={handleRemoval}
              itemTransform={itemTransform}
            />
          </InputContainer>
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default MouldingsAndTrimmings;
