import { Provider, observer, inject } from 'mobx-react';
import React from 'react';
import { Stage, Layer, Line, Circle } from 'react-konva';

import { ICasement } from '@stores/CasementStore';

import Button from '@components/buttons/ButtonLink';

import { calculateCanvasScale } from '@utils/canvasHelpers';
import { calculateLevelLength } from '@utils/dimensionHelpers';

import Dimensions from './Dimensions';
import Frame from './Frame';
import ModelSectionOrigins from './ModelSectionOrigins';
import SectionsView from './SectionsView';

const WIDTH = 960;
const HEIGHT = 1000;

type Props = {
  stageRef: React.RefObject<any>;
  casementStore?: ICasement;
  id?: Number;
  registerSashRef?: Function;
};

class Canvas extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    if (e.target.nodeType === 'Stage') {
      this.props.casementStore.setCurrentSection(null);
    }
  };

  handleDownload = () => {
    const downloadURI = (uri, name) => {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    var dataURL = this.props.stageRef.current.toDataURL({ pixelRatio: 3 });
    downloadURI(dataURL, `${this.props.id}-full.png`);
  };

  render() {
    const { size, sashesOnly, sillHornsWidth } = this.props.casementStore;
    const levelsX = calculateLevelLength(this.props.casementStore.currentDimensionLevels['x']);
    const levelsY = calculateLevelLength(this.props.casementStore.currentDimensionLevels['y']);
    let scale = calculateCanvasScale(
      WIDTH,
      HEIGHT,
      size.width + sillHornsWidth * 2 + levelsX,
      size.height + levelsY
    );
    let secondaryScale = scale;
    const modelOrigin = {
      x: 15,
      y: 15,
    };

    modelOrigin.x = (WIDTH - (size.width - sillHornsWidth * 2 + levelsX) * scale) / 2 + 5;
    modelOrigin.y = HEIGHT - (size.height + levelsY) * scale + 5;

    return (
      <div className="flex flex-col">
        <div className="mr-2">
          <Button as="button" onClick={() => this.handleDownload()} id="dw-diagram">
            Download
          </Button>
        </div>
        <div style={{ width: WIDTH + 20, height: HEIGHT + 20 }}>
          <Stage
            ref={this.props.stageRef}
            width={WIDTH}
            height={HEIGHT}
            onClick={this.handleClick}
            scaleY={-1}
            offsetY={HEIGHT}
          >
            <Provider casementStore={this.props.casementStore}>
              <Layer
                x={modelOrigin.x}
                y={modelOrigin.y}
                scaleX={secondaryScale}
                scaleY={secondaryScale}
                offsetX={0}
                offsetY={0}
              >
                {/* <Line stroke="red" strokeWidth={1} points={[0, 0, WIDTH - PADDING, 0]} />
              <Line stroke="orange" strokeWidth={1} points={[0, 0, 0, HEIGHT - PADDING]} />
              <Circle radius={5} x={0} y={0} fill="green" stroke="none" strokeWidth={0} /> */}
                {/* @ts-ignore */}
                {!sashesOnly && <Frame />}
                <SectionsView />
                {/* @ts-ignore */}
                <Dimensions scaleFactor={scale} mainDimensionsOnly={this.props.id != null} />

                {/* <ModelSectionOrigins section={this.props.casementStore.sections[0]} /> */}
              </Layer>
            </Provider>
          </Stage>
        </div>
      </div>
    );
  }
}

export default inject('casementStore')(observer(Canvas));
