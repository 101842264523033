import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import { isAlive, onSnapshot } from 'mobx-state-tree';
import React from 'react';
import { Stage, Layer, Group, Circle, Line } from 'react-konva';
import { connect } from 'react-redux';

import { setConfigurationData } from '@appSrc/actions/configuratorActions';

import { ICasement } from '@stores/CasementStore';
import { ISection } from '@stores/SectionStore';

import SectionDimensions from '@components/configurators/Casement/GeometricDimensions/SectionDimensions';
import SectionView from '@components/configurators/Casement/SectionView';

import { calculateCanvasScale } from '@utils/canvasHelpers';
import { calculateLevelLength } from '@utils/dimensionHelpers';

import Dimensions from './Dimensions';
import Frame from './Frame';

const WIDTH = 960;
const HEIGHT = 1000;

type Props = {
  stageRef?: React.RefObject<any>;
  casementStore?: ICasement;
  id?: Number;
  registerSashRef?: Function;
  dispatch?: Function;
};

type State = {
  sections: ISection[];
};

// @ts-ignore
const FrameDiagramsToSave = observer(({ rootSection }) => {
  if (!rootSection || !isAlive(rootSection)) return <></>;

  const { size, currentDimensionLevels } = rootSection.store;
  const levelsX = calculateLevelLength(currentDimensionLevels['x']);
  const levelsY = calculateLevelLength(currentDimensionLevels['y']);
  let scale = calculateCanvasScale(WIDTH, HEIGHT, size.width + levelsX, size.height + levelsY);
  let secondaryScale = scale;
  const modelOrigin = {
    x: 15,
    y: 15,
  };

  modelOrigin.x = (WIDTH - (size.width + levelsX) * scale) / 2 + 5;
  modelOrigin.y = HEIGHT - (size.height + levelsY) * scale - 10;

  return (
    <React.Fragment key={rootSection.id}>
      <div
        key={'hidden' + rootSection.id}
        id={rootSection.id}
        className="hidden js-frame-stage-container"
      ></div>
      <Stage
        key={rootSection.id + '-stage'}
        name={'frame-stage'}
        container={`#${rootSection.id}`}
        width={WIDTH}
        height={HEIGHT}
        scaleY={-1}
        offsetY={HEIGHT}
      >
        <Layer
          x={modelOrigin.x}
          y={modelOrigin.y}
          scaleX={secondaryScale}
          scaleY={secondaryScale}
          offsetX={0}
          offsetY={0}
        >
          {/* <Line stroke="red" strokeWidth={1} points={[0, 0, WIDTH - PADDING, 0]} />
              <Line stroke="orange" strokeWidth={1} points={[0, 0, 0, HEIGHT - PADDING]} />
              <Circle radius={5} x={0} y={0} fill="green" stroke="none" strokeWidth={0} /> */}
          <Frame casementStore={rootSection.store} />
          <Group {...rootSection.absoluteOrigin} {...rootSection.size} name="sections">
            <SectionView
              store={rootSection.store}
              section={rootSection}
              key={rootSection.id}
              rootOnly={true}
            />
          </Group>
          <Dimensions
            scaleFactor={scale}
            casementStore={rootSection.store}
            mainDimensionsOnly={false}
          />
        </Layer>
      </Stage>
    </React.Fragment>
  );
});

// @ts-ignore
const SectionDiagramsToSave = observer(({ section, registerSectionRef }) => {
  if (!section || !isAlive(section)) return <></>;

  if (['fixed', 'dummy', 'leaf'].includes(section.sectionType)) {
    const { size } = section;
    const levelsX = calculateLevelLength(section.store.currentDimensionLevels['x']);
    const levelsY = calculateLevelLength(section.store.currentDimensionLevels['y']);
    let scale = calculateCanvasScale(WIDTH, HEIGHT, size.width + levelsX, size.height + levelsY);
    const modelOrigin = {
      x: 15,
      y: 15,
    };

    modelOrigin.x = (WIDTH - (size.width + levelsX) * scale) / 2 + 5;
    modelOrigin.y = HEIGHT - (size.height + levelsY) * scale + 5;

    return (
      <React.Fragment key={section.id}>
        <div
          key={'hidden' + section.id}
          id={section.id}
          className="hidden js-sash-stage-container"
        ></div>
        <Stage
          key={section.id + '-stage'}
          name={'sash-stage'}
          container={`#${section.id}`}
          width={WIDTH}
          height={HEIGHT}
          scaleY={-1}
          offsetY={HEIGHT}
        >
          <Layer
            x={modelOrigin.x}
            y={modelOrigin.y}
            scaleX={scale}
            scaleY={scale}
            offsetX={0}
            offsetY={0}
          >
            {/* <Line stroke="red" strokeWidth={1} points={[0, 0, WIDTH - PADDING, 0]} />
          <Line stroke="orange" strokeWidth={1} points={[0, 0, 0, HEIGHT - PADDING]} />
          <Circle radius={5} x={0} y={0} fill="green" stroke="none" strokeWidth={0} /> */}
            <Group offsetX={section.origin.x} offsetY={section.origin.y} {...section.size}>
              <SectionDimensions section={section} scaleFactor={scale} />
              <SectionView x={0} y={0} store={section.store} section={section} />
              {/* <Circle radius={5} x={0} y={0} fill="red" stroke="none" strokeWidth={0} /> */}
            </Group>
          </Layer>
        </Stage>
      </React.Fragment>
    );
  } else {
    return null;
  }
});

class DiagramsToSave extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
    };

    // @ts-ignore
    this.childSections = [];
    // @ts-ignore
    this.unsubscribe = null;
  }

  componentDidMount() {
    // @ts-ignore
    this.unsubscribe = onSnapshot(this.props.casementStore, (snapshot) => {
      this.setState({ sections: this.props.casementStore.diagramableSections });
    });
  }

  componentWillUnmount(): void {
    // @ts-ignore
    this.unsubscribe && this.unsubscribe();
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return !isEqual(
  //     this.props.sectionsToRender.map((s) => s.id),
  //     nextProps.sectionsToRender.map((s) => s.id)
  //   );
  // }

  // componentDidUpdate(prevProps) {
  //   console.log('DiagramsToSave.componentDidUpdate');
  // }

  render() {
    console.log('DiagramsToSave.render');
    return (
      <React.Fragment key={this.props.casementStore.sections[0].id}>
        <FrameDiagramsToSave rootSection={this.props.casementStore.sections[0]} />
        {this.state.sections.map((section) => {
          return (
            <SectionDiagramsToSave
              key={section.id}
              section={section}
              registerSectionRef={this.props.registerSashRef}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default connect()(DiagramsToSave);
