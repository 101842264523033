import React from 'react';
import { useSelector } from 'react-redux';

import { Collapse } from 'react-collapse';

const DebugConsole = ({ data }) => {
  const state = useSelector((state) => {
    return {
      debug: state.debug.status,
    };
  });

  return (
    <Collapse isOpened={state.debug}>
      <div className="mt-4">
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </Collapse>
  );
};

export default DebugConsole;
