import { get, post } from './helpers';

export const getHardwareForm = () => {
  return get('/api/v1/hardware').then((resp) => {
    return resp;
  });
};

export const getHardware = (id) => {
  return get(`/api/v1/hardwares/${id}`).then((resp) => {
    return resp;
  });
};

export const getMouldingsAndTrimmingsItem = (id) => {
  return get(`/api/v1/mouldings/${id}`).then((resp) => {
    return resp;
  });
};

export const getMiscItem = (id) => {
  return get(`/api/v1/miscelleneous/${id}`).then((resp) => {
    return resp;
  });
};

export const getTimberFinishItem = (id) => {
  return get(`/api/v1/timber_finishes/${id}`).then((resp) => {
    return resp;
  });
};

export const getGlazingUnitItem = (id) => {
  return get(`/api/v1/glazing_units/${id}`).then((resp) => {
    return resp;
  });
};

export const getTimberItem = (id) => {
  return get(`/api/v1/timber_items/${id}`).then((resp) => {
    return resp;
  });
};

export const getHardwareItem = (id) => {
  return get(`/api/v1/hardwares/${id}`).then((resp) => {
    return resp;
  });
};

export const getClient = (id) => {
  return get(`/api/v1/clients/${id}`).then((resp) => {
    return resp;
  });
};

export const getProject = (id) => {
  return get(`/api/v1/projects/${id}`).then((resp) => {
    return resp;
  });
};

export const getSupplier = (id) => {
  return get(`/api/v1/suppliers/${id}`).then((resp) => {
    return resp;
  });
};

export const getTimberType = (id) => {
  return get(`/api/v1/timber_types/${id}`).then((resp) => {
    return resp;
  });
};

export const getHardwareSet = (id) => {
  return get(`/api/v1/box_sash_hardware_sets/${id}`).then((resp) => {
    return resp;
  });
};

export const getCasementHardwareSet = (id) => {
  return get(`/api/v1/casement_hardware_sets/${id}`).then((resp) => {
    return resp;
  });
};

export const getBoxSashConfig = (id) => {
  return get(`/api/v1/box_sash_configs/${id}`).then((resp) => {
    return resp;
  });
};

export const getProducts = ({ category, projectID }) => {
  return get(`/api/v1/products`, { category, project_id: projectID }).then((resp) => {
    return resp;
  });
};

export const getProductItem = (id) => {
  return get(`/api/v1/product_items/${id}`).then((resp) => {
    return resp;
  });
};

export const getCasements = (id) => {
  return get(`/api/v1/casements/${id}`).then((resp) => {
    return resp;
  });
};

export const getDrawingInstruction = (id) => {
  return get(`/api/v1/drawing_instructions/${id}`).then((resp) => {
    return resp;
  });
};

export const getFinishSystem = (id) => {
  return get(`/api/v1/finish_systems/${id}`).then((resp) => {
    return resp;
  });
};

export const getCasementTimberSummary = (body) => {
  return post(`/api/v1/casements/materials/timber/price`, body).then((resp) => {
    return resp;
  });
};

export const getCasementGlassSummary = (body) => {
  return post(`/api/v1/casements/materials/glass/price`, body).then((resp) => {
    return resp;
  });
};

export const getCasementHardwareSummary = (body) => {
  return post(`/api/v1/casements/materials/hardware/price`, body).then((resp) => {
    return resp;
  });
};

export const getCasementLabourSummary = (body) => {
  return post(`/api/v1/casements/labour/price`, body).then((resp) => {
    return resp;
  });
};
