import API from 'apiv1';
import { getSnapshot } from 'mobx-state-tree';
import { NotificationManager } from 'react-notifications';

import { store } from '@appSrc/store';

import { handleVariants } from '@components/configurators/Casement/ProductItemFields';

const API_ENDPOINT = {
  new: API.createCasement,
  update: API.updateCasement,
  newItem: API.createCasementProductItems,
  updateItem: API.updateCasementProductItem,
};

const hardwareSetToFormParams = (hardwareSet) => {
  if (!hardwareSet) return {};

  const items = {};
  Object.entries(hardwareSet.materials).forEach(([key, value]) => {
    // @ts-ignore
    items[key] = { id: value.id };
  });

  return {
    parent_id: hardwareSet['parent_id'] || hardwareSet.id,
    items: items,
  };
};

const finishSystemToFormParams = (finishSystem) => {
  if (!finishSystem) return {};

  const items = [];
  finishSystem.timber_finishes.forEach((timberFinish) => {
    items.push({ id: timberFinish.id });
  });

  const params = {
    parent_id: finishSystem['parent_id'] || finishSystem.id,
    timber_finishes: items,
  };

  if (finishSystem.dual_finish && finishSystem.outer_frame['timber_finish']) {
    // @ts-ignore
    params.outer_frame = {
      id: finishSystem.outer_frame.timber_finish.id,
    };
  } else {
    // @ts-ignore
    params.outer_frame = {};
  }

  return params;
};

const sectionEditorsToFormParams = (sectionEditors) => {
  if (!sectionEditors) return [];

  const items = [];
  Object.entries(sectionEditors).forEach(([key, value]) => {
    items.push({
      section_id: key,
      // @ts-ignore
      glazing_unit_id: value.glazingUnit && value.glazingUnit.id,
    });
  });

  return items;
};

const newItemsStateToPrams = (configurationFormsState) => {
  if (Object.keys(configurationFormsState.casementProductItemData).length === 0) return {};
  if (configurationFormsState.casementProductItemData.product_items) return {};

  const productItem = configurationFormsState.casementProductItemData.product_item;

  return {
    product_item: { ...productItem, site: productItem.site.value },
  };
};

export async function handleSubmit(
  casementStore,
  data = null,
  editMode,
  casementId = null,
  stageRef,
  rfqId = null,
  parentId = null,
  meta = {},
  formChecks = []
) {
  if (formChecks.length > 0) {
    const checks = [];

    formChecks.forEach((check) => {
      checks.push(check());
    });

    const results = await Promise.all(checks);
    if (results.includes(false)) {
      return false;
    }
  }

  const state = store.getState();
  const configForm = state.configuratorForms;
  const sashRefs = state.configData.sashRefs;
  const hardwareSet = configForm.hardwareSet;
  const finishSystem = configForm.finishSystem;
  const sectionEditors = configForm.sectionEditors;
  let newCasementParams = {};
  let name, configData;

  if (data) {
    name = data.name;
    configData = data;
  } else {
    name = configForm.casementData.name;
    configData = configForm.casementData;
  }

  if (rfqId || parentId) {
    newCasementParams = {
      product_items: handleVariants(configForm.casementProductItemData.product_items),
      rfq_id: rfqId,
      parent_id: parentId,
    };
  }

  let glazingData = [];

  glazingData = casementStore.glassSummary.map((glass) => ({
    ...glass,
    glazing_unit_id:
      sectionEditors[glass.sectionID] && sectionEditors[glass.sectionID].glazingUnit.id,
  }));

  let hardwareData = casementStore.hardwareSummary;
  hardwareData.sections = hardwareData.sections.map((section) => {
    const { sectionID, ...rest } = section;

    const editorState = sectionEditors[sectionID];
    if (!editorState) return section;

    return {
      ...rest,
      sectionID: sectionID,
      restrictor_quantity: editorState.restrictorQuantity,
      has_shootbolt: editorState.hasShootbolt,
      has_trickle_vent: editorState.hasTrickleVent,
      has_brake: editorState.hasBrake,
      has_extension_set: editorState.hasExtensionSet,
      has_lockable_stay_pin: editorState.hasLockableStayPin,
      has_seal: editorState.hasSeal,
    };
  });

  const hardwareFinishes = {};
  if (configData.hardware_finish_id) {
    // @ts-ignore
    hardwareFinishes.hardware_finish_id = configData.hardware_finish_id.value;
  }
  if (configData.trickle_vent_finish_id) {
    // @ts-ignore
    hardwareFinishes.trickle_vent_finish_id = configData.trickle_vent_finish_id.value;
  }
  if (configData.shootbolt_finish_id) {
    // @ts-ignore
    hardwareFinishes.shootbolt_finish_id = configData.shootbolt_finish_id.value;
  }

  const labourStatistics = casementStore.statistics;

  labourStatistics.is_dual_colour = finishSystem && finishSystem.dual_finish;

  const preparedData = {
    data: {
      ...newItemsStateToPrams(configForm),
      ...newCasementParams,
      name: name,
      // snapshot: getSnapshot(casementStore),
      snapshot: configForm.casementSnapshot,
      data: configData,
      section_editor_data: sectionEditors,
      hardware_set: { ...hardwareSetToFormParams(hardwareSet), ...hardwareFinishes },
      finish_system: finishSystemToFormParams(finishSystem),
      sections: sectionEditorsToFormParams(sectionEditors),

      timber_config: {
        timber: {
          frame_timber_id: configData.frame_timber_id,
          leaf_timber_id: configData.leaf_timber_id,
          sill_timber_id: configData.sill_timber_id,
          frame_engineered: configData.frame_engineered && configData.frame_engineered === 'true',
          sill_engineered: configData.sill_engineered && configData.sill_engineered === 'true',
          leaf_engineered: configData.leaf_engineered && configData.leaf_engineered === 'true',
        },
        timber_items: casementStore.allTimber,
      },

      glazing_config: {
        glass_summary: glazingData,
      },

      labour_config: labourStatistics,

      finish_config: {
        total_surface_area: casementStore.surfaceArea,
      },

      hardware_config: hardwareData,
    },
  };

  // console.log('FORM DATA', preparedData);

  const formData = API.toFormData({ data: JSON.stringify(preparedData.data) });

  function getSashDiagram(container, category = 'sash') {
    return new Promise((resolve) => {
      const key = container.id;
      if (!container.querySelector('canvas')) {
        console.debug("Can't find canvas in container", container);
        resolve(true);
        return;
      }

      container.querySelector('canvas').toBlob(async (blob) => {
        const file = new File([blob], `${category}_${key}.png`, { type: 'image/png' });

        formData.append('sash_diagrams[]', file);
        resolve(true);
      }, 'image/png');
    });
  }

  function getFrameDiagram(frame) {
    return new Promise((resolve) => {
      frame.content.childNodes[0].toBlob((blob) => {
        const file = new File([blob], 'casement.png', { type: 'image/png' });
        formData.append('full_diagram', file);
        resolve(true);
      }, 'image/png');
    });
  }

  try {
    casementStore.clearCurrentSection();
    const promises = [];
    promises.push(getFrameDiagram(stageRef.current));

    document.querySelectorAll('.js-sash-stage-container').forEach((container) => {
      promises.push(getSashDiagram(container, 'sash'));
    });

    document.querySelectorAll('.js-frame-stage-container').forEach((container) => {
      promises.push(getSashDiagram(container, 'frame'));
    });

    await Promise.all(promises);

    await API_ENDPOINT[editMode](formData, casementId);
    NotificationManager.success('Success');
    window.location.href = `/projects/${meta['projectId']}`;
  } catch (err) {
    console.error(err);
    NotificationManager.error(err.message);
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const DEFAULT_VALUES = {
  name: '',
  window_style: { value: 'flush', label: 'Flush' },
  sub_type: { value: 'standard', label: 'Standard' },
  size_type: { value: 'fabrication', label: 'Fabrication' },
  size: { width: 1800, height: 1500 },
  mullion: { thickness: 90, width: 55 },
  jamb: { thickness: 90, width: 55 },
  head: { thickness: 90, width: 55 },
  sill: { thickness: 90, width: 70, projection: 0, angle: 7 },
  sill_horns: { width: 50 },
  transom: { thickness: 90, width: 70, angle: 7 },
  secondary_transom: { thickness: 90, width: 60 },
  transom_rain_drip: { thickness: 30, width: 15 },
  head_rain_drip: { thickness: 30, width: 15 },
  top_rail: { thickness: 54, width: 55 },
  stiles: { thickness: 54, width: 55 },
  sash_moulding: { thickness: 12, width: 9 },
  bottom_rail: { thickness: 54, width: 90 },
  top_sash_bottom_rail: { width: 55 },
  mid_rail: { thickness: 54, width: 110 },
  muntin: { thickness: 54, width: 110 },
  sash_rebate: { thickness: 0, width: 0 },
  sash_glazing_bead: { thickness: 12, width: 16 },
  frame_glazing_bead: { thickness: 12, width: 24 },
  rebate: { thickness: 55, width: 15 },
  glazing_rebate: { width: 15 },
  bars: { thickness: 24, width: 22 },
  stick_on_bars: {
    in: { thickness: 24, width: 22 },
    out: { thickness: 24, width: 22 },
  },
  sash_gaps: {
    top: 3,
    left: 3,
    bottom: 5,
    right: 3,
  },
  true_bars: { thickness: 24, width: 22, rebate: 8 },
  arc_rise: 900,
  hidden_in_brick: 0,
  glass_gap: 3,
  frame_glass_gap: 2,
  frame_timber_id: null,
  leaf_timber_id: null,
  sill_timber_id: null,
  draft_seal_gap: 5,
};

export function upgradeCasementFormData(data) {
  if (data == null || typeof data === undefined) return JSON.parse(JSON.stringify(DEFAULT_VALUES));

  const dataDup = JSON.parse(JSON.stringify(data));

  if (dataDup.transom) {
    dataDup.transom.angle = dataDup.transom.angle || DEFAULT_VALUES.transom.angle;
  }
  if (dataDup.true_bars && dataDup.true_bars.in) {
    dataDup.true_bars = DEFAULT_VALUES.true_bars;
  }
  if (dataDup.sash_gap && dataDup.sash_gap['width']) {
    dataDup.sash_gaps = {
      top: dataDup.sash_gap['width'],
      left: dataDup.sash_gap['width'],
      bottom: dataDup.sash_gap['width'],
      right: dataDup.sash_gap['width'],
    };
  }

  return { ...DEFAULT_VALUES, ...dataDup };
}
