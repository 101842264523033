import { observer, inject } from 'mobx-react';
import React from 'react';

import HorizontalBar from '@components/configurators/Casement/GeorgianBars/HorizontalBar';
import VerticalBar from '@components/configurators/Casement/GeorgianBars/VerticalBar';

const GeorgianBars = observer(({ section }) => {
  if (!section.hasBars) return <></>;

  const { vertical } = section.georgianBars;
  const { width, height } = section.georgianBarsInternalSize;

  const isFixed = section.sectionType === 'fixed';
  const isGlazing = section.sectionType === 'glazing';

  const barsOrigin = { x: 0, y: 0 };

  if (isFixed) {
    barsOrigin.y = section.store.frame_glazing_bead.width;
    barsOrigin.x = section.store.frame_glazing_bead.width;
  } else if (isGlazing) {
    barsOrigin.y = -section.store.sash_moulding.width;
    barsOrigin.x = -section.store.sash_moulding.width;
  } else {
    barsOrigin.y = section.bottomRailWidth - section.store.sash_moulding.width;
    barsOrigin.x = section.store.stiles.width - section.store.sash_moulding.width;
  }

  const horizontalPnaelWidthReduction = isFixed ? 0 : section.store.sash_moulding.width * 2;
  const horizontalPaneWidth =
    (width - horizontalPnaelWidthReduction - section.store.stickOnBars.out.width * vertical) /
    (vertical + 1);

  const horBarOrigins = section.horizontalBarRenderOrigins;

  let horizontalBars = horBarOrigins.map((barOrigin, i) => {
    return (
      <HorizontalBar
        key={`h-${i}`}
        {...barOrigin}
        width={width}
        height={section.store.stickOnBars.out.width}
        section={section}
        fill="white"
        stroke="black"
        strokeWidth={4}
        opacity={1}
        isFixed={isFixed}
      />
    );
  });

  const verticalBars = Array(vertical)
    .fill(0)
    .map((_, index) => {
      let x, y;

      if (isFixed) {
        x =
          section.store.frame_glazing_bead.width +
          (index + 1) * horizontalPaneWidth +
          index * section.store.stickOnBars.out.width;
        y = section.store.frame_glazing_bead.width;
      } else if (isGlazing) {
        x = (index + 1) * horizontalPaneWidth + index * section.store.stickOnBars.out.width;
        y = -section.store.sash_moulding.width;
      } else {
        x =
          section.store.stiles.width +
          (index + 1) * horizontalPaneWidth +
          index * section.store.stickOnBars.out.width;
        y = section.bottomRailWidth - section.store.sash_moulding.width;
      }
      return (
        <VerticalBar
          key={`v-${index}`}
          x={x}
          y={y}
          width={section.store.stickOnBars.out.width}
          height={height}
          section={section}
          fill="white"
          stroke="black"
          strokeWidth={4}
          opacity={1}
        />
      );
    });

  return (
    <>
      {verticalBars}
      {horizontalBars}
    </>
  );
});

export default GeorgianBars;
