import API from 'apiv1';
import React from 'react';
import { BiDuplicate } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { NotificationManager } from 'react-notifications';
import { Provider } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import ButtonIcon from '@components/buttons/ButtonIcon';
import Button from '@components/buttons/ButtonLink';
import Datatable from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import ImageWithFallback from '@components/images/ImageWithFallback';
import ProductsMenu from '@components/menus/products';
import SimpleTooltip from '@components/tooltips/SimpleTooltip';
import Widget from '@components/widget';

import { store } from '../store';

const Page = ({ casements }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => {
        return (
          <SimpleTooltip
            usePortal={true}
            hideArrow={false}
            portalContainer={document.getElementById('portal-container')}
            trigger="hover"
            placement="bottom"
            tooltip={
              <div className="bg-white" style={{ width: 'max-content' }}>
                <ImageWithFallback
                  style={{ maxWidth: 360, maxHeight: 560, width: 'auto' }}
                  src={props.row.original['frame_diagram']}
                  fallback="/missing.png"
                />
              </div>
            }
          >
            {props.value}
          </SimpleTooltip>
        );
      },
    },
    {
      Header: 'Dimensions',
      accessor: 'dimensions',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => (
        <div className="flex flex-row">
          <SimpleTooltip
            usePortal={true}
            hideArrow={false}
            portalContainer={document.getElementById('portal-container')}
            trigger="hover"
            placement="top"
            tooltip={<span>Replicate</span>}
          >
            <ButtonIcon
              as="button"
              onClick={() => replicateCasement(value)}
              bgColor="teal-60"
              textColor="teal-60"
              className="m-2"
            >
              <BiDuplicate size={18} color="#1bc5bd" />
            </ButtonIcon>
          </SimpleTooltip>
          {RecordActions(value, `/casements/${value}/edit`, API.deleteCasement)}
        </div>
      ),
    },
  ];

  function replicateCasement(id) {
    API.replicateCasement(id)
      .then((resp) => {
        NotificationManager.success('Casement replicated.', 'Replication Successful', 40000);
        window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to replicate the casement, our team has been informed of this error.',
          'Replication Error',
          40000
        )
      );
  }

  return (
    <>
      <ProductsMenu />

      <Widget
        title="Casement Products"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Button as="a" href="/casements/new">
              <FiPlus size={22} /> New Product
            </Button>
          </div>
        }
      >
        <Datatable columns={columns} data={casements} />
      </Widget>
    </>
  );
};

const CasementProductsPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default CasementProductsPage;
