import { observer, inject } from 'mobx-react';
import { isAlive } from 'mobx-state-tree';
import React, { useState } from 'react';
import { Group, Line } from 'react-konva';

import ArcRect from '@components/configurators/Casement/ArcRect';
import GeorgianBars from '@components/configurators/Casement/GeorgianBars';
import Mullion from '@components/configurators/Casement/Mullion';
import Sash from '@components/configurators/Casement/Sash';
import FixedSash from '@components/configurators/Casement/Sash/Fixed';
import Openings from '@components/configurators/Casement/Sash/Openings';
import Transom from '@components/configurators/Casement/Transom';

const FRAME_SECTION_RENDERS = {
  transom: Transom,
  mullion: Mullion,
};

const SectionView = observer(({ store, section, x = null, y = null, rootOnly = false }) => {
  const [group, setGroup] = useState();

  function handleClick(e) {
    const firstSection = e.target.findAncestor('.section');
    if (firstSection === group) {
      store.setCurrentSection(section.id);
    }
  }

  if (section === undefined || section === null || !isAlive(section)) return <></>;

  const isSelected = store.selectedSectionId === section.id;

  let childrenSections = [];
  if (rootOnly) {
    section.sections.forEach((s) => {
      if (s.sectionType == 'transom' || s.sectionType == 'mullion' || s.sectionType == 'node') {
        childrenSections.push(<SectionView key={s.id} store={store} section={s} rootOnly={true} />);
      }
      if (s.sectionType == 'blank' && s.sections.length > 0) {
        childrenSections.push(<SectionView key={s.id} store={store} section={s} rootOnly={true} />);
      }
    });
  } else {
    childrenSections = section.sections.map((childSection) => {
      return <SectionView key={childSection.id} store={store} section={childSection} />;
    });
  }

  const lineProps = {
    closed: true,
    stroke: 'black',
    strokeWidth: 4,
    fill: 'white',
    opacity: 1,
  };

  const quarterWidth = Math.min(section.size.width, section.size.height) / 4;
  const halfHeight = section.size.height / 2;
  const halfWidth = section.size.width / 2;

  if (rootOnly && section.sectionType != 'root' && !section.parent) {
    return (
      <Group
        // @ts-ignore
        ref={(node) => setGroup(node)}
        onClick={handleClick}
        x={x || section.origin.x}
        y={y || section.origin.y}
        {...section.size}
        name="section"
      ></Group>
    );
  }

  return (
    <Group
      // @ts-ignore
      ref={(node) => setGroup(node)}
      onClick={handleClick}
      x={x || section.origin.x}
      y={y || section.origin.y}
      {...section.size}
      name="section"
    >
      {FRAME_SECTION_RENDERS[section.sectionType] &&
        FRAME_SECTION_RENDERS[section.sectionType]({ section })}

      {/* Selector for when pane is empty */}
      {childrenSections}

      {section.sections.length === 0 && (
        <ArcRect
          section={section}
          fill={
            section.sectionType === 'blank' || section.sectionType === 'root' ? 'blue' : 'white'
          }
          stroke={'black'}
          strokeWidth={4}
          opacity={section.sectionType === 'blank' || section.sectionType === 'root' ? 0.1 : 1}
        />
      )}
      {['fixed'].includes(section.sectionType) && <FixedSash {...lineProps} section={section} />}

      {section.sections.length === 0 && <GeorgianBars section={section} />}

      {['fixed', 'dummy'].includes(section.sectionType) && (
        <>
          {/* Horizontal */}
          <Line
            {...lineProps}
            closed={false}
            points={[halfWidth - quarterWidth, halfHeight, halfWidth + quarterWidth, halfHeight]}
          />
          {/* Vertical */}
          <Line
            {...lineProps}
            closed={false}
            points={[halfWidth, halfHeight - quarterWidth, halfWidth, halfHeight + quarterWidth]}
          />
        </>
      )}

      {['leaf', 'dummy'].includes(section.sectionType) && (
        <>
          {/* Sash */}
          <Sash {...lineProps} section={section} />
          {/* Opening */}
          {Openings[section.parameters.leafOpening] &&
            Openings[section.parameters.leafOpening](section, lineProps)}
          {/* <Rect {...section.size} fill={'none'} opacity={0.1} /> */}
        </>
      )}

      {isSelected && <ArcRect section={section} fill="green" opacity={0.3} />}
    </Group>
  );
});

export default SectionView;
