import { put, post } from './helpers';

export const updateMouldingsAndTrimmingsItem = (data, id) => {
  return put(`/api/v1/mouldings/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateMiscItem = (data, id) => {
  return put(`/api/v1/miscelleneous/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateTimberFinishItem = (data, id) => {
  return put(`/api/v1/timber_finishes/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateGlazingUnitItem = (data, id) => {
  return put(`/api/v1/glazing_units/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateTimberItem = (data, id) => {
  return put(`/api/v1/timber_items/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateHardwareItem = (data, id) => {
  return put(`/api/v1/hardwares/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateClient = (data, id) => {
  return put(`/api/v1/clients/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateProject = (data, id) => {
  return put(`/api/v1/projects/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateSupplier = (data, id) => {
  return put(`/api/v1/suppliers/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateTimberType = (data, id) => {
  return put(`/api/v1/timber_types/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateHardwareSet = (data, id) => {
  return put(`/api/v1/box_sash_hardware_sets/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateCasementHardwareSet = (data, id) => {
  return put(`/api/v1/casement_hardware_sets/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateBoxSashConfig = (data, id) => {
  return put(`/api/v1/box_sash_configs/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateBusiness = (data, id) => {
  return put(`/api/v1/business/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updatePricingSetting = (data, id) => {
  return put(`/api/v1/pricing_setting/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateDefaultQuoteSetting = (data) => {
  return put(`/api/v1/default_quote_settings`, data).then((resp) => {
    return resp;
  });
};

export const updateBoxSashProductItems = (data, id) => {
  return put(`/api/v1/box_sash_configs/${id}/products`, data).then((resp) => {
    return resp;
  });
};

export const updateCasementProductItem = (data, id) => {
  return put(`/api/v1/casements/product_items/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateCasement = (data, id) => {
  return put(`/api/v1/casements/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateProductItem = (data, id) => {
  return put(`/api/v1/product_items/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateBoxSashProduction = (data, id) => {
  return put(`/api/v1/product_production/${id}/box_sash`, data).then((resp) => {
    return resp;
  });
};
export const updateCasementProduction = (data, id) => {
  return put(`/api/v1/product_production/${id}/casement`, data).then((resp) => {
    return resp;
  });
};
export const updateGlobalProduction = (data, id) => {
  return put(`/api/v1/product_production/${id}/global`, data).then((resp) => {
    return resp;
  });
};

export const updateBOMItem = (data, id) => {
  return put(`/api/v1/version/bill_of_materials/${id}/items`, data).then((resp) => {
    return resp;
  });
};

export const updateDrawingInstruction = (data, id) => {
  return put(`/api/v1/drawing_instructions/${id}`, data).then((resp) => {
    return resp;
  });
};

export const syncBOM = (id) => {
  return post(`/api/v1/rfqs/${id}`, {}, {"Content-Type": 'application/json'}).then((resp) => {
    return resp;
  });
};

export const saveQuote = (id) => {
  return post(`/rfqs/${id}/save`, {}, {"Content-Type": 'application/json'}).then((resp) => {
    return resp;
  });
};

export const saveGlassSummary = (id) => {
  return post(`/rfqs/${id}/glass_summary/save`, {}, {"Content-Type": 'application/json'}).then((resp) => {
    return resp;
  });
};

export const updateQuoteDetails = (data, id) => {
  return put(`/api/v1/rfqs/${id}`, data).then((resp) => {
    return resp;
  });
};

export const updateFinishSystem = (data, id) => {
  return put(`/api/v1/finish_systems/${id}`, data).then((resp) => {
    return resp;
  });
};
