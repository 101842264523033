import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { GrAddCircle } from 'react-icons/gr';

import UUID from '@utils/uuid';
import Section, { InputContainer } from './Section';
import { itemToInput } from '@components/forms/GridForm';

const EditProductItemSection = ({ availableSiteOptions }) => {
  const [sites, setSites] = useState(availableSiteOptions);
  const methods = useFormContext();

  return (
    <Section title={'Product allocation'}>
      <div className={'flex flex-col space-y-6'} style={{ maxWidth: '992px' }}>
        <div className={`flex flex-row`}>
          <div className="flex flex-row space-x-12">
            {itemToInput(
              {
                label: 'Reference',
                name: `product_item[reference]`,
                type: 'text',
                error: { required: 'Please enter SKU' },
                defaultValue: '',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
            {itemToInput(
              {
                label: 'Site',
                name: `product_item[site]`,
                type: 'combobox',
                error: { required: 'Please enter Finish' },
                options: sites,
                // defaultValue: '',
                placeholder: '',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
            {itemToInput(
              {
                label: 'Quantity',
                name: `product_item[quantity]`,
                type: 'text',
                error: { required: 'Please enter SKU' },
                defaultValue: '',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default EditProductItemSection;
