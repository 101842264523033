import * as createRecord from './createRecords';
import * as updateRecord from './updateRecords';
import * as fetchRecord from './fetchRecords';
import * as deleteRecord from './deleteRecords';
import { toFormData } from './helpers';

const APIv1 = {
  ...createRecord,
  ...updateRecord,
  ...fetchRecord,
  ...deleteRecord,
  toFormData,
};

export default APIv1;
