import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import {
  setFormField,
  setFinishSystem,
  setLayerFinish,
  setOuterFrameFinish,
} from '@appSrc/actions/configuratorActions';

import SelectorModal from '@components/DataModal';
import SwitchInput from '@components/forms/Switch';

import Card from '../Card';
import Section, { InputContainer } from '../Section';

export const INNER_FINISH = 'inner_finish';
export const OUTER_FINISH = 'outer_finish';
export const SINGLE_COLOUR = 'singleColour';

const Finish = ({ recordId = null, timberFinish = [], finishSystems = [] }) => {
  const state = useSelector((state) => {
    return {
      // @ts-ignore
      form: state.configuratorForms.formFields,
      // @ts-ignore
      finishSystem: state.configuratorForms.finishSystem,
    };
  });
  const dispatch = useDispatch();

  const handleSelection = (target, layerCategory, item) => {
    console.log(`Selected ${target}.${layerCategory}: `, item);
    switch (target) {
      case 'frame':
        dispatch(setLayerFinish(layerCategory, item));
        return true;
      case 'outerFrame':
        dispatch(setOuterFrameFinish(layerCategory, item));
        return true;
      default:
        break;
    }
  };

  const handleDualColorChange = (value) => {
    dispatch(setFormField(SINGLE_COLOUR, !value));
  };

  const renderCard = (finishItemId) => {
    if (finishItemId) {
      const finishItem = timberFinish.find((finish) => finish.id === finishItemId);

      return (
        <Card
          defaultOpen={false}
          title={finishItem['name'] + ' - ' + finishItem['code']}
          narrowStyle={true}
        />
      );
    } else {
      return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
    }
  };

  const finishSystemCard = (finishSystem) => {
    if (finishSystem) {
      return <Card defaultOpen={false} title={finishSystem['name']} narrowStyle={true} />;
    }

    return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
  };

  const switches = [
    <SwitchInput
      key="fin1"
      initialState={!state.form[SINGLE_COLOUR]}
      leftLabel="Dual color"
      onChange={handleDualColorChange}
    />,
  ];

  function handleSystemSelection(finishSystem) {
    dispatch(setFinishSystem(finishSystem));
    return true;
  }

  const lastLayer =
    state.finishSystem &&
    state.finishSystem['data'] &&
    state.finishSystem.data[state.finishSystem.data.length - 1];

  return (
    <Section title={'Finish'} switches={switches}>
      <InputContainer className="flex flex-col space-y-6 w-full">
        <span className="mb-0">Finish System</span>
        <InputContainer className="flex flex-row space-x-2 w-2/5">
          {finishSystemCard(state.finishSystem)}
          <div className="flex flex-col space-y-2 self-center">
            <SelectorModal
              data={finishSystems}
              materialKey="finishSystem"
              onItemSelect={handleSystemSelection}
            />
          </div>
        </InputContainer>
        {state.finishSystem &&
          state.finishSystem.data &&
          state.finishSystem.data.map((layer) => (
            <>
              <span className="mb-2">{layer['display_name']}</span>
              <InputContainer key={layer.category} className="flex flex-row space-x-2 w-2/5">
                {renderCard(layer['timber_finish_id'])}
                <div className="flex flex-col space-y-2 self-center">
                  <SelectorModal
                    data={timberFinish}
                    materialKey="timberFinish"
                    onItemSelect={(finishItem) =>
                      handleSelection('frame', layer.category, finishItem)
                    }
                  />
                </div>
              </InputContainer>
            </>
          ))}
        {!state.form[SINGLE_COLOUR] && lastLayer && (
          <>
            <span className="mb-2">{lastLayer['display_name']}</span>
            <InputContainer key={lastLayer.category} className="flex flex-row space-x-2 w-2/5">
              {renderCard(
                (state.finishSystem['outer_frame'] &&
                  state.finishSystem['outer_frame']['timber_finish_id']) ||
                  lastLayer['timber_finish_id']
              )}
              <div className="flex flex-col space-y-2 self-center">
                <SelectorModal
                  data={timberFinish}
                  materialKey="timberFinish"
                  onItemSelect={(finishItem) =>
                    handleSelection('outerFrame', lastLayer.category, finishItem)
                  }
                />
              </div>
            </InputContainer>
          </>
        )}
      </InputContainer>
    </Section>
  );
};

export default Finish;
