import _ from 'lodash';
import { SELECT_MATERIAL, REMOVE_SELECTED_MATERIAL } from '../actions/materialSelectionActions';

export default function selectedMaterials(state = {}, action) {
  switch (action.type) {
    case SELECT_MATERIAL: {
      let { namespace, key, item } = action.payload;
      return {
        ...state,
        [`${namespace}`]: { ...(state[namespace] || {}), [`${key}`]: item },
      }
    };
    case REMOVE_SELECTED_MATERIAL: {
      let { namespace, key } = action.payload;
      let newState = Object.assign({}, state);
      _.unset(newState, `${namespace}.${key}`);
      return newState
    };
    default:
      return state;
  }
}
