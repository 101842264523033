import React from 'react';
import { FiMail, FiStar, FiUser, FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const AccountLinks = () => {
  const items = [
    // {
    //   url: '/',
    //   icon: <FiMail size={18} className="stroke-current" />,
    //   name: 'Inbox',
    //   badge: {
    //     number: 2,
    //     color: 'bg-red-500 text-white'
    //   }
    // },
    // {
    //   url: '/',
    //   icon: <FiStar size={18} className="stroke-current" />,
    //   iconColor: 'default',
    //   name: 'Messages',
    //   badge: {
    //     number: 3,
    //     color: 'bg-blue-500 text-white'
    //   }
    // },
    // {
    //   url: '/extras/user-profile',
    //   icon: <FiUser size={18} className="stroke-current" />,
    //   name: 'Profile',
    //   badge: null
    // },
    {
      url: '/users/sign_out',
      method: 'DELETE',
      icon: <FiLogIn size={18} className="stroke-current" />,
      name: 'Logout',
      badge: null,
      callback: (e, item) => linkTo(e, item),
    },
  ];

  const linkTo = async (event, { url, method }) => {
    event.preventDefault();
    event.stopPropagation();

    await fetch(url, {
      method: method || 'GET',
      credentials: 'same-origin',
      headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content },
      redirect: 'follow',
    }).then(() => (window.location.href = window.location.href));
  };

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li key={i} className="dropdown-item">
            <a
              href={item.url}
              onClick={(e) => item.callback && item.callback(e, item)}
              className="flex flex-row items-center justify-start h-10 w-full px-2"
            >
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                <span
                  className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}
                >
                  {item.badge.number}
                </span>
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountLinks;
