import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const Tooltip = ({
  arrowRef,
  tooltipRef,
  getArrowProps,
  getTooltipProps,
  placement,
  hideArrow,
  tooltip,
}) => (
  <ToolTipWrapper
    {...getTooltipProps({
      ref: tooltipRef,
    })}
  >
    {!hideArrow && (
      <div
        {...getArrowProps({
          ref: arrowRef,
          className: 'tooltip-arrow',
          'data-placement': placement,
        })}
      ></div>
    )}
    {tooltip}
  </ToolTipWrapper>
);

const Trigger = ({ getTriggerProps, triggerRef, children }) => (
  <span
    {...getTriggerProps({
      ref: triggerRef,
      className: 'trigger',
    })}
  >
    {children}
  </span>
);

const SimpleTooltip = ({
  children,
  tooltip,
  hideArrow,
  trigger,
  placement,
  closeOnOutsideClick = true,
  closeOnTriggerHidden = false,
  ...props
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: trigger || 'hover',
      placement: placement || 'bottom',
      interactive: true,
      closeOnOutsideClick: closeOnOutsideClick,
      closeOnTriggerHidden: closeOnTriggerHidden,
    });
  return (
    <div>
      <span ref={setTriggerRef} {...props}>
        {children}
      </span>

      {visible &&
        ReactDOM.createPortal(
          <ToolTipWrapper
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip-container' })}
          >
            {!hideArrow && (
              <div
                {...getArrowProps({
                  className: 'tooltip-arrow',
                })}
              ></div>
            )}
            {tooltip}
          </ToolTipWrapper>,
          document.body
        )}
    </div>
  );
};

const ToolTipWrapper = styled.div`
  ${tw`bg-white text-grey-900 border-grey-200 dark:bg-grey-800 dark:text-white dark:border-grey-700 border-0 font-normal text-xs max-w-xs no-underline break-words z-10`}
  ${tw`rounded-lg`}
  box-shadow: 0 0 20px 0 rgba(0,0,0,.15);
  padding: 0.75rem 1rem;
  max-width: max-content !important;
`;

export default SimpleTooltip;
