import React from 'react';
import Truncate from 'react-truncate';

import SimpleTooltip from '@components/tooltips/SimpleTooltip';

const TruncatedCell = ({ value, body = null, maxWidth = 400, toolTipStyle = {} }) => (
  <SimpleTooltip
    usePortal={true}
    hideArrow={false}
    portalContainer={document.getElementById('portal-container')}
    trigger="hover"
    placement="top"
    tooltip={
      <div className="w-full" style={toolTipStyle}>
        <span
          className="text-sm w-full tracking-normal text-justify"
          // style={{ minWidth: 'max-content' }}
        >
          {body || value}
        </span>
      </div>
    }
  >
    <Truncate width={maxWidth} lines={1} ellipsis={'...'}>
      {value}
    </Truncate>
  </SimpleTooltip>
);

export default TruncatedCell;
