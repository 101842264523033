import React from 'react';
import { useFormContext } from 'react-hook-form';

import { itemToInput } from '@components/forms/GridForm';

import Section, { InputContainer } from '../Section';
import TableInputs from '../TableInputs';

const GlazingBars = ({ recordId, borderBottom = true, borderTop = false, isTriple }) => {
  const methods = useFormContext();

  const columnsBars = [{ name: 'Vertical' }, { name: 'Horizontal' }];

  const rowsMiddleBars = [
    {
      name: 'Upper',
      inputs: [
        { name: 'glazing_bars[middle][upper][vertical]', type: 'number', inputClass: 'w-full' },
        { name: 'glazing_bars[middle][upper][horizontal]', type: 'number', inputClass: 'w-full' },
      ],
    },
    {
      name: 'Lower',
      inputs: [
        { name: 'glazing_bars[middle][lower][vertical]', type: 'number', inputClass: 'w-full' },
        { name: 'glazing_bars[middle][lower][horizontal]', type: 'number', inputClass: 'w-full' },
      ],
    },
  ];

  const rowsSideBars = [
    {
      name: 'Upper',
      inputs: [
        { name: 'glazing_bars[sides][upper][vertical]', type: 'number', inputClass: 'w-full' },
        { name: 'glazing_bars[sides][upper][horizontal]', type: 'number', inputClass: 'w-full' },
      ],
    },
    {
      name: 'Lower',
      inputs: [
        { name: 'glazing_bars[sides][lower][vertical]', type: 'number', inputClass: 'w-full' },
        { name: 'glazing_bars[sides][lower][horizontal]', type: 'number', inputClass: 'w-full' },
      ],
    },
  ];

  return (
    <Section title={'Glazing Bars'} borderBottom={borderBottom} borderTop={borderTop}>
      <InputContainer className="flex flex-row space-x-12 w-full">
        <InputContainer className="flex flex-col space-y-2 w-24 h-1"></InputContainer>

        <InputContainer className="flex flex-row space-x-20">
          <InputContainer className="flex flex-col space-y-2">
            <span className="pl-16 h-6"></span>
            {itemToInput(
              {
                label: 'Bar type',
                name: 'glazing_bars[true_bars]',
                type: 'radio',
                orientation: 'vertical',
                options: [
                  { label: 'Stick on bars', value: 'stickOn', checked: true },
                  { label: 'True bars', value: 'trueBars' },
                ],
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
          </InputContainer>
          {itemToInput(
            {
              label: 'Glazing bar width (mm)',
              name: 'glazing_bars[bars_width]',
              error: { required: 'Please provide bar width' },
              type: 'number',
              defaultValue: '',
              inputClass: 'w-2/4',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
          {itemToInput(
            {
              label: 'Glass gap (mm)',
              name: 'glazing_bars[glass_gap]',
              error: { required: 'Please provide glass gap' },
              type: 'number',
              defaultValue: '',
              inputClass: 'w-2/4',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
          {/* Middle Bars  */}
          <InputContainer className="flex flex-col space-y-2">
            {isTriple && <span className="pb-4 self-center">Middle</span>}
            <TableInputs methods={methods} columns={columnsBars} rows={rowsMiddleBars} />
          </InputContainer>
          {/* Side Bars  */}
          {isTriple && (
            <InputContainer className="flex flex-col space-y-2">
              <span className="pb-4 self-center">Side</span>
              <TableInputs methods={methods} columns={columnsBars} rows={rowsSideBars} />
            </InputContainer>
          )}
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default GlazingBars;
