import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FaPercentage } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

import '../../css/react-notifications.css';
import DebugConsole from '../DebugOptions/DebugConsole';
import { Alert } from '../alerts';
import ContextForm from './ContextForm';
import { CurrencyInput } from './currencyInput';
import { NumberInput } from './numberInput';

const dimensionsComponent = (item, alerts, register, errors, control) => {
  return (
    <div className="form-element flex justify-start flex-col">
      <div className="flex flex-row items-center justify-start space-x-2">
        <div className="form-element">
          <div className="form-label required">Thickness mm</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={NumberInput}
              name="thickness"
              style={`form-textarea ${errors['thickness'] ? 'border border-red-500' : ''} w-32`}
              icon={null}
              control={control}
              rules={{ required: 'Thickness is required' }}
              min={0}
              defaultValue={(item.defaultValues && item.defaultValues['thickness']) || ''}
            />
          </div>
        </div>
        <div className="form-element">
          <div className="form-label required">Width mm</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={NumberInput}
              name="width"
              style={`form-textarea ${errors['width'] ? 'border border-red-500' : ''} w-32`}
              icon={null}
              control={control}
              rules={{ required: 'Width is required' }}
              min={0}
              defaultValue={(item.defaultValues && item.defaultValues['width']) || ''}
            />
          </div>
        </div>
        <div className="form-element">
          <div className="form-label required">Length mm</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={NumberInput}
              name="length"
              style={`form-textarea ${errors['length'] ? 'border border-red-500' : ''} w-32`}
              icon={null}
              control={control}
              rules={{ required: 'Length is required' }}
              min={0}
              defaultValue={(item.defaultValues && item.defaultValues['length']) || ''}
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-row justify-start space-x-2">
        {!alerts && errors['thickness'] && (
          <div className="form-error">{errors['thickness'].message}</div>
        )}
        {!alerts && errors['width'] && <div className="form-error">{errors['width'].message}</div>}
        {!alerts && errors['length'] && (
          <div className="form-error">{errors['length'].message}</div>
        )}
      </div>
    </div>
  );
};

const priceAndVAT = (item, alerts, register, errors, control) => {
  return (
    <div className="form-element flex justify-start flex-col">
      <div className="flex flex-row items-center justify-start space-x-2">
        <div className="form-element">
          <div className="form-label required">Price m/l</div>
          {/* @ts-ignore */}
          <Controller
            as={CurrencyInput}
            name="price"
            style={`form-textarea ${errors['price'] ? 'border border-red-500' : ''} w-40`}
            placeholder={'30.02'}
            control={control}
            rules={{ required: 'Please enter price' }}
            defaultValue={(item.defaultValues && item.defaultValues['price']) || ''}
          />
        </div>
        <div className="form-element">
          <div className="form-label required">VAT</div>
          {/* @ts-ignore */}
          <Controller
            as={NumberInput}
            name="vat_rate"
            style={`form-textarea ${errors['vat_rate'] ? 'border border-red-500' : ''} w-24`}
            placeholder=""
            icon={<FaPercentage />}
            control={control}
            rules={{ required: 'Please a valid percentage value.' }}
            defaultValue={(item.defaultValues && item.defaultValues['vat_rate']) || 20}
            min={0}
          />
        </div>
      </div>
      <div className=" flex flex-row justify-start space-x-2">
        {!alerts && errors['price'] && <div className="form-error">{errors['price'].message}</div>}
        {!alerts && errors['vat_rate'] && (
          <div className="form-error">{errors['vat_rate'].message}</div>
        )}
      </div>
    </div>
  );
};

const MODE_HANDLER = {
  edit: API.updateMouldingsAndTrimmingsItem,
  default: API.createMouldingsAndTrimmingsItem,
};

const MouldingForm = ({ message = null, formId, formValues = {}, recordId, mode }) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();
  const state = useSelector((state) => {
    return {
      file: state.forms['product_image' + recordId],
      supplierOptions: state.forms.suppliers,
    };
  });

  useEffect(() => {
    if (recordId == null) return;
    if (typeof state.supplierOptions === 'undefined') return;

    API.getMouldingsAndTrimmingsItem(recordId).then((data) => {
      const supplier = state.supplierOptions.find(
        (option) => Number(option.value) === data['supplier_id']
      );
      setDefaultValues(data);
      reset({ ...data, supplier });
    });
  }, [reset, recordId, state.supplierOptions]);

  const onSubmitFn = (data) => {
    console.log('Data', data);

    const imageParams = state.file ? { product_image: state.file } : {};
    MODE_HANDLER[mode || 'default'](
      API.toFormData({ ...data, supplier: data.supplier.value, ...imageParams }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Image',
          error: {},
          name: 'product_image',
          type: 'image',
          recordId,
          files: state.file,
          defaultValue: defaultValues['product_image'],
        },
      ],
    },
    {
      config: ['full', 'full', 'full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Name',
          error: { required: 'Please enter an item name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Product Code',
          error: { required: false },
          name: 'product_reference',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'URL',
          error: { required: false },
          name: 'product_url',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Description',
          error: { required: 'Please enter an item description' },
          name: 'description',
          type: 'textarea',
          defaultValue: '',
        },
      ],
    },
    {
      config: ['full', '3/4', '3/4', '3/4'],
      style: { orientation: 'column' },
      items: [
        {
          error: { required: 'You must select at least one option' },
          type: 'custom',
          names: ['thickness', 'width', 'length'],
          /* @ts-ignore */
          component: (...props) => dimensionsComponent(...props),
        },
        {
          label: 'Finish',
          error: { required: false },
          name: 'finish',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Supplier',
          name: 'supplier',
          error: { required: 'Pleae select a supplier.' },
          options: state.supplierOptions,
          type: 'combobox',
          inlineClass: 'w-1/4',
        },
        {
          label: 'Material',
          error: { required: false },
          name: 'material',
          type: 'text',
          defaultValue: '',
        },
      ],
    },
    {
      config: ['full mbt-auto'],
      style: { orientation: 'column' },
      items: [
        {
          error: { required: 'Please enter price and vat' },
          names: ['price', 'vat_rate'],
          name: '',
          type: 'custom',
          /* @ts-ignore */
          component: priceAndVAT,
        },
      ],
    },
  ];

  function applyDefaults(items, defaults) {
    return items.map((groupConfig) => {
      groupConfig.items = groupConfig.items.map((item) => {
        if (item.name && item.name.length !== 0) {
          item.defaultValue = defaults[item.name];
        } else {
          item.defaultValues = {};
          item.names.forEach((name) => (item.defaultValues[name] = defaults[name]));
        }
        return item;
      });

      return groupConfig;
    });
  }

  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
            formId={formId}
          />
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default MouldingForm;
