import React from 'react';

import { itemToInput } from '../forms/GridForm';
import { InputContainer } from './Section';

export const DoubleInputs = ({
  methods,
  first,
  second,
  third = null,
  distance = 20,
  positive = false,
}) => (
  <InputContainer className={`flex flex-row ${positive ? '' : '-'}space-x-${distance}`}>
    {itemToInput(
      {
        label: 'Label',
        name: 'name',
        type: 'number',
        defaultValue: '',
        inputClass: 'w-2/4',
        ...first,
      },
      null,
      methods.register,
      methods.errors,
      methods.control
    )}
    {itemToInput(
      {
        label: 'Label',
        name: 'name',
        type: 'number',
        defaultValue: '',
        inputClass: 'w-2/4',
        ...second,
      },
      null,
      methods.register,
      methods.errors,
      methods.control
    )}
    {third &&
      itemToInput(
        {
          label: 'Label',
          name: 'name',
          type: 'number',
          defaultValue: '',
          inputClass: 'w-2/4',
          ...third,
        },
        null,
        methods.register,
        methods.errors,
        methods.control
      )}
  </InputContainer>
);
