import { SET_CONFIG_KEY, SET_CONFIG } from '../actions/configActions';

export default function config(
  state = {
    name: 'Radiko',
    description: 'Platform for joinery professionals',
    url: 'siliconorigami.com',
    layout: 'layout-1',
    collapsed: false,
    rightSidebar: false,
    backdrop: false,
    loading: false,
  },
  action
) {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        ...action.config,
      };
    case SET_CONFIG_KEY:
      let { key, value } = { ...action };
      return {
        ...state,
        [`${key}`]: value,
      };
    default:
      return state;
  }
}
