import _set from 'lodash/set';
import {
  SET_FORM_FIELD
} from '../actions/glazingUnitActions';

export default function glazingUnitForms(
  state = {
    timber: {},
    glazingUnits: {},
    finish: {},
    glazingBar: null,
    hardwareSet: null,
    formFields: { singleColour: true },
    finishSystem: null
  },
  action
) {
  switch (action.type) {
    case SET_FORM_FIELD: {
      const { keyPath, data } = action.payload;
      if (keyPath.length === 0) return state

      let newState = Object.assign({}, state);
      return _set(newState, keyPath, data);
    }
    default:
      return state;
  }
}

