import React from 'react';
import { Rect, Shape, Circle } from 'react-konva';

import { pythagaros, bezierArcPoints } from '@utils/geometry';

export const ArcRectX = ({
  x = 0,
  y = 0,
  altSize = { width: null, height: null },
  noArc = false,
  section,
  ...shapeProps
}) => {
  let { width, height } = altSize;
  if (width == null) width = section.size.width;
  if (height == null) height = section.size.height;

  if (!section.isHeadNeighbour || section.store.headStyle === 'standard') {
    return <Rect x={x} y={y} width={width} height={height} {...shapeProps} />;
  }
  let radius = section.store.arcRadius;

  if (section.store.finalSize.width / 2 === section.store.arcRadius) {
    // If it is a semi-circle we can no longer approximate the arc with bezier curves.
    radius = radius + 0.0001;
  }

  const radiusReduction =
    section.store.finalSize.height -
    (section.absoluteOrigin.y + section.size.height - section.store.top_rail.width);
  const upperArcRadius = radius - radiusReduction;

  const arcCenterY = section.absoluteOrigin.y + (y - upperArcRadius); // relative to section
  const arcCenterX =
    section.store.finalSize.width / 2 - (section.absoluteOrigin.x + x + section.store.stiles.width); // relative to section
  const arcCenter = { x: arcCenterX, y: arcCenterY };

  const isWithinRise =
    section.absoluteOrigin.y + y > section.store.finalSize.height - section.store.arcRise;

  let upperStartX, upperEndX, lowerStartX, lowerEndX;

  if (isWithinRise) {
    const upperXDistance = pythagaros(arcCenterY - height, upperArcRadius);
    console.log('upperXDistance', upperXDistance);
    upperStartX = upperXDistance + (arcCenterX >= 0 ? -arcCenterX : arcCenterX);
    upperEndX = upperXDistance + (arcCenterX >= 0 ? arcCenterX : -arcCenterX);
    const lowerXDistance = pythagaros(arcCenterY, upperArcRadius);
    console.log('lowerXDistance', lowerXDistance);
    // debugger;
    lowerStartX = lowerXDistance + (arcCenterX >= 0 ? -arcCenterX : arcCenterX);
    lowerEndX = lowerXDistance + (arcCenterX >= 0 ? arcCenterX : -arcCenterX);
  } else {
    upperStartX = 0;
    upperEndX = width;
    lowerStartX = 0;
    lowerEndX = width;
  }

  console.log('X values for bar', upperStartX, upperEndX, lowerStartX, lowerEndX);

  return (
    <Shape
      {...shapeProps}
      width={width}
      height={height}
      x={x}
      y={y}
      sceneFunc={(ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(lowerStartX, 0);
        ctx.lineTo(lowerEndX, 0);
        ctx.lineTo(upperEndX, height);
        ctx.lineTo(upperStartX, height);
        ctx.closePath();

        // Konva specific method
        ctx.fillStrokeShape(shape);
      }}
    />
  );
};

const ArcRect = ({
  x = 0,
  y = 0,
  altSize = { width: null, height: null },
  noArc = false,
  section,
  ...shapeProps
}) => {
  let { width, height } = altSize;
  if (width == null) width = section.size.width;
  if (height == null) height = section.size.height;

  if (!section.isHeadNeighbour || section.store.headStyle === 'standard') {
    return <Rect x={x} y={y} width={width} height={height} {...shapeProps} />;
  }

  let radius = section.store.arcRadius;

  if (section.store.finalSize.width / 2 === section.store.arcRadius) {
    // If it is a semi-circle we can no longer approximate the arc with bezier curves.
    radius = radius + 0.0001;
  }

  let radiusReduction = section.store.finalSize.height - (section.absoluteOrigin.y + y + height);
  if (['leaf', 'dummy'].includes(section.sectionType)) {
    radiusReduction = radiusReduction - section.sashGapTop;
  }

  const upperArcRadius = radius - radiusReduction;

  const arcCenterY = height - upperArcRadius; // relative to section
  let arcCenterX = section.store.finalSize.width / 2 - (section.absoluteOrigin.x + x); // relative to section
  if (['leaf', 'dummy'].includes(section.sectionType)) {
    arcCenterX = arcCenterX - section.sashGapLeft;
  }
  const arcCenter = { x: arcCenterX, y: arcCenterY };
  console.log('arc center', arcCenter);
  console.log('SectionAbs', section.absoluteOrigin);

  // Since we are drawing the arc via bezier approximation its not a true arc and therefore fine details like bars
  // cannot be drawn accurately on the arc, so to make it visually correct we load the Y axis with an arbitrary value.
  // let loadY = section.store.finalSize.width / 2 === section.store.arcRadius ? 2 : 0;

  const upperStartY = pythagaros(arcCenterX, upperArcRadius) + arcCenterY;
  const upperEndY = pythagaros(arcCenterX - width, upperArcRadius) + arcCenterY;

  const [arcPoints1, arcPoints2] = bezierArcPoints(
    { x: 0, y: upperStartY },
    { x: width, y: upperEndY },
    height,
    arcCenter,
    upperArcRadius
  );

  return (
    <>
      {/* Uncomment for debugging */}
      {/* <Circle x={arcCenter.x} y={arcCenter.y} radius={upperArcRadius} fill={'red'} /> */}
      <Shape
        {...shapeProps}
        width={width}
        height={height}
        x={x}
        y={y}
        closed={false}
        sceneFunc={(ctx, shape) => {
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(0, upperStartY);
          if (noArc) {
            ctx.lineTo(width, upperEndY);
          } else {
            // @ts-ignore
            ctx.bezierCurveTo(...arcPoints1);
            // @ts-ignore
            ctx.bezierCurveTo(...arcPoints2);
          }
          ctx.lineTo(width, upperEndY);
          ctx.lineTo(width, 0);
          // ctx.closePath();

          // Konva specific method
          ctx.fillStrokeShape(shape);
        }}
      />
    </>
  );
};

export default ArcRect;
