import React, { useState, useEffect } from 'react';
import { FaPercentage } from 'react-icons/fa';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import '../../css/react-notifications.css';

import { NumberInput } from './numberInput';
import { CurrencyInput } from './currencyInput';
import ContextForm from './ContextForm';
import { Alert } from '../alerts';
import API from 'apiv1';
import DebugConsole from '../DebugOptions/DebugConsole';

const priceAndVAT = (item, alerts, register, errors, control) => {
  return (
    <div
      className="form-element flex items-center justify-start flex-row space-x-2"
      style={{ flexDirection: 'row' }}
    >
      <div className="form-element">
        <div className="form-label">Price</div>
        {/* @ts-ignore */}
        <Controller
          as={CurrencyInput}
          name="price"
          style={`form-textarea ${errors['price'] ? 'border border-red-500' : ''} w-40`}
          placeholder={'30.02'}
          control={control}
          rules={{ required: 'Please enter price' }}
          defaultValue={''}
        />
      </div>
      <div className="form-element">
        <div className="form-label">VAT</div>
        {/* @ts-ignore */}
        <Controller
          as={NumberInput}
          name="vat_rate"
          style={`form-textarea ${errors['vat_rate'] ? 'border border-red-500' : ''} w-24`}
          placeholder=""
          icon={<FaPercentage />}
          control={control}
          rules={{ required: 'Please a valid percentage value.' }}
          defaultValue={20}
          min={0}
        />
      </div>
      {!alerts && errors['price'] && <div className="form-error">{errors['price'].message}</div>}
      {!alerts && errors['vat_rate'] && (
        <div className="form-error">{errors['vat_rate'].message}</div>
      )}
    </div>
  );
};

const MODE_HANDLER = {
  edit: API.updateMiscItem,
  default: API.createMiscItem,
};

const MiscForm = ({ message = null, formId, recordId, mode }) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();
  const state = useSelector((state) => {
    return {
      file: state.forms['product_image' + recordId],
      supplierOptions: state.forms.suppliers,
    };
  });

  useEffect(() => {
    if (recordId == null) return;
    if (typeof state.supplierOptions === 'undefined') return;

    API.getMiscItem(recordId).then((data) => {
      const supplier = state.supplierOptions.find(
        (option) => Number(option.value) === data['supplier_id']
      );
      setDefaultValues(data);
      reset({ ...data, supplier });
    });
  }, [reset, recordId, state.supplierOptions]);

  const onSubmitFn = (data) => {
    console.log('Data', data);
    console.log('Submit count', methods.formState.submitCount);
    // NotificationManager.success('Success message', 'Title here');
    const imageParams = state.file ? { product_image: state.file } : {};
    MODE_HANDLER[mode || 'default'](
      API.toFormData({ ...data, supplier: data.supplier.value, ...imageParams }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Image',
          error: {},
          name: 'product_image',
          type: 'image',
          recordId,
          defaultValue: defaultValues['product_image'],
          files: state.file,
        },
      ],
    },
    {
      config: ['full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Item name',
          error: { required: 'Please enter an item name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Supplier',
          error: {},
          name: 'supplier',
          options: state.supplierOptions,
          type: 'combobox',
          inlineClass: 'w-1/4',
        },
      ],
    },
    {
      config: [' w-94', 'full mbt-auto'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Description',
          error: { required: 'Please enter a description' },
          name: 'description',
          type: 'textarea',
          placeholder: 'Burgendy matt',
          defaultValue: '',
        },
        {
          error: { required: 'Please enter price and vat' },
          name: '',
          type: 'custom',
          /* @ts-ignore */
          component: priceAndVAT,
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            formId={formId}
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          />
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default MiscForm;
