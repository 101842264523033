import { post, toFormData } from './helpers';

export const createMiscItem = (data) => {
  return post('/api/v1/miscelleneous', data).then((resp) => {
    return resp;
  });
};

export const createMouldingsAndTrimmingsItem = (data) => {
  return post('/api/v1/mouldings', data).then((resp) => {
    return resp;
  });
};

export const createTimberFinishItem = (data) => {
  return post('/api/v1/timber_finishes', data).then((resp) => {
    return resp;
  });
};

export const createGlazingUnitItem = (data) => {
  return post('/api/v1/glazing_units', data).then((resp) => {
    return resp;
  });
};

export const createTimberItem = (data) => {
  return post('/api/v1/timber_items', data).then((resp) => {
    return resp;
  });
};

export const createHardwareItem = (data) => {
  return post('/api/v1/hardwares', data).then((resp) => {
    return resp;
  });
};

export const createClient = (data) => {
  return post('/api/v1/clients', data).then((resp) => {
    return resp;
  });
};

export const createProject = (data) => {
  return post('/api/v1/projects', data).then((resp) => {
    return resp;
  });
};

export const createSupplier = (data) => {
  return post('/api/v1/suppliers', data).then((resp) => {
    return resp;
  });
};

export const createTimberType = (data) => {
  return post('/api/v1/timber_types', data).then((resp) => {
    return resp;
  });
};

export const createHardwareSet = (data) => {
  return post('/api/v1/box_sash_hardware_sets', data).then((resp) => {
    return resp;
  });
};

export const createCasementHardwareSet = (data) => {
  return post('/api/v1/casement_hardware_sets', data).then((resp) => {
    return resp;
  });
};

export const createBoxSashConfig = (data) => {
  return post('/api/v1/box_sash_configs', data).then((resp) => {
    return resp;
  });
};

export const createCasement = (data) => {
  return post('/api/v1/casements', data).then((resp) => {
    return resp;
  });
};

export const createBoxSashProductItems = (data) => {
  return post('/api/v1/box_sash_configs/products', data).then((resp) => {
    return resp;
  });
};

export const createCasementProductItems = (data) => {
  return post('/api/v1/casements/product_items', data).then((resp) => {
    return resp;
  });
};

export const createDrawing = (data) => {
  return post('/api/v1/drawing_editor', data).then((resp) => {
    return resp;
  });
};

export const createFinishSystem = (data) => {
  return post('/api/v1/finish_systems', data).then((resp) => {
    return resp;
  });
};

export const createProductItem = (data) => {
  return post(`/api/v1/product_items`, data).then((resp) => {
    return resp;
  });
};

export const replicateBoxSashProductItem = (id) => {
  return post(`/api/v1/product_items/${id}/replicate`, toFormData({ data: null })).then((resp) => {
    return resp;
  });
};

export const replicateProductItem = (id) => {
  return post(`/api/v1/casements/product_items/${id}/replicate`, toFormData({ data: null })).then(
    (resp) => {
      return resp;
    }
  );
};

export const replicateCasement = (id) => {
  return post(`/api/v1/casements/${id}/replicate`, toFormData({ data: null })).then((resp) => {
    return resp;
  });
};

export const replicateBoxSash = (id) => {
  return post(`/api/v1/box_sash_configs/${id}/replicate`, toFormData({ data: null })).then(
    (resp) => {
      return resp;
    }
  );
};
