import React from 'react';
import { Line, Shape } from 'react-konva';

import { bezierArcPoints } from '@utils/geometry';

const ArchedTopBead = ({ section, _upperY, lowerY, radius, center }) => {
  const lowerArcRadius = radius - section.store.sash_moulding.width;
  const beadWidth = section.store.frame_glazing_bead.width;

  const [lowerArcPointsP1, lowerArcPointsP2] = bezierArcPoints(
    { x: beadWidth, y: lowerY },
    { x: section.size.width - beadWidth, y: lowerY },
    section.size.height - beadWidth,
    center,
    lowerArcRadius
  );

  const mouldingLineProps = {
    closed: false,
    stroke: 'black',
    strokeWidth: 4,
    // fill: 'none',
    opacity: 1,
  };

  return (
    <Shape
      {...mouldingLineProps}
      {...section.size}
      sceneFunc={(ctx, shape) => {
        ctx.beginPath();
        ctx.moveTo(beadWidth, lowerY);
        // @ts-ignore
        ctx.bezierCurveTo(...lowerArcPointsP1);
        // @ts-ignore
        ctx.bezierCurveTo(...lowerArcPointsP2);
        // Konva specific method
        ctx.fillStrokeShape(shape);
      }}
    />
  );
};

const GlazingBeads = ({ section, topBead = {} }) => {
  const { internalSize, internalOrigin } = section;
  const beadWidth = section.store.frame_glazing_bead.width;
  const beadHorizontalLength = internalSize.width;
  const beadInnerHorizontalLength = internalSize.width - beadWidth * 2;
  const beadVerticalLength = (topBead['upperY'] && topBead['upperY']) || internalSize.height;
  const beadInnerVerticalLength =
    (topBead['lowerY'] && topBead['lowerY']) || internalSize.height - beadWidth;
  const beadOrigin = {
    x: internalOrigin.x,
    y: internalOrigin.y,
  };
  const rightbeadOrigin = {
    x: internalOrigin.x + internalSize.width - beadWidth,
    y: internalOrigin.y,
  };
  const topBeadOrigin = {
    x: internalOrigin.x,
    y: internalOrigin.y + internalSize.height - beadWidth,
  };

  const beadLineProps = {
    stroke: 'black',
    strokeWidth: 4,
    fill: 'white',
    opacity: 1,
  };

  return (
    <>
      {/* Left bead */}
      <Line
        {...beadLineProps}
        {...beadOrigin}
        points={[
          0,
          0,
          beadWidth,
          beadWidth,
          beadWidth,
          beadInnerVerticalLength,
          0,
          beadVerticalLength,
          0,
          0,
        ]}
      />
      {/* Right bead */}
      <Line
        {...beadLineProps}
        {...rightbeadOrigin}
        points={[
          0,
          beadWidth,
          0,
          beadInnerVerticalLength,
          beadWidth,
          beadVerticalLength,
          beadWidth,
          0,
          0,
          beadWidth,
        ]}
      />
      {/* Bottom bead */}
      <Line
        {...beadLineProps}
        {...beadOrigin}
        points={[
          0,
          0,
          beadWidth,
          beadWidth,
          beadInnerHorizontalLength + beadWidth,
          beadWidth,
          beadHorizontalLength,
          0,
          0,
          0,
        ]}
      />
      {/* Top bead */}
      {!section.isHeadNeighbour || section.store.headStyle === 'standard' ? (
        <Line
          {...beadLineProps}
          {...topBeadOrigin}
          points={[
            beadWidth,
            0,
            beadWidth + beadInnerHorizontalLength,
            0,
            beadHorizontalLength,
            beadWidth,
            0,
            beadWidth,
            beadWidth,
            0,
          ]}
        />
      ) : (
        // @ts-ignore
        <ArchedTopBead section={section} {...topBead} />
      )}
    </>
  );
};

export default GlazingBeads;
