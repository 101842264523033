import API from 'apiv1';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import tw from 'twin.macro';

import '@appSrc/css/react-notifications.css';

import Button from '@components/buttons/ButtonLink';
import { itemToInput } from '@components/forms/GridForm';

import { update } from '@utils/formHelpers';

import DebugConsole from '../DebugOptions/DebugConsole';
import ContextForm from './ContextForm';

const rateToPercent = (data) => {
  return { rate: Math.round(Number(data.rate) * 100 * 100) / 100 };
};

const extractRates = (rates) => {
  return { rate: Math.round(rates.rate) / 100 };
};

const GlobalProductionForm = ({ productOperationSummary }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm();

  React.useEffect(() => {
    const data = Object.assign({}, productOperationSummary.data);
    update(data, 'timber.solid.waste', rateToPercent);
    update(data, 'timber.engineered.waste', rateToPercent);
    reset(data);
  }, [productOperationSummary]);

  const onSubmitFn = (data) => {
    update(data, 'timber.solid.waste', extractRates);
    update(data, 'timber.engineered.waste', extractRates);
    API.updateGlobalProduction(
      API.toFormData({
        data: JSON.stringify(data),
      }),
      productOperationSummary.id
    )
      .then((resp) => {
        NotificationManager.success(
          'Operations Summary details have been updated successfully',
          'Update Successful',
          40000
        );
        // window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const finishConfig = [
    {
      itemName: (
        <>
          Primer consumption per coat (l/m <sup>2</sup>)
        </>
      ),
      name: 'finish[primer][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Primer number of coats',
      name: 'finish[primer][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: (
        <>
          Impregnant consumption per coat (l/m <sup>2</sup>)
        </>
      ),
      name: 'finish[impregnant][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Impregnant number of coats',
      name: 'finish[impregnant][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: (
        <p style={{ whiteSpace: 'break-spaces' }}>
          Top coat consumption per coat (l/m <sup>2</sup>)
        </p>
      ),
      name: 'finish[top_coat][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Top coat number of coats',
      name: 'finish[top_coat][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: (
        <>
          Oil consumption per coat (l/m <sup>2</sup>)
        </>
      ),
      name: 'finish[oil][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Oil number of coats',
      name: 'finish[oil][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
  ];

  const solidTimberItems = [
    {
      itemName: 'Increase in thickness',
      name: 'timber[solid][thickness]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Increase in width',
      name: 'timber[solid][width]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Increase in length',
      name: 'timber[solid][length]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
  ];

  const engineeredTimberItems = [
    {
      itemName: 'Increase in thickness',
      name: 'timber[engineered][thickness]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Increase in width',
      name: 'timber[engineered][width]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Increase in length',
      name: 'timber[engineered][length]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
  ];

  const wasteSettings = [
    {
      itemName: 'Solid Timber waste',
      name: 'timber[solid][waste][rate]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Engineered Timber waste',
      name: 'timber[engineered][waste][rate]',
      type: 'number',
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
  ];

  function renderOpsSection(heading, items, unit = null) {
    return (
      <>
        <h5 className="mb-4 mt-4">{heading}</h5>
        <Table className="table-auto">
          <thead>
            <tr>
              <th className="text-left" style={{ maxWidth: '320px', width: '320px' }}>
                Item
              </th>
              <th className="text-left" style={{ maxWidth: '100px', width: '100px' }}>
                {unit || 'Minutes'}
              </th>
              <th></th>
            </tr>
          </thead>
          <TBody>
            {items.map((item) => (
              <tr>
                <td role="cell">{item.itemName}</td>
                <td role="cell">
                  {itemToInput(item, null, methods.register, methods.errors, methods.control)}
                </td>
                <td></td>
              </tr>
            ))}
          </TBody>
        </Table>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-row w-full">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={[]}
            formId={'business'}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          >
            <div className="max-w-md">
              {renderOpsSection('Finish', finishConfig)}
              {renderOpsSection('Solid Timber', solidTimberItems)}
              {renderOpsSection('Engineered Timber', engineeredTimberItems)}
              {renderOpsSection('Waste settings', wasteSettings, 'Rate (%)')}
            </div>

            <div className="mt-6" style={{ maxWidth: '140px' }}>
              <Button as="button" type="submit" wrap={false}>
                Update Settings
              </Button>
            </div>
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

const Table = styled.table`
  ${tw`table-auto`}
  td[role="cell"] {
    ${tw`border border-black px-2 py-2`}
  }
`;

const TBody = styled.tbody`
  tr {
    td {
      .form-element {
        ${tw`mb-0`}
      }

      input {
        ${tw`border-0 m-0 focus:(ring-inset ring-opacity-100)`}
      }
    }
  }
`;
export default GlobalProductionForm;
