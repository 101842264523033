import API from 'apiv1';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';

import DebugConsole from '@appSrc/components/DebugOptions/DebugConsole';

import Button from '@components/buttons/ButtonLink';

import '../../../css/react-notifications.css';
import ContextForm from '../ContextForm';

const DefaultQuoteSettingForm = ({ defaultQuoteSetting }) => {
  const [data, setData] = useState(null);
  const { reset, ...methods } = useForm({ defaultValues: defaultQuoteSetting });

  const onSubmitFn = (data) => {
    API.updateDefaultQuoteSetting(API.toFormData(data))
      .then((resp) => {
        NotificationManager.success(
          'Default quote settings updated successfully',
          'Update Successful',
          40000
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const items = [
    {
      config: ['1/2', '1/2'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Descriptions & Greetings',
          error: {},
          name: 'greetings',
          type: 'textarea',
          rows: 6,
          inputClass:
            'md:w-full lg:w-1/2 rounded-md focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent',
        },
        {
          label: 'Notes',
          error: {},
          name: 'notes',
          type: 'textarea',
          rows: 6,
          inputClass:
            'md:w-full lg:w-1/2 rounded-md focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            formId={'business'}
            onSubmit={(data) => {
              setData(data);
              onSubmitFn(data);
            }}
            formStyle="w-full"
          >
            <div style={{ maxWidth: '140px' }}>
              <Button as="button" type="submit">
                Update Details
              </Button>
            </div>
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default DefaultQuoteSettingForm;
