import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import '../../css/react-notifications.css';

import ContextForm from './ContextForm';
import { Alert } from '../alerts';
import API from 'apiv1';
import DebugConsole from '../DebugOptions/DebugConsole';

const MODE_HANDLER = {
  edit: API.updateProject,
  default: API.createProject,
};

const ProjectsForm = ({ message = null, formId, recordId = null, mode = 'default' }) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();
  const state = useSelector((state) => {
    return {
      clientOptions: state.forms.clients,
    };
  });

  useEffect(() => {
    if (recordId == null) return;
    API.getProject(recordId).then((data) => {
      const client = state.clientOptions.find(
        (option) => Number(option.value) === data['client_id']
      );
      setDefaultValues(data);
      reset({ ...data, client_id: client });
    });
  }, [reset, recordId]);

  const onSubmitFn = (data) => {
    console.log('Data', data);
    console.log('Submit count', methods.formState.submitCount);
    // NotificationManager.success('Success message', 'Title here');
    MODE_HANDLER[mode || 'default'](
      API.toFormData({ ...data, client_id: data['client_id'].value }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Project Name',
          error: { required: 'Please enter a project name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
      ],
    },
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Client',
          error: { required: 'Please select a client' },
          name: 'client_id',
          options: state.clientOptions,
          type: 'combobox',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            formId={formId}
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          />
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default ProjectsForm;
