import API from 'apiv1';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable, { TruncatedCell } from '@components/datatable';

import { setSuppliers } from '../actions/formActions';
import RecordActions from '../components/datatable/RecordActions';
import MouldingForm from '../components/forms/MouldingForm';
import MaterialsMenu from '../components/menus/materials';
import Modal from '../components/modals/modal';
// import SectionTitle from '../components/section-title';
import Widget from '../components/widget';
import { store } from '../store';

const Page = ({ records, suppliers }) => {
  const dispatch = useDispatch();
  const formId = 'mouldings';

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, [records]);

  const columns = [
    {
      Header: 'Product Code',
      accessor: 'product_code',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Dimensions',
      accessor: 'dimensions',
    },
    {
      Header: 'Finish',
      accessor: 'finish',
    },
    {
      Header: 'Material',
      accessor: 'material',
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
    },
    {
      Header: 'VAT',
      accessor: 'vat_rate',
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => RecordActions(value, MouldingForm, API.deleteMouldingsAndTrimmingsItem),
    },
  ];

  return (
    <>
      {/* <SectionTitle title="Materials & Pricing" subtitle="Mouldings & Trimmings" /> */}

      <MaterialsMenu />

      <Widget
        title="Mouldings & Trimmings"
        borderless={true}
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Mouldings & Trimmings"
              body={<MouldingForm formId={formId} />}
              buttonTitle="Add item"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
              buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <Datatable columns={columns} data={records} />
      </Widget>
    </>
  );
};

const MouldingPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default MouldingPage;
