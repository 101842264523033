import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import dig from './dig';

// XXX: Babel preset-env does NOT correctly realise that Webpack requires
// these polyfills when using dynamic import
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

const REACT_APP_SELECTOR = '[data-react-app]';

let observer: MutationObserver | null;

/**
 * Used to mount a component into a HTMLElement based on the data-react-app property set
 * on the element. Uses dot-notation to find the specific component in the provided component
 * tree. For example, if you Specify `data-react-app="One.Two.Three"`, it will attempt to read
 * `components.One.Two.Three` and render the component into the element. If it cannot find the
 * specified component it will issue a warning.
 *
 * @param element An HTMLElement with data-react-app and (optionally) data-react-props set
 * @param components An object containing a tree of components to search
 */
const mountComponent = async function (element: HTMLElement, components: any) {
  const appName = element.dataset.reactApp;
  const anchorSelector = element.dataset.anchorSelector;

  if (!appName) {
    // eslint-disable-next-line no-console
    console.warn(`Missing ${REACT_APP_SELECTOR} attribute`);
    return;
  }

  const props = JSON.parse(element.dataset.reactProps || '{}');

  const app = dig(appName, components) as any;

  if (!app) {
    // eslint-disable-next-line no-console
    console.warn('Attempted to load unknown React app', appName);
    return;
  }

  const { render } = await import('react-dom');

  if (anchorSelector) {
    render(React.createElement(app, props), document.getElementById(anchorSelector));
  } else {
    render(React.createElement(app, props), element);
  }
};

/**
 * This will search the given rootElement for elements with `data-react-app`,including
 * the rootElement itself.
 * @param rootElement the element to begin searching from
 */
const findReactAppElements = function (rootElement: HTMLElement | Document) {
  const result = Array.from(rootElement.querySelectorAll<HTMLElement>(REACT_APP_SELECTOR));

  // Is the root element a React app?
  if (rootElement instanceof HTMLElement && rootElement.matches(REACT_APP_SELECTOR)) {
    result.push(rootElement);
  }

  return result;
};

/**
 * This will search the given rootElement for elements with `data-react-app` and attempt to
 * render them with the components.
 * @param rootElement the element to begin searching from
 * @param components an object containing a tree of React.Component subclasses
 */
const mountComponents = function (rootElement: HTMLElement | Document, components: any) {
  findReactAppElements(rootElement).forEach((element) => {
    // console.log('FOUND ELEMENTS', element);
    mountComponent(element, components);
  });
};

/**
 * This will search the given rootElement for elements with `data-react-app` and attempt to
 * unmount them.
 * @param rootElement the element to begin searching from
 */
const unmountComponents = async function (rootElement: HTMLElement | Document) {
  const { unmountComponentAtNode } = await import('react-dom');
  findReactAppElements(rootElement).forEach((element) => unmountComponentAtNode(element));
};

const unmountComponent = async function (element: HTMLElement | Document) {
  // console.log('unmounting', element, );
  unmountComponentAtNode(element)
  // unmountComponentAtNode(element);
};

/**
 * Creates a MutationObserver to initialise dynamically created React app elements in the
 * document.
 * @param rootElement the element to begin searching from
 * @param components an object containing a tree of React.Component subclasses
 */
const createObserver = function (rootElement: HTMLElement | Document, components: any) {
  observer = new MutationObserver((mutationsList, _observer) => {
    mutationsList.forEach((mutation) => {
      Array.from(mutation.addedNodes)
        .filter((addedNode) => addedNode.nodeType === Node.ELEMENT_NODE)
        .forEach((addedNode) => mountComponents(<HTMLElement>addedNode, components));
      Array.from(mutation.removedNodes)
        .filter((removedNode) => removedNode.nodeType === Node.ELEMENT_NODE)
        .forEach((removedNode) => unmountComponents(<HTMLElement>removedNode));
    });
  });
  observer.observe(rootElement, { childList: true, subtree: true });
};

/**
 * Initalises component mounting on the given element, including monitoring the DOM for
 * new elements with the required data attributes for dynamic mounting.
 * @param rootElement the element to begin searching from
 * @param components an object containing a tree of React.Component subclasses
 */
const initialize = function (rootElement: HTMLElement | Document, components: any) {
  mountComponents(rootElement, components);
  createObserver(rootElement, components);
};

/**
 * Removes the MutationObserver from the document and stops watching for DOM changes.
 */
const uninitialize = function () {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
};

export { initialize, uninitialize, mountComponent, mountComponents, unmountComponents, unmountComponent };
