import API from 'apiv1';
import { startCase } from 'lodash';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable, { TruncatedCell } from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import CasementHardwareSetForm from '@components/forms/CasementHardwareSetForm';
import MaterialsMenu from '@components/menus/materials';
import Modal from '@components/modals/modal';
import SimpleTooltip from '@components/tooltips/SimpleTooltip';
import Widget from '@components/widget';

import { store } from '../store';

const Page = ({ hardwareSets, hardware }) => {
  const dispatch = useDispatch();
  const formId = 'casementHardwareSet';

  const columns = [
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Sub-category',
      accessor: 'sub_category',
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => {
        return (
          <SimpleTooltip
            usePortal={true}
            hideArrow={false}
            portalContainer={document.getElementById('portal-container')}
            trigger="click"
            placement="bottom"
            tooltip={
              <div className="w-full">
                <table className="table w-full text-black bg-white">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.row.original.materials &&
                      Object.entries(props.row.original.materials).map(([category, value]) => (
                        <tr>
                          <td>{startCase(category)}</td>
                          {/* @ts-ignore */}
                          <td className="text-right">{value.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            }
          >
            {props.value}
          </SimpleTooltip>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) =>
        RecordActions(value, CasementHardwareSetForm, API.deleteCasementHardwareSet, { hardware }),
    },
  ];

  return (
    <>
      {/* <SectionTitle title="Materials & Pricing " subtitle="Timber" /> */}

      <MaterialsMenu />

      <Widget
        title="Casement Hardware Sets"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="New Hardware Set"
              body={<CasementHardwareSetForm formId={formId} hardware={hardware} />}
              buttonTitle="Create"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
              buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <Datatable columns={columns} data={hardwareSets} />
      </Widget>
    </>
  );
};

const CasementHardwareSetPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default CasementHardwareSetPage;
