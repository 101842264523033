import { TOGGLE_DEBUG } from '../actions/debugActions';

export default function forms(state = { status: false }, action) {
  switch (action.type) {
    case TOGGLE_DEBUG:
      // const { key, file } = action.payload;
      return {
        ...state,
        status: !state.status,
      };
    default:
      return state;
  }
}
