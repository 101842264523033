import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';
import API from 'apiv1';
import React, { useState, useRef, useEffect } from 'react';
import { CgDarkMode } from 'react-icons/cg';
import { FaSquareRootAlt } from 'react-icons/fa';
import { Provider } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import tw from 'twin.macro';
import { GlobalStyles } from 'twin.macro';

import '@appSrc/css/_components.css';
import '@appSrc/css/main.css';
import '@appSrc/css/tailwind.css';
import BoxedLayout from '@appSrc/layouts/content/Boxed';
import { store } from '@appSrc/store';

import ButtonIcon from '@components/buttons/ButtonIcon';
import Button from '@components/buttons/ButtonLink';
import RadiusArc from '@components/icons/RadiusArc';
import ThreePointArc from '@components/icons/ThreePointArc';
import Widget from '@components/widget';

const stickyStyle = {
  position: 'fixed',
  top: 0,
  width: '100%',
  paddingTop: 60,
};

const funcs = {
  sqrt: ['sqrt()', 5],
  cubert: ['cubert()', 7],
  pi: ['pi', 2],
  sin: ['sin()', 4],
  cos: ['cos()', 4],
  tan: ['tan()', 4],
  atan: ['atan()', 5],
  acos: ['acos()', 5],
  asin: ['asin()', 5],
  radiusArc: ['arc_two_points_radius(:name, pointA, pointB, radius)', 23],
  threePointArc: ['arc_three_points(:name, pointA, pointB, pointC)', 18],
};

const DrawingEditorPage = ({ instructions, id = null }) => {
  const svgContainer = useRef(null);
  const editorRef = useRef(null);
  const [svg, setSVG] = useState('');
  const [editorTheme, setEditorTheme] = useState('vs-dark');
  const [rawElement, setRaw] = useState(null);
  const monaco = useMonaco();

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function showValue() {
    alert(editorRef.current.getValue());
  }

  function toggleTheme() {
    if (editorTheme === 'vs-dark') {
      setEditorTheme('light');
    } else {
      setEditorTheme('vs-dark');
    }
  }

  function insertFunction(funcName) {
    let p = editorRef.current.getPosition();
    editorRef.current.executeEdits('', [
      {
        range: new monaco.Range(p.lineNumber, p.column, p.lineNumber, p.column),
        text: funcs[funcName][0],
      },
    ]);
    editorRef.current.setPosition({
      lineNumber: p.lineNumber,
      column: p.column + funcs[funcName][1],
    });
    editorRef.current.focus();
  }

  const handleRefresh = () => {
    // console.log(svgContainer.current.innerHTML);
    const formData = { drawing_data: editorRef.current.getValue() };
    if (id !== null) {
      formData['id'] = id;
    }
    API.createDrawing(API.toFormData(formData)).then((generatedSVG) => {
      svgContainer.current.innerHTML = generatedSVG.data;
      setRaw(svgContainer.current.firstChild);
      // svgContainer.current.innerHTML.width = '50vw'
    });
  };

  useEffect(() => {
    const formData = { drawing_data: instructions };
    if (id !== null) {
      formData['id'] = id;
    }
    API.createDrawing(API.toFormData(formData)).then((generatedSVG) => {
      svgContainer.current.innerHTML = generatedSVG.data;
      setRaw(svgContainer.current.firstChild);
      // svgContainer.current.innerHTML.width = '50vw'
    });
  }, []);

  if (rawElement) {
    rawElement.setAttribute('width', '40vw');
    rawElement.setAttribute('height', '98vh');
  }

  return (
    // <Provider store={store}>
    <MainContainer>
      <GlobalStyles />
      <EditorContainer>
        <ToolsContainer>
          <Button as="a" href="/drawing_instructions">
            Back
          </Button>
          <Button as="button" onClick={handleRefresh}>
            Save & Refresh
          </Button>
          <ButtonIcon as="button" onClick={toggleTheme}>
            <CgDarkMode size={16} />
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('sqrt')}>
            <FaSquareRootAlt size={16} />
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('cubert')}>
            <span style={{ fontSize: 16 }}>3√𝑥</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('pi')}>
            <span style={{ fontSize: 16 }}>π</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('sin')}>
            <span style={{ fontSize: 16 }}>sin θ</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('cos')}>
            <span style={{ fontSize: 16 }}>cos θ</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('tan')}>
            <span style={{ fontSize: 16 }}>tan θ</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('atan')}>
            <span style={{ fontSize: 16 }}>arctan(𝑥)</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('acos')}>
            <span style={{ fontSize: 16 }}>arccos(𝑥)</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('asin')}>
            <span style={{ fontSize: 16 }}>arcsin(𝑥)</span>
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('radiusArc')}>
            <RadiusArc size={8} />
          </ButtonIcon>
          <ButtonIcon as="button" onClick={(e) => insertFunction('threePointArc')}>
            <ThreePointArc size={6} />
          </ButtonIcon>
        </ToolsContainer>
        <Editor
          height="90vh"
          defaultLanguage="ruby"
          theme={editorTheme}
          defaultValue={instructions}
          options={{ fontSize: 18, cursorWidth: 4 }}
          onMount={handleEditorDidMount}
        />
      </EditorContainer>
      <DiagramPreviewContainer>
        <TransformWrapper defaultScale={1}>
          <TransformComponent>
            <div
              style={{ padding: 20 }}
              ref={svgContainer}
              // style={{ minWidth: '50vw', minHeight: '100vh', maxWidth: '50vw', maxHeight: '100vh' }}
              // dangerouslySetInnerHTML={{ __html: svg }}
            ></div>
          </TransformComponent>
        </TransformWrapper>
      </DiagramPreviewContainer>
    </MainContainer>
    // </Provider>
  );
};

const MainContainer = styled.div`
  ${tw`flex flex-row`}

  input[type='text'], input[type='number'], textarea {
    ${tw`border-0 ring-0 focus:ring-0`};
  }
`;

const ToolsContainer = styled.div`
  ${tw`flex flex-row space-x-2 mb-2 px-2 py-3`}
`;

const EditorContainer = styled.div`
  ${tw`w-1/2 h-screen bg-grey-M80`}
`;

const DiagramPreviewContainer = styled.div`
  ${tw`w-1/2 h-screen bg-blue-400 flex justify-center items-center`}
  background-image: linear-gradient(#4589D2 1pt, transparent 1pt), linear-gradient(90deg, #4589D2 1pt, transparent 1pt);
  background-size: 10pt 10pt;
`;

export default DrawingEditorPage;
