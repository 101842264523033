import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';

import ButtonIcon from '@components/buttons/ButtonIcon';
import Button from '@components/buttons/ButtonLink';

import '../../css/components/modals.css';

const DataModal = ({
  buttonComponent = null,
  modalButtonText,
  buttonClass = null,
  onButtonClick = null,
  handleSearch = null,
  columns = [],
  data = [],
  title = null,
  clearable = false,
  onSelect = (_) => {},
  handleClear = () => {},
}) => {
  const modalRef = useRef(null);
  const [hidden, setHidden] = useState(true);
  const [inModal, setInModal] = useState(null);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  // Check if another modal is already open so we do not close the backdrop
  useSelector((state) => {
    if (inModal !== null) return;
    // @ts-ignore
    setInModal(state.config['backdrop']);
  });

  const show = () => {
    setHidden(false);
    onButtonClick && onButtonClick();
    if (!inModal) {
      dispatch({
        type: 'SET_CONFIG_KEY',
        key: 'backdrop',
        value: true,
      });
    }
  };

  const closeBackdrop = () => {
    if (!inModal) {
      // Do not close backdrop if we are in another modal
      dispatch({
        type: 'SET_CONFIG_KEY',
        key: 'backdrop',
        value: false,
      });
    }
  };

  const hide = () => {
    setHidden(true);
    closeBackdrop();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hidden || modalRef.current.contains(event.target)) {
        return false;
      }

      setHidden(!hidden);
      closeBackdrop();
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        setHidden(true);
        closeBackdrop();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyPress);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [hidden, modalRef, dispatch]);

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    handleSearch(e.target.value);
  };

  const clearSelected = () => {
    handleClear();
  };

  const renderOriginButton = () => {
    if (buttonComponent) {
      return buttonComponent(show, modalButtonText);
    }

    return (
      <div className={`flex flex-row ${clearable && 'space-x-2'}`}>
        <Button className={buttonClass} style={{ maxHeight: '2.5rem' }} as="button" onClick={show}>
          {modalButtonText}
        </Button>
        {clearable && (
          <ButtonIcon as="button" onClick={clearSelected}>
            <AiOutlineClear size={24} />
          </ButtonIcon>
        )}
      </div>
    );
  };

  if (columns.length === 0) {
    columns = [
      {
        title: 'Timber name',
        accessor: 'timber_name',
      },
      {
        title: 'Density',
        accessor: 'density',
      },
      {
        title: 'U-value',
        accessor: 'uvalue',
      },
      {
        title: 'Type S/H',
        accessor: 'type',
      },
      {
        title: 'Certificates',
        accessor: 'certificates',
      },
    ];
  }

  const handleSelection = (item) => {
    hide();
    onSelect(item);
  };

  return (
    <>
      {renderOriginButton()}
      {!hidden ? (
        <div className="modal show">
          <div className="relative w-auto lg:my-4 mx-auto" ref={modalRef}>
            <div
              className="bg-white text-grey-900 border-grey-200 dark:bg-grey-800 dark:text-white dark:border-grey-700 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none"
              style={{ height: 916, maxHeight: 916, width: 800, maxWidth: 800 }}
            >
              <div
                className="flex flex-col w-full px-12 pt-8 pb-4 space-y-12"
                style={{ maxHeight: 'inherit' }}
              >
                <div className="self-center">{title || 'Frame Timber Selector'}</div>
                <div className="self-center w-3/4 h-12">
                  <div className="form-element">
                    <input
                      name="search"
                      type={'text'}
                      className={`form-input`}
                      placeholder={'Search'}
                      onChange={handleSearchChange}
                      value={query}
                    />
                  </div>
                </div>
                <div className="self-center overflow-y-auto w-full">
                  <table className="table w-full">
                    <thead className="">
                      <tr>
                        {/* <th className="py-2 px-4 border-solid border-b border-r"></th>
                          <th
                            className="py-2 px-4 border-solid border-b border-r"
                            style={{ width: '100px' }}
                          >
                            Thickness
                          </th>
                          <th
                            className="py-2 px-4 border-solid border-b border-r"
                            style={{ minWidth: '100px', maxWidth: '110px' }}
                          >
                            Width
                          </th> */}
                        {columns.map((column) => (
                          <th
                            className="py-2 px-4 border-solid border-b text-left"
                            style={{ minWidth: '200px' }}
                            key={column.title}
                          >
                            {column.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="">
                      {data.map((record) => (
                        <tr
                          className="bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white cursor-pointer"
                          key={record.id}
                        >
                          {columns.map((column, i) => (
                            <td
                              className="py-2 px-4 border-solid border-b border-r"
                              style={{ minWidth: '200px' }}
                              onClick={() => handleSelection(record)}
                              key={record.id + i}
                            >
                              {record[column.accessor]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DataModal;
