import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';

import '../../css/react-notifications.css';
import { Alert } from '../alerts';
import ContextForm from './ContextForm';

const MODE_HANDLER = {
  edit: API.updateTimberType,
  default: API.createTimberType,
};

const TimberTypeForm = ({ message = null, formId, recordId = null, mode = 'default' }) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();

  useEffect(() => {
    if (recordId == null) return;
    API.getTimberType(recordId).then((data) => {
      setDefaultValues(data);
      reset({ ...data });
    });
  }, [reset, recordId]);

  const onSubmitFn = (data) => {
    console.log('Data', data);
    console.log('Submit count', methods.formState.submitCount);
    // NotificationManager.success('Success message', 'Title here');
    MODE_HANDLER[mode || 'default'](API.toFormData({ ...data }), recordId)
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['3/4', '3/4', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Timber name',
          error: { required: 'Please enter timber name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Timber type',
          error: {},
          name: 'timber_type',
          options: [
            { label: 'Hardwood', value: 'hardwood' },
            { label: 'Soft wood', value: 'soft wood' },
          ],
          type: 'select',
        },
        {
          label: 'Description',
          error: { required: 'Please enter an item description' },
          name: 'description',
          type: 'textarea',
          defaultValue: '',
          inlineClass: 'min-w-26',
        },
      ],
    },
    {
      config: ['full', 'full', 'full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Color',
          error: { required: 'Please enter timber color' },
          name: 'color',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Lambda',
          error: { required: 'Please enter timber lambda' },
          name: 'timber_lambda',
          type: 'number',
          defaultValue: '',
        },
        {
          label: 'Density',
          error: { required: 'Please enter timber density' },
          name: 'density',
          type: 'number',
          defaultValue: '',
        },
      ],
    },
  ];

  return (
    <div className="flex flex-row">
      {data && message && (
        <div className="w-full mb-4">
          <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
            {message}
          </Alert>
        </div>
      )}
      <FormProvider {...methods} reset={reset}>
        <ContextForm
          formId={formId}
          gridItems={items}
          onSubmit={(data) => {
            onSubmit(data);
            onSubmitFn(data);
          }}
          formStyle="flex flex-row"
        />
      </FormProvider>
    </div>
  );
};

export default TimberTypeForm;
