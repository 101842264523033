import API from 'apiv1';
import React, { useEffect, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import ButtonIcon from '@components/buttons/ButtonIcon';
import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable, { TruncatedCell } from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import CordsForm from '@components/forms/CordsForm';
import HardwareForm from '@components/forms/HardwareForm';
import InsulatorForm from '@components/forms/InsulatorForm';
import WeightsForm from '@components/forms/WeightsForm';
import MaterialsMenu from '@components/menus/materials';
import Modal from '@components/modals/modal';
import { DefaultTabs } from '@components/tabs';
// import SectionTitle from '@components/section-title';
import Widget from '@components/widget';

import { setSuppliers } from '../actions/formActions';
import { store } from '../store';

const Page = ({ records, casements, universal, suppliers, hardwareConfig, weightsAndCords }) => {
  const dispatch = useDispatch();
  const formId = 'hardwareForm';
  const [tabID, setTabID] = useState('box_sash');

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, [records]);

  const hardwareAction = ({ value, ...props }) => {
    switch (props.row.original['hardware_type']) {
      case 'NORMAL':
        return RecordActions(value, HardwareForm, API.deleteHardwareItem, {
          newFormConfig: hardwareConfig,
        });
        break;
      case 'INSULATOR':
        return RecordActions(value, InsulatorForm, API.deleteHardwareItem, {
          newFormConfig: hardwareConfig,
        });
        break;

      case 'WEIGHTS':
        return RecordActions(value, WeightsForm, API.deleteHardwareItem);
        break;

      case 'CORDS':
        return RecordActions(value, CordsForm, API.deleteHardwareItem);
        break;

      default:
        return RecordActions(value, HardwareForm, API.deleteHardwareItem, {
          newFormConfig: hardwareConfig,
        });
        break;
    }
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, ...props }) => {
        return (
          <span>
            {value}
            {props.row.original['product_url'] && (
              <ButtonIcon
                as="a"
                href={props.row.original['product_url']}
                className="ml-1"
                style={{ padding: '2px' }}
                target="_blank"
              >
                {<MdOpenInNew size={10} />}
              </ButtonIcon>
            )}
          </span>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      maxWidth: 400,
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 200, toolTipStyle: { maxWidth: 400 } }),
    },
    {
      Header: 'Finish',
      accessor: 'finish',
      Cell: ({ value, ...props }) =>
        TruncatedCell({ value, body: props.row.original['finish_full'] }),
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Cost',
      accessor: 'price',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: hardwareAction,
    },
  ];

  const weightsAndCordsColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, ...props }) => {
        return (
          <span>
            {value}
            {props.row.original['product_url'] && (
              <ButtonIcon
                as="a"
                href={props.row.original['product_url']}
                className="ml-1"
                style={{ padding: '2px' }}
                target="_blank"
              >
                {<MdOpenInNew size={10} />}
              </ButtonIcon>
            )}
          </span>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      maxWidth: 400,
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 200 }),
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Cost',
      accessor: 'price',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: hardwareAction,
    },
  ];

  const tabs = [
    {
      title: 'Box Sash',
      id: 'box_sash',
      index: 0,
      content: <Datatable columns={columns} data={records} />,
    },
    {
      title: 'Weights & Cords',
      id: 'box_sash_weight_cords',
      index: 1,
      content: <Datatable columns={weightsAndCordsColumns} data={weightsAndCords} />,
    },
    {
      title: 'Casement',
      id: 'casement',
      index: 2,
      content: <Datatable columns={columns} data={casements} />,
    },
    {
      title: 'Universal',
      id: 'all',
      index: 3,
      content: <Datatable columns={columns} data={universal} />,
    },
  ];

  const handleTabChange = (tab) => {
    setTabID(tab.id);
  };

  return (
    <>
      <MaterialsMenu />

      <Widget
        title="Hardware"
        borderless={true}
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            {tabID === 'box_sash_weight_cords' && (
              <>
                <Modal
                  title="Add Weights"
                  body={<WeightsForm formId={formId + 'w'} />}
                  buttonTitle="Add Hardware"
                  buttonComponent={NewResourceModalButton}
                  modalButtonText="New Weight"
                  buttonFormId={formId + 'w'}
                  buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                />
                <Modal
                  title="Add Cord"
                  body={<CordsForm formId={formId + 'c'} />}
                  buttonTitle="Add Hardware"
                  buttonComponent={NewResourceModalButton}
                  modalButtonText="New Cord"
                  buttonFormId={formId + 'c'}
                  buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                />
              </>
            )}
            {tabID !== 'box_sash_weight_cords' && (
              <>
                <Modal
                  title="Add Seal"
                  body={
                    <InsulatorForm
                      newFormConfig={hardwareConfig}
                      formId={formId + 'i'}
                      category={tabID}
                    />
                  }
                  buttonTitle="Add Hardware"
                  buttonComponent={NewResourceModalButton}
                  modalButtonText="New Seal"
                  buttonFormId={formId + 'i'}
                  buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                />
                <Modal
                  title="Add Hardware"
                  body={
                    <HardwareForm newFormConfig={hardwareConfig} formId={formId} category={tabID} />
                  }
                  buttonTitle="Add Hardware"
                  buttonComponent={NewResourceModalButton}
                  modalButtonText="New"
                  buttonFormId={formId}
                  buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                />
              </>
            )}
          </div>
        }
      >
        <DefaultTabs tabs={tabs} onChange={handleTabChange} />
      </Widget>
    </>
  );
};

const HardwarePage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default HardwarePage;
