import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';

import { setToLoading, loadingFinished } from '@appSrc/actions/configActions';
import {
  setHardwareSet,
  setTimber,
  setGlazingUnit,
  setFinishSystem,
  setFormField,
} from '@appSrc/actions/configuratorActions';
import Button from '@appSrc/components/buttons/ButtonLink';
import '@appSrc/css/react-notifications.css';

import DebugConsole from '@components/DebugOptions/DebugConsole';

import { updateSelectionKeys, insertKey, update } from '@utils/formHelpers';

import EditProductItemSection from '../EditProductItemSection';
import ProductItemsSection, { handleVariants } from '../ProductItemsSection';
import Finish, { SINGLE_COLOUR } from './Finish';
import Frame, { FRAME_TIMBER_KEY } from './Frame';
import Glazing, { LOWER_GLAZING_UNIT, UPPER_GLAZING_UNIT } from './Glazing';
import GlazingBars from './GlazingBars';
// import GlazingBead from './GlazingBead';
import Hardware, { HARDWARE_SET } from './Hardware';
import Misc from './Misc';
import MouldingsAndTrimmings from './MouldingsAndTrimmings';
import ProductInformation from './ProductInformation';
import Sashes, { SASH_TIMBER_KEY } from './Sashes';
import Sill, { SILL_TIMBER_KEY } from './Sill';

const MODE_HANDLER = {
  default: {
    edit: API.updateBoxSashConfig,
    default: API.createBoxSashConfig,
  },
  productItems: {
    edit: API.updateBoxSashProductItems,
    default: API.createBoxSashProductItems,
  },
};

const BoxSashConfigurator = ({
  recordId = null,
  rfqID = null,
  productItemID = null,
  materials = {},
  availableSiteOptions = [],
  mode = 'default',
  boxSashConfig = null,
  context = 'default',
  redirectToURL = '/box_sash_configs',
}) => {
  const [sashThicknessMemo, setSashThicknessMemo] = useState(null);
  const { reset, ...methods } = useForm();
  const state = useSelector((state) => {
    return {
      // @ts-ignore
      file: state.forms['product_image' + recordId],
      // @ts-ignore
      timbers: state.configuratorForms.timber,
      // @ts-ignore
      glazingUnits: state.configuratorForms.glazingUnits,
      // @ts-ignore
      formFields: state.configuratorForms.formFields,
      // @ts-ignore
      hardwareSet: state.configuratorForms[HARDWARE_SET],
      // @ts-ignore
      hardwareSetItems: state.configuratorForms.hardwareSetItems,
      // @ts-ignore
      finishSystem: state.configuratorForms.finishSystem,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (recordId == null || boxSashConfig == null) return;

    console.log('BoxSashConfig: ', boxSashConfig);
    // We need to process the incoming raw data and set the form state
    // 1. Transform any fields (TODO: do this in the backend)
    update(boxSashConfig, 'glazing_bars.true_bars', (val) =>
      val === true ? 'trueBars' : 'stickOn'
    );
    // 2. Set the form fields
    reset(boxSashConfig);
    // 3. Set the items via redux dispatch
    // sill_timber
    dispatch(setTimber(SILL_TIMBER_KEY, boxSashConfig['sill_timber']));
    // frame_timber
    dispatch(setTimber(FRAME_TIMBER_KEY, boxSashConfig['frame_timber']));
    // sash_timber
    dispatch(setTimber(SASH_TIMBER_KEY, boxSashConfig['sash_timber']));
    // upper_sash
    dispatch(setGlazingUnit(UPPER_GLAZING_UNIT, boxSashConfig['upper_sash']));
    // lower_sash
    dispatch(setGlazingUnit(LOWER_GLAZING_UNIT, boxSashConfig['lower_sash']));
    // hardware_set
    dispatch(setHardwareSet(HARDWARE_SET, boxSashConfig['hardware_set']));
    // hardware_set_items
    dispatch(setHardwareSet('hardwareSetItems', remapHardwareSet(boxSashConfig['hardware_set'])));
    // inner_frame_finish
    dispatch(setFinishSystem(boxSashConfig['finish_system']));
    // single_colour
    dispatch(setFormField(SINGLE_COLOUR, boxSashConfig['single_colour']));
  }, [reset, recordId, boxSashConfig]);

  const remapHardwareSet = (hardwareSet) => {
    if (hardwareSet == null) return {};

    const newHardwareSet = {};
    Object.keys(hardwareSet).forEach((key) => {
      if (key === 'seal' && hardwareSet[key] && hardwareSet[key]['hardware_variant']) {
        newHardwareSet[key] = hardwareSet[key]['hardware_variant'];
      } else if (hardwareSet[key] && hardwareSet[key]['hardware']) {
        newHardwareSet[key] = hardwareSet[key]['hardware'];
      }

      if (key === 'weight' && hardwareSet[key]) {
        newHardwareSet['weight_supplier'] = hardwareSet[key];
      }
    });
    return newHardwareSet;
  };

  const remapHardwareSetItems = (hardwareSetItems) => {
    if (hardwareSetItems == null) return {};

    const newHardwareSet = {};
    Object.keys(hardwareSetItems).forEach((key) => {
      if (hardwareSetItems[key]) {
        newHardwareSet[key] = { id: hardwareSetItems[key]['id'] };
      }
    });
    return newHardwareSet;
  };

  const typeOfSash = methods.watch('type_of_sash');

  const isTriple = () => typeOfSash && typeOfSash.value === 'triple';
  const isDouble = () => typeOfSash && typeOfSash.value === 'double';
  const isSashOnly = () => typeOfSash && typeOfSash.value === 'sash_only';

  const values = { state, isTriple: isTriple(), ...methods.watch() };

  const handleSubmit = async (formData) => {
    const result = await methods.trigger();
    if (result) submitForm(formData);
  };

  const submitForm = (formData) => {
    dispatch(setToLoading());

    update(formData, 'glazing_bars.true_bars', (val) => val === 'trueBars');
    updateSelectionKeys(formData, 'spring');
    updateSelectionKeys(formData, 'type_of_sash');
    updateSelectionKeys(formData, 'hardware_finish_id');
    if (context === 'productItems' && mode !== 'edit') {
      update(formData, 'product_items', handleVariants);
      insertKey(formData, 'rfq_id', rfqID);
      if (productItemID) insertKey(formData, 'product_item_id', productItemID);
    }

    if (context === 'productItems' && mode === 'edit') {
      updateSelectionKeys(formData, 'product_item.site');
      insertKey(formData, 'rfq_id', rfqID);
      insertKey(formData, 'product_item_id', productItemID);
    }

    insertKey(formData, 'frame.fabrication_sizes', formData.frame['fabrication'] ? true : false);
    insertKey(
      formData,
      'frame.arched_top_outer',
      formData.frame['arch_rise_type'] === 'visibleArch' ? 1 : 0
    );
    insertKey(
      formData,
      'frame.fully_arched_head',
      formData.frame['arch_rise_type'] === 'fullyArched' ? 1 : 0
    );
    insertKey(formData, 'glazing.type_of_sash', typeOfSash.value);
    insertKey(formData, 'glazing_bars.type_of_sash', typeOfSash.value);
    insertKey(
      formData,
      'sill_timber.id',
      state.timbers[SILL_TIMBER_KEY] && state.timbers[SILL_TIMBER_KEY]['id']
    );
    insertKey(
      formData,
      'frame_timber.id',
      state.timbers[FRAME_TIMBER_KEY] && state.timbers[FRAME_TIMBER_KEY]['id']
    );
    insertKey(
      formData,
      'sash_timber.id',
      state.timbers[SASH_TIMBER_KEY] && state.timbers[SASH_TIMBER_KEY]['id']
    );
    insertKey(
      formData,
      'upper_sash.id',
      state.glazingUnits[UPPER_GLAZING_UNIT] && state.glazingUnits[UPPER_GLAZING_UNIT]['id']
    );
    insertKey(
      formData,
      'lower_sash.id',
      state.glazingUnits[LOWER_GLAZING_UNIT] && state.glazingUnits[LOWER_GLAZING_UNIT]['id']
    );
    insertKey(formData, 'hardware_set.id', state.hardwareSet && state.hardwareSet['id']);
    insertKey(formData, 'hardware_set_items', remapHardwareSetItems(state.hardwareSetItems));
    insertKey(formData, 'finish_system', state.finishSystem);
    insertKey(formData, 'single_colour', state.formFields[SINGLE_COLOUR]);
    insertKey(formData, 'parent_id', boxSashConfig && boxSashConfig.id);

    console.log('FORM DATA: ', formData);
    MODE_HANDLER[context][mode](API.toFormData({ data: JSON.stringify(formData) }), recordId)
      .then((resp) => {
        dispatch(loadingFinished());
        window.location.replace(redirectToURL);
      })
      .catch(() => {
        dispatch(loadingFinished());
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        );
      });
  };

  useEffect(() => {
    const { isDirty, dirtyFields } = methods.formState;

    if (
      isDirty &&
      dirtyFields['sash'] &&
      dirtyFields['sash']['sash'] &&
      dirtyFields['sash']['sash']['thickness']
    ) {
      const sashThickness = methods.watch('sash[sash][thickness]');

      if (sashThicknessMemo !== sashThickness) {
        setSashThicknessMemo(sashThickness);
        const wholeState = methods.watch();
        reset(
          {
            ...wholeState,
            sash: {
              ...wholeState.sash,
              stiles: { thickness: sashThickness, width: wholeState.sash.stiles.width },
              top_rail: { thickness: sashThickness, width: wholeState.sash.top_rail.width },
              meeting_rail: { thickness: sashThickness, width: wholeState.sash.meeting_rail.width },
              bottom_rail: { thickness: sashThickness, width: wholeState.sash.bottom_rail.width },
            },
          },
          {
            errors: true,
          }
        );
      }
    }
  }, [methods.formState]);

  return (
    <div className="flex flex-col">
      <FormProvider {...methods} reset={reset}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="box-sash-form">
          <ProductInformation recordId={recordId} imageFile={state.file} />

          {context === 'productItems' && mode !== 'edit' && (
            <ProductItemsSection recordId={recordId} availableSiteOptions={availableSiteOptions} />
          )}

          {context === 'productItems' && mode === 'edit' && (
            <EditProductItemSection availableSiteOptions={availableSiteOptions} />
          )}

          <Frame
            recordId={recordId}
            timberItems={materials['timber_types']}
            isFabrication={boxSashConfig !== null && !!boxSashConfig['frame']['fabrication']}
            isTriple={isTriple()}
            isDouble={isDouble()}
          />

          <Sill recordId={recordId} timberItems={materials['timber_types']} />

          <Sashes
            recordId={recordId}
            borderBottom={false}
            timberItems={materials['timber_types']}
            isTriple={isTriple()}
          />
          {/* <GlazingBead recordId={recordId} borderTop={false} /> */}

          <Glazing
            recordId={recordId}
            borderBottom={false}
            glazingUnits={materials['glazing_units']}
          />

          <GlazingBars recordId={recordId} borderTop={false} isTriple={isTriple()} />

          <Finish
            recordId={recordId}
            timberFinish={materials['timber_finishes']}
            finishSystems={materials['finish_systems']}
          />

          <Hardware
            // @ts-ignore
            recordId={recordId}
            hardwareSets={materials['hardware_sets']}
            hardware={materials['hardware']}
            sealHardware={materials['seal_hardware_variants']}
            weightSuppliers={materials['weight_suppliers']}
          />

          <MouldingsAndTrimmings recordId={recordId} mouldings={materials['mouldings']} />

          {/* <Misc recordId={recordId} misc={materials['misc']} borderBottom={false} /> */}
          <div
            className="fixed bottom-0 py-4 pl-64 inset-x-0 bg-orange-M100"
            style={{ zIndex: 100 }}
          >
            <Button as="button" type="submit">
              Save
            </Button>
            <Button
              buttonStyle="light-secondary-outline"
              as="button"
              className="ml-2"
              onClick={() => (window.location.href = redirectToURL)}
            >
              Back To {redirectToURL === '/box_sash_configs' ? 'Products' : 'Project'}
            </Button>
          </div>
        </form>
      </FormProvider>
      <DebugConsole data={values} />
    </div>
  );
};

export default BoxSashConfigurator;
