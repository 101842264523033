import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../store';

// import SectionTitle from '@components/section-title';
import Widget from '@components/widget';
import BoxSash from '@components/configurators/BoxSash';
import BoxedLayout from '@appSrc/layouts/content/Boxed';

const Page = ({
  materials,
  boxSashConfig = null,
  recordId = null,
  mode = 'default',
  redirectToUrl,
}) => {
  return (
    <Widget title="Box Sash">
      <BoxSash
        materials={materials}
        boxSashConfig={boxSashConfig}
        recordId={recordId}
        mode={mode}
        redirectToURL={redirectToUrl}
      />
    </Widget>
  );
};

const ConfiguratorPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default ConfiguratorPage;
