import React from 'react';
import { Rect, Line } from 'react-konva';

const SubSectionMoulding = ({ section, shapeProps }) => {
  const mouldingWidth = section.store.sash_moulding.width;
  if (mouldingWidth === 0) return null;

  const mouldingLineProps = {
    stroke: 'black',
    strokeWidth: 4,
    fill: 'white',
    opacity: 1,
  };

  const {
    size: { width, height },
  } = section;

  const mouldingInnerLength = height - mouldingWidth * 2;

  return (
    <>
      {/* Left moulding */}
      <Line
        {...mouldingLineProps}
        {...section.origin}
        points={[
          -mouldingWidth,
          mouldingWidth,
          -mouldingWidth,
          mouldingInnerLength + mouldingWidth,
          0,
          height,
          0,
          0,
          -mouldingWidth,
          mouldingWidth,
        ]}
      />
      {/* Right moulding */}
      <Line
        {...mouldingLineProps}
        {...section.origin}
        points={[
          width,
          0,
          width + mouldingWidth,
          mouldingWidth,
          width + mouldingWidth,
          mouldingInnerLength + mouldingWidth,
          width,
          height,
          width,
          0,
        ]}
      />
    </>
  );
};

const SubSection = ({ section, ...shapeProps }) => {
  if (section.sectionType === 'muntin')
    return (
      <>
        {/* Muntin */}
        <Rect {...shapeProps} fill="white" {...section.origin} {...section.size} />
        <SubSectionMoulding section={section} shapeProps={shapeProps} />
      </>
    );

  return <></>;
};

export default SubSection;
