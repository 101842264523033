import React from 'react';
import { Rect, Shape, Circle } from 'react-konva';

import { pythagaros, bezierCurveApproximatedArc } from '@utils/geometry';

export const HorizontalBar = ({ x = 0, y = 0, width, height, section, ...shapeProps }) => {
  const isWithinRise =
    section.absoluteOrigin.y + y > section.store.finalSize.height - section.store.arcRise;

  if (!section.isHeadNeighbour || section.store.headStyle === 'standard' || !isWithinRise) {
    return <Rect x={x} y={y} width={width} height={height} {...shapeProps} />;
  }

  let radius = section.relativeRadius;
  const isHalfCircle = section.store.finalSize.width / 2 === section.store.arcRadius;

  if (isHalfCircle) {
    // If it is a semi-circle we can no longer approximate the arc with bezier curves.
    radius = radius + 0.0001;
  }

  const isFixed = section.sectionType === 'fixed';

  const elementWidth = isFixed
    ? section.store.frame_glazing_bead.width
    : section.store.top_rail.width - section.store.sash_moulding.width;

  const arcRadius = radius - elementWidth;

  let radiusReduction = section.store.finalSize.height - section.store.arcRadius;
  if (['leaf', 'dummy'].includes(section.sectionType)) {
    radiusReduction = radiusReduction - section.sashGapTop;
  }
  const arcCenter = section.relativeArcCenter;
  arcCenter.y = radiusReduction - section.absoluteOrigin.y;
  const lowerDistToCenter = y - arcCenter.y;
  const upperDistToCenter = y + height - arcCenter.y;

  let upperStartX, upperEndX, lowerStartX, lowerEndX;

  const upperXDistance = pythagaros(upperDistToCenter, arcRadius);
  upperStartX = arcCenter.x - upperXDistance - x;
  upperEndX = arcCenter.x + upperXDistance - x;

  const lowerXDistance = pythagaros(lowerDistToCenter, arcRadius);
  lowerStartX = arcCenter.x - lowerXDistance - x;
  lowerEndX = arcCenter.x + lowerXDistance - x;

  return (
    <>
      {/* Uncomment for debugging */}
      {/* <Circle x={arcCenter.x} y={arcCenter.y} radius={arcRadius} fill={'red'} /> */}
      <Shape
        {...shapeProps}
        width={width}
        height={height}
        x={x}
        y={y}
        sceneFunc={(ctx, shape) => {
          ctx.beginPath();
          ctx.moveTo(lowerStartX, 0);
          ctx.lineTo(lowerEndX, 0);
          ctx.lineTo(upperEndX, height);
          ctx.lineTo(upperStartX, height);
          ctx.closePath();

          // Konva specific method
          ctx.fillStrokeShape(shape);
        }}
      />
    </>
  );
};

export default HorizontalBar;
