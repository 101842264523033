import { observer, inject } from 'mobx-react';
import { isAlive } from 'mobx-state-tree';
import React from 'react';
import { Group } from 'react-konva';

import { ISection } from '@stores/SectionStore';

import {
  HorizontalDimension,
  VerticalDimension,
} from '@components/configurators/Casement/Dimensions';

import { round, LEVELS } from '@utils/dimensionHelpers';

type Props = {
  section: ISection;
  scaleFactor?: number;
};

export default function SectionDimensions({ section, scaleFactor = 1 }: Props) {
  const { id, sectionType, size, dimensions, origin, absoluteOrigin } = section;
  let horizontal = [];
  let vertical = [];
  const isSash = ['leaf', 'dummy'].includes(sectionType);
  const stageXOffset = 0;

  if (isSash) {
    let visibleTopRailLength = size.width - section.store.stileWidth(false) * 2;
    horizontal.push(
      <HorizontalDimension
        x={0}
        y={LEVELS[dimensions.levelY] || 0}
        width={round(section.store.stileWidth(false))}
        scaleFactor={scaleFactor}
        key={id + '-stile-left'}
      />
    );
    horizontal.push(
      <HorizontalDimension
        x={round(section.store.stileWidth(false))}
        y={LEVELS[dimensions.levelY] || 0}
        width={round(visibleTopRailLength)}
        scaleFactor={scaleFactor}
        key={id + '-top-rail'}
      />
    );
    horizontal.push(
      <HorizontalDimension
        x={round(visibleTopRailLength) + round(section.store.stileWidth(false))}
        y={LEVELS[dimensions.levelY] || 0}
        width={round(section.store.stileWidth(false))}
        scaleFactor={scaleFactor}
        key={id + '-stile-right'}
      />
    );

    let stileJointToJointLength =
      size.height -
      section.store.topRailWidth(false) -
      section.store.bottomRailWidth(false, section.isOnTopOfTransom);
    // Vertical
    vertical.push(
      <VerticalDimension
        x={0}
        y={section.store.absoluteOrigin.y}
        height={round(section.store.bottomRailWidth(false, section.isOnTopOfTransom))}
        scaleFactor={scaleFactor}
        key={id + '-bottom-rail'}
      />
    );
    vertical.push(
      <VerticalDimension
        x={0}
        y={
          section.store.absoluteOrigin.y +
          round(section.store.bottomRailWidth(false, section.isOnTopOfTransom))
        }
        height={round(stileJointToJointLength)}
        scaleFactor={scaleFactor}
        key={id + '-stile-height'}
      />
    );
    vertical.push(
      <VerticalDimension
        x={0}
        y={
          section.store.absoluteOrigin.y +
          round(section.store.bottomRailWidth(false, section.isOnTopOfTransom)) +
          round(stileJointToJointLength)
        }
        height={round(section.store.topRailWidth(false))}
        scaleFactor={scaleFactor}
        key={id + '-top-rail'}
      />
    );
  }

  if (sectionType === 'fixed') {
    const glazingBeadWidth = section.store.frame_glazing_bead.width;
    let visualOpeningLength = size.width - glazingBeadWidth * 2;
    horizontal.push(
      <HorizontalDimension
        x={0}
        y={LEVELS[dimensions.levelY] || 0}
        width={round(glazingBeadWidth)}
        scaleFactor={scaleFactor}
        key={id + '-glazing-bead-left'}
      />
    );
    horizontal.push(
      <HorizontalDimension
        x={round(glazingBeadWidth)}
        y={LEVELS[dimensions.levelY] || 0}
        width={round(visualOpeningLength)}
        scaleFactor={scaleFactor}
        key={id + '-opening'}
      />
    );
    horizontal.push(
      <HorizontalDimension
        x={round(visualOpeningLength) + round(glazingBeadWidth)}
        y={LEVELS[dimensions.levelY] || 0}
        width={round(glazingBeadWidth)}
        scaleFactor={scaleFactor}
        key={id + '-glazing-bead-right'}
      />
    );

    let visualOpeningLengthY = size.height - glazingBeadWidth * 2;
    // Vertical
    vertical.push(
      <VerticalDimension
        x={0}
        y={section.store.absoluteOrigin.y}
        height={round(glazingBeadWidth)}
        scaleFactor={scaleFactor}
        key={id + '-bottom-rail'}
      />
    );
    vertical.push(
      <VerticalDimension
        x={0}
        y={section.store.absoluteOrigin.y + round(glazingBeadWidth)}
        height={round(visualOpeningLengthY)}
        scaleFactor={scaleFactor}
        key={id + '-stile-height'}
      />
    );
    vertical.push(
      <VerticalDimension
        x={0}
        y={section.store.absoluteOrigin.y + round(glazingBeadWidth) + round(visualOpeningLengthY)}
        height={round(glazingBeadWidth)}
        scaleFactor={scaleFactor}
        key={id + '-top-rail'}
      />
    );
  }

  return (
    <Group {...origin} x={origin.x + stageXOffset}>
      <Group x={size.width} y={0}>
        {vertical}
      </Group>
      <Group y={size.height} x={0}>
        {horizontal}
      </Group>
    </Group>
  );
}
