import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import Button from '@components/buttons/ButtonLink';

const Component = ({ rfq }) => {
  return (
    <div className="fixed top-6 left-4 print:hidden">
      <Button className="mt-6 ml-6" as="a" href={`/projects/${rfq['project_id']}`}>
        Back
      </Button>
    </div>
  );
};

const QuoteUtilities = (props) => <Component {...props} />;

export default QuoteUtilities;
