import React, { useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { GrAddCircle } from 'react-icons/gr';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useSelector } from 'react-redux';

import { CurrencyInput } from '@components/forms/currencyInput';

const textInput = (item) => {
  return (
    <div className="form-element" style={item.wrapperStyles || {}}>
      {item.label && <div className={'form-label' + item.isRequired}>{item.label}</div>}
      <input
        name={item.name}
        type={'text'}
        className="form-textarea"
        disabled={!!item.disabled}
        value={item.defaultValue || ''}
        onChange={(e) => item.handleChange(e, item.itemID || item.gid)}
      />
    </div>
  );
};

const numberInput = (item) => {
  return (
    <div className="form-element" style={item.wrapperStyles || {}}>
      {item.label && <div className={'form-label' + item.isRequired}>{item.label}</div>}
      <input
        name={item.name}
        type={'number'}
        // min={0}
        // step={0.25}
        className="form-textarea"
        disabled={!!item.disabled}
        value={item.defaultValue || ''}
        onChange={(e) => item.handleChange(e, item.itemID || item.gid)}
      />
    </div>
  );
};

const currencyInput = (item) => {
  return (
    <div className="form-element" style={item.wrapperStyles || {}} key={item.name}>
      {item.label && <div className={'form-label' + item.isRequired}>{item.label}</div>}
      {/* @ts-ignore */}
      <CurrencyInput
        name={item.name}
        style="form-textarea"
        value={item.defaultValue}
        placeholder={item.placeholder}
        // @ts-ignore
        disabled={item.disabled}
        onChange={(e) => item.handleChange(e, item.itemID || item.gid)}
      />
    </div>
  );
};

const checkboxInput = (item) => {
  const orientation =
    item.orientation === 'vertical'
      ? 'items-start justify-start flex-col'
      : 'items-center justify-start space-x-2';
  return (
    <div className="form-element" style={item.wrapperStyles || {}} key={item.name}>
      {item.label && <div className={'form-label' + item.isRequired}>{item.label}</div>}
      <div className={`flex ${orientation}`}>
        {item.options.map((option, j) => (
          <label className="flex items-center justify-start space-x-2" key={option.value + j}>
            <input
              type="checkbox"
              value={option.value}
              name={item.name}
              className={`form-checkbox h-4 w-4`}
              defaultChecked={option.checked}
              onChange={(e) => item.handleChange(e, item.itemID)}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

const OverheadItems = ({ items, onDelete, onAdd, onChange }) => {
  return (
    <tbody>
      {items.map((item, index) => (
        <tr
          key={item.itemID}
          className={`border-2 rounded-sm ${item.enabled ? '' : 'bg-grey-200'}`}
        >
          <td style={{ maxWidth: '300px', width: '300px' }}>
            {item['system_record']
              ? item.name
              : textInput({
                  isRequired: false,
                  name: `pricing_setting_items[${item.itemID}].name`,
                  defaultValue: item.name,
                  wrapperStyles: { margin: 0 },
                  disabled: !item.enabled,
                  handleChange: (e) => onChange(e, item.itemID, 'name'),
                })}
          </td>
          <td>
            {currencyInput({
              isRequired: false,
              name: `pricing_setting_items[${item.itemID}].cost`,
              defaultValue: item.cost,
              wrapperStyles: { margin: 0 },
              placeholder: '',
              disabled: !item.enabled,
              handleChange: (e) => onChange(e, item.itemID, 'cost'),
            })}
          </td>
          <td>
            <div className="px-3 py-2 position-center self-center h-full">
              {checkboxInput({
                isRequired: false,
                name: `pricing_setting_items[${item.itemID}].enabled`,
                options: [{ label: '', value: 'true', checked: item.enabled }],
                wrapperStyles: { margin: 0 },
                handleChange: (e) => onChange(e, item.itemID, 'enabled'),
              })}
            </div>
          </td>
          <td>
            {!item['system_record'] && (
              <span className="position-center self-center">
                <IoMdCloseCircleOutline
                  size={24}
                  color={'red'}
                  className="cursor-pointer"
                  onClick={(_) => onDelete(item.itemID)}
                />
              </span>
            )}
          </td>
        </tr>
      ))}
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <span className="position-center self-center">
            <GrAddCircle size={24} color={'green'} className="cursor-pointer" onClick={onAdd} />
          </span>
        </td>
      </tr>
    </tbody>
  );
};

export const Employees = ({ employees, onDelete, onAdd, onChange }) => {
  return (
    <tbody>
      {employees.map((employee, index) => (
        <tr key={employee.itemID} className={'border-2 rounded-sm'}>
          <td style={{ maxWidth: '300px', width: '300px' }}>
            {textInput({
              isRequired: false,
              name: `pricing_setting_employees[${employee.gid}].name`,
              defaultValue: employee.name,
              wrapperStyles: { margin: 0 },
              handleChange: (e) => onChange(employee.gid, { name: e.target.value }),
            })}
          </td>
          <td>
            {numberInput({
              isRequired: false,
              name: `pricing_setting_employees[${employee.gid}].weekly_work_hours`,
              defaultValue: employee.weekly_work_hours,
              wrapperStyles: { margin: 0 },
              handleChange: (e) => onChange(employee.gid, { weekly_work_hours: e.target.value }),
            })}
          </td>
          <td>
            {currencyInput({
              isRequired: false,
              name: `pricing_setting_employees[${employee.gid}].hourly_rate`,
              defaultValue: employee.hourly_rate,
              wrapperStyles: { margin: 0 },
              placeholder: '',
              handleChange: (e) => onChange(employee.gid, { hourly_rate: e.target.value }),
            })}
          </td>
          <td>
            <span className="position-center self-center">
              <IoMdCloseCircleOutline
                size={24}
                color={'red'}
                className="cursor-pointer"
                onClick={(_) => onDelete(employee.gid)}
              />
            </span>
          </td>
        </tr>
      ))}
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <span className="position-center self-center">
            <GrAddCircle size={24} color={'green'} className="cursor-pointer" onClick={onAdd} />
          </span>
        </td>
      </tr>
    </tbody>
  );
};

export default OverheadItems;
