import React from 'react';
import { FiSettings, FiMenu } from 'react-icons/fi';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';

// import Dropdown6 from './dropdown-6';
// import Search from './search';
import '../../css/components/navbar.css';
import Dropdown5 from './dropdown-5';

const NavbarContainer = styled.div`
  ${tw`flex items-center justify-start`}
  margin: 20px 0 0 20px;
  border-radius: 1.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  background-color: #f5ebdf;
`;

const Navbar = () => {
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  let { rightSidebar, collapsed } = { ...config };
  const dispatch = useDispatch();
  return (
    // <div className="navbar navbar-1 border-b">
    <NavbarContainer>
      <button
        onClick={() =>
          dispatch({
            type: 'SET_CONFIG_KEY',
            key: 'collapsed',
            value: !collapsed,
          })
        }
        className="mx-4"
      >
        <FiMenu size={20} />
      </button>
      {/* <Search /> */}
      {/* <Dropdown6 /> */}
      <span className="ml-auto"></span>
      <Dropdown5 />
      {/* <button
        className="btn-transparent flex items-center justify-center h-16 w-8 mx-4"
        onClick={() =>
          dispatch({
            type: 'SET_CONFIG_KEY',
            key: 'rightSidebar',
            value: !rightSidebar,
          })
        }
      >
        <FiSettings size={18} />
      </button> */}
    </NavbarContainer>
    // </div>
  );
};

export default Navbar;
