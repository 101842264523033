import React, { useState } from 'react';
import { Group, Line } from 'react-konva';

export const sideHungRight = (section, lineProps) => (
  <>
    <Line
      {...lineProps}
      closed={false}
      points={[0, 0, section.size.width, section.size.height / 2]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width, section.size.height / 2, 0, section.size.height]}
    />
  </>
);

export const sideHungLeft = (section, lineProps) => (
  <>
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width, 0, 0, section.size.height / 2]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[0, section.size.height / 2, section.size.width, section.size.height]}
    />
  </>
);

export const awning = (section, lineProps) => (
  <>
    <Line
      {...lineProps}
      closed={false}
      points={[0, section.size.height, section.size.width / 2, 0]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width / 2, 0, section.size.width, section.size.height]}
    />
  </>
);

export const topHung = (section, lineProps) => (
  <>
    <Line
      {...lineProps}
      closed={false}
      points={[0, 0, section.size.width / 2, section.size.height]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width / 2, section.size.height, section.size.width, 0]}
    />
  </>
);

export const tiltAndTurnRight = (section, lineProps) => (
  <>
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width, 0, 0, section.size.height / 2]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[0, section.size.height / 2, section.size.width, section.size.height]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[0, 0, section.size.width / 2, section.size.height]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width / 2, section.size.height, section.size.width, 0]}
    />
  </>
);

export const tiltAndTurnLeft = (section, lineProps) => (
  <>
    <Line
      {...lineProps}
      closed={false}
      points={[0, 0, section.size.width, section.size.height / 2]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width, section.size.height / 2, 0, section.size.height]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[0, 0, section.size.width / 2, section.size.height]}
    />
    <Line
      {...lineProps}
      closed={false}
      points={[section.size.width / 2, section.size.height, section.size.width, 0]}
    />
  </>
);

export default {
  side_hung_right: sideHungRight,
  side_hung_left: sideHungLeft,
  awning: awning,
  top_hung: topHung,
  tilt_and_turn_right: tiltAndTurnRight,
  tilt_and_turn_left: tiltAndTurnLeft,
};
