import React from 'react';
import { css } from 'styled-components';
import tw from 'twin.macro';

import ButtonLink from '@components/buttons/ButtonLink';

const ProductsMenu = (props) => {
  return (
    <div className="flex flex-col lg:flex-row lg:flex-wrap items-start lg:items-center content-between justify-center space-x-2 my-6">
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/box_sash_configs">
        Box Sash
      </ButtonLink>
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/casements">
        Casements
      </ButtonLink>
    </div>
  );
};

export default ProductsMenu;
