import API from 'apiv1';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin5Line, RiErrorWarningLine } from 'react-icons/ri';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import DebugConsole from '@components/DebugOptions/DebugConsole';
import ButtonIcon from '@components/buttons/ButtonIcon';
import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import BoxSashHardwareSetForm from '@components/forms/BoxSashHardwareSetForm';
import MaterialsMenu from '@components/menus/materials';
import Modal from '@components/modals/modal';
import SimpleTooltip from '@components/tooltips/SimpleTooltip';
import Widget from '@components/widget';

import { setSuppliers } from '../actions/formActions';
import { store } from '../store';

const ELEMENT_DISPLAY_NAME = {
  pulley: 'Pulley',
  lift: 'Lift',
  handle: 'Handle',
  fitch_fastener: 'Fitch Fastener',
  vent_lock: 'Vent Lock',
};
const ELEMENTS = ['pulley', 'lift', 'handle', 'fitch_fastener', 'vent_lock'];

const SetCard = ({ boxSashSet, hardware, weightSuppliers }) => {
  return (
    <div
      className="flex flex-col py-2 border-solid border rounded-md border-grey-500 w-1/4 lg:w-1/2 xl:w-2/5 m-2"
      style={{ maxWidth: '600px' }}
    >
      <span className="w-full pb-2 px-4 text-center mb-2 border-solid border-b-2 border-dark-90">
        {boxSashSet.name}
      </span>
      <div className="px-4 mb-auto">
        <table>
          <thead>
            <tr className="border-solid border-b">
              <th style={{ minWidth: 91 }} className="py-2 px-2 border-solid"></th>
              <th className="py-2 px-2 border-solid text-left">Product name</th>
            </tr>
          </thead>
          <tbody>
            {ELEMENTS.filter((element) => !!boxSashSet[element]).map((element) => {
              const attributes = boxSashSet[element];
              return (
                <tr className="border-solid border-b" key={attributes.id}>
                  <td style={{ minWidth: 91 }} className="py-2 px-2 border-solid">
                    {ELEMENT_DISPLAY_NAME[element]}
                  </td>
                  <td className="py-2 px-2 border-solid">{attributes['hardware']['name']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Finishes */}
      <div className="px-4 mt-6 w-full">
        <span className="border-solid border-b w-full" style={{ width: '100%' }}>
          Weight Supplier
        </span>
        <div className="flex flex-wrap w-full mt-2">
          <span className={`border-grey-700 w-1/2`}>
            {boxSashSet.weight && boxSashSet.weight.label}
          </span>
        </div>
      </div>
      <div className="px-4 mt-6 w-full">
        <span className="border-solid border-b w-full" style={{ width: '100%' }}>
          Available Hardware Finish
        </span>
        <div className="flex flex-wrap mt-2 justify-between">
          {boxSashSet['finish_names'].map((finishName) => (
            <span
              key={finishName}
              className={`${
                boxSashSet['finish_names'].length > 1
                  ? 'border-solid border-r flex-1 text-center'
                  : ''
              } border-grey-700`}
            >
              {finishName}
            </span>
          ))}
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <SimpleTooltip
          usePortal={true}
          hideArrow={true}
          portalContainer={document.getElementById('portal-container')}
          trigger="hover"
          placement="top"
          tooltip={<span>Edit</span>}
        >
          <Modal
            title="Update"
            body={
              <BoxSashHardwareSetForm
                formId={'editRecordForm' + boxSashSet.id}
                mode="edit"
                recordId={boxSashSet.id}
                hardware={hardware}
                weightSuppliers={weightSuppliers}
                boxSashSet={boxSashSet}
              />
            }
            buttonTitle="Update"
            buttonComponent={(showCallback, _) => (
              <ButtonIcon onClick={(e) => showCallback(e)} as="button" className="m-2">
                <FaEdit size={18} />
              </ButtonIcon>
            )}
            buttonFormId={'editRecordForm' + boxSashSet.id}
            buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
          />
        </SimpleTooltip>

        <SimpleTooltip
          usePortal={true}
          hideArrow={true}
          portalContainer={document.getElementById('portal-container')}
          trigger="hover"
          placement="top"
          tooltip={<span>Delete</span>}
        >
          <Modal
            title="Delete"
            icon={<RiErrorWarningLine size={32} className={'text-red-500'} />}
            body={
              <p>
                This action is irreversible.
                <br />
                Are you sure you want to continue?
              </p>
            }
            handleButtonClick={() => {
              API.deleteHardwareSet(boxSashSet.id).then((_) => window.location.reload());
            }}
            buttonTitle="Delete"
            buttonComponent={(showCallback, _) => (
              <ButtonIcon onClick={(e) => showCallback(e)} as="button" className="m-2">
                <RiDeleteBin5Line size={18} />
              </ButtonIcon>
            )}
            buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
          />
        </SimpleTooltip>
      </div>
    </div>
  );
};

const Page = ({ records, suppliers, hardware, boxSashSets, weightSuppliers }) => {
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const formId = 'hardwareSetForm';

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, []);

  // const columns = [
  //   {
  //     Header: 'Actions',
  //     accessor: 'id',
  //     Cell: ({ value }) =>
  //       RecordActions(value, BoxSashHardwareSetForm, API.deleteHardwareItem, {
  //         newFormConfig: hardwareConfig,
  //       }),
  //   },
  // ];

  const handleFormSubmit = (_) => {
    setSubmit(true);
  };

  return (
    <>
      <MaterialsMenu />

      <Widget
        title="Box Sash Hardware Set"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Add Box Sash Set"
              body={
                <BoxSashHardwareSetForm
                  formId={formId}
                  hardware={hardware}
                  weightSuppliers={weightSuppliers}
                />
              }
              buttonTitle="Create Set"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
              handleButtonClick={handleFormSubmit}
              buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <div className="flex flex-row flex-wrap -m-2">
          {boxSashSets.map((boxSashSet) => (
            <SetCard
              boxSashSet={boxSashSet}
              key={boxSashSet.id}
              hardware={hardware}
              weightSuppliers={weightSuppliers}
            />
          ))}
        </div>
        <DebugConsole data={boxSashSets} />
      </Widget>
    </>
  );
};

const HardwarePage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default HardwarePage;
