import _get from 'lodash/get';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import { ComboMultiSelect } from '@components/forms/controls/MultiSelect';

import { Alert } from '../alerts';
import Grid from './Grid';
import MediaFile from './MediaFile';
import CustomSelect from './SelectInput';
import SwitchInput from './Switch';
import UploadFile from './UploadFile';
import { CurrencyInput } from './currencyInput';
import { NumberInput } from './numberInput';

export const itemToInput = (item, alerts, register, errors, control) => {
  if (item.static) {
    return <span className="ml-1">{item.value}</span>;
  }

  if (item['error']) {
    item['ref'] = register(item['error']);
  }

  const isRequired = item.error && item.error.required ? ' required' : '';
  const itemErrors = _get(errors, item.name);

  if (item.remove) {
    return <span key={item.name}></span>;
  }

  if (item.type === 'custom') {
    return item.component(item, alerts, register, errors, control);
  }
  if (item.type === 'combobox') {
    let wrapperStyles = item.wrapperStyles || {};
    return (
      <div className="form-element" style={{ minWidth: '300px', ...wrapperStyles }} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <Controller
          name={item.name}
          as={Creatable}
          options={item.options}
          control={control}
          rules={item.error}
          defaultValue={item.defaultValue || ''}
          menuPortalTarget={document.body}
          className={'theme-multi-select'}
          styles={{
            control: (styles) => ({
              ...styles,
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
              backgroundColor: '#fff',
              borderColor: '#e2e8f0',
              borderWidth: '1px',
              borderRadius: '0.675rem',
              lineHeight: 1.5,
              fontSize: '0.825rem',
              width: '100%',
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingTop: '0.25rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              borderRadius: '0.675rem',
            }),
            menu: (styles) => ({
              ...styles,
              paddingTop: '0.25rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              borderRadius: '0.675rem',
              zIndex: 9999,
              minWidth: 'max-content',
            }),
            menuPortal: (styles) => ({
              ...styles,
              zIndex: 9990,
            }),
            //   container: (styles) => ({ ...styles, backgroundColor: 'green' }),
          }}
        />
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'multiSelect') {
    let wrapperStyles = item.wrapperStyles || {};
    return (
      <div className="form-element" style={{ minWidth: '300px', ...wrapperStyles }} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <Controller
          isMulti
          isClearable
          isSearchable
          name={item.name}
          as={Select}
          options={item.options}
          control={control}
          rules={item.error}
          defaultValue={''}
          menuPortalTarget={document.body}
          className={'theme-multi-select'}
          styles={{
            control: (styles) => ({
              ...styles,
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
              backgroundColor: '#fff',
              borderColor: '#e2e8f0',
              borderWidth: '1px',
              borderRadius: '0.675rem',
              lineHeight: 1.5,
              fontSize: '0.825rem',
              width: '100%',
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingTop: '0.25rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              borderRadius: '0.675rem',
              borderWidth: 0,
            }),
            menu: (styles) => ({
              ...styles,
              paddingTop: '0.25rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              borderRadius: '0.675rem',
              zIndex: 9999,
            }),
            menuPortal: (styles) => ({
              ...styles,
              zIndex: 9990,
            }),
            //   container: (styles) => ({ ...styles, backgroundColor: 'green' }),
          }}
        />
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }

  if (item.type === 'comboMultiSelect') {
    let wrapperStyles = item.wrapperStyles || {};
    return (
      <div className="form-element" style={{ minWidth: '300px', ...wrapperStyles }} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <Controller
          name={item.name}
          options={item.options}
          control={control}
          rules={item.error}
          defaultValue={item.defaultValue || ''}
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => {
            return (
              <ComboMultiSelect
                defaultValue={item.defaultValue}
                options={item.options}
                innerRef={ref}
                name={name}
                value={value}
                className={item.className}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }

  if (item.type === 'newSelect') {
    return (
      <div
        key={item.name}
        className="form-element"
        style={{ minWidth: '300px', ...(item.style || {}) }}
      >
        {item.label && <div className="form-label">{item.label}</div>}
        <Controller
          control={control}
          name={item.name}
          defaultValue={item.defaultValue}
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => {
            return (
              <Select
                defaultValue={item.defaultValue}
                options={item.options}
                innerRef={ref}
                name={name}
                value={value}
                menuPortalTarget={document.body}
                className={'theme-multi-select' + (item.className || '')}
                onChange={onChange}
                onBlur={onBlur}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                    appearance: 'none',
                    backgroundColor: '#fff',
                    borderColor: '#e2e8f0',
                    borderWidth: '1px',
                    borderRadius: '0.675rem',
                    lineHeight: 1.5,
                    fontSize: '0.825rem',
                    width: '100%',
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingTop: '0.25rem',
                    paddingRight: '0.5rem',
                    paddingBottom: '0.25rem',
                    paddingLeft: '0.5rem',
                    borderRadius: '0.675rem',
                  }),
                  menu: (styles) => ({
                    ...styles,
                    paddingTop: '0.25rem',
                    paddingRight: '0.5rem',
                    paddingBottom: '0.25rem',
                    paddingLeft: '0.5rem',
                    borderRadius: '0.675rem',
                    zIndex: 9999,
                  }),
                  menuPortal: (styles) => ({
                    ...styles,
                    zIndex: 9990,
                  }),
                  //   container: (styles) => ({ ...styles, backgroundColor: 'green' }),
                }}
              />
            );
          }}
        />
        {/* <Controller
          name={item.name}
          as={Select}
          options={item.options}
          control={control}
          rules={errors}
          defaultValue={item.defaultValue}
          menuPortalTarget={document.body}
          className={'theme-multi-select ' + (item.className || '')}
          styles={{
            control: (styles) => ({
              ...styles,
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
              backgroundColor: '#fff',
              borderColor: '#e2e8f0',
              borderWidth: '1px',
              borderRadius: '0.675rem',
              lineHeight: 1.5,
              fontSize: '0.825rem',
              width: '100%',
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingTop: '0.25rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              borderRadius: '0.675rem',
            }),
            menu: (styles) => ({
              ...styles,
              paddingTop: '0.25rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.25rem',
              paddingLeft: '0.5rem',
              borderRadius: '0.675rem',
              zIndex: 9999,
            }),
            menuPortal: (styles) => ({
              ...styles,
              zIndex: 9990,
            }),
            //   container: (styles) => ({ ...styles, backgroundColor: 'green' }),
          }}
        /> */}
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'image') {
    return (
      <div className="form-element" style={item.wrapperStyles || {}}>
        <MediaFile
          defaultValue={item.defaultValue}
          file={item.files}
          storeKey={item.name + item.recordId}
        />
        <div className="flex items-center">
          <UploadFile item={item} />
        </div>
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'checkbox') {
    const orientation =
      item.orientation === 'vertical'
        ? 'items-start justify-start flex-col'
        : 'items-center justify-start space-x-2';
    return (
      <div className={`form-element`} style={item.wrapperStyles || {}} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <div className={`flex ${orientation} ${item.wrapperClass}`}>
          {item.options.map((option, j) => (
            <label className="flex items-center justify-start space-x-2" key={option.value + j}>
              <input
                ref={item.ref || register}
                type="checkbox"
                value={option.value}
                name={item.name}
                className={`form-checkbox h-4 w-4 ${itemErrors ? 'text-red-500' : ''}`}
                defaultChecked={option.checked}
              />
              <span className={`${itemErrors ? 'text-red-500' : ''}`}>{option.label}</span>
            </label>
          ))}
        </div>
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'radio') {
    const orientation =
      item.orientation === 'vertical'
        ? 'items-start justify-start flex-col'
        : 'items-center justify-start space-x-2';

    return (
      <div className="form-element" style={item.wrapperStyles || {}} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <div className={`flex ${orientation}`}>
          {item.options.map((option, j) => (
            <label
              className="flex items-center justify-start space-x-2"
              key={String(option.value) + j}
            >
              <input
                type="radio"
                value={option.value}
                name={item.name}
                ref={item.ref || register}
                className={`form-radio h-4 w-4 ${itemErrors ? 'text-red-500' : ''}`}
                defaultChecked={option.checked}
                disabled={option.disabled || false}
              />
              <span className={`${itemErrors ? 'text-red-500' : ''}`}>{option.label}</span>
            </label>
          ))}
        </div>
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'select') {
    return (
      <div
        className={`form-element ${item.wrapperClassName}`}
        style={item.wrapperStyles || {}}
        key={item.name}
      >
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <select
          ref={item.ref || register}
          name={item.name}
          className={`form-select ${itemErrors ? 'border border-red-500' : ''}`}
        >
          {item.options.map((option, j) => (
            <option key={j} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'textarea') {
    return (
      <div className="form-element" style={item.wrapperStyles || {}} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <textarea
          ref={item.ref || register}
          name={item.name}
          className={`form-textarea focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent ${
            itemErrors ? 'border border-red-500' : ''
          } ${item.inlineClass || item.inputClass}`}
          rows={item.rows || 3}
          placeholder={item.placeholder}
          defaultValue={item.defaultValue || ''}
        ></textarea>
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'currency') {
    return (
      <div className="form-element" style={item.wrapperStyles || {}} key={item.name}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        {/* @ts-ignore */}
        <Controller
          as={CurrencyInput}
          name={item.name}
          style={`form-textarea ${itemErrors ? 'border border-red-500' : ''}`}
          placeholder={item.placeholder || ''}
          control={control}
          rules={item.error}
          defaultValue={''}
          disabled={!!item.disabled}
        />
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'number') {
    return (
      <div className="form-element" style={item.wrapperStyles || {}} key={item.name}>
        {item.label && (
          <div className={'form-label' + isRequired}>
            {typeof item.label === 'function' ? item.label() : item.label}
          </div>
        )}
        <Controller
          as={NumberInput}
          name={item.name}
          style={`form-textarea ${itemErrors ? 'border border-red-500' : ''} ${item.inputClass}`}
          placeholder={item.placeholder}
          icon={item.icon || null}
          control={control}
          rules={item.error}
          min={item.min || 0}
          // @ts-ignore
          step={item.step || 0.01}
          disabled={!!item.disabled}
          defaultValue={item.defaultValue}
        />
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    );
  }
  if (item.type === 'hidden') {
    return (
      <input
        ref={item.ref || register}
        name={item.name}
        type={'text'}
        key={item.name}
        className="hidden"
        defaultValue={item.defaultValue || ''}
      />
    );
  }
  return (
    <>
      <div className="form-element" key={item.label || item.key} style={item.wrapperStyles || {}}>
        {item.label && <div className={'form-label' + isRequired}>{item.label}</div>}
        <input
          ref={item.ref || register}
          name={item.name}
          type={item.type}
          className={`form-input ${itemErrors ? 'border-red-500' : ''}`}
          placeholder={item.placeholder}
          disabled={!!item.disabled}
          defaultValue={item.defaultValue || ''}
        />
        {!alerts && itemErrors && <div className="form-error">{itemErrors.message}</div>}
      </div>
    </>
  );
};

const GridForm = ({
  gridItems,
  onSubmit,
  alerts,
  formStyle,
  switchOptions,
  onWatch,
  watchFields,
}) => {
  const { handleSubmit, errors, register, control, reset, watch } = useForm();
  const onSubmitFn = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  // if (watchFields && onWatch) {
  //   onWatch(watch(watchFields));
  // }

  const registerItemAlerts = (items) => {
    return (
      alerts &&
      items.map((item, i) => {
        if (!itemErrors) return null;
        let msg = itemErrors.message;
        if (msg.length === 0) msg = `${item.label} is required`;
        return (
          <div className="flex flex-col w-full" key={item.name + i}>
            {itemErrors && (
              <div className="mb-2" key={i}>
                <Alert color="bg-transparent border-red-500 text-red-500" borderLeft raised>
                  {msg}
                </Alert>
              </div>
            )}
          </div>
        );
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFn)} className="form flex flex-wrap w-full">
      {gridItems.map((gridConfig) => registerItemAlerts(gridConfig.items))}
      {switchOptions && (
        <div className="w-full">
          <SwitchInput {...switchOptions} />
        </div>
      )}
      <div className={`w-full ${formStyle}`}>
        {gridItems.map((gridConfig, i) => (
          <Grid config={gridConfig.config} gridStyle={gridConfig.style}>
            {gridConfig.items.map((item) => itemToInput(item, alerts, register, errors, control))}
          </Grid>
        ))}
      </div>
      <input
        type="submit"
        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded"
      />
    </form>
  );
};
export default GridForm;
