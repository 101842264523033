import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { setGlazingUnit } from '@appSrc/actions/configuratorActions';

import DataModal from '@components/DataModal';
import { itemToInput } from '@components/forms/GridForm';

import Card from '../Card';
import Section, { InputContainer } from '../Section';

export const UPPER_GLAZING_UNIT = 'upper_sash';
export const LOWER_GLAZING_UNIT = 'lower_sash';

const Glazing = ({ recordId, triple = true, borderBottom = true, glazingUnits = [] }) => {
  const methods = useFormContext();
  const stateGlazingUnits = useSelector((state) => {
    // @ts-ignore
    return state.configuratorForms.glazingUnits;
  });
  const dispatch = useDispatch();

  const handleTopSash = (item) => {
    dispatch(setGlazingUnit(UPPER_GLAZING_UNIT, item));
    return true;
  };

  const handleLowerpSash = (item) => {
    dispatch(setGlazingUnit(LOWER_GLAZING_UNIT, item));
    return true;
  };

  const renderCard = (item) => {
    if (item) {
      return (
        <Card
          narrowStyle
          defaultOpen={false}
          title={item['product_name']}
          attributes={[
            { label: 'Gas type', value: item['spacer_gas'] },
            { label: 'U-value', value: item['u_value'] },
          ]}
        />
      );
    } else {
      return <Card defaultOpen={false} blank={true} narrowStyle />;
    }
  };

  return (
    <Section title={'Glazing'} borderBottom={borderBottom}>
      <InputContainer className="flex flex-row space-x-12 w-full">
        <InputContainer className="flex flex-col space-y-2">
          <span className="pl-16 h-6"></span>
          {itemToInput(
            {
              label: 'Glazed',
              name: 'glazing[external]',
              type: 'radio',
              orientation: 'vertical',
              options: [
                { label: 'Internally', value: 'false' },
                { label: 'Externally', value: 'true' },
              ],
              defaultValue: '',
              inputClass: 'w-2/4',
            },
            null,
            methods.register,
            methods.errors,
            methods.control
          )}
        </InputContainer>

        <InputContainer className="flex flex-col space-y-12 w-full">
          <InputContainer className="flex flex-row space-x-12 w-full">
            {/* Upper Sash  */}
            <InputContainer className="flex flex-col space-y-2 w-full">
              <span className="pl-16 pb-4">Upper Sash</span>
              <div className="flex flex-row space-x-2 w-3/5 lg:w-2/5">
                {renderCard(stateGlazingUnits[UPPER_GLAZING_UNIT])}
                <div className="self-center">
                  <DataModal
                    data={glazingUnits}
                    materialKey="glazingUnit"
                    onItemSelect={handleTopSash}
                  />
                </div>
              </div>
            </InputContainer>
          </InputContainer>

          <InputContainer className="flex flex-row space-x-12 w-full">
            {/* Lower Sash  */}
            <InputContainer className="flex flex-col space-y-2 w-full">
              <span className="pl-16 pb-4">Lower Sash</span>
              <div className="flex flex-row space-x-2 w-3/5 lg:w-2/5">
                {renderCard(stateGlazingUnits[LOWER_GLAZING_UNIT])}
                <div className="self-center">
                  <DataModal
                    data={glazingUnits}
                    materialKey="glazingUnit"
                    onItemSelect={handleLowerpSash}
                  />
                </div>
              </div>
            </InputContainer>
          </InputContainer>
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default Glazing;
