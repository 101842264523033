import API from 'apiv1';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import tw from 'twin.macro';

import '@appSrc/css/react-notifications.css';

import Button from '@components/buttons/ButtonLink';
import { itemToInput } from '@components/forms/GridForm';

import { update } from '@utils/formHelpers';

import DebugConsole from '../DebugOptions/DebugConsole';
import ContextForm from './ContextForm';

const extractRates = (rates) => {
  return Object.keys(rates).map((key) => ({ key, rate: rates[key].rate }));
};

const BoxSashProductionForm = ({ productOperationSummary }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm({ defaultValues: productOperationSummary.data });

  const onSubmitFn = (data) => {
    update(data, 'size_load_rates', extractRates);
    update(data, 'arch_load_rates', extractRates);
    update(data, 'finish_load_rates', extractRates);
    API.updateBoxSashProduction(
      API.toFormData({
        data: JSON.stringify(data),
      }),
      productOperationSummary.id
    )
      .then((resp) => {
        NotificationManager.success(
          'Operations Summary details have been updated successfully',
          'Update Successful',
          40000
        );
        // window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const bodyItems = [
    {
      itemName: 'Upper Sash',
      name: 'body[upper_sash]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Lower Sash',
      name: 'body[lower_sash]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Sill',
      name: 'body[sill]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Parting Bead (average)',
      name: 'body[parting_bead]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Head',
      name: 'body[head]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Staff Bead (average)',
      name: 'body[staff_bead]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Jamb (per single)',
      name: 'body[jamb]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Lining (per single)',
      name: 'body[lining]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Assembling',
      name: 'body[assembling]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Sash horns',
      name: 'body[horns]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
  ];

  const uniqueFeature = [
    {
      itemName: 'Bay',
      name: 'unique_feature[bay]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
  ];

  const finishItems = [
    {
      itemName: 'Body',
      name: 'finish[body]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Upper Sash',
      name: 'finish[upper_sash]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Lower Sash',
      name: 'finish[lower_sash]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
  ];

  const finishConfig = [
    {
      itemName: (
        <>
          Primer consumption per coat (l/m <sup>2</sup>)
        </>
      ),
      name: 'finish[primer][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Primer number of coats',
      name: 'finish[primer][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: (
        <>
          Impregnant consumption per coat (l/m <sup>2</sup>)
        </>
      ),
      name: 'finish[impregnant][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Impregnant number of coats',
      name: 'finish[impregnant][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: (
        <p style={{ whiteSpace: 'break-spaces' }}>
          Top coat consumption per coat (l/m <sup>2</sup>)
        </p>
      ),
      name: 'finish[top_coat][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Top coat number of coats',
      name: 'finish[top_coat][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: (
        <>
          Oil consumption per coat (l/m <sup>2</sup>)
        </>
      ),
      name: 'finish[oil][consumption]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Oil number of coats',
      name: 'finish[oil][coat_count]',
      type: 'number',
      min: 0,
      defaultValue: 0,
      inlineClass: 'w-1/3',
    },
  ];

  const dimensionRates = productOperationSummary['data']
    ? productOperationSummary.data['size_load_rates'].map((config) => ({
        itemName: config.label,
        name: `size_load_rates[${config.key}][rate]`,
        defaultValue: Number(config.rate) * 100,
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      }))
    : [];

  const archRates = productOperationSummary['data']
    ? productOperationSummary.data['arch_load_rates'].map((config) => ({
        itemName: config.label,
        name: `arch_load_rates[${config.key}][rate]`,
        defaultValue: Number(config.rate) * 100,
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      }))
    : [];

  const finishRates = productOperationSummary['data']
    ? productOperationSummary.data['finish_load_rates'].map((config) => ({
        itemName: config.label,
        name: `finish_load_rates[${config.key}][rate]`,
        defaultValue: Number(config.rate) * 100,
        type: 'number',
        min: 0,
        inlineClass: 'w-1/3',
      }))
    : [];

  const glazingItems = [
    {
      itemName: 'Upper Sash',
      name: 'glazing[upper_sash]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Lower Sash',
      name: 'glazing[lower_sash]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Assembling',
      name: 'glazing[assembling]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Georgian Bar',
      name: 'glazing[georgian_bar]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'True Bar',
      name: 'glazing[true_bar]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
  ];

  const packagingItems = [
    {
      itemName: 'Packaging',
      name: 'delivery_preparation[packaging]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
    {
      itemName: 'Loading',
      name: 'delivery_preparation[loading]',
      type: 'number',
      inlineClass: 'w-1/3',
    },
  ];

  function renderOpsSection(heading, items, unit = null) {
    return (
      <>
        <h5 className="mb-4 mt-4">{heading}</h5>
        <Table className="table-auto">
          <thead>
            <tr>
              <th className="text-left" style={{ maxWidth: '320px', width: '320px' }}>
                Item
              </th>
              <th className="text-left" style={{ maxWidth: '100px', width: '100px' }}>
                {unit || 'Minutes'}
              </th>
              <th></th>
            </tr>
          </thead>
          <TBody>
            {items.map((item) => (
              <tr>
                <td role="cell">{item.itemName}</td>
                <td role="cell">
                  {itemToInput(item, null, methods.register, methods.errors, methods.control)}
                </td>
                <td></td>
              </tr>
            ))}
          </TBody>
        </Table>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-row w-full">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={[]}
            formId={'business'}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          >
            <div className="max-w-md">
              {renderOpsSection('Body', bodyItems)}
              {renderOpsSection('Unique Feature', uniqueFeature)}
              {renderOpsSection('Finish', finishItems)}
              {renderOpsSection('Finish Configuration', finishConfig, ' ')}
              {renderOpsSection('Glazing', glazingItems)}
              {renderOpsSection('Packaging & Miscellenous', packagingItems)}
              {renderOpsSection('Dimension load rates', dimensionRates, 'Rate (%)')}
              {renderOpsSection('Arch load rates', archRates, 'Rate (%)')}
              {renderOpsSection('Finish load rates', finishRates, 'Rate (%)')}
            </div>

            <div className="mt-6" style={{ maxWidth: '140px' }}>
              <Button as="button" type="submit" wrap={false}>
                Update Settings
              </Button>
            </div>
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

const Table = styled.table`
  ${tw`table-auto`}
  td[role="cell"] {
    ${tw`border border-black px-2 py-2`}
  }
`;

const TBody = styled.tbody`
  tr {
    td {
      .form-element {
        ${tw`mb-0`}
      }

      input {
        ${tw`border-0 m-0 focus:(ring-inset ring-opacity-100)`}
      }
    }
  }
`;
export default BoxSashProductionForm;
