import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Provider, useDispatch } from 'react-redux';

import { setConfigurationData, setFormData } from '@appSrc/actions/configuratorActions';
import Button from '@appSrc/components/buttons/ButtonLink';
import BoxedLayout from '@appSrc/layouts/content/Boxed';
import { store } from '@appSrc/store';

import Casement from '@components/configurators/Casement';
import ProductItemFields from '@components/configurators/Casement/ProductItemFields';
import Widget from '@components/widget';

import { handleSubmit, sleep } from '@utils/casementFormHelpers';

import { migrateCasementStore } from './EditProductItemCasementPage';

const Page = ({
  recordId = null,
  rfqId,
  projectId,
  parentId = null,
  snapshot,
  data,
  sites = [],
  productData,
  hardwareSet,
  finishSystem,
  sectionEditorData,
}) => {
  const casementStore = migrateCasementStore(snapshot);
  const stageRef = React.useRef(null);
  const dispatch = useDispatch();
  const productItemMethods = useForm();

  useEffect(() => {
    dispatch(setConfigurationData('timber', productData['timber_types'] || []));
    dispatch(setConfigurationData('hardwareSets', productData['hardware_sets'] || []));
    dispatch(setConfigurationData('hardware', productData['hardware'] || []));
    dispatch(setConfigurationData('glazingUnits', productData['glazing_units'] || []));
    dispatch(setConfigurationData('finishSystems', productData['finish_systems'] || []));
    dispatch(setConfigurationData('timberFinishes', productData['timber_finishes'] || []));

    dispatch(setFormData('hardwareSet', hardwareSet));
    dispatch(setFormData('finishSystem', finishSystem));
    dispatch(setFormData('sectionEditors', sectionEditorData || {}));
  }, [productData]);

  return (
    <Widget title="New Casement">
      <div
        className="w-1/12 fixed top-0.5 right-0"
        style={{ zIndex: 100 }}
        onClick={() => {
          casementStore.clearCurrentSection();
          sleep(800).then(() => {
            handleSubmit(
              casementStore,
              null,
              'newItem',
              null,
              stageRef,
              rfqId,
              parentId,
              {
                projectId,
              },
              [productItemMethods.trigger]
            );
          });
        }}
      >
        <div className="fixed bottom-0 py-4 pl-64 inset-x-0 bg-orange-M100">
          <Button as="button">Save</Button>
          <Button
            buttonStyle="light-secondary-outline"
            as="button"
            className="ml-2"
            onClick={() => (window.location.href = `/projects/${projectId}`)}
          >
            Back To Project
          </Button>
        </div>
      </div>
      <FormProvider {...productItemMethods}>
        <ProductItemFields availableSiteOptions={sites} />
      </FormProvider>
      <Casement
        stageRef={stageRef}
        id={recordId}
        snapshot={snapshot}
        store={casementStore}
        data={data}
      />
    </Widget>
  );
};

const NewProductItemCasementPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default NewProductItemCasementPage;
