import API from 'apiv1';
import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Provider } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import Datatable, { TruncatedCell } from '@components/datatable';

import RecordActions from '../components/datatable/RecordActions';
import ClientsForm from '../components/forms/ClientsForm';
import Modal from '../components/modals/modal';
import Widget from '../components/widget';
import { store } from '../store';

const Page = ({ records }) => {
  const formId = 'clientsForm';

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Point of contact',
      accessor: 'point_of_contact',
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 100 }),
    },
    {
      Header: 'Telephone',
      accessor: 'contact_number',
    },
    {
      Header: 'Registered Address',
      accessor: 'address.line_1',
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 200 }),
    },
    {
      Header: 'VAT No.',
      accessor: 'vat_number',
    },
    {
      Header: 'Active projects',
      accessor: 'active_projects',
    },
    // TODO: Uncomment once implemented
    // {
    //   Header: 'Conversion rate',
    //   accessor: 'conversion_rate',
    //   Cell: ({ value }) => <span>{value}%</span>,
    // },
    // {
    //   Header: 'Notes',
    //   accessor: 'notes',
    // },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value, row }) =>
        RecordActions(
          value,
          ClientsForm,
          API.deleteClient,
          {},
          {
            delete: {
              fn: () => row.original.active_projects === 0,
              body: (
                <span>
                  The client has active projects, please delete them before deleting the client.
                </span>
              ),
            },
          }
        ),
    },
  ];

  return (
    <Widget
      title="Clients"
      right={
        <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
          <Modal
            title="Add Clients"
            body={<ClientsForm formId={formId} />}
            buttonTitle="Add Client"
            // @ts-ignore
            modalButtonText={
              <>
                <FiPlus size={22} /> New Record
              </>
            }
            buttonFormId={formId}
            buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
          />
        </div>
      }
    >
      <Datatable columns={columns} data={records} />
    </Widget>
  );
};

const ClientsPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default ClientsPage;
