import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';
import BoxedLayout from '@appSrc/layouts/content/Boxed';

import Casement from '@components/configurators/Casement';
import Widget from '@components/widget';

import { store } from '../store';

const Page = ({ snapshot, data, productData }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setConfigurationData('timber', productData['timber_types'] || []));
    dispatch(setConfigurationData('hardwareSets', productData['hardware_sets'] || []));
    dispatch(setConfigurationData('hardware', productData['hardware'] || []));
    dispatch(setConfigurationData('glazingUnits', productData['glazing_units'] || []));
    dispatch(setConfigurationData('finishSystems', productData['finish_systems'] || []));
    dispatch(setConfigurationData('timberFinishes', productData['timber_finishes'] || []));
  }, [productData]);

  return (
    <Widget title="New Casement">
      <Casement editMode={'new'} />
    </Widget>
  );
};

const NewCasementPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default NewCasementPage;
