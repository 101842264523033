// @ts-nocheck
import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components';
import tw from 'twin.macro';

const Button = ({
  as = 'a',
  buttonStyle = 'default',
  wrap = true,
  disabled = false,
  ...linkProps
}) => {
  let Component = LightLink;
  let componentDefaultProps = {};
  switch (as) {
    case 'a':
      Component = LightLink;
      break;

    case 'button':
      Component = LightButton;
      componentDefaultProps = { type: 'button', wrap: String(wrap) };
      break;

    default:
      break;
  }

  return (
    <Component
      {...componentDefaultProps}
      {...linkProps}
      buttonStyle={buttonStyle}
      disabled={disabled}
    ></Component>
  );
};

const noTextWrapCss = css`
  ${tw`whitespace-nowrap`}
`;

const buttonCss = css`
  ${tw`inline-flex items-center px-4 py-2 border-solid border-transparent rounded-md shadow-sm text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deep-purple-M600`}
`;

const defaultStyleCss = css`
  ${tw`text-white`}
  ${tw`bg-deep-purple-M600`}
  ${tw`border-deep-purple-M600`}
  ${tw`hover:bg-deep-purple-M700`}

  svg {
    padding-right: 0.35rem;
  }
`;

const lightPrimaryCss = css`
  ${tw`text-deep-purple-M700 bg-pink-60 hover:bg-deep-purple-M700 hover:text-white focus:ring-deep-purple-M700`}
`;

const lightSecondaryOutline = css`
  ${tw`border border-pink-60 bg-white text-deep-purple-M700 hover:bg-deep-purple-M700 hover:text-white hover:border-transparent focus:ring-deep-purple-M700`}
`;

const determineStyle = (style: string): string => {
  switch (style) {
    case 'default':
      return defaultStyleCss;
      break;

    case 'light-primary':
      return lightPrimaryCss;
      break;

    case 'light-secondary-outline':
      return lightSecondaryOutline;
      break;

    default:
      return '';
      break;
  }
};

const LightLink = styled.a`
  ${buttonCss}
  ${({ buttonStyle }) => determineStyle(buttonStyle)}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed opacity-50` : '')}
`;

const LightButton = styled.button`
  ${buttonCss}
  ${({ wrap }) => (wrap === 'true' ? '' : noTextWrapCss)}
  ${({ buttonStyle }) => determineStyle(buttonStyle)}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed opacity-50` : '')}
`;

// ${({ color }) =>
// color &&
// tw`text-${color}-M900 bg-${color}-M100 hover:bg-${color}-M500 focus:ring-${color}-M900`}

export default Button;
