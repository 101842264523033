import { intersection } from 'lodash/array';
import { startCase } from 'lodash/string';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';

import DataModal from '@components/DataModal';
import Card from '@components/configurators/Card';
import { itemToInput } from '@components/forms/GridForm';

import c from '@utils/constants';

import { InputContainer } from '../Section';

type State = {
  hardwareSet: any;
};

const HARDWARE_FINSIH_ITEMS = [
  'hinge',
  'top_hung_hinge',
  'espag_handle',
  'stay',
  'handle',
  'flushbolt',
  'lockable_stay_pins',
];

const HardwareSet = ({ productData }) => {
  const { reset, ...methods } = useFormContext();
  const dispatch = useDispatch();
  const state = useSelector((state: State) => {
    return {
      // @ts-ignore
      hardwareSet: state.configuratorForms.hardwareSet,
    };
  });

  const materialCard = (material) => {
    if (material) {
      return <Card defaultOpen={false} title={material['name']} narrowStyle={true} />;
    }

    return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
  };

  const handleHardwareSetChange = (hardwareSet) => {
    dispatch(setFormData('hardwareSet', hardwareSet));
  };

  const handleHardwareSetItemChange = (itemKey, hardwareSetItem) => {
    dispatch(setFormData(`hardwareSet.materials.${itemKey}`, hardwareSetItem));
    if (HARDWARE_FINSIH_ITEMS.includes(itemKey)) {
      methods.setValue('hardware_finish_id', '');
    }
    if (itemKey === 'shootbolt') {
      methods.setValue('shootbolt_finish_id', '');
    }
    if (itemKey === 'trickle_vent') {
      methods.setValue('trickle_vent_finish_id', '');
    }
    return true;
  };

  const findAvailableHardwareFinish = () => {
    let hardwareFinishes = [];

    if (!state.hardwareSet) return null;

    HARDWARE_FINSIH_ITEMS.forEach((itemSetName) => {
      let hardwareSetItem = state.hardwareSet.materials[itemSetName];

      if (hardwareSetItem && hardwareFinishes.length === 0 && hardwareSetItem.finish_full) {
        hardwareFinishes = hardwareSetItem['finish_full'].split(', ');
      }
      if (hardwareSetItem && hardwareFinishes.length > 0 && hardwareSetItem.finish_full) {
        hardwareFinishes = intersection(
          hardwareFinishes,
          hardwareSetItem['finish_full'].split(', ')
        );
      }
    });

    if (hardwareFinishes.length === 0) return null;

    return hardwareFinishes.map((hardwareFinish) => ({
      label: hardwareFinish,
      value: hardwareFinish,
    }));
  };

  return (
    <>
      <span className="my-2">Hardware Set</span>
      <InputContainer className="flex flex-row space-x-2 w-full">
        {materialCard(state.hardwareSet)}
        <div className="flex flex-col space-y-2 self-center">
          <DataModal
            data={productData.hardwareSets}
            materialKey="casementHardwareSet"
            // @ts-ignore
            onItemSelect={(hardwareSet) => handleHardwareSetChange(hardwareSet)}
          />
        </div>
      </InputContainer>
      <InputContainer className="flex flex-row space-x-2 w-full">
        <div className="flex flex-col space-y-2 self-center">
          {state.hardwareSet &&
            itemToInput(
              {
                label: 'Hardware Finish',
                name: 'hardware_finish_id',
                type: 'newSelect',
                options:
                  findAvailableHardwareFinish() || state.hardwareSet.available_hardware_finishes,
                defaultValue: '',
                inputClass: 'w-2/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
        </div>
      </InputContainer>
      {state.hardwareSet &&
        c.CASEMENT_HARDWARE_SET_ITEMS.map((itemKey) => {
          const item = state.hardwareSet.materials[itemKey];
          return (
            <React.Fragment key={itemKey}>
              <span className="my-2">{startCase(itemKey)}</span>
              <InputContainer className="flex flex-col space-y-2 w-full">
                <div className="flex flex-row space-x-2">
                  {materialCard(item)}
                  <div className="flex flex-col space-y-2 self-center">
                    <DataModal
                      data={
                        itemKey === 'weatherseal'
                          ? productData.hardware.filter((h) => h['hardware_type'] === 'INSULATOR')
                          : productData.hardware.filter((h) => h['hardware_type'] !== 'INSULATOR')
                      }
                      materialKey="casementHardwareSet"
                      // @ts-ignore
                      onItemSelect={(hardwareSetItem) =>
                        handleHardwareSetItemChange(itemKey, hardwareSetItem)
                      }
                      clearable={true}
                      handleClear={() => handleHardwareSetItemChange(itemKey, { id: null })}
                    />
                  </div>
                </div>
                {itemKey === 'trickle_vent' &&
                  item &&
                  item.id &&
                  itemToInput(
                    {
                      label: 'Trickle-Vent Finish',
                      name: 'trickle_vent_finish_id',
                      type: 'newSelect',
                      options: item.finish_options || [],
                      defaultValue: '',
                      inputClass: 'w-2/4',
                    },
                    null,
                    methods.register,
                    methods.errors,
                    methods.control
                  )}
                {itemKey === 'shootbolt' &&
                  item &&
                  item.id &&
                  itemToInput(
                    {
                      label: 'Shootbolt Finish',
                      name: 'shootbolt_finish_id',
                      type: 'newSelect',
                      options: item.finish_options || [],
                      defaultValue: '',
                      inputClass: 'w-2/4',
                    },
                    null,
                    methods.register,
                    methods.errors,
                    methods.control
                  )}
              </InputContainer>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default HardwareSet;
