import React from 'react';
import { Provider } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import BoxSashProductionForm from '@components/forms/BoxSashProductionForm';
import Widget from '@components/widget';

import { store } from '../store';

const Page = ({ productOperationSummary }) => {
  return (
    <Widget title="Box Sash Manufacturing Time-Sheet">
      <BoxSashProductionForm productOperationSummary={productOperationSummary} />
    </Widget>
  );
};

const BoxSashProductionPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default BoxSashProductionPage;
