import range from 'lodash/range';
import { types, getParent, IAnyModelType, destroy, Instance } from 'mobx-state-tree';

import { Coordinate } from '@utils/ducks';
import uuid from '@utils/uuid';

import { Casement, ICasement } from './CasementStore';
import { equalHorizontalSplitInternalSection, toMeters } from './sectionUtilities';

const parseFunction = (value) => {
  try {
    const fn = Function(`'use strict'; return (${value});`)();
    return fn;
  } catch (e) {
    throw new Error(`${value} is not a valid function`);
  }
};

export const functionType = types.custom<string, Function>({
  name: 'functionType',
  fromSnapshot(value: string) {
    return parseFunction(value);
  },
  toSnapshot(value: Function) {
    return value.toString();
  },
  getValidationMessage(value: string) {
    try {
      parseFunction(value);
      return '';
    } catch (e) {
      return `value "${value}" is Not a valid function ${e}`;
    }
  },
  isTargetType(value: any) {
    return value instanceof Function;
  },
});

// @ts-ignore
const Section = types
  // @ts-ignore
  .model({
    id: types.identifier,
    store: types.late(() => types.reference(Casement)),
    splitVia: types.optional(types.string, 'none'),
    transomDrop: types.optional(types.number, 0),
    midRailDrop: types.optional(types.number, 0),
    hasMidRail: types.optional(types.boolean, false),
    muntinQuantity: types.optional(types.number, 0),
    isLast: types.optional(types.boolean, false),
    sectionType: types.optional(types.string, 'node'),
    leafStyle: types.optional(types.string, 'standard'),
    openDirection: types.optional(types.string, 'none'),
    origin: types.frozen({ x: 0, y: 0 }),
    absoluteOrigin: types.frozen({ x: 0, y: 0 }),
    size: types.frozen({ width: 1000, height: 1000 }),
    dimensions: types.frozen({ x: false, y: false, levelX: 0, levelY: 0, yDisabled: false }),
    parameters: types.frozen({
      leafOpening: 'none',
      mullionDistance: null,
    }),
    georgianBars: types.frozen({
      horizontal: 0,
      vertical: 0,
      alignWith: null,
    }),
    sash_gaps: types.frozen({
      top: null,
      left: null,
      bottom: null,
      right: null,
    }),
    isHeadNeighbour: types.optional(types.boolean, false),
    hasBars: types.optional(types.boolean, false),
    usingStickOnBars: types.optional(types.boolean, true),
    hasRainDrip: types.optional(types.boolean, false),
    calculateAbsOrigin: functionType,
    transomBalanceTarget: types.maybe(
      // @ts-ignore
      types.late((): IAnyModelType => types.safeReference(Section))
    ),
    barsBalanceTarget: types.maybe(
      // @ts-ignore
      types.late((): IAnyModelType => types.safeReference(Section))
    ),
    // @ts-ignore
    parent: types.maybe(types.late((): IAnyModelType => types.reference(Section))),
    // @ts-ignore
    sections: types.maybe(types.late((): IAnyModelType => types.array(Section))),
    relativePosition: types.frozen({ top: null, left: null, bottom: null, right: null }),
  })
  // .volatile((self) => ({
  //   calculateAbsOrigin: functionType,
  // }))
  .views((self) => ({
    get originalSize() {
      if (self.sectionType !== 'leaf' && self.sectionType !== 'dummy') {
        return self.size;
      }

      let bottomOffset =
        self.relativePosition.bottom === 'sill'
          ? self.store.sashHeightOffsetOnSill
          : self.store.sashHeightOffsetOnTransom;

      return {
        width: self.size.width + this.sashGapX,
        height: self.size.height + this.sashGapY + bottomOffset,
      };
    },

    get originalAbsOrigin() {
      if (self.sectionType !== 'leaf' && self.sectionType !== 'dummy') {
        return self.absoluteOrigin;
      }

      let bottomOffset =
        self.relativePosition.bottom === 'sill'
          ? self.store.sashHeightOffsetOnSill
          : self.store.sashHeightOffsetOnTransom;

      return {
        x: self.absoluteOrigin.x - this.sashGapLeft,
        y: self.absoluteOrigin.y - this.sashGapBottom - bottomOffset,
      };
    },

    get hasMuntin() {
      return self.muntinQuantity > 0;
    },

    get internalSize() {
      if (['transom', 'mullion', 'muntin'].includes(self.sectionType))
        return { width: 0, height: 0 };
      if (['node', 'blank', 'fixed', 'glazing'].includes(self.sectionType)) return self.size;

      if (['leaf', 'dummy'].includes(self.sectionType)) {
        return {
          width: self.size.width - self.store.stiles.width * 2,
          height: self.size.height - (self.store.top_rail.width + this.bottomRailWidth),
        };
      }
    },

    get visualOpeningSize() {
      if (['transom', 'mullion', 'muntin'].includes(self.sectionType))
        return { width: 0, height: 0 };
      if (['node', 'blank', 'glazing'].includes(self.sectionType)) return self.size;
      if (['fixed'].includes(self.sectionType)) {
        return {
          width: self.size.width - self.store.frame_glazing_bead.width * 2,
          height: self.size.height - self.store.frame_glazing_bead.width * 2,
        };
      }

      if (['leaf', 'dummy'].includes(self.sectionType)) {
        return {
          width: self.size.width - self.store.stiles.width * 2,
          height: self.size.height - (self.store.top_rail.width + this.bottomRailWidth),
        };
      }
    },

    get georgianBarsInternalSize() {
      if (['leaf', 'dummy'].includes(self.sectionType)) {
        return {
          width: self.size.width - self.store.stiles.width * 2 + self.store.sash_moulding.width * 2,
          height:
            self.size.height -
            (self.store.top_rail.width + this.bottomRailWidth) +
            self.store.sash_moulding.width * 2,
        };
      }

      if (self.sectionType === 'fixed') {
        return {
          width: self.size.width - self.store.frame_glazing_bead.width * 2,
          height: self.size.height - self.store.frame_glazing_bead.width * 2,
        };
      }

      if (self.sectionType === 'glazing') {
        return {
          width: self.size.width + self.store.sash_moulding.width * 2,
          height: self.size.height + self.store.sash_moulding.width * 2,
        };
      }
    },

    get trueBarGlassPanes() {
      if (self.usingStickOnBars) return [];
      if (self.sectionType === 'leaf' && self.sections.length > 0) return [];

      const panes = [];

      if (!this.glassSize) return [];

      // Get the entire glass unit sizes
      const { width, height } = this.glassSize[0];

      // Get the bars { thickness, width, rebate }
      const bar = self.store.trueBars;
      const barWidth = bar.width;
      const _barThickness = bar.thickness;
      const barRebate = bar.rebate;
      const glassGap = self.store.glass_gap;

      // Get the bar origins from them we can calculate the dist between bars
      // @ts-ignore
      const barOrigins = self.barOrigins()[0];

      // Build a matrix of the pane sizes
      barOrigins.vertical.forEach((origin, i) => {
        const isFirst = i === 0;
        const isLastBar = i === barOrigins.vertical.length - 1;

        const pane = [
          {
            width: 0,
            height: 0,
          },
        ];

        if (isFirst) {
          pane[0] = { ...pane[0], width: origin.x + barRebate - glassGap };
        }

        if (isLastBar && !isFirst) {
          pane[0].width = width - (origin.x + barWidth - barRebate + glassGap);
        }

        if (isFirst && !isLastBar) {
          const nextBar = barOrigins.vertical[i + 1];
          pane.push({
            width: nextBar.x + barRebate - glassGap - (origin.x + barWidth - barRebate + glassGap),
            height: 0,
          });
        }

        if (isFirst && isLastBar) {
          pane.push({
            width: width - (origin.x + barWidth - barRebate + glassGap),
            height: 0,
          });
        }

        if (!isFirst && !isLastBar) {
          const nextBar = barOrigins.vertical[i + 1];
          pane[0].width =
            nextBar.x + barRebate - glassGap - (origin.x + barWidth - barRebate + glassGap);
        }

        barOrigins.horizontal.forEach((horizontalOrigin, j) => {
          const isFirstHor = j === 0;
          const isLastHorBar = j === barOrigins.horizontal.length - 1;
          let paneHeight = 0;

          if (isFirstHor) {
            paneHeight = horizontalOrigin.y + barRebate - glassGap;

            pane.forEach((p) =>
              panes.push({
                ...p,
                height: paneHeight,
                area: toMeters(p.width) * toMeters(paneHeight),
              })
            );
          }

          if (isLastHorBar) {
            paneHeight = height - (horizontalOrigin.y + barWidth - barRebate + glassGap);

            pane.forEach((p) =>
              panes.push({
                ...p,
                height: paneHeight,
                area: toMeters(p.width) * toMeters(paneHeight),
              })
            );
          }

          if ((isFirstHor && !isLastHorBar) || (!isFirstHor && !isLastHorBar)) {
            const nextHorBar = barOrigins.horizontal[j + 1];
            paneHeight =
              nextHorBar.y +
              barRebate -
              glassGap -
              (horizontalOrigin.y + barWidth - barRebate + glassGap);

            pane.forEach((p) =>
              panes.push({
                ...p,
                height: paneHeight,
                area: toMeters(p.width) * toMeters(paneHeight),
              })
            );
          }
        });
      });

      return panes;
    },

    get glassSize() {
      if (!['leaf', 'dummy', 'fixed', 'glazing'].includes(self.sectionType)) return null;
      if (self.sections.length > 0) {
        let sizes = [];
        self.sections.forEach(
          (section) => section.glassSize && (sizes = sizes.concat(section.glassSize))
        );

        return sizes;
      }

      if (self.sectionType === 'fixed') {
        let bottomReduction =
          self.relativePosition.bottom === 'transom'
            ? self.store.transomAngleReduction
            : self.store.sillAngleReduction;

        return [
          {
            width: self.size.width - self.store.frame_glass_gap * 2,
            height: self.size.height - bottomReduction - self.store.frame_glass_gap * 2,
          },
        ];
      }

      if (self.sectionType === 'glazing') {
        return [
          {
            width: self.size.width + (self.store.glazing_rebate.width - self.store.glass_gap) * 2,
            height: self.size.height + (self.store.glazing_rebate.width - self.store.glass_gap) * 2,
          },
        ];
      }

      if (self.sectionType !== 'fixed') {
        const width =
          self.size.width -
          (self.store.stiles.width - self.store.glazing_rebate.width) * 2 -
          self.store.glass_gap * 2;
        const height =
          self.size.height -
          (self.store.top_rail.width -
            self.store.glazing_rebate.width +
            this.bottomRailWidth -
            self.store.glazing_rebate.width) -
          self.store.glass_gap * 2;
        return [
          {
            width: width,
            height: height,
          },
        ];
      }
    },

    get glassArea() {
      if (!['leaf', 'dummy', 'fixed'].includes(self.sectionType)) return 0;
      if (self.sections.length > 0)
        return self.sections.reduce((acc, section) => acc + section.glassArea, 0);

      const glassSizes = this.glassSize;
      const area = glassSizes.reduce((acc, size) => acc + size.width * size.height * 1e-6, 0);

      return Math.round(area * 10000) / 10000;
    },

    get glassSummary() {
      const truePanes = self.usingStickOnBars ? [] : this.trueBarGlassPanes;
      const area = self.usingStickOnBars
        ? this.glassArea
        : truePanes.reduce((acc, pane) => acc + pane.area, 0);

      return {
        sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
        sizes: this.glassSize,
        area: area,
        quantity: this.sashPaneQuantity,
        unit_area: area / this.sashPaneQuantity, // Average area of each pane when it's true panes
        bars: self.georgianBars,
        bar: self.usingStickOnBars ? self.store.stickOnBars.out : self.store.trueBars,
        is_stick_on: self.usingStickOnBars,
        true_bar_panes: truePanes,
        // @ts-ignore
        barOrigins: self.barOrigins(),
      };
    },

    get surfaceArea() {
      if (!['leaf', 'dummy', 'fixed', 'mullion', 'transom', 'muntin'].includes(self.sectionType))
        return 0;

      const { width, height } = this.size;

      if (self.sectionType === 'muntin') {
        const totalSurfaceArea =
          (self.store.muntin.width * height * 2 + self.store.muntin.thickness * height * 2) * 1e-6;

        return Math.round(totalSurfaceArea * 10000) / 10000;
      }

      if (self.sectionType === 'mullion') {
        const totalSurfaceArea =
          (self.store.mullion.width * height * 2 + self.store.mullion.thickness * height * 2) *
          1e-6;

        return Math.round(totalSurfaceArea * 10000) / 10000;
      }

      if (self.sectionType === 'transom') {
        let totalSurfaceArea =
          (self.store.transom.width * width * 2 + self.store.transom.thickness * width * 2) * 1e-6;

        if (self.hasRainDrip) {
          totalSurfaceArea += this.store.transomRainDrip.width * this.rainDripWidth[0] * 2 * 1e-6;
          totalSurfaceArea +=
            this.store.transomRainDrip.thickness * this.rainDripWidth[0] * 2 * 1e-6;
        }

        return Math.round(totalSurfaceArea * 10000) / 10000;
      }

      if (['leaf', 'dummy'].includes(self.sectionType)) {
        const botRailArea =
          (self.store.bottom_rail.width * width * 2 +
            self.store.bottom_rail.thickness * width * 2) *
          1e-6;
        const topRailArea =
          (self.store.top_rail.width * width * 2 + self.store.top_rail.thickness * width * 2) *
          1e-6;
        const stileArea =
          (self.store.stiles.width * height * 2 + self.store.stiles.thickness * height * 2) * 1e-6;

        const internalSize = this.internalSize;
        const glazingBeadVerticalArea =
          (self.store.sash_glazing_bead.width * internalSize.height * 2 +
            self.store.sash_glazing_bead.thickness * internalSize.height * 2) *
          1e-6;
        const glazingBeadHorizontalArea =
          (self.store.sash_glazing_bead.width * internalSize.width * 2 +
            self.store.sash_glazing_bead.thickness * internalSize.width * 2) *
          1e-6;

        let subSectionArea = 0;
        if (self.sections.length > 0) {
          subSectionArea = self.sections.reduce((acc, section) => acc + section.surfaceArea, 0);
        }

        const totalSurfaceArea =
          botRailArea +
          topRailArea +
          stileArea * 2 +
          glazingBeadVerticalArea * 2 +
          glazingBeadHorizontalArea * 2 +
          this.glazingBarsSurfaceArea +
          subSectionArea;

        return Math.round(totalSurfaceArea * 10000) / 10000;
      }

      if (self.sectionType === 'fixed') {
        const glazingBeadVerticalArea =
          (self.store.frame_glazing_bead.width * height * 2 +
            self.store.frame_glazing_bead.thickness * height * 2) *
          1e-6;
        const glazingBeadHorizontalArea =
          (self.store.frame_glazing_bead.width * width * 2 +
            self.store.frame_glazing_bead.thickness * width * 2) *
          1e-6;
        const totalSurfaceArea =
          glazingBeadVerticalArea * 2 + glazingBeadHorizontalArea * 2 + this.glazingBarsSurfaceArea;

        return Math.round(totalSurfaceArea * 10000) / 10000;
      }
    },

    get glazingBarsSurfaceArea() {
      if (!['leaf', 'dummy', 'fixed'].includes(self.sectionType)) return 0;
      if (!self.hasBars) return 0;

      const { width, height } = this.georgianBarsInternalSize;

      let horizontalBarArea = 0;
      let verticalBarArea = 0;

      if (self.usingStickOnBars) {
        horizontalBarArea +=
          (self.store.stickOnBars.in.width * width * 2 +
            self.store.stickOnBars.in.thickness * width * 2 +
            self.store.stickOnBars.in.width * self.store.stickOnBars.in.thickness * 2) *
          1e-6;
        horizontalBarArea +=
          (self.store.stickOnBars.out.width * width * 2 +
            self.store.stickOnBars.out.thickness * width * 2 +
            self.store.stickOnBars.out.width * self.store.stickOnBars.out.thickness * 2) *
          1e-6;
        verticalBarArea +=
          (self.store.stickOnBars.in.width * height * 2 +
            self.store.stickOnBars.in.thickness * height * 2 +
            self.store.stickOnBars.in.width * self.store.stickOnBars.in.thickness * 2) *
          1e-6;
        verticalBarArea +=
          (self.store.stickOnBars.out.width * height * 2 +
            self.store.stickOnBars.out.thickness * height * 2 +
            self.store.stickOnBars.out.width * self.store.stickOnBars.out.thickness * 2) *
          1e-6;
      } else {
        horizontalBarArea +=
          (self.store.trueBars.width * width * 2 +
            self.store.trueBars.thickness * width * 2 +
            self.store.trueBars.width * self.store.trueBars.thickness * 2) *
          1e-6;
        verticalBarArea +=
          (self.store.trueBars.width * height * 2 +
            self.store.trueBars.thickness * height * 2 +
            self.store.trueBars.width * self.store.trueBars.thickness * 2) *
          1e-6;
      }

      const totalBarArea =
        horizontalBarArea * self.georgianBars.horizontal +
        verticalBarArea * self.georgianBars.vertical;

      return Math.round(totalBarArea * 10000) / 10000;
    },

    get sashPaneQuantity() {
      if (['leaf', 'dummy'].includes(self.sectionType) && self.sections.length > 0) return 0;

      if (!self.usingStickOnBars) {
        if (this.barQuantity === 0) return 1;

        return self.georgianBars.horizontal + 1 * self.georgianBars.vertical + 1;
      }

      return 1;
    },

    get barQuantity() {
      if (!self.hasBars) return 0;

      return self.georgianBars.horizontal + self.georgianBars.vertical;
    },

    get sashFinishMeters() {
      const { width, height } = this.size;
      return Math.round((width + height) * 2 * 1e-3 * 1000) / 1000;
    },

    get frameFinishMeters() {
      const { width, height } = this.size;
      if (self.sectionType === 'transom') {
        let totalWidth = width;
        if (self.hasRainDrip) totalWidth += this.rainDripWidth[0];
        return Math.round(totalWidth * 1e-3 * 1000) / 1000;
      }
      if (self.sectionType === 'mullion') {
        return Math.round(height * 1e-3 * 1000) / 1000;
      }
    },

    get hardwareSummary() {
      if (['glazing', 'muntin', 'fixed', 'root', 'node', 'blank'].includes(self.sectionType)) {
        return null;
      }

      let hardwareSummary = {
        sectionID: self.id,
        type: self.sectionType,
        open: self.parameters.leafOpening,
        size: self.size,
        circumference: toMeters(self.size.width * 2 + self.size.height * 2),
        internal_circumference: toMeters(
          this.internalSize.width * 2 + this.internalSize.height * 2
        ),
      };
      // DEBUG
      // console.log('Hardware summary: ', hardwareSummary);

      if (self.sectionType === 'muntin') {
        hardwareSummary['circumference'] = toMeters(self.size.height * 2);
      }

      return hardwareSummary;
    },

    get sashGapTop() {
      return self.sash_gaps.top || self.store.sash_gaps.top;
    },

    get sashGapLeft() {
      return self.sash_gaps.left || self.store.sash_gaps.left;
    },

    get sashGapBottom() {
      return self.sash_gaps.bottom || self.store.sash_gaps.bottom;
    },

    get sashGapRight() {
      return self.sash_gaps.right || self.store.sash_gaps.right;
    },

    get sashGapY() {
      return this.sashGapTop + this.sashGapBottom;
    },

    get sashGapX() {
      return this.sashGapLeft + this.sashGapRight;
    },

    get statistics() {
      let statistics = {
        sash_pane_quantity: 0,
        bars_quantity: 0,
        sash_finish_meters: 0,
        sash_sealing_meters: 0,
        frame_sealing_meters: 0,
        frame_finish_meters: 0,
        rain_drip_quantity: self.hasRainDrip ? 1 : 0,
      };

      if (['leaf', 'dummy', 'fixed'].includes(self.sectionType)) {
        statistics.sash_pane_quantity = this.sashPaneQuantity;
        statistics.bars_quantity = this.barQuantity;
        statistics.sash_finish_meters = this.sashFinishMeters;
        statistics.sash_sealing_meters = this.sashFinishMeters;
      }

      if (['transom', 'mullion'].includes(self.sectionType)) {
        statistics.frame_sealing_meters = this.frameFinishMeters * 2;
        statistics.frame_finish_meters = this.frameFinishMeters;
      }

      if (['muntin'].includes(self.sectionType)) {
        statistics.bars_quantity = this.barQuantity;
        statistics.sash_finish_meters = this.sashFinishMeters;
        statistics.sash_sealing_meters = this.sashFinishMeters;
      }

      if (['glazing'].includes(self.sectionType)) {
        statistics.sash_pane_quantity = this.sashPaneQuantity;
      }

      if (self.sectionType === 'transom') {
        statistics.rain_drip_quantity = self.hasRainDrip ? 1 : 0;
      }

      return statistics;
    },

    get internalOrigin() {
      if (
        self.sectionType === 'node' ||
        self.sectionType === 'blank' ||
        self.sectionType === 'fixed' ||
        self.sectionType === 'root'
      )
        return { x: 0, y: 0 };

      if (self.sectionType === 'leaf' || self.sectionType === 'dummy') {
        return {
          x: self.store.stiles.width,
          y: this.bottomRailWidth,
        };
      }
    },

    get bottomRail() {
      if (self.relativePosition.bottom === 'transom') {
        return self.store.top_sash_bottom_rail;
      } else {
        return self.store.bottom_rail;
      }
    },

    get bottomRailWidth() {
      return this.bottomRail.width;
    },

    get relativeRadius() {
      let radiusReduction =
        self.store.finalSize.height - (self.absoluteOrigin.y + self.size.height);

      if (['dummy', 'leaf'].includes(self.sectionType)) {
        radiusReduction -= self.store['sash_gap'];
      }

      return self.store.arcRadius - radiusReduction;
    },

    get relativeArcCenter() {
      let arcCenterX, arcCenterY;

      if (['dummy', 'leaf'].includes(self.sectionType)) {
        arcCenterY = self.size.height + this.sashGapBottom - this.relativeRadius; // relative to self
        arcCenterX = self.store.finalSize.width / 2 - (this.sashGapLeft + self.absoluteOrigin.x); // relative to self
      } else {
        arcCenterY = self.size.height - this.relativeRadius; // relative to self
        arcCenterX = self.store.finalSize.width / 2 - self.absoluteOrigin.x; // relative to self
      }

      return { x: arcCenterX, y: arcCenterY };
    },

    get rainDripWidth() {
      let rainDripWidth = self.size.width;
      let leftOffset = 0;

      if (self.relativePosition.left === 'jamb') {
        rainDripWidth += self.store.visibleJambWidth;
        leftOffset = self.store.visibleJambWidth;
      } else {
        rainDripWidth += self.store.visibleMullionWidth;
        leftOffset = self.store.visibleMullionWidth;
      }

      if (self.relativePosition.right === 'jamb') {
        rainDripWidth += self.store.visibleJambWidth;
      } else {
        rainDripWidth += self.store.visibleMullionWidth;
      }

      return [rainDripWidth, leftOffset];
    },

    get timber() {
      if (!['leaf', 'dummy', 'fixed', 'mullion', 'transom', 'muntin'].includes(self.sectionType))
        return [];

      if (self.store.sashesOnly && ['mullion', 'transom'].includes(self.sectionType)) return [];

      const { width, height } = this.size;
      const internalSize = this.internalSize;

      let timber = [];
      if (['leaf', 'dummy'].includes(self.sectionType)) {
        timber.push({
          ...this.bottomRail,
          sectionID: self.id,
          length: width,
          quantity: 1,
          profile: 'bottom_rail',
        });
        timber.push({
          ...self.store.top_rail,
          sectionID: self.id,
          length: width,
          quantity: 1,
          profile: 'top_rail',
        });
        timber.push({
          ...self.store.stiles,
          sectionID: self.id,
          length: height,
          quantity: 2,
          profile: 'stile',
        });

        timber.push({
          ...self.store.sash_glazing_bead,
          sectionID: self.id,
          length: internalSize.width,
          quantity: 2,
          orientation: 'horizontal',
          profile: 'glazing_bead',
        });
        timber.push({
          ...self.store.sash_glazing_bead,
          sectionID: self.id,
          length: internalSize.height,
          quantity: 2,
          orientation: 'vertical',
          profile: 'glazing_bead',
        });

        timber = timber.concat(this.glazingBarsTimber);
      }

      if (self.sectionType === 'fixed') {
        timber.push({
          ...self.store.frame_glazing_bead,
          sectionID: self.id,
          length: internalSize.width,
          quantity: 2,
          orientation: 'horizontal',
          profile: 'frame_glazing_bead',
        });
        timber.push({
          ...self.store.frame_glazing_bead,
          sectionID: self.id,
          length: internalSize.height,
          quantity: 2,
          orientation: 'vertical',
          profile: 'frame_glazing_bead',
        });

        timber = timber.concat(this.glazingBarsTimber);
      }

      if (self.sectionType === 'mullion') {
        let topLength =
          self.relativePosition.top === 'transom'
            ? self.store.visibleTransomWidth / 2
            : self.store.visibleHeadWidth;
        let bottomLength =
          self.relativePosition.bottom === 'transom'
            ? self.store.visibleTransomWidth / 2
            : self.store.visibleSillHeight;
        timber.push({
          ...self.store.mullion,
          sectionID: self.id,
          length: height + topLength + bottomLength,
          quantity: 1,
          profile: 'mullion',
        });
      }

      if (self.sectionType === 'muntin') {
        timber.push({
          ...self.store.muntin,
          sectionID: self.parent.id,
          length: height + self.parent.bottomRailWidth + self.store.top_rail.width,
          quantity: 1,
          profile: 'muntin',
        });
      }

      if (self.sectionType === 'transom') {
        let leftLength =
          self.relativePosition.left === 'mullion'
            ? self.store.visibleMullionWidth / 2
            : self.store.visibleJambWidth;
        let rightLength =
          self.relativePosition.right === 'mullion'
            ? self.store.visibleMullionWidth / 2
            : self.store.visibleJambWidth;
        timber.push({
          ...self.store.transom,
          sectionID: self.id,
          length: width + leftLength + rightLength,
          quantity: 1,
          profile: 'transom',
          transomDrop:
            self.transomDrop === 0
              ? self.store.finalSize.height - self.absoluteOrigin.y - self.size.width / 2
              : self.transomDrop,
        });
        if (self.hasRainDrip) {
          timber.push({
            ...self.store.transomRainDrip,
            sectionID: self.id,
            length: this.rainDripWidth[0],
            quantity: 1,
            profile: 'transom_rain_drip',
          });
        }
      }

      return timber;
    },

    get glazingBarsTimber() {
      if (!['leaf', 'dummy', 'fixed', 'glazing'].includes(self.sectionType)) return 0;
      if (self.sections.length > 0) return 0;
      if (!self.hasBars) return 0;

      const bars = self.usingStickOnBars ? self.store.stickOnBars : self.store.trueBars;
      const profile = self.usingStickOnBars ? 'georgian_bar' : 'true_bar';
      const { width, height } = this.georgianBarsInternalSize;
      const timber = [];

      if (self.usingStickOnBars) {
        // IN
        timber.push({
          ...bars.in,
          sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
          length: width,
          quantity: self.georgianBars.horizontal,
          orientation: 'horizontal',
          profile: profile + '_in',
        });
        timber.push({
          ...bars.in,
          sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
          length: height,
          quantity: self.georgianBars.vertical,
          orientation: 'vertical',
          profile: profile + '_in',
        });
        // OUT
        timber.push({
          ...bars.out,
          sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
          length: width,
          quantity: self.georgianBars.horizontal,
          orientation: 'horizontal',
          profile: profile + '_out',
        });
        timber.push({
          ...bars.out,
          sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
          length: height,
          quantity: self.georgianBars.vertical,
          orientation: 'vertical',
          profile: profile + '_out',
        });
      } else {
        timber.push({
          ...bars,
          sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
          length: width,
          quantity: self.georgianBars.horizontal,
          orientation: 'horizontal',
          profile: profile,
        });
        timber.push({
          ...bars,
          sectionID: self.sectionType === 'glazing' ? self.parent.id : self.id,
          length: height,
          quantity: self.georgianBars.vertical,
          orientation: 'vertical',
          profile: profile,
        });
      }

      return timber;
    },

    get isOnTopOfTransom() {
      return self.relativePosition.bottom === 'transom';
    },

    get normalizedDimensions() {
      return {
        ...self.dimensions,
        levelX: self.dimensions.levelX < 0 ? 0 : self.dimensions.levelX,
        levelY: self.dimensions.levelY < 0 ? 0 : self.dimensions.levelY,
      };
    },

    get horizontalBarRenderOrigins() {
      if (!this.hasBars) return [];

      const { horizontal } = self.georgianBars;
      const { height } = this.georgianBarsInternalSize;

      const horizontalPaneHeight =
        (height - self.store.stickOnBars.out.width * horizontal) / (horizontal + 1);

      const isFixed = self.sectionType === 'fixed';
      const isGlazing = self.sectionType === 'glazing';

      const barsOrigin = { x: 0, y: 0 };

      if (isFixed) {
        barsOrigin.y = self.store.frame_glazing_bead.width;
        barsOrigin.x = self.store.frame_glazing_bead.width;
      } else if (isGlazing) {
        barsOrigin.y = -self.store.sash_moulding.width;
        barsOrigin.x = -self.store.sash_moulding.width;
      } else {
        barsOrigin.y = this.bottomRailWidth - self.store.sash_moulding.width;
        barsOrigin.x = self.store.stiles.width - self.store.sash_moulding.width;
      }

      const horizontalBarOrigins = Array(horizontal)
        .fill(0)
        .map((_, index) => {
          let x: number, y: number;

          y =
            barsOrigin.y +
            horizontalPaneHeight * (index + 1) +
            index * self.store.stickOnBars.out.width;

          x = barsOrigin.x;

          return { x, y };
        });

      let newHorizontalBarOrigins = [];

      if (self.transomBalanceTarget) {
        const transomYPosition =
          self.transomBalanceTarget.absoluteOrigin.y +
          self.transomBalanceTarget.size.height / 2 -
          self.absoluteOrigin.y;

        const distToTransom = horizontalBarOrigins.map(({ y }, i) => [
          Math.abs(y - transomYPosition),
          i,
        ]);
        const anchorBarIndex = distToTransom.sort((a, b) => a[0] - b[0])[0][1];
        const barsAboveTransom = horizontalBarOrigins.length - (anchorBarIndex + 1);
        const barsBelowTransom = horizontalBarOrigins.length - 1 - barsAboveTransom;

        const heightBelowTransom =
          transomYPosition - barsOrigin.y - self.store.stickOnBars.out.width / 2;
        const heightAboveTransom = height - heightBelowTransom - self.store.stickOnBars.out.width;
        const horizontalPanelHeightBelowTransom =
          (heightBelowTransom - self.store.stickOnBars.out.width * barsBelowTransom) /
          (barsBelowTransom + 1);
        const horizontalPanelHeightAboveTransom =
          (heightAboveTransom - self.store.stickOnBars.out.width * barsAboveTransom) /
          (barsAboveTransom + 1);

        Array(barsBelowTransom)
          .fill(0)
          .map((_, index) => {
            let x, y;

            x = barsOrigin.x;

            y =
              barsOrigin.y +
              horizontalPanelHeightBelowTransom * (index + 1) +
              index * self.store.stickOnBars.out.width;

            newHorizontalBarOrigins.push({ x, y });
          });

        // Anchor bar
        newHorizontalBarOrigins.push({
          x: barsOrigin.x,
          y: transomYPosition - self.store.stickOnBars.out.width / 2,
        });

        Array(barsAboveTransom)
          .fill(0)
          .map((_, index) => {
            let x, y;

            x = barsOrigin.x;

            y =
              barsOrigin.y +
              heightBelowTransom +
              self.store.stickOnBars.out.width +
              horizontalPanelHeightAboveTransom * (index + 1) +
              index * self.store.stickOnBars.out.width;

            newHorizontalBarOrigins.push({ x, y });
          });
      }

      const lowerBound = self.absoluteOrigin.y;
      const upperBound = self.absoluteOrigin.y + self.size.height;

      function sectionBarOrigin(s) {
        const barsOrigin = { x: 0, y: 0 };

        if (s.sectionType === 'fixed') {
          barsOrigin.y = self.store.frame_glazing_bead.width;
          barsOrigin.x = self.store.frame_glazing_bead.width;
        } else if (s.sectionType === 'glazing') {
          barsOrigin.y = -self.store.sash_moulding.width;
          barsOrigin.x = -self.store.sash_moulding.width;
        } else {
          barsOrigin.y = s.bottomRailWidth - self.store.sash_moulding.width;
          barsOrigin.x = self.store.stiles.width - self.store.sash_moulding.width;
        }

        return barsOrigin;
      }

      function relativeBottomOffset(s) {
        if (s.sectionType === 'fixed') return 0;

        let bottomOffset =
          s.relativePosition.bottom === 'sill'
            ? s.store.sashHeightOffsetOnSill
            : s.store.sashHeightOffsetOnTransom;

        return s.sashGapBottom + bottomOffset;
      }

      function translateToLocalYCoordinate(y) {
        if (self.sectionType === 'glazing') {
          return (
            self.barsBalanceTarget.absoluteOrigin.y +
            relativeBottomOffset(self.barsBalanceTarget) +
            y -
            (self.absoluteOrigin.y +
              barsOrigin.y +
              // @ts-ignore
              self.bottomRailWidth)
          );
        }

        if (
          self.barsBalanceTarget.relativePosition.top === 'transom' &&
          self.relativePosition.top === 'transom'
        ) {
          return (
            self.barsBalanceTarget.absoluteOrigin.y +
            relativeBottomOffset(self.barsBalanceTarget) +
            y -
            (self.absoluteOrigin.y + relativeBottomOffset(self) + barsOrigin.y)
          );
        }

        if (
          self.barsBalanceTarget.relativePosition.bottom === 'transom' &&
          self.relativePosition.bottom !== 'transom'
        ) {
          return (
            self.barsBalanceTarget.absoluteOrigin.y +
            y -
            (self.absoluteOrigin.y + self.origin.y + barsOrigin.y)
          );
        }

        if (
          self.barsBalanceTarget.relativePosition.bottom === 'transom' &&
          self.relativePosition.bottom === 'transom'
        ) {
          return (
            self.barsBalanceTarget.absoluteOrigin.y + y - (self.absoluteOrigin.y + barsOrigin.y)
          );
        }

        if (
          ['leaf', 'dummy'].includes(self.sectionType) &&
          self.barsBalanceTarget.sectionType === 'fixed'
        ) {
          return (
            self.barsBalanceTarget.absoluteOrigin.y +
            self.barsBalanceTarget.origin.y +
            y -
            (self.absoluteOrigin.y + self.origin.y + barsOrigin.y)
          );
        } else {
          return (
            self.barsBalanceTarget.absoluteOrigin.y +
            self.barsBalanceTarget.origin.y +
            y -
            (self.absoluteOrigin.y + barsOrigin.y)
          );
        }
      }

      function withinBounds(y) {
        return y >= lowerBound && y <= upperBound;
      }

      if (self.barsBalanceTarget) {
        let targetAbsY = self.barsBalanceTarget.absoluteOrigin.y;
        let targetOriginY = self.barsBalanceTarget.origin.y;
        let targetBars = self.barsBalanceTarget.horizontalBarRenderOrigins;

        targetBars = targetBars.filter(({ y }) =>
          withinBounds(targetOriginY > 0 ? targetAbsY + targetOriginY + y : targetAbsY + y)
        );

        if (targetBars.length === horizontalBarOrigins.length) {
          targetBars.forEach(({ y }, i) => {
            newHorizontalBarOrigins.push({
              x: barsOrigin.x,
              y: translateToLocalYCoordinate(y) + barsOrigin.y,
            });
          });
        }
      }

      const finalBarOrigins =
        newHorizontalBarOrigins.length > 0 ? newHorizontalBarOrigins : horizontalBarOrigins;

      return finalBarOrigins;
    },
  }))
  .actions((self) => ({
    addSection(section) {
      self.sections = [...self.sections, section];
    },

    setCalculateAbsOrigin(func) {
      self.calculateAbsOrigin = func;
    },

    annotateVertically() {
      return self.dimensions.y;
    },

    annotateHorizontally() {
      return self.dimensions.x;
    },

    findSectionId(sectionId) {
      if (self.id === sectionId) return self;
      if (self.sections.length === 0) return null;

      let res = null;
      function recurse(sections, id) {
        for (let section of sections) {
          if (section.id === id) return (res = section);
          if (section.sections.length > 0) {
            res = recurse(section.sections, id);
            if (res) return res;
          }
        }
      }

      recurse(self.sections, sectionId);
      return res;
    },

    findSectionType(sectionType) {
      if (self.sectionType === sectionType) return self;
      if (self.sections.length === 0) return null;

      let res = null;
      function recurse(sections, type) {
        for (let section of sections) {
          if (section.sectionType === type) return (res = section);
          if (section.sections.length > 0) {
            res = recurse(section.sections, type);
            if (res) return res;
          }
        }
      }

      recurse(self.sections, sectionType);
      return res;
    },

    findSectionsType(sectionType) {
      if (self.sections.length === 0) return [];

      let res = [];
      function recurse(sections, type) {
        for (let section of sections) {
          if (section.sectionType === type) res.push(section);
          if (section.sections.length > 0) {
            recurse(section.sections, type);
          }
        }
      }

      recurse(self.sections, sectionType);
      return res;
    },

    findSectionsWithTransomBalanceTarget(transomID) {
      if (self.sections.length === 0) return [];
      const property = 'transomBalanceTarget';

      let res = [];
      function recurse(sections, target) {
        for (let section of sections) {
          if (section[property] && section[property].id === target) res.push(section);

          if (section.sections.length > 0) {
            recurse(section.sections, target);
          }
        }
      }

      recurse(self.sections, transomID);
      return res;
    },

    setOrigin({ x, y, skipAbsoluteOrigin = false, applyType = false }) {
      // Same a set section type
      let newX = x;
      let newY = y;
      if (applyType) {
        if (self.sectionType === 'leaf' || self.sectionType === 'dummy') {
          let bottomOffset =
            self.relativePosition.bottom === 'sill'
              ? self.store.sashHeightOffsetOnSill
              : self.store.sashHeightOffsetOnTransom;

          newX = x + self.sashGapLeft;
          newY = y + self.sashGapBottom + bottomOffset;
        }
      }

      if (!skipAbsoluteOrigin) {
        self.absoluteOrigin = self.calculateAbsOrigin(
          self.absoluteOrigin,
          { x: newX, y: newY },
          self.origin,
          self
        );
      }
      self.origin = { x: newX, y: newY };
    },

    setStickOnBars(stickOnBars) {
      self.usingStickOnBars = stickOnBars;
    },

    setAbsoluteOrigin({ x, y }) {
      self.absoluteOrigin = { x, y };
    },

    getParent() {
      if (!self.parent) return null;

      return self.store.sections[0].findSectionId(self.parent.id);
    },

    setSashGaps(sashGaps) {
      const prevX = self.sashGapX;
      const prevY = self.sashGapY;
      const prevLeft = self.sashGapLeft;
      const prevBottom = self.sashGapBottom;

      self.sash_gaps = { ...self.sash_gaps, ...sashGaps };

      const diffX = self.sashGapX - prevX;
      const diffY = self.sashGapY - prevY;
      const diffLeft = self.sashGapLeft - prevLeft;
      const diffBottom = self.sashGapBottom - prevBottom;

      this.setOrigin({
        x: self.origin.x + diffLeft,
        y: self.origin.y + diffBottom,
        skipAbsoluteOrigin: false,
      });
      this.setSectionSize({
        width: self.size.width - diffX,
        height: self.size.height - diffY,
      });

      if (self.muntinQuantity == 1) {
        this.removeMuntin();
        this.addMuntin();
        self.sections.forEach((section) => section.setGeorgianBars(self.georgianBars));
      }
    },

    setHasRainDrip(hasRainDrip) {
      self.hasRainDrip = hasRainDrip;
    },

    setDimensions({ x = false, y = false, levelX = 0, levelY = 0, yDisabled = false }) {
      if (x) self.store.setCurrentDimensionalLevel(levelX, 'x');
      if (y) self.store.setCurrentDimensionalLevel(levelY, 'y');

      self.dimensions = { x, y, levelX, levelY, yDisabled };
    },

    setSectionType(sectionType) {
      if (sectionType === 'mullion' || sectionType === 'transom') {
        self.splitVia = sectionType;
      }
      self.sectionType = sectionType;
    },

    setLeafStyle(leafStyle = 'standard') {
      self.leafStyle = leafStyle;
    },

    removeFalseMullion() {
      if (self.leafStyle !== 'frenchLeaf') return;
      const parent = self.parent;
      const ancestor = parent.parent;

      self.store.clearCurrentSection();

      if (ancestor) {
        parent.setSectionType('blank');
      } else {
        parent.setSectionType('root');
      }
      parent.setLeafStyle('standard');

      parent.sections.forEach((section) => parent.removeSection(section));
    },

    setFalseMullion(numberOfFalseMullions) {
      self.leafStyle = 'french';
      self.sectionType = 'node';

      this.addParameters({ falseMullions: numberOfFalseMullions });
      const perSectionWidth = self.size.width / (numberOfFalseMullions + 1);

      for (let i = 0; i <= numberOfFalseMullions; i++) {
        const x = perSectionWidth * i;

        this.addSection(
          Section.create({
            store: self.store,
            parent: self.id,
            sections: [],
            sectionType: 'blank',
            leafStyle: 'frenchLeaf',
            isHeadNeighbour: self.isHeadNeighbour,
            isLast: i === numberOfFalseMullions,
            origin: {
              x: x,
              y: 0,
            },
            absoluteOrigin: {
              x: self.absoluteOrigin.x + x,
              y: self.absoluteOrigin.y,
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
              };
            },
            size: {
              width: perSectionWidth,
              height: self.size.height,
            },
            parameters: self.parameters,
            dimensions: {
              x: true,
              y: false,
              levelY: self.dimensions.levelY + 1,
              levelX: self.dimensions.levelX,
            },
            id: uuid(),
          })
        );
      }

      self.store.clearCurrentSection();
    },

    setFalseTransom(transomDrop) {
      self.sectionType = 'node';
      let topLeaf = Section.create({
        store: self.store,
        parent: self.id,
        sections: [],
        sectionType: 'blank',
        leafStyle: 'falseTransomTop',
        isHeadNeighbour: self.isHeadNeighbour,
        transomDrop: transomDrop,
        origin: {
          x: 0,
          y: self.size.height - transomDrop,
        },
        absoluteOrigin: {
          x: self.absoluteOrigin.x,
          y: self.absoluteOrigin.y + (self.size.height - transomDrop),
        },
        calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
          return {
            x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
            y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
          };
        },
        size: {
          width: self.size.width,
          height: transomDrop,
        },
        parameters: self.parameters,
        dimensions: {
          x: false,
          y: true,
          levelY: self.dimensions.levelY,
          levelX: self.dimensions.levelX + 1,
        },
        id: uuid(),
      });
      let bottomLeaf = Section.create({
        store: self.store,
        parent: self.id,
        sections: [],
        sectionType: 'blank',
        isHeadNeighbour: false,
        origin: {
          x: 0,
          y: 0,
        },
        absoluteOrigin: {
          x: self.absoluteOrigin.x,
          y: self.absoluteOrigin.y,
        },
        calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
          return {
            x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
            y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
          };
        },
        size: {
          width: self.size.width,
          height: self.size.height - transomDrop,
        },
        parameters: self.parameters,
        dimensions: {
          x: false,
          y: true,
          levelY: self.dimensions.levelY,
          levelX: self.dimensions.levelX + 1,
        },
        id: uuid(),
      });
      this.addSection(topLeaf);
      this.addSection(bottomLeaf);

      self.store.clearCurrentSection();
    },

    addParameters(parameters) {
      self.parameters = { ...self.parameters, ...parameters };
    },

    setParameters(parameters) {
      self.parameters = parameters;
    },

    setSectionSize({ width, height }, applyType = false) {
      // To save time and spread of logic, caller can optionally state if we should
      // apply section type specific pre-calculations on the size e.g. sash gap for
      // leafs.
      let newWidth = width || self.size.width;
      let newHeight = height || self.size.height;

      if (applyType) {
        if (['leaf', 'dummy'].includes(self.sectionType)) {
          let bottomOffset =
            self.relativePosition.bottom === 'sill'
              ? self.store.sashHeightOffsetOnSill
              : self.store.sashHeightOffsetOnTransom;

          newWidth = width ? width - self.sashGapX : self.size.width;
          newHeight = height ? height - self.sashGapY - bottomOffset : self.size.height;
        }
        self.size = { width: newWidth, height: newHeight };
      } else {
        self.size = { width: newWidth, height: newHeight };
      }

      // Pass-along the size changes to children
      self.sections.forEach((section) => {
        if (self.sectionType === 'blank') {
          if (section.sectionType === 'transom') {
            section.setSectionSize({ width, height: section.size.height }, false);
          }
        }

        if (self.sectionType === 'transom') {
          if (['leaf', 'dummy', 'fixed'].includes(section.sectionType)) {
            section.setSectionSize({ width, height: null }, true);
          }
        }
      });
    },

    setTransomDrop(transomDrop) {
      self.transomDrop = transomDrop;
      self.store.rebalanceFrame('vertical');
    },

    setFalseMullionSectionSize(mullionSectionSizeWidth) {
      if (mullionSectionSizeWidth <= 0) {
        // @ts-ignore
        const { sectionWidthOverride, ...params } = self.parameters;
        this.setParameters(params);
      } else {
        this.addParameters({ sectionWidthOverride: mullionSectionSizeWidth });
      }
      this.rebalanceFalseMullions();
    },

    rebalanceFalseMullions() {
      const parent = self.parent;
      const numberOfFalseMullions = parent.parameters.falseMullions;
      const sectionWidthOverride = parent.parameters.sectionWidthOverride;
      const perSectionWidth = parent.size.width / (numberOfFalseMullions + 1);

      let widthUpToNow = 0;
      let widthRemaining = parent.size.width;
      for (let i = 0; i <= numberOfFalseMullions; i++) {
        const x = widthUpToNow;
        const sectionWidth =
          parent.sections[i].parameters.sectionWidthOverride ||
          widthRemaining / (numberOfFalseMullions + 1 - i);
        widthUpToNow += sectionWidth;
        widthRemaining -= sectionWidth;

        const origin = {
          x: x,
          y: 0,
          skipAbsoluteOrigin: true,
          applyType: true,
        };

        const absoluteOrigin = {
          x: parent.absoluteOrigin.x + x,
          y: parent.absoluteOrigin.y,
        };

        const size = {
          width: sectionWidth,
          height: parent.size.height,
        };

        parent.sections[i].setOrigin(origin);
        parent.sections[i].setAbsoluteOrigin(absoluteOrigin);
        parent.sections[i].setSectionSize(size, true);
      }
    },

    setMullionDistance(mullionDistance) {
      self.parameters = { ...self.parameters, mullionDistance };

      self.store.rebalanceFrame('horizontal');
    },

    setMidRail(midRailDrop) {
      self.hasMidRail = true;
      self.midRailDrop = midRailDrop;
    },

    removeMidRail() {
      self.hasMidRail = false;
      self.midRailDrop = 0;
    },

    addMuntin() {
      self.muntinQuantity = 1;

      const newSections = equalHorizontalSplitInternalSection(self, {
        splitCount: self.muntinQuantity,
        splitWidth: self.store.muntin.width,
        separatorType: 'muntin',
        separatorIncMoulding: true,
      });

      newSections.forEach((section) => {
        const newSection = Section.create({
          store: self.store,
          parent: self.id,
          sections: [],
          sectionType: section.sectionType,
          isHeadNeighbour: self.isHeadNeighbour,
          origin: section.origin,
          absoluteOrigin: section.absoluteOrigin,
          size: section.size,
          parameters: self.parameters,
          calculateAbsOrigin: (abs, newOrigin, oldOrigin, self) => ({
            x: self.store.visibleJambWidth,
            y: self.store.visibleSillHeight,
          }),
          id: uuid(),
        });
        this.addSection(newSection);
      });
    },

    removeMuntin() {
      self.muntinQuantity = 0;
      self.sections.forEach((section) => this.removeSection(section));
    },

    setLeafOpening(opening) {
      self.parameters = { ...self.parameters, leafOpening: opening };
    },

    setGeorgianBars(georgianBars) {
      if (!['leaf', 'dummy', 'fixed', 'glazing'].includes(self.sectionType)) return;

      self.georgianBars = georgianBars;
      self.hasBars = georgianBars.horizontal > 0 || georgianBars.vertical > 0;

      self.sections.forEach((section) => section.setGeorgianBars(georgianBars));
    },

    setTransomBalanceTarget(section) {
      // @ts-ignore
      if (self.withinVerticalBounds(section)) {
        self.transomBalanceTarget = section;
      }
    },

    removeTransomBalanceTarget() {
      self.transomBalanceTarget = undefined;
    },

    setBarsBalanceTarget(section) {
      if (!['dummy', 'fixed', 'leaf', 'glazing'].includes(self.sectionType)) return;

      if (!section.hasBars) {
        return false;
      }

      if (self.sections.length > 0) {
        self.sections.forEach((childSection) => childSection.setBarsBalanceTarget(section));
        return true;
      }

      self.transomBalanceTarget = undefined;

      // Avoid cyclic dependency
      if (section.barsBalanceTarget && section.barsBalanceTarget.id === self.id) {
        section.removeBarsBalanceTarget();
      }

      self.barsBalanceTarget = section;

      return true;
    },

    removeBarsBalanceTarget() {
      self.barsBalanceTarget = undefined;
    },

    /* Delete #delete */

    removeSection(section) {
      destroy(section);
    },

    async deleteSection() {
      self.store.deselectCurrentSection();
      const parent = self.parent;

      if (['transom'].includes(self.sectionType)) {
        parent.setDimensions({
          ...parent.dimensions,
          y: parent.dimensions.yDisabled ? true : false,
          x: true,
        });
      }

      if (['mullion'].includes(self.sectionType)) {
        parent.setDimensions({ ...parent.dimensions, x: true });
      }

      await parent.removeSection(self);

      if (parent.sections.length >= 1) {
        parent.sections[0].rebalanceSections();
      }
    },

    /* Rebalancers #balance */

    rebalanceSections() {
      const balancers = {
        transom: this.rebalanceTransoms2,
        mullion: this.rebalanceMullions2,
      };
      if (balancers[self.sectionType]) {
        balancers[self.sectionType]();
      }
    },

    transomSectionHeights() {
      if (self.sectionType !== 'transom') return;

      const siblingTransoms = self.parent.sections;
      const numberOfTransoms = siblingTransoms.length;

      let transomDrops = [];
      for (let i = numberOfTransoms - 1; i >= 0; i -= 1) {
        transomDrops.push(siblingTransoms[i].transomDrop);
      }

      transomDrops = transomDrops
        .sort((x, y) => {
          if (x > 0 && y > 0) {
            return y - x;
          }
          return x - y;
        })
        .reverse(); // Will be sorted into e.g. [0,750,300,...] then we flip it to get [...,300,750,0]

      const sectionHeights = [];

      let continuousTransoms = 0;
      let belowTransomVisHeight = 0;

      transomDrops.forEach((transomDrop, i) => {
        if (transomDrop <= 0) {
          continuousTransoms += 1;
        }

        if (transomDrop > 0) {
          let privVisHeight = 0;
          let aboveTransomVisHeight =
            self.parent.absoluteOrigin.y +
            self.parent.size.height -
            (self.store.finalSize.height - transomDrop) -
            self.store.visibleTransomWidth / 2;

          if (sectionHeights.length > 0) {
            const previousTransomHeight = sectionHeights.length * self.store.visibleTransomWidth;
            const previousHeight =
              sectionHeights.reduce((a, b) => a + b, 0) + previousTransomHeight;
            privVisHeight = previousHeight;
            aboveTransomVisHeight = aboveTransomVisHeight - previousHeight;
          }

          belowTransomVisHeight =
            self.parent.size.height -
            (privVisHeight + aboveTransomVisHeight) -
            self.store.visibleTransomWidth;
          const internalSectionHeight = aboveTransomVisHeight / (continuousTransoms + 1);

          range(continuousTransoms).forEach(() => {
            sectionHeights.push(internalSectionHeight);
          });
          sectionHeights.push(internalSectionHeight);
        }

        if (
          i === transomDrops.length - 1 &&
          continuousTransoms === 0 &&
          belowTransomVisHeight > 0
        ) {
          sectionHeights.push(belowTransomVisHeight);
        }

        if (
          i === transomDrops.length - 1 &&
          continuousTransoms !== 0 &&
          belowTransomVisHeight > 0
        ) {
          const internalSectionHeight =
            (belowTransomVisHeight - continuousTransoms * self.store.visibleTransomWidth) /
            (continuousTransoms + 1);
          range(continuousTransoms + 1).forEach(() => {
            sectionHeights.push(internalSectionHeight);
          });
        }

        if (
          i === transomDrops.length - 1 &&
          continuousTransoms !== 0 &&
          belowTransomVisHeight <= 0
        ) {
          const internalSectionHeight =
            (self.parent.size.height - continuousTransoms * self.store.visibleTransomWidth) /
            (continuousTransoms + 1);
          range(continuousTransoms).forEach(() => {
            sectionHeights.push(internalSectionHeight);
          });
          sectionHeights.push(internalSectionHeight);
        }

        if (i === transomDrops.length - 1 && belowTransomVisHeight !== 0) {
        }
      });

      return sectionHeights.map((height) => height);
    },

    rebalanceTransoms2() {
      if (self.sectionType !== 'transom') return;

      const sectionHeights = this.transomSectionHeights().reverse();

      const siblingTransoms = self.parent.sections;
      const numberOfTransoms = siblingTransoms.length;
      let sectionIndex = 0;
      let currentTotalHeight = 0;

      siblingTransoms.forEach((transom, i) => {
        let sectionHeight = sectionHeights[sectionIndex];
        currentTotalHeight += sectionHeight + self.store.visibleTransomWidth * i;

        const section = transom.sections[0];

        transom.setOrigin({
          x: transom.origin.x,
          y: currentTotalHeight,
          skipAbsoluteOrigin: true,
        });
        transom.setAbsoluteOrigin({
          x: transom.parent.absoluteOrigin.x,
          y: transom.parent.absoluteOrigin.y + currentTotalHeight,
        });

        let sectionWidth = section.parent.size.width;
        let bottomOffset = 0;
        if (['leaf', 'dummy'].includes(section.sectionType)) {
          bottomOffset =
            section.relativePosition.bottom === 'sill'
              ? self.store.sashHeightOffsetOnSill
              : self.store.sashHeightOffsetOnTransom;

          sectionHeight -= section.sashGapY + bottomOffset;
          sectionWidth -= section.sashGapX;
        }
        section.setSectionSize(
          {
            width: sectionWidth,
            height: sectionHeight,
          },
          false
        );
        section.setOrigin({
          x: section.origin.x,
          y: -sectionHeight - section.sashGapTop,
          skipAbsoluteOrigin: true,
          applyType: false,
        });

        section.setAbsoluteOrigin({
          x: transom.absoluteOrigin.x,
          y: transom.absoluteOrigin.y - sectionHeight,
        });

        sectionIndex += 1;

        // Last transom which will have two sections attached
        if (i === numberOfTransoms - 1) {
          let sectionHeight = sectionHeights[sectionIndex];

          if (transom.sections.length > 1) {
            const section = transom.sections[1];

            let sashHeightAdjustment = 0;
            if (['leaf', 'dummy'].includes(section.sectionType)) {
              let topBottomOffset =
                section.relativePosition.bottom === 'sill'
                  ? self.store.sashHeightOffsetOnSill
                  : self.store.sashHeightOffsetOnTransom;
              sectionHeight -= section.sashGapY + topBottomOffset;
              sashHeightAdjustment = section.sashGapBottom + topBottomOffset;
            }
            section.setSectionSize(
              {
                width: sectionWidth,
                height: sectionHeight,
              },
              false
            );
            section.setOrigin({
              x: section.origin.x,
              y: self.store.visibleTransomWidth + sashHeightAdjustment,
              skipAbsoluteOrigin: true,
              applyType: false,
            });

            section.setAbsoluteOrigin({
              x: transom.absoluteOrigin.x,
              y: transom.absoluteOrigin.y + self.store.visibleTransomWidth,
            });
          } else {
            // We know now that we need to add a section as the top transom was deleted
            let blankPanel = Section.create({
              store: self.store,
              parent: transom.id,
              sections: [],
              sectionType: 'blank',
              isHeadNeighbour: transom.isHeadNeighbour,
              origin: {
                x: section.origin.x,
                y: self.store.visibleTransomWidth,
              },
              absoluteOrigin: {
                x: transom.absoluteOrigin.x,
                y: transom.absoluteOrigin.y + self.store.visibleTransomWidth,
              },
              calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
                return {
                  x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                  y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
                };
              },
              size: {
                width: sectionWidth,
                height: sectionHeight,
              },
              parameters: transom.parameters,
              dimensions: {
                x: false,
                y: true,
                levelX: transom.dimensions.levelX,
                levelY: self.dimensions.levelY,
              },
              id: uuid(),
            });

            transom.addSection(blankPanel);
          }
        }
      });
    },

    rebalanceMullions2() {
      if (self.sectionType !== 'mullion') return;

      // Get all sibling mullion sections
      const siblingMullions = self.parent.sections;

      // No sibling were found so nothing to rebalance
      if (siblingMullions.length === 0) return;

      // 1. Recalculate the mullion section disatances and take into account any mullion distances
      const numberOfMullions = siblingMullions.length;
      const totalMullionWidth = self.store.visibleMullionWidth * numberOfMullions;
      const totalVisibleWidthPerMullionSection =
        (self.parent.size.width - totalMullionWidth) / (numberOfMullions + 1);

      let carryOverSectionWidth = 0;
      const mullionDistribution = [];
      siblingMullions.forEach((mullion, i) => {
        const config = {};
        let leftSectionWidth = totalVisibleWidthPerMullionSection;

        // 2. Set the mullion section sizes
        mullion.setSectionSize({
          width: mullion.size.width,
          height: mullion.parent.size.height,
        });

        if (mullion.parameters.mullionDistance) {
          carryOverSectionWidth =
            totalVisibleWidthPerMullionSection - mullion.parameters.mullionDistance;
          leftSectionWidth = mullion.parameters.mullionDistance;
        }

        if (i === 0) {
          config['mullionOrigin'] = {
            x: leftSectionWidth,
            y: mullion.origin.y,
          };

          config['mullionAbsoluteOrigin'] = {
            x: mullion.parent.absoluteOrigin.x + leftSectionWidth,
            y: mullion.absoluteOrigin.y,
          };

          config['leftSection'] = {
            size: {
              width: leftSectionWidth,
              height: mullion.parent.size.height,
            },
            origin: {
              x: -leftSectionWidth,
              y: mullion.origin.y,
            },
            absoluteOrigin: {
              x: config['mullionAbsoluteOrigin'].x + -leftSectionWidth,
              y: mullion.absoluteOrigin.y,
            },
          };
        }

        if (i !== 0) {
          const previousConfig = mullionDistribution[i - 1];
          const sectionWidth = leftSectionWidth + carryOverSectionWidth;
          config['mullionOrigin'] = {
            x: previousConfig['mullionOrigin'].x + sectionWidth + mullion.size.width,
            y: mullion.origin.y,
          };

          config['mullionAbsoluteOrigin'] = {
            x: previousConfig['mullionAbsoluteOrigin'].x + sectionWidth + mullion.size.width,
            y: mullion.absoluteOrigin.y,
          };

          config['leftSection'] = {
            size: {
              width: sectionWidth,
              height: mullion.parent.size.height,
            },
            origin: {
              x: -sectionWidth,
              y: mullion.origin.y,
            },
            absoluteOrigin: {
              x: previousConfig['mullionAbsoluteOrigin'].x + mullion.size.width,
              y: mullion.absoluteOrigin.y,
            },
          };

          carryOverSectionWidth = 0;
        }

        if (i === numberOfMullions - 1) {
          config['rightSection'] = {
            size: {
              width: totalVisibleWidthPerMullionSection + carryOverSectionWidth,
              height: mullion.parent.size.height,
            },
            origin: {
              x: mullion.size.width,
              y: mullion.origin.y,
            },
            absoluteOrigin: {
              x: config['mullionAbsoluteOrigin'].x + mullion.size.width,
              y: mullion.absoluteOrigin.y,
            },
          };
        }

        mullionDistribution.push(config);
      });

      // 2. Update the mullion sections with the computed configs
      mullionDistribution.forEach((config, i) => {
        const leftSection = siblingMullions[i].sections[0];

        leftSection.setSectionSize(config.leftSection.size, true);
        leftSection.setOrigin({
          ...config.leftSection.origin,
          skipAbsoluteOrigin: true,
          applyType: true,
        });
        leftSection.setAbsoluteOrigin(config.leftSection.absoluteOrigin);

        siblingMullions[i].setOrigin({
          ...config.mullionOrigin,
          skipAbsoluteOrigin: true,
          applyType: true,
        });
        siblingMullions[i].setAbsoluteOrigin(config.mullionAbsoluteOrigin);

        if (i === numberOfMullions - 1) {
          // Check if we are missing a section
          if (siblingMullions[i].sections.length === 1) {
            let blankPanel = Section.create({
              store: self.store,
              parent: siblingMullions[i].id,
              sections: [],
              sectionType: 'blank',
              isHeadNeighbour: self.isHeadNeighbour,
              origin: config.rightSection.origin,
              absoluteOrigin: {
                x: siblingMullions[i].absoluteOrigin.x + self.store.visibleMullionWidth,
                y: siblingMullions[i].absoluteOrigin.y,
              },
              calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
                return {
                  x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                  y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
                };
              },
              size: config.rightSection.size,
              parameters: self.parameters,
              dimensions: {
                x: true,
                y: false,
                levelY: siblingMullions[i].dimensions.levelY,
                levelX: self.dimensions.levelX,
              },
              id: uuid(),
            });

            siblingMullions[i].addSection(blankPanel);
          } else {
            const rightSection = siblingMullions[i].sections[1];
            rightSection.setSectionSize(config.rightSection.size, true);
            rightSection.setOrigin({
              ...config.rightSection.origin,
              skipAbsoluteOrigin: true,
              applyType: true,
            });
            rightSection.setAbsoluteOrigin(config.rightSection.absoluteOrigin);
          }
        }
      });
    },

    /* Type switches #types */

    makeLeaf() {
      let bottomOffset =
        self.relativePosition.bottom === 'sill'
          ? self.store.sashHeightOffsetOnSill
          : self.store.sashHeightOffsetOnTransom;

      self.sectionType = 'leaf';
      this.setOrigin({
        x: self.origin.x + self.sashGapLeft,
        y: self.origin.y + self.sashGapBottom + bottomOffset,
        skipAbsoluteOrigin: false,
      });
      this.setSectionSize({
        width: self.size.width - self.sashGapX,
        height: self.size.height - self.sashGapY - bottomOffset,
      });
    },

    makeFixed() {
      self.sectionType = 'fixed';
    },

    makeDummy() {
      let bottomOffset =
        self.relativePosition.bottom === 'sill'
          ? self.store.sashHeightOffsetOnSill
          : self.store.sashHeightOffsetOnTransom;

      self.sectionType = 'dummy';
      this.setOrigin({
        x: self.origin.x + self.sashGapLeft,
        y: self.origin.y + self.sashGapBottom + bottomOffset,
      });
      this.setSectionSize({
        width: self.size.width - self.sashGapX,
        height: self.size.height - self.sashGapY - bottomOffset,
      });
    },

    clearType() {
      if (['dummy', 'leaf'].includes(self.sectionType)) {
        let bottomOffset =
          self.relativePosition.bottom === 'sill'
            ? self.store.sashHeightOffsetOnSill
            : self.store.sashHeightOffsetOnTransom;

        this.setOrigin({
          x: self.origin.x - self.sashGapLeft,
          y: self.origin.y - self.sashGapBottom - bottomOffset,
        });
        this.setSectionSize({
          width: self.size.width + self.sashGapX,
          height: self.size.height + self.sashGapY + bottomOffset,
        });
      }
      this.setGeorgianBars({ horizontal: 0, vertical: 0 });
      self.usingStickOnBars = true;

      self.sash_gaps = { top: null, left: null, bottom: null, right: null };

      // In case it had a muntin
      this.removeMuntin();

      if (!self.parent) {
        self.sectionType = 'root';
      } else {
        self.sectionType = 'blank';
      }
      // @ts-ignore
      self.parameters = {};
    },

    splitViaTransom(numberOfTransoms) {
      let totalTransomWidth = self.store.visibleTransomWidth * numberOfTransoms;
      let totalVisibleWidthPerTransom =
        (self.size.height - totalTransomWidth) / (numberOfTransoms + 1);

      const enableDimension = self.parent && self.parent.sectionType !== 'transom';
      let dimensionLevel = self.normalizedDimensions.levelX;

      if (!enableDimension) {
        this.setDimensions({ ...self.dimensions, y: false, yDisabled: true });
      }

      // Disable parent dimension annotation as we will handle it here
      this.setDimensions({ ...self.dimensions, x: false });

      range(numberOfTransoms).forEach((i, index) => {
        let transom;

        transom = Section.create({
          store: self.store,
          parent: self.id,
          sections: [],
          splitVia: 'transom',
          sectionType: 'transom',
          origin: {
            x: 0,
            y: totalVisibleWidthPerTransom * (index + 1) + self.store.visibleTransomWidth * index,
          },
          absoluteOrigin: {
            x: self.absoluteOrigin.x + 0,
            y:
              self.absoluteOrigin.y +
              totalVisibleWidthPerTransom * (index + 1) +
              self.store.visibleTransomWidth * index,
          },
          calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
            return {
              x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
              y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
            };
          },
          size: {
            width: self.size.width,
            height: self.store.visibleTransomWidth,
          },
          parameters: self.parameters,
          dimensions: {
            x: false,
            y: true,
            levelX: dimensionLevel,
            levelY: self.normalizedDimensions.levelY,
          },
          id: uuid(),
        });

        this.addSection(transom);

        if (index === 0) {
          let bottomPanel = Section.create({
            store: self.store,
            parent: transom.id,
            sections: [],
            sectionType: 'blank',
            origin: {
              x: 0,
              y: -(totalVisibleWidthPerTransom * (index + 1)),
            },
            absoluteOrigin: {
              x: transom.absoluteOrigin.x + 0,
              y: transom.absoluteOrigin.y - totalVisibleWidthPerTransom * (index + 1),
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
              };
            },
            size: {
              width: self.size.width,
              height: totalVisibleWidthPerTransom,
            },
            parameters: self.parameters,
            id: uuid(),
          });

          transom.addSection(bottomPanel);

          bottomPanel.setDimensions({
            x: false,
            y: true,
            levelX: transom.dimensions.levelX,
            levelY: self.normalizedDimensions.levelY + 1,
          });
        } else {
          let bottomPanel = Section.create({
            store: self.store,
            parent: transom.id,
            sections: [],
            sectionType: 'blank',
            origin: {
              x: 0,
              y: -totalVisibleWidthPerTransom,
            },
            absoluteOrigin: {
              x: transom.absoluteOrigin.x + 0,
              y: transom.absoluteOrigin.y - totalVisibleWidthPerTransom,
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
              };
            },
            size: {
              width: self.size.width,
              height: totalVisibleWidthPerTransom,
            },
            parameters: self.parameters,
            id: uuid(),
          });

          transom.addSection(bottomPanel);

          bottomPanel.setDimensions({
            x: false,
            y: true,
            levelX: transom.dimensions.levelX,
            levelY: self.normalizedDimensions.levelY + i + 1,
          });
        }
        if (index + 1 === numberOfTransoms) {
          let topPanel = Section.create({
            store: self.store,
            parent: transom.id,
            sections: [],
            sectionType: 'blank',
            isHeadNeighbour: self.isHeadNeighbour,
            origin: {
              x: 0,
              y: self.store.visibleTransomWidth,
            },
            absoluteOrigin: {
              x: transom.absoluteOrigin.x,
              y: transom.absoluteOrigin.y + self.store.visibleTransomWidth,
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
              };
            },
            size: {
              width: self.size.width,
              height: totalVisibleWidthPerTransom,
            },
            parameters: self.parameters,
            id: uuid(),
          });

          transom.addSection(topPanel);

          topPanel.setDimensions({
            x: true,
            y: true,
            levelX: transom.dimensions.levelX,
            levelY: self.normalizedDimensions.levelY,
          });
        }
      });

      self.store.clearCurrentSection();
      self.store.sections[0].computeRelativePosition();
    },

    computeRelativePosition() {
      if (
        !['blank', 'node', 'leaf', 'fixed', 'dummy', 'mullion', 'transom', 'root'].includes(
          self.sectionType
        )
      )
        return;

      let sectionType = null;
      let multipleChildren = self.sections.length > 1;

      self.sections.forEach((section, i) => {
        sectionType = section.sectionType;

        if (section.sectionType !== 'mullion' && section.sectionType !== 'transom') {
          section.setRelativePosition({});
        }

        // MULLION
        if (section.sectionType === 'mullion') {
          if (multipleChildren && i === 0) {
            section.setRelativePosition({ right: sectionType });
          } else if (multipleChildren && i !== self.sections.length - 1) {
            section.setRelativePosition({ left: sectionType, right: sectionType });
          } else if (multipleChildren && i === self.sections.length - 1) {
            section.setRelativePosition({ left: sectionType });
          } else {
            section.setRelativePosition({});
          }
        }

        if (self.sectionType === 'mullion') {
          if (i === 0) {
            section.setRelativePosition({ right: 'mullion' });
          } else if (i === 1) {
            section.setRelativePosition({ left: 'mullion' });
          }
        }

        // TRANSOM
        if (section.sectionType === 'transom') {
          if (multipleChildren && i === 0) {
            section.setRelativePosition({ top: sectionType });
          } else if (multipleChildren && i !== self.sections.length - 1) {
            section.setRelativePosition({ top: sectionType, bottom: sectionType });
          } else if (multipleChildren && i === self.sections.length - 1) {
            section.setRelativePosition({ bottom: sectionType });
          } else {
            section.setRelativePosition({});
          }
        }

        if (self.sectionType === 'transom') {
          if (i === 0) {
            section.setRelativePosition({ top: 'transom' });
          } else if (i === 1) {
            section.setRelativePosition({ bottom: 'transom' });
          }
        }

        section.computeRelativePosition();
      });
    },

    setRelativePosition(positions) {
      let def = self.parent.relativePosition;

      self.relativePosition = { ...def, ...positions };
    },

    splitViaMullion(numberOfMullions) {
      let totalMullionsWidth = self.store.visibleMullionWidth * numberOfMullions;
      let totalVisibleWidthPerMullion =
        (self.size.width - totalMullionsWidth) / (numberOfMullions + 1);

      const enableDimension = self.parent && self.parent.sectionType !== 'mullion';
      let dimensionLevel = self.normalizedDimensions.levelY;

      if (!['root', 'transom'].includes(self.sectionType)) {
        dimensionLevel = enableDimension
          ? self.normalizedDimensions.levelY
          : self.normalizedDimensions.levelY;
      }

      // if (!enableDimension) {
      this.setDimensions({ ...self.normalizedDimensions, x: false });
      // }

      range(numberOfMullions).forEach(async (i, index) => {
        let mullion;

        mullion = Section.create({
          store: self.store,
          parent: self.id,
          sections: [],
          splitVia: 'mullion',
          sectionType: 'mullion',
          isHeadNeighbour: self.isHeadNeighbour,
          origin: {
            x: totalVisibleWidthPerMullion * (index + 1) + self.store.visibleMullionWidth * index,
            y: 0,
          },
          absoluteOrigin: {
            x:
              self.absoluteOrigin.x +
              totalVisibleWidthPerMullion * (index + 1) +
              self.store.visibleMullionWidth * index,
            y: self.absoluteOrigin.y,
          },
          calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
            return {
              x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
              y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
            };
          },
          size: {
            width: self.store.visibleMullionWidth,
            height: self.size.height,
          },
          parameters: self.parameters,
          dimensions: {
            x: true,
            y: false,
            levelY: dimensionLevel,
            levelX: self.normalizedDimensions.levelX,
          },
          id: uuid(),
        });

        // Add first to root
        this.addSection(mullion);

        if (index === 0) {
          let leftPanel = Section.create({
            store: self.store.id,
            parent: mullion.id,
            sections: [],
            sectionType: 'blank',
            isHeadNeighbour: self.isHeadNeighbour,
            origin: {
              x: -(totalVisibleWidthPerMullion * (index + 1)),
              y: 0,
            },
            absoluteOrigin: {
              x: mullion.absoluteOrigin.x - totalVisibleWidthPerMullion * (index + 1),
              y: mullion.absoluteOrigin.y + 0,
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x,
                y: currentAbsOrigin.y,
              };
            },
            size: {
              width: totalVisibleWidthPerMullion,
              height: self.size.height,
            },
            parameters: self.parameters,
            id: uuid(),
          });

          await mullion.addSection(leftPanel);

          leftPanel.setDimensions({
            x: true,
            y: false,
            levelY: mullion.dimensions.levelY,
            levelX: mullion.normalizedDimensions.levelX + 1,
          });
        } else {
          let leftPanel = Section.create({
            store: self.store,
            parent: mullion.id,
            sections: [],
            sectionType: 'blank',
            isHeadNeighbour: self.isHeadNeighbour,
            origin: {
              x: -totalVisibleWidthPerMullion,
              y: 0,
            },
            absoluteOrigin: {
              x: mullion.absoluteOrigin.x - totalVisibleWidthPerMullion,
              y: mullion.absoluteOrigin.y + 0,
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
              };
            },
            size: {
              width: totalVisibleWidthPerMullion,
              height: self.size.height,
            },
            parameters: self.parameters,
            id: uuid(),
          });

          await mullion.addSection(leftPanel);

          leftPanel.setDimensions({
            x: true,
            y: false,
            levelY: mullion.dimensions.levelY,
            levelX: mullion.normalizedDimensions.levelX + i + 1,
          });
        }
        if (index + 1 === numberOfMullions) {
          let rightPanel = Section.create({
            store: self.store,
            parent: mullion.id,
            sections: [],
            sectionType: 'blank',
            isHeadNeighbour: self.isHeadNeighbour,
            origin: {
              x: self.store.visibleMullionWidth,
              y: 0,
            },
            absoluteOrigin: {
              x: mullion.absoluteOrigin.x + self.store.visibleMullionWidth,
              y: mullion.absoluteOrigin.y + 0,
            },
            calculateAbsOrigin: (currentAbsOrigin, newOrigin, oldOrigin) => {
              return {
                x: currentAbsOrigin.x + (newOrigin.x - oldOrigin.x),
                y: currentAbsOrigin.y + (newOrigin.y - oldOrigin.y),
              };
            },
            size: {
              width: totalVisibleWidthPerMullion,
              height: self.size.height,
            },
            parameters: self.parameters,
            id: uuid(),
          });

          await mullion.addSection(rightPanel);

          rightPanel.setDimensions({
            x: true,
            y: false,
            levelY: mullion.dimensions.levelY,
            levelX: mullion.dimensions.levelX,
          });
        }
      });

      self.store.clearCurrentSection();
      this.computeRelativePosition();
    },

    withinVerticalBounds(section) {
      const lowerBound = self.absoluteOrigin.y;
      const upperBound = self.absoluteOrigin.y + self.size.height;

      return section.absoluteOrigin.y >= lowerBound && section.absoluteOrigin.y <= upperBound;
    },

    barOrigins(forBalancing = false) {
      if (!self.hasBars) return [];
      if (self.sections.length > 0 && !forBalancing) return [];

      const glassSizes = self.glassSize;

      let barOrigins = [];

      const { horizontal, vertical } = self.georgianBars;

      glassSizes.forEach(({ width, height }, i) => {
        let section = self;
        if (self.sections.length > 0) {
          section = self.sections[i];
        }

        const visWidth = section.visualOpeningSize.width;
        const visHeight = section.visualOpeningSize.height;

        const isFixed = section.sectionType === 'fixed';
        const isGlazing = section.sectionType === 'glazing';

        let relativeYOffset: number;

        if (isFixed) {
          relativeYOffset = section.store.frame_glazing_bead.width;
        } else if (isGlazing) {
          relativeYOffset = 0;
        } else {
          relativeYOffset = section.bottomRailWidth;
        }

        const barsOrigin = { x: width / 2 - visWidth / 2, y: height / 2 - visHeight / 2 };

        const horizontalPaneHeight =
          (visHeight - section.store.stickOnBars.out.width * horizontal) / (horizontal + 1);
        const horizontalPaneWidth =
          (visWidth - section.store.stickOnBars.out.width * vertical) / (vertical + 1);

        const horizontalBarOrigins = Array(horizontal)
          .fill(0)
          .map((_, index) => {
            let x: number, y: number;

            y =
              barsOrigin.y +
              horizontalPaneHeight * (index + 1) +
              index * section.store.stickOnBars.out.width;
            x = 0;

            return { x, y };
          });

        let newHorizontalBarOrigins = [];

        if (section.transomBalanceTarget) {
          const transomYPosition =
            section.transomBalanceTarget.absoluteOrigin.y +
            section.transomBalanceTarget.size.height / 2 -
            (section.absoluteOrigin.y + relativeYOffset);

          const distToTransom = horizontalBarOrigins.map(({ y }, i) => [
            Math.abs(y - transomYPosition),
            i,
          ]);
          const anchorBarIndex = distToTransom.sort((a, b) => a[0] - b[0])[0][1];
          const barsAboveTransom = horizontalBarOrigins.length - (anchorBarIndex + 1);
          const barsBelowTransom = horizontalBarOrigins.length - 1 - barsAboveTransom;

          const heightBelowTransom =
            transomYPosition - barsOrigin.y - section.store.stickOnBars.out.width / 2;
          const heightAboveTransom =
            visHeight - heightBelowTransom - section.store.stickOnBars.out.width;
          const horizontalPanelHeightBelowTransom =
            (heightBelowTransom - section.store.stickOnBars.out.width * barsBelowTransom) /
            (barsBelowTransom + 1);
          const horizontalPanelHeightAboveTransom =
            (heightAboveTransom - section.store.stickOnBars.out.width * barsAboveTransom) /
            (barsAboveTransom + 1);

          Array(barsBelowTransom)
            .fill(0)
            .map((_, index) => {
              let x, y;

              x = 0;

              y =
                barsOrigin.y +
                horizontalPanelHeightBelowTransom * (index + 1) +
                index * section.store.stickOnBars.out.width;

              newHorizontalBarOrigins.push({ x, y });
            });

          // Anchor bar
          newHorizontalBarOrigins.push({
            x: barsOrigin.x,
            y: transomYPosition - section.store.stickOnBars.out.width / 2,
          });

          Array(barsAboveTransom)
            .fill(0)
            .map((_, index) => {
              let x, y;

              x = 0;

              y =
                barsOrigin.y +
                heightBelowTransom +
                section.store.stickOnBars.out.width +
                horizontalPanelHeightAboveTransom * (index + 1) +
                index * section.store.stickOnBars.out.width;

              newHorizontalBarOrigins.push({ x, y });
            });
        }

        const lowerBound = section.absoluteOrigin.y;
        const upperBound = section.absoluteOrigin.y + section.size.height;

        function withinBounds(y) {
          return y >= lowerBound && y <= upperBound;
        }

        function relativeBottomOffset(s) {
          if (s.sectionType === 'fixed') return 0;

          let bottomOffset =
            s.relativePosition.bottom === 'sill'
              ? s.store.sashHeightOffsetOnSill
              : s.store.sashHeightOffsetOnTransom;

          return s.sashGapBottom + bottomOffset;
        }

        function translateToLocalYCoordinate(y) {
          if (section.sectionType === 'glazing') {
            return (
              section.barsBalanceTarget.absoluteOrigin.y +
              relativeBottomOffset(section.barsBalanceTarget) +
              y -
              (section.absoluteOrigin.y +
                barsOrigin.y +
                // @ts-ignore
                section.bottomRailWidth)
            );
          }

          if (
            section.barsBalanceTarget.relativePosition.top === 'transom' &&
            section.relativePosition.top === 'transom'
          ) {
            return (
              section.barsBalanceTarget.absoluteOrigin.y +
              relativeBottomOffset(section.barsBalanceTarget) +
              y -
              (section.absoluteOrigin.y + relativeBottomOffset(self) + barsOrigin.y)
            );
          }

          if (
            section.barsBalanceTarget.relativePosition.bottom === 'transom' &&
            section.relativePosition.bottom !== 'transom'
          ) {
            return (
              section.barsBalanceTarget.absoluteOrigin.y +
              y -
              (section.absoluteOrigin.y + section.origin.y + barsOrigin.y)
            );
          }

          if (
            section.barsBalanceTarget.relativePosition.bottom === 'transom' &&
            section.relativePosition.bottom === 'transom'
          ) {
            return (
              section.barsBalanceTarget.absoluteOrigin.y +
              y -
              (section.absoluteOrigin.y + barsOrigin.y)
            );
          }

          if (
            ['leaf', 'dummy'].includes(section.sectionType) &&
            section.barsBalanceTarget.sectionType === 'fixed'
          ) {
            return (
              section.barsBalanceTarget.absoluteOrigin.y +
              section.barsBalanceTarget.origin.y +
              y -
              (section.absoluteOrigin.y + section.origin.y + barsOrigin.y)
            );
          } else {
            return (
              section.barsBalanceTarget.absoluteOrigin.y +
              section.barsBalanceTarget.origin.y +
              y -
              (section.absoluteOrigin.y + barsOrigin.y)
            );
          }
        }

        if (section.barsBalanceTarget && section.barsBalanceTarget.barOrigins(true)[i]) {
          let targetAbsY = section.barsBalanceTarget.absoluteOrigin.y;
          let targetOriginY = section.barsBalanceTarget.origin.y;
          let targetBars = section.barsBalanceTarget.barOrigins(true)[i].horizontal;

          targetBars = targetBars.filter(({ y }) =>
            withinBounds(targetOriginY > 0 ? targetAbsY + targetOriginY + y : targetAbsY + y)
          );

          if (targetBars.length === horizontalBarOrigins.length) {
            targetBars.forEach(({ y }, i) => {
              newHorizontalBarOrigins.push({
                x: 0,
                y: translateToLocalYCoordinate(y) + barsOrigin.y,
              });
            });
          }
        }

        const horBarOrigins =
          newHorizontalBarOrigins.length > 0 ? newHorizontalBarOrigins : horizontalBarOrigins;

        const verticalBars = Array(vertical)
          .fill(0)
          .map((_, index) => {
            let x, y;

            if (isFixed) {
              x =
                barsOrigin.x +
                (index + 1) * horizontalPaneWidth +
                index * section.store.stickOnBars.out.width;
              y = 0;
            } else if (isGlazing) {
              x = (index + 1) * horizontalPaneWidth + index * section.store.stickOnBars.out.width;
              y = 0;
            } else {
              x =
                barsOrigin.x +
                (index + 1) * horizontalPaneWidth +
                index * section.store.stickOnBars.out.width;
              y = 0;
            }

            return { x, y };
          });

        barOrigins.push({
          vertical: verticalBars,
          horizontal: horBarOrigins,
        });
      });

      return barOrigins;
    },
  }));

export interface ISection extends Instance<typeof Section> {}

export function createSection(casementStore, data?: ISection): ISection {
  let defaultData;

  if (!data || Object.entries(data).length === 0) {
    defaultData = {
      store: casementStore,
      sectionType: 'root',
      sections: [],
      isHeadNeighbour: true,
      origin: {
        x: 0,
        y: 0,
      },
      absoluteOrigin: {
        x: casementStore.visibleJambWidth,
        y: casementStore.visibleSillHeight,
      },
      size: {
        width: casementStore.visiualOpeningSizes.width,
        height: casementStore.visiualOpeningSizes.height,
      },
      calculateAbsOrigin: (abs, newOrigin, oldOrigin, self) => ({
        x: self.store.visibleJambWidth,
        y: self.store.visibleSillHeight,
      }),
      id: uuid(),
    };
  } else {
    defaultData = data;
  }

  return Section.create(defaultData);
}

export default Section;
