import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const activeCss = css`
  &.active {
    ${tw`text-deep-purple-M100`}
    ${tw`bg-deep-purple-M500`}
  }
`;

const IconWrapper = styled.span`
  ${tw`inline-flex items-center px-2 py-2 ml-2 mr-1 border-solid border-transparent rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-M900`}
  ${tw`text-deep-purple-M100`}
  ${tw`hover:bg-deep-purple-M500`}
  ${tw`border-none`}
`;

const Item = ({ url, icon, title, badge, items }) => {
  const [hidden, setHidden] = useState(true);
  let location = useLocation();
  let { pathname } = { ...location };
  let active = pathname === url ? true : false;
  if (pathname === '/' && url === '/dashboard') {
    active = true;
  }
  if (pathname === '/' && url !== '/dashboard') {
    active = false;
  }
  if (items.length === 0) {
    return (
      <a href={url} to={url} className={`left-sidebar-item ${active ? 'active' : ''}`}>
        {icon && (
          <IconWrapper css={[activeCss]} className={`${active ? 'active' : ''}`}>
            {icon}
          </IconWrapper>
        )}
        <span className="title">{title}</span>
      </a>
    );
  }
  return (
    <button
      onClick={() => setHidden(!hidden)}
      className={`left-sidebar-item ${active ? 'active' : ''} ${
        hidden ? 'hidden-sibling' : 'open-sibling'
      }`}
    >
      {icon && (
        <IconWrapper css={[activeCss]} className={`${active ? 'active' : ''}`}>
          {icon}
        </IconWrapper>
      )}
      <span className="title">{title}</span>
      <FiChevronRight className="ml-auto arrow" />
    </button>
  );
};

export default Item;
