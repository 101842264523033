import { clone } from 'mobx-state-tree';
import React from 'react';
import { Rect } from 'react-konva';

import SashMoulding from './SashMoulding';
import SubSection from './SubSection';

const StandardSash = ({ section, ...shapeProps }) => {
  const bottomRailWidth =
    section.leafStyle === 'falseTransomTop'
      ? section.store['stiles'].width
      : section.bottomRailWidth;
  const stileHeight = section.size.height;
  const topRailY = stileHeight - section.store['top_rail'].width;
  const stileY = bottomRailWidth;
  const midRailY =
    section.midRailDrop > 0 && section.hasMidRail
      ? section.size.height - (section.midRailDrop + section.store['mid_rail'].width / 2)
      : section.size.height / 2;

  shapeProps = { ...shapeProps, fill: 'white' };

  return (
    <>
      <SashMoulding section={section} />
      {/* Muntin */}
      {section.sections.map((subSection) => (
        <SubSection section={subSection} shapeProps={shapeProps} key={subSection.id} />
      ))}

      {/* Mid rail */}
      {section.hasMidRail && (
        <Rect
          {...shapeProps}
          x={section.store.stileWidth(false)}
          y={midRailY}
          width={section.size.width - section.store.stileWidth(false) * 2}
          height={section.store['mid_rail'].width}
        />
      )}
      {/* Top Rail */}
      <Rect
        {...shapeProps}
        x={0}
        y={topRailY + section.store['sash_moulding'].width}
        width={section.size.width}
        height={section.store['top_rail'].width - section.store['sash_moulding'].width}
      />
      {/* Bottom rail */}
      <Rect
        {...shapeProps}
        x={0}
        y={0}
        width={section.size.width}
        height={bottomRailWidth - section.store['sash_moulding'].width}
      />
      {/* Left Stile */}
      <Rect
        {...shapeProps}
        x={0}
        y={0}
        width={section.store['stiles'].width - section.store['sash_moulding'].width}
        height={stileHeight}
      />
      {/* Right Stile */}
      <Rect
        {...shapeProps}
        x={
          section.size.width - section.store['stiles'].width + section.store['sash_moulding'].width
        }
        y={0}
        width={section.store['stiles'].width - section.store['sash_moulding'].width}
        height={stileHeight}
      />
    </>
  );
};

export default StandardSash;
