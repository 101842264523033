import React, { useState, useEffect } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { GrAddCircle } from 'react-icons/gr';

import UUID from '@utils/uuid';
import { itemToInput } from '@components/forms/GridForm';

const WeightsVariants = ({ methods, hardwareFinishes, dataKeys }) => {
  const [variants, setVariants] = useState([UUID()]);

  useEffect(() => {
    dataKeys && setVariants(dataKeys);
  }, [dataKeys, setVariants]);

  const handleAddVariant = () => {
    setVariants([...variants, UUID()]);
  };

  const handleRemoveVariant = (variantUUID) => {
    setVariants(variants.filter((variant) => variant !== variantUUID));
  };

  return (
    <div className={'flex flex-col space-y-6'} style={{ maxWidth: '992px' }}>
      {variants.map((variantUUID, index) => {
        const last = variants.length - 1 === index;
        const first = index === 0;
        return (
          <div className={`flex flex-row`} key={variantUUID}>
            <div className="w-2/6 h-1"></div>

            <div className="flex flex-row space-x-12">
              {itemToInput(
                {
                  label: 'SKU',
                  name: `variants[${variantUUID}][product_reference]`,
                  type: 'text',
                  error: { required: 'Please enter SKU' },
                  defaultValue: '',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Size',
                  name: `variants[${variantUUID}][size]`,
                  type: 'text',
                  error: { required: 'Please enter size' },
                  placeholder: '12.4 x 10.9',
                  defaultValue: '',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Finish',
                  name: `variants[${variantUUID}][hardware_finish_id]`,
                  type: 'combobox',
                  error: { required: 'Please enter Finish' },
                  options: hardwareFinishes,
                  // defaultValue: '',
                  placeholder: '',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Price',
                  name: `variants[${variantUUID}][price]`,
                  type: 'currency',
                  placeholder: '',
                  error: { required: 'Please enter a price' },
                  defaultValue: '',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  name: `variants[${variantUUID}][id]`,
                  type: 'hidden',
                  error: {},
                  defaultValue: '',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </div>
            {!(first && last) && (
              <span className="position-center self-center ml-4">
                <IoMdCloseCircleOutline
                  size={24}
                  color={'red'}
                  style={{ marginTop: '0.5rem' }}
                  className="cursor-pointer"
                  onClick={(_) => handleRemoveVariant(variantUUID)}
                />
              </span>
            )}
            {last && (
              <span className="position-center self-center ml-4">
                <GrAddCircle
                  size={24}
                  color={'green'}
                  style={{ marginTop: '0.5rem' }}
                  className="cursor-pointer"
                  onClick={handleAddVariant}
                />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WeightsVariants;
