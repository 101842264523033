import _ from 'lodash';

import {
  SET_TIMBER,
  SET_GLAZING_UNIT,
  SET_FINISH,
  SET_FORM_FIELD,
  SET_FORM_DATA,
  SET_DIAGRAM_SNAPSHOT,
  SET_HARDWARE_SET,
  SET_HARDWARE_SET_ITEM,
  SET_FINISH_SYSTEM,
  SET_LAYER_FINISH,
  SET_OUTER_FRAME_FINISH,
} from '../actions/configuratorActions';

export default function configuratorForms(
  state = {
    timber: {},
    glazingUnits: {},
    finish: {},
    glazingBar: null,
    hardwareSet: null,
    formFields: { singleColour: true },
    finishSystem: null,
    hardwareSetItems: {},
    sectionEditors: {},
    timberSummary: {},
    labourSummary: {},
    glassSummary: {},
    hardwareSummary: {},
    casementProductItemData: {},
  },
  action
) {
  switch (action.type) {
    case SET_TIMBER: {
      const { keyPath, timberItem } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, 'timber.' + keyPath, timberItem);
    }
    case SET_GLAZING_UNIT: {
      const { keyPath, glazingUnit } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, 'glazingUnits.' + keyPath, glazingUnit);
    }
    case SET_FINISH: {
      const { keyPath, finishItem } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, 'finishLayers.' + keyPath, finishItem);
    }
    case SET_LAYER_FINISH: {
      const { categoryName, finishItem } = action.payload;
      let newFinishSystem = Object.assign({}, state.finishSystem);
      newFinishSystem.data = newFinishSystem.data.map((layer) => {
        if (layer.category === categoryName) {
          layer['timber_finish_id'] = finishItem.id;
        }

        return layer;
      });

      return { ...state, finishSystem: newFinishSystem };
    }
    case SET_OUTER_FRAME_FINISH: {
      const { categoryName, finishItem } = action.payload;
      let newFinishSystem = Object.assign({}, state.finishSystem);
      newFinishSystem['outer_frame'] = {
        category: categoryName,
        timber_finish_id: finishItem.id,
      };
      return { ...state, finishSystem: newFinishSystem };
    }
    case SET_HARDWARE_SET: {
      const { keyPath, hardwareSet } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, keyPath, hardwareSet);
    }
    case SET_HARDWARE_SET_ITEM: {
      const { keyPath, hardwareSetItem } = action.payload;

      let newHardwareSetItem = Object.assign({}, state.hardwareSetItems);
      newHardwareSetItem[keyPath] = hardwareSetItem;

      return { ...state, hardwareSetItems: newHardwareSetItem };
    }
    case SET_FINISH_SYSTEM: {
      const { finishSystem } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, 'finishSystem', finishSystem);
    }
    case SET_FORM_FIELD: {
      const { keyPath, value } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, 'formFields.' + keyPath, value);
    }
    case SET_FORM_DATA: {
      const { keyPath, value } = action.payload;
      let newState = Object.assign({}, state);
      // @ts-ignore
      if (keyPath === 'casementData' && newState.casementData) {
        // @ts-ignore
        const data = { ...newState.casementData, ...value };
        return _.set(newState, keyPath, data);
      } else {
        return _.set(newState, keyPath, value);
      }
    }
    case SET_DIAGRAM_SNAPSHOT: {
      const { keyPath, value } = action.payload;
      let newState = Object.assign({}, state);
      return _.set(newState, keyPath, value);
    }
    default:
      return state;
  }
}
