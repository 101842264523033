export const SET_TIMBER = 'SET_TIMBER';
export const SET_GLAZING_UNIT = 'SET_GLAZING_UNIT';
export const SET_FINISH = 'SET_FINISH';
export const SET_FORM_FIELD = 'SET_FORM_FIELD';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_DIAGRAM_SNAPSHOT = 'SET_DIAGRAM_SNAPSHOT';
export const SET_HARDWARE_SET = 'SET_HARDWARE_SET';
export const SET_HARDWARE_SET_ITEM = 'SET_HARDWARE_SET_ITEM';
export const SET_FINISH_SYSTEM = 'SET_FINISH_SYSTEM';
export const SET_LAYER_FINISH = 'SET_LAYER_FINISH';
export const SET_OUTER_FRAME_FINISH = 'SET_OUTER_FRAME_FINISH';
export const SET_CONFIG_DATA = 'SET_CONFIG_DATA';

export function setConfigurationData(key: string, data: any) {
  return {
    type: SET_CONFIG_DATA,
    payload: { keyPath: key, data },
  };
}

export function setFormData(key: string, value: any) {
  return {
    type: SET_FORM_DATA,
    payload: { keyPath: key, value },
  };
}

export function setDiagramSnapshot(key: string, value: any) {
  return {
    type: SET_DIAGRAM_SNAPSHOT,
    payload: { keyPath: key, value },
  };
}

export function setTimber(keyPath, timberItem) {
  return {
    type: SET_TIMBER,
    payload: { keyPath, timberItem },
  };
}

export function setGlazingUnit(keyPath, glazingUnit) {
  return {
    type: SET_GLAZING_UNIT,
    payload: { keyPath, glazingUnit },
  };
}

export function setFinish(keyPath, finishItem) {
  return {
    type: SET_FINISH,
    payload: { keyPath, finishItem },
  };
}

export function setFormField(keyPath, value) {
  return {
    type: SET_FORM_FIELD,
    payload: { keyPath, value },
  };
}

export function setHardwareSet(keyPath, hardwareSet) {
  return {
    type: SET_HARDWARE_SET,
    payload: { keyPath, hardwareSet },
  };
}

export function setHardwareSetItem(keyPath, hardwareSetItem) {
  return {
    type: SET_HARDWARE_SET_ITEM,
    payload: { keyPath, hardwareSetItem },
  };
}

export function setFinishSystem(finishSystem) {
  return {
    type: SET_FINISH_SYSTEM,
    payload: { finishSystem },
  };
}

export function setLayerFinish(categoryName, finishItem) {
  return {
    type: SET_LAYER_FINISH,
    payload: { categoryName, finishItem },
  };
}

export function setOuterFrameFinish(categoryName, finishItem) {
  return {
    type: SET_OUTER_FRAME_FINISH,
    payload: { categoryName, finishItem },
  };
}
