import React from 'react';

type Props = {
  inline: boolean;
  label: string;
  name: string;
  style: string;
  placeholder: string;
  icon: JSX.Element;
  disabled: boolean;
};

const RenderLabel = ({ children, icon }) => {
  if (icon) {
    return (
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">{icon}</span>
        {children}
      </div>
    );
  }

  return children;
};

const TextInput = React.forwardRef(
  (
    {
      inline = false,
      name = 'name',
      style = '',
      placeholder = 'Enter something...',
      icon,
      ...otherProps
    }: Props,
    ref
  ) => {
    const inputStyle = icon
      ? 'rounded-md pl-8 focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent  ' +
        style
      : style;

    return (
      <RenderLabel icon={icon}>
        <input
          name={name}
          type="text"
          className={inputStyle}
          placeholder={placeholder}
          ref={ref}
          autoComplete="off"
          {...otherProps}
        />
      </RenderLabel>
    );
  }
);

export default TextInput;
