import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@components/buttons/ButtonLink';

import '../../css/components/modals.css';

const Modal = ({
  title,
  icon = null,
  buttonTitle = '',
  buttonClassName = null,
  buttonHidden = false,
  minWidth = null,
  body,
  modalButtonText = 'Open modal',
  handleButtonClick = (_) => {},
  buttonFormId = 'default',
  buttonComponent = null,
}) => {
  const modalRef = useRef(null);
  const [hidden, setHidden] = useState(true);
  const dispatch = useDispatch();
  const show = () => {
    setHidden(false);
    dispatch({
      type: 'SET_CONFIG_KEY',
      key: 'backdrop',
      value: true,
    });
  };
  const hide = () => {
    setHidden(true);
    dispatch({
      type: 'SET_CONFIG_KEY',
      key: 'backdrop',
      value: false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hidden || modalRef.current.contains(event.target)) {
        return false;
      }

      setHidden(!hidden);
      dispatch({
        type: 'SET_CONFIG_KEY',
        key: 'backdrop',
        value: false,
      });
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        setHidden(true);
        dispatch({
          type: 'SET_CONFIG_KEY',
          key: 'backdrop',
          value: false,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyPress);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [hidden, modalRef, dispatch]);

  const buttonAttributes = buttonFormId
    ? { form: buttonFormId, type: 'submit' }
    : { type: 'button' };

  const renderOriginButton = () => {
    if (buttonComponent) {
      return buttonComponent(show, modalButtonText);
    }

    return (
      <Button as="button" onClick={show}>
        {modalButtonText}
      </Button>
    );
  };

  return (
    <>
      {renderOriginButton()}
      {!hidden ? (
        <>
          <div className="modal show">
            <div className="relative w-auto lg:my-4 mx-auto" ref={modalRef}>
              <div
                className="bg-white text-grey-900 border-grey-200 dark:bg-grey-800 dark:text-white dark:border-grey-700 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none overflow-y-auto"
                style={{ maxHeight: '58rem' }}
              >
                <div
                  className="relative p-4 flex-auto min-w-max"
                  style={minWidth && { minWidth: minWidth }}
                >
                  <div className="flex items-start justify-start p-2 space-x-4">
                    {icon && <div className="shrink-0 w-12">{icon}</div>}
                    <div className="flex flex-col w-full min-w-max">
                      <div className="text-lg mb-2 font-bold">{title}</div>
                      {React.cloneElement(body, { onClose: hide })}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end p-4 border-t border-grey-200 dark:border-grey-700 border-solid rounded-b space-x-2">
                  <Button
                    className="bg-white hover:bg-grey-100 text-grey-900"
                    as="button"
                    onClick={hide}
                  >
                    Cancel
                  </Button>
                  {!buttonHidden && (
                    <Button
                      as="button"
                      className={buttonClassName}
                      {...buttonAttributes}
                      onClick={handleButtonClick}
                    >
                      {buttonTitle}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
