import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { GrAddCircle } from 'react-icons/gr';

import UUID from '@utils/uuid';
import Section, { InputContainer } from './Section';
import { itemToInput } from '@components/forms/GridForm';

export const reMapVariants = (variants, sites = []) => {
  const remappedVariants = Object.assign({}, variants);
  Object.keys(remappedVariants).forEach((key) => {
    const siteOption = sites.find((option) => option.value === remappedVariants[key]['site']);
    remappedVariants[key] = {
      // @ts-ignore
      ...remappedVariants[key],
      site: siteOption,
    };
  });
  return remappedVariants;
};

export const handleVariants = (variants) => {
  return Object.entries(variants).map(([key, value]) => {
    return {
      // @ts-ignore
      ...value,
      site: value['site'].value,
    };
  });
};

const ProductItemSection = ({ recordId, availableSiteOptions }) => {
  const [variants, setVariants] = useState([UUID()]);
  const [sites, setSites] = useState(availableSiteOptions);
  const methods = useFormContext();

  variants.map((variantUUID) => {
    const site = methods.watch(`product_items[${variantUUID}][site]`);
    if (site == null || site == '' || sites.find((availSite) => availSite.value === site.value)) {
      // Nothingt to do skipping and since continue can't be used...
    } else {
      setSites([...sites, site]);
    }
  });

  // useEffect(() => {
  //   dataKeys && setVariants(dataKeys);
  // }, [dataKeys, setVariants]);

  const handleAddVariant = () => {
    setVariants([...variants, UUID()]);
  };

  const handleRemoveVariant = (variantUUID) => {
    setVariants(variants.filter((variant) => variant !== variantUUID));
  };

  return (
    <Section title={'Product allocation'}>
      <div className={'flex flex-col space-y-6'} style={{ maxWidth: '992px' }}>
        {variants.map((variantUUID, index) => {
          const last = variants.length - 1 === index;
          const first = index === 0;
          return (
            <div className={`flex flex-row`} key={variantUUID}>
              <div className="flex flex-row space-x-12">
                {itemToInput(
                  {
                    label: 'Reference',
                    name: `product_items[${variantUUID}][reference]`,
                    type: 'text',
                    error: { required: 'Please enter SKU' },
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Site',
                    name: `product_items[${variantUUID}][site]`,
                    type: 'combobox',
                    error: { required: 'Please enter Finish' },
                    options: sites,
                    // defaultValue: '',
                    placeholder: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Quantity',
                    name: `product_items[${variantUUID}][quantity]`,
                    type: 'text',
                    error: { required: 'Please enter SKU' },
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </div>
              {!(first && last) && (
                <span className="position-center self-center ml-4">
                  <IoMdCloseCircleOutline
                    size={24}
                    color={'red'}
                    style={{ marginTop: '0.5rem' }}
                    className="cursor-pointer"
                    onClick={(_) => handleRemoveVariant(variantUUID)}
                  />
                </span>
              )}
              {last && (
                <span className="position-center self-center ml-4">
                  <GrAddCircle
                    size={24}
                    color={'green'}
                    style={{ marginTop: '0.5rem' }}
                    className="cursor-pointer"
                    onClick={handleAddVariant}
                  />
                </span>
              )}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ProductItemSection;
