import React from 'react';
import { Provider } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import DefaultQuoteSettingForm from '@components/forms/Account/DefaultQuoteSettingForm';
import Widget from '@components/widget';

import { store } from '../../store';

const Page = ({ defaultQuoteSetting }) => {
  return (
    <>
      <Widget title="Company Details">
        <DefaultQuoteSettingForm defaultQuoteSetting={defaultQuoteSetting} />
      </Widget>
    </>
  );
};

const BusinessPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default BusinessPage;
