import React, { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { useSelector, shallowEqual } from 'react-redux';

import '@appSrc/css/layouts/layout-1.css';

import DebugOptions from '@components/DebugOptions';
import Backdrop from '@components/backdrop';
import Hotkeys from '@components/hotkeys';
import UpIcon from '@components/icons/UpIcon';
import LeftSidebar1 from '@components/left-sidebar-1';
import Loader from '@components/loader';
import DefaultLoader from '@components/loaders/DefaultLoader';
import Navbar1 from '@components/navbar-1';
import RightSidebar1 from '@components/right-sidebar-1';
import Scrolltop from '@components/scrolltop';

const Layout1 = ({ children }) => {
  const { config, palettes } = useSelector(
    (state) => ({
      config: state.config,
      palettes: state.palettes,
    }),
    shallowEqual
  );
  const { layout, collapsed, loading } = { ...config };
  let { background, navbar, logo, leftSidebar, rightSidebar } = {
    ...palettes,
  };
  let key = `${[layout, collapsed, background, navbar, logo, leftSidebar, rightSidebar].join('-')}`;

  useEffect(() => {
    Scrolltop('scrolltop');
  }, []);

  return (
    <div
      data-layout={layout}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      data-right-sidebar={rightSidebar}
      className={`${background === 'dark' ? 'dark-mode' : 'default-mode'}`}
    >
      {process.env.NODE_ENV === 'development' && <DebugOptions />}
      <Loader />
      <Backdrop />
      <NotificationContainer />
      <Hotkeys key={key} />
      <RightSidebar1 />
      <div className="wrapper">
        <LeftSidebar1 />
        <div className="main w-full bg-grey-70 text-grey-900 dark:bg-grey-900 dark:text-white">
          <Navbar1 />
          <DefaultLoader />
          <div id="page-content" className="min-h-screen w-full pl-4 pt-4">
            {children}
          </div>
        </div>
      </div>
      <div id="scrolltop" className="scrolltop">
        <span className="svg-icon">
          <UpIcon size={24} color="white" />
        </span>
      </div>
    </div>
  );
};
export default Layout1;
