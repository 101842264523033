import React from 'react';
import { Provider } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import AccountForm from '@components/forms/AccountForm';
import TermsAndConditionsForm from '@components/forms/AccountForm/TermsAndConditionsForm';
import Widget from '@components/widget';

import { store } from '../store';

const Page = ({ business }) => {
  return (
    <>
      <Widget title="Company Details">
        <AccountForm business={business} businessID={business.id} />
      </Widget>
      <Widget title="Terms and Conditions">
        <TermsAndConditionsForm business={business} businessID={business.id} />
      </Widget>
    </>
  );
};

const BusinessPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default BusinessPage;
