import React from 'react';
import { Rect, Shape, Circle } from 'react-konva';

import { pythagaros, bezierCurveApproximatedArc } from '@utils/geometry';

const VerticalBar = ({
  x = 0,
  y = 0,
  width,
  height,
  section,
  isGeorgian = false,
  ...shapeProps
}) => {
  if (!section.isHeadNeighbour || section.store.headStyle === 'standard') {
    return <Rect x={x} y={y} width={width} height={height} {...shapeProps} />;
  }

  let radius = section.relativeRadius;
  const isHalfCircle = section.store.finalSize.width / 2 === section.store.arcRadius;

  if (isHalfCircle) {
    // If it is a semi-circle we can no longer approximate the arc with bezier curves.
    radius = radius + 0.0001;
  }

  let radiusReduction = section.store.finalSize.height - section.store.arcRadius;

  const arcCenter = section.relativeArcCenter;
  arcCenter.y = radiusReduction - section.absoluteOrigin.y;
  const distToCenter = x - arcCenter.x;

  const arcRadius = height + y - arcCenter.y;

  // Since we are drawing the arc via bezier approximation its not a true arc and therefore fine details like bars
  // cannot be drawn accurately on the arc, so to make it visually correct we load the Y axis with an arbitrary value.
  let loadY = isHalfCircle ? 2 : 0;

  const upperStartY = Math.abs(pythagaros(distToCenter, arcRadius) + arcCenter.y - y + loadY);
  const upperEndY = Math.abs(
    pythagaros(distToCenter < 0 ? distToCenter + width : distToCenter + width, arcRadius) +
      arcCenter.y -
      y +
      loadY
  );

  const arcPoints = bezierCurveApproximatedArc(
    { x: 0, y: upperStartY },
    { x: width, y: upperEndY },
    arcCenter,
    arcRadius
  );

  return (
    <>
      {/* Uncomment for debugging */}
      {/* <Circle x={arcCenter.x} y={arcCenter.y} radius={arcRadius} fill={'red'} /> */}
      <Shape
        {...shapeProps}
        width={width}
        height={height}
        x={x}
        y={y}
        closed={false}
        sceneFunc={(ctx, shape) => {
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(0, upperStartY);
          // @ts-ignore
          ctx.bezierCurveTo(...arcPoints);

          ctx.lineTo(width, upperEndY);
          ctx.lineTo(width, 0);
          // ctx.closePath();

          // Konva specific method
          ctx.fillStrokeShape(shape);
        }}
      />
    </>
  );
};

export default VerticalBar;
