import _ from 'lodash';
import { types, IAnyModelType, destroy, Instance } from 'mobx-state-tree';

import digests from '@utils/digests';
import { pythagaros, radiusFromSagittaAndChord } from '@utils/geometry';
import uuid from '@utils/uuid';

import Section from './SectionStore';

export const CasementStore = types.model('RootCasementStore', {});

function castValuesToNumber(obj) {
  Object.keys(obj).forEach((key) => {
    obj[key] = Number(obj[key]);
  });
  return obj;
}

export const Casement = types
  .model('Casement', {
    id: types.identifier,
    store: types.map(CasementStore),
    sections: types.late(() => types.array(Section)),
    selectedSectionId: types.union(types.string, types.null),
    currentSection: types.union(types.reference(Section), types.null),
    origin: types.frozen({ x: 0, y: 0 }),
    style: types.frozen({ type: 'flush', subType: 'standard' }),
    size: types.frozen({ width: 1000, height: 1500 }),
    internalSize: types.frozen({ width: 0, height: 0 }),
    finalSize: types.frozen({ width: 1000, height: 1500 }),
    mullion: types.frozen({ thickness: 90, width: 55 }),
    visible_section: types.frozen({ head: 15, sill: 15, jamb: 15 }),
    jamb: types.frozen({ thickness: 90, width: 55 }),
    head: types.frozen({ thickness: 90, width: 55 }),
    sill: types.frozen({ thickness: 90, width: 70, projection: 0, angle: 7 }),
    sill_horns: types.frozen({ thickness: 90, width: 60 }),
    transom: types.frozen({ thickness: 90, width: 55, angle: 7 }),
    secondary_transom: types.frozen({ thickness: 90, width: 60 }),
    headRainDrip: types.frozen({ thickness: 30, width: 15 }),
    transomRainDrip: types.frozen({ thickness: 30, width: 15 }),
    top_rail: types.frozen({ thickness: 90, width: 55 }),
    stiles: types.frozen({ thickness: 90, width: 55 }),
    sash_moulding: types.frozen({ thickness: 12, width: 9 }),
    bottom_rail: types.frozen({ thickness: 90, width: 65 }),
    top_sash_bottom_rail: types.frozen({ width: 55 }),
    mid_rail: types.frozen({ thickness: 54, width: 110 }),
    muntin: types.frozen({ thickness: 54, width: 110 }),
    sash_rebate: types.frozen({ thickness: 0, width: 0 }),
    sash_glazing_bead: types.frozen({ thickness: 12, width: 12 }),
    frame_glazing_bead: types.frozen({ thickness: 12, width: 24 }),
    sashesOnly: types.optional(types.boolean, false),
    rebate: types.frozen({ thickness: 55, width: 15 }),
    glazing_rebate: types.frozen({ width: 15 }),
    stickOnBars: types.frozen({
      in: { thickness: 24, width: 20 },
      out: { thickness: 24, width: 20 },
    }),
    trueBars: types.frozen({ thickness: 24, width: 20, rebate: 8 }),
    sash_gap: types.optional(types.number, 3),
    sash_gaps: types.frozen({
      top: 3,
      left: 3,
      bottom: 5,
      right: 3,
    }),
    glass_gap: types.optional(types.number, 3),
    frame_glass_gap: types.optional(types.number, 2),
    headStyle: types.optional(types.string, 'standard'),
    arcRise: types.optional(types.number, 0),
    arcRadius: types.optional(types.number, 0),
    hiddenInBrick: types.optional(types.number, 0),
    transomDrop: types.optional(types.number, 0),
    isInternallyGlazed: types.optional(types.boolean, true),
    isPartOfBay: types.optional(types.boolean, false),
    hasHeadRainDrip: types.optional(types.boolean, false),

    secondaryTransom: types.optional(types.boolean, false),
    dimensions: types.frozen({ levelX: 0, levelY: 0 }),
    sectionType: types.string,
    draftSealGap: types.optional(types.number, 5),
    currentDimensionLevels: types.frozen({ x: 2, y: 2 }),
  })
  .views((self) => ({
    get drawSize() {
      return {
        width: self.finalSize.width + this.sillHornsWidth * 2,
        height: self.finalSize.height,
      };
    },

    get absoluteOrigin() {
      return { x: 0, y: 0 };
    },

    get topRailThickness() {
      return self.top_rail.thickness;
    },

    get visiualOpeningSizes() {
      return {
        width: self.finalSize.width - this.visibleJambWidth * 2,
        height: self.finalSize.height - (this.visibleSillHeight + this.visibleHeadWidth),
      };
    },

    get visibleHeadWidth() {
      return self.head.width - self.rebate.width;
    },

    get visibleJambWidth() {
      return self.jamb.width - self.rebate.width;
    },

    get visibleTransomWidth() {
      return (
        self.transom.width -
        self.rebate.width * 2 -
        Math.tan(self.transom.angle * (Math.PI / 180)) * self.rebate.thickness
      );
    },

    get transomAngleReduction() {
      return Math.tan(self.transom.angle * (Math.PI / 180)) * self.rebate.thickness;
    },

    get sashHeightOffsetOnTransom() {
      return (
        this.transomAngleReduction -
        Math.tan(self.transom.angle * (Math.PI / 180)) * (self.draftSealGap + self.stiles.thickness)
      );
    },

    get visibleMullionWidth() {
      return self.mullion.width - self.rebate.width * 2;
    },

    get visibleSillHeight() {
      return (
        self.sill.width -
        self.rebate.width -
        Math.tan(self.sill.angle * (Math.PI / 180)) * self.rebate.thickness
      );
    },

    get sillAngleReduction() {
      return Math.tan(self.sill.angle * (Math.PI / 180)) * self.rebate.thickness;
    },

    get sashHeightOffsetOnSill() {
      return (
        this.sillAngleReduction -
        Math.tan(self.sill.angle * (Math.PI / 180)) * (self.draftSealGap + self.stiles.thickness)
      );
    },

    get glazingRebateThickness() {
      return self.stiles.thickness - self.sash_moulding.thickness;
    },

    get sillHornsWidth() {
      return self.sill.projection > 0 ? self.sill_horns.width : 0;
    },

    get surfaceArea() {
      const { width, height } = self.finalSize;

      const sillArea = (self.sill.width * width * 2 + self.sill.thickness * width * 2) * 1e-6;
      const projectionArea =
        (self.sill.projection * (width + this.sillHornsWidth * 2) * 2 +
          self.sill.thickness * (width + this.sillHornsWidth * 2) * 2) *
        1e-6;
      const headArea = (self.head.width * width * 2 + self.head.thickness * width * 2) * 1e-6;
      const jambArea = (self.jamb.width * height * 2 + self.jamb.thickness * height * 2) * 1e-6;

      const totalSurfaceArea =
        sillArea +
        projectionArea +
        headArea +
        jambArea * 2 +
        this.mullionSurfaceArea +
        this.transomSurfaceArea +
        this.headRainDripArea;

      return Math.round(totalSurfaceArea * 10000) / 10000;
    },

    get headRainDripArea() {
      const { width } = self.finalSize;
      const rainDripArea =
        (self.headRainDrip.width * width * 2 + self.headRainDrip.thickness * width * 2) * 1e-6;
      return Math.round(rainDripArea * 10000) / 10000;
    },

    get totalSurfaceArea() {
      let totalArea = this.surfaceArea;

      function recurse(sections) {
        sections.forEach((section) => {
          if (!['mullion', 'transom'].includes(section.sectionType)) {
            totalArea += section.surfaceArea;
          }
          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);
      return totalArea;
    },

    get diagramableSections() {
      const leafs = this.leafSections || [];
      const dummies = this.dummySections || [];
      const fixed = this.fixedSections || [];

      return leafs.concat(dummies, fixed);
    },

    get leafSections() {
      if (self.sections[0].sectionType === 'leaf') return [self.sections[0]];

      return self.sections[0].findSectionsType('leaf');
    },

    get dummySections() {
      if (self.sections[0].sectionType === 'dummy') return [self.sections[0]];

      return self.sections[0].findSectionsType('dummy');
    },

    get fixedSections() {
      if (self.sections[0].sectionType === 'fixed') return [self.sections[0]];

      return self.sections[0].findSectionsType('fixed');
    },

    get mullionSections() {
      return self.sections[0].findSectionsType('mullion');
    },

    get mullionSurfaceArea() {
      return this.mullionSections.reduce((a, section) => {
        return a + section.surfaceArea;
      }, 0);
    },

    get transomSections() {
      return self.sections[0].findSectionsType('transom');
    },

    get transomSurfaceArea() {
      return this.transomSections.reduce((a, section) => {
        return a + section.surfaceArea;
      }, 0);
    },

    get timber() {
      if (self.sashesOnly) return [];

      const { width, height } = this.finalSize;
      self.sections[0].computeRelativePosition();

      let timber = [];
      const { thickness: sillThickness, width: sillWidth, projection } = self.sill;
      timber.push({
        thickness: sillThickness,
        width: sillWidth,
        length: width,
        quantity: 1,
        profile: 'sill',
      });
      if (projection > 0) {
        timber.push({
          thickness: projection + 8,
          width: this.visibleSillHeight - 3,
          length: width + this.sillHornsWidth * 2,
          quantity: 1,
          profile: 'projection',
        });
      }
      if (self.hasHeadRainDrip) {
        timber.push({
          thickness: self.headRainDrip.thickness,
          width: self.headRainDrip.width,
          length: width,
          quantity: 1,
          profile: 'head_rain_drip',
        });
      }

      timber.push({ ...self.head, length: width, quantity: 1, profile: 'head' });
      timber.push({ ...self.jamb, length: height, quantity: 2, profile: 'jamb' });

      return timber;
    },

    get allTimber() {
      let timber = this.timber;

      function recurse(sections) {
        sections.forEach((section) => {
          timber = timber.concat(section.timber);

          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);

      return timber.filter((item) => item !== 0);
    },

    get frameFinishMeters() {
      const { width, height } = self.finalSize;
      return Math.round((width + height) * 2 * 1e-3 * 1000) / 1000;
    },

    get frameSealingMeters() {
      const { width, height } = this.visiualOpeningSizes;
      return Math.round((width + height) * 1e-3 * 1000) / 1000;
    },

    get glassSummary() {
      let glass = [];

      function recurse(sections) {
        sections.forEach((section) => {
          if (
            ['leaf', 'dummy', 'fixed', 'glazing'].includes(section.sectionType) &&
            section.sections.length === 0
          ) {
            glass.push(section.glassSummary);
          }

          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);

      return glass;
    },

    get hardwareSummary() {
      const hardwareSummary = {
        type: self.style.type,
        sub_type: self.style.subType,
        frame_seal_meters: this.frameSealingMeters,
        sections: [],
      };

      function recurse(sections) {
        sections.forEach((section) => {
          if (!['glazing', 'muntin', 'fixed'].includes(section.sectionType)) {
            const sectionHardwareSummary = section.hardwareSummary;
            if (sectionHardwareSummary) {
              hardwareSummary.sections.push(sectionHardwareSummary);
            }
          }

          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);

      return hardwareSummary;
    },

    get statistics() {
      let statistics = {
        size: self.finalSize,
        is_arched: self.headStyle !== 'standard',
        is_semi_circle:
          self.headStyle !== 'standard' && self.arcRise > self.finalSize.width / 2 - 10,
        is_dual_colour: false,
        part_of_bay: self.isPartOfBay,
        sash_pane_quantity: 0,
        bars_quantity: 0,
        leaf_quantity: 0,
        dummy_quantity: 0,
        sash_finish_meters: 0,
        sash_sealing_meters: 0,
        sill_quantity: self.sashesOnly ? 0 : 1,
        sill_projection: !self.sashesOnly && self.sill.projection > 0,
        jamb_quantity: self.sashesOnly ? 0 : 2,
        transom_quantity: 0,
        mullion_quantity: 0,
        rain_drip_quantity: self.hasHeadRainDrip ? 1 : 0,
        frame_finish_meters: this.frameFinishMeters,
        frame_sealing_meters: this.frameSealingMeters,
        sashes_only: self.sashesOnly,
      };

      function recurse(sections) {
        sections.forEach((section) => {
          let sectionStats = section.statistics;

          if (['mullion', 'transom'].includes(section.sectionType)) {
            statistics.frame_sealing_meters += sectionStats.frame_sealing_meters;
            statistics.frame_finish_meters += sectionStats.frame_finish_meters;
          }

          if (['mullion'].includes(section.sectionType)) {
            statistics.mullion_quantity += 1;
          }

          if (['transom'].includes(section.sectionType)) {
            statistics.transom_quantity += 1;
            statistics.rain_drip_quantity += sectionStats.rain_drip_quantity;
          }

          if (['leaf', 'dummy', 'fixed'].includes(section.sectionType)) {
            statistics.sash_pane_quantity += sectionStats.sash_pane_quantity;
            statistics.bars_quantity += sectionStats.bars_quantity;
            statistics.sash_finish_meters += sectionStats.sash_finish_meters;
            statistics.sash_sealing_meters += sectionStats.sash_sealing_meters;
          }

          if (section.sectionType === 'leaf') {
            statistics.leaf_quantity += 1;
          }

          if (section.sectionType === 'dummy') {
            statistics.dummy_quantity += 1;
          }

          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);

      return statistics;
    },

    get leftJambPoints() {
      const yCenter = self.arcRise - self.arcRadius;
      const yDistance = pythagaros(self.finalSize.width / 2, self.arcRadius);
      const y = yCenter + yDistance;

      const lowerArcRise = self.arcRise - this.visibleHeadWidth;
      const lowerArcRadius = self.arcRadius - this.visibleHeadWidth;
      const lowerYCenter = lowerArcRise - lowerArcRadius;
      const lowerYDistance = pythagaros(
        (self.finalSize.width - self.jamb.width * 2) / 2,
        lowerArcRadius
      );
      const lowerY = lowerYCenter + lowerYDistance;

      const jambLength =
        self.finalSize.height -
        (self.headStyle !== 'standard' ? self.arcRise : this.visibleHeadWidth);
      const outerArchedJambY = self.headStyle === 'full_arch' ? jambLength + y : jambLength;
      const innerArchedJambY = self.headStyle === 'full_arch' ? jambLength + lowerY : jambLength;

      return [
        0,
        this.visibleSillHeight,
        this.visibleJambWidth,
        this.visibleSillHeight,
        this.visibleJambWidth,
        innerArchedJambY,
        0,
        outerArchedJambY,
      ];
    },

    get rightJambPoints() {
      const yCenter = self.arcRise - self.arcRadius;
      const yDistance = pythagaros(self.finalSize.width / 2, self.arcRadius);
      const y = yCenter + yDistance;

      const lowerArcRise = self.arcRise - this.visibleHeadWidth;
      const lowerArcRadius = self.arcRadius - this.visibleHeadWidth;
      const lowerYCenter = lowerArcRise - lowerArcRadius;
      const lowerYDistance = pythagaros(
        (self.finalSize.width - self.jamb.width * 2) / 2,
        lowerArcRadius
      );
      const lowerY = lowerYCenter + lowerYDistance;

      const jambLength =
        self.finalSize.height -
        (self.headStyle !== 'standard' ? self.arcRise : this.visibleHeadWidth);
      const outerArchedJambY = self.headStyle === 'full_arch' ? jambLength + y : jambLength;
      const innerArchedJambY = self.headStyle === 'full_arch' ? jambLength + lowerY : jambLength;

      const rightJambOffset = self.finalSize.width - this.visibleJambWidth;

      return [
        rightJambOffset,
        this.visibleSillHeight,
        rightJambOffset + this.visibleJambWidth,
        this.visibleSillHeight,
        rightJambOffset + this.visibleJambWidth,
        outerArchedJambY,
        rightJambOffset,
        innerArchedJambY,
      ];
    },

    get sillPoints() {
      const { finalSize, sill, rebate } = self;

      const sillHornWidth = this.sillHornsWidth;
      const sillLineOffset =
        Math.tan((sill.angle * Math.PI) / 180) *
        (sill.thickness - rebate.thickness + Number(sill.projection));

      return [
        [
          0 - sillHornWidth,
          0,
          finalSize.width + sillHornWidth,
          0,
          finalSize.width + sillHornWidth,
          this.visibleSillHeight,
          0 - sillHornWidth,
          this.visibleSillHeight,
        ],
        [
          0 - sillHornWidth,
          this.visibleSillHeight - sillLineOffset,
          finalSize.width + sillHornWidth,
          this.visibleSillHeight - sillLineOffset,
        ],
      ];
    },

    get cuttingList() {
      let cuttingList = {
        frame: {
          internal_size: this.visiualOpeningSizes,
          sectionID: self.sections[0].id,
          jamb: {},
          mullions: [],
          transoms: [],
          transom_rain_drips: [],
          sashes_only: self.sashesOnly,
        },
        sashes: {},
      };

      this.timber.forEach((timber) => {
        cuttingList.frame[timber.profile] = {
          ...timber,
        };
      });

      function recurse(sections) {
        sections.forEach((section) => {
          let sectionTimber = section.timber;
          let sectionID = null;

          sectionTimber.forEach((timber) => {
            if (['mullion'].includes(timber.profile)) {
              cuttingList.frame.mullions.push({
                ...timber,
              });
            }

            if (['transom'].includes(timber.profile)) {
              cuttingList.frame.transoms.push({
                ...timber,
              });
            }

            if (['transom_rain_drip'].includes(timber.profile)) {
              cuttingList.frame.transom_rain_drips.push({
                ...timber,
              });
            }

            if (
              [
                'top_rail',
                'bottom_rail',
                'stile',
                'muntin',
                'georgian_bar_in',
                'georgian_bar_out',
                'true_bar',
                'frame_glazing_bead',
                'glazing_bead',
              ].includes(timber.profile)
            ) {
              if (cuttingList.sashes[timber.sectionID]) {
                cuttingList.sashes[timber.sectionID] = {
                  ...cuttingList.sashes[timber.sectionID],
                  [[timber.profile, timber.orientation || null].filter((a) => a).join('_')]: {
                    ...timber,
                  },
                };
              } else {
                sectionID = timber.sectionID;
                cuttingList.sashes[timber.sectionID] = {
                  [[timber.profile, timber.orientation || null].filter((a) => a).join('_')]: {
                    ...timber,
                  },
                };
              }
            }
          });

          if (sectionID) {
            cuttingList.sashes[sectionID].size = section.size;
            cuttingList.sashes[sectionID].section_type = section.sectionType;
            cuttingList.sashes[sectionID].stick_on_bars = section.usingStickOnBars;
          }

          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);

      // Generate digests for each sash so we can estimate likness
      Object.keys(cuttingList.sashes).forEach((key) => {
        const digestData = _.pick(cuttingList.sashes[key], [
          'size',
          'stile',
          'top_rail',
          'bottom_rail',
        ]);
        cuttingList.sashes[key].digest = digests.sha1Digest(digestData);
      });

      return cuttingList;
    },
  }))
  .actions((self) => ({
    clearCurrentSection() {
      self.currentSection = null;
      self.selectedSectionId = null;
    },

    removeSection(section) {
      destroy(section);
      // self.sections.splice(self.sections.indexOf(section), 1);
    },

    addSection(section) {
      // @ts-ignore
      self.sections = [...self.sections, section];
    },

    annotateVertically() {
      // Placeholder to prevent recursion
      return false;
    },

    annotateHorizontally() {
      // Placeholder to prevent recursion
      return false;
    },

    internalOrigin() {
      return { x: self.jamb.width, y: self.sill.width };
    },

    stileWidth(withMoulding = true) {
      const { stiles, sash_moulding } = self;

      return withMoulding ? stiles.width : stiles.width - sash_moulding.width;
    },

    topRailWidth(withMoulding = true) {
      const { top_rail, sash_moulding } = self;

      return withMoulding ? top_rail.width : top_rail.width - sash_moulding.width;
    },

    bottomRailWidth(withMoulding = true, aboveTransom = false) {
      const { bottom_rail, top_sash_bottom_rail, sash_moulding } = self;
      const width = aboveTransom ? top_sash_bottom_rail.width : bottom_rail.width;

      return withMoulding ? width : width - sash_moulding.width;
    },

    brickToFabricationSizes(sizes) {
      return {
        width: sizes.width + self.hiddenInBrick,
        height: sizes.height + self.hiddenInBrick,
      };
    },

    updateInternalSize() {
      self.internalSize = {
        width: self.finalSize.width - 2 * this.visibleJambWidth,
        height: self.finalSize.height - (this.visibleSillHeight + this.visibleHeadWidth),
      };
    },

    setSize(size) {
      self.size = { ...self.size, ...size };
      self.sections[0].setSectionSize({
        width: self.visiualOpeningSizes.width,
        height: self.visiualOpeningSizes.height,
      });
      this.updateInternalSize();
      const transom = self.sections[0].findSectionType('transom');
      if (transom) transom.rebalanceSections();
      const mullion = self.sections[0].findSectionType('mullion');
      if (mullion) mullion.rebalanceSections();
    },

    rebalanceFrame() {
      function recurse(sections) {
        sections.forEach((section) => {
          if (['transom', 'mullion'].includes(section.sectionType)) {
            section.rebalanceSections();
          }

          if (section.sections.length > 0) {
            recurse(section.sections);
          }
        });
      }

      recurse(self.sections);
    },

    setArcRise(arcRise: number) {
      self.arcRise = arcRise;
      self.arcRadius =
        self.arcRise > 0 ? radiusFromSagittaAndChord(self.arcRise, self.finalSize.width) : 0;
    },

    loadFormInputs(formInputs) {
      let formUnputsDup = JSON.parse(JSON.stringify(formInputs));
      self.style = {
        type: formUnputsDup['window_style'].value,
        subType: formUnputsDup['sub_type'].value,
      };
      self.size = castValuesToNumber(formUnputsDup['size']);
      self.mullion = castValuesToNumber(formUnputsDup['mullion']);
      self.jamb = castValuesToNumber(formUnputsDup['jamb']);
      self.head = castValuesToNumber(formUnputsDup['head']);
      self.sill = castValuesToNumber(formUnputsDup['sill']);
      self.sill_horns = castValuesToNumber(formUnputsDup['sill_horns']);
      self.transom = castValuesToNumber(formUnputsDup['transom']);
      self.secondary_transom = castValuesToNumber(formUnputsDup['secondary_transom']);
      self.transomRainDrip = castValuesToNumber(formUnputsDup['transom_rain_drip']);
      self.headRainDrip = castValuesToNumber(formUnputsDup['head_rain_drip']);
      self.top_rail = castValuesToNumber(formUnputsDup['top_rail']);
      self.stiles = castValuesToNumber(formUnputsDup['stiles']);
      self.sash_moulding = castValuesToNumber(formUnputsDup['sash_moulding']);
      self.bottom_rail = castValuesToNumber(formUnputsDup['bottom_rail']);
      self.top_sash_bottom_rail = castValuesToNumber(formUnputsDup['top_sash_bottom_rail']);
      self.mid_rail = castValuesToNumber(formUnputsDup['mid_rail']);
      self.muntin = castValuesToNumber(formUnputsDup['muntin']);
      self.sash_glazing_bead = castValuesToNumber(formUnputsDup['sash_glazing_bead']);
      self.frame_glazing_bead = castValuesToNumber(formUnputsDup['frame_glazing_bead']);
      self.rebate = castValuesToNumber(formUnputsDup['rebate']);
      self.glazing_rebate = castValuesToNumber(formUnputsDup['glazing_rebate']);
      self.sash_rebate = castValuesToNumber(formUnputsDup['sash_rebate']);
      self.stickOnBars = {
        in: castValuesToNumber(formUnputsDup['stick_on_bars']['in']),
        out: castValuesToNumber(formUnputsDup['stick_on_bars']['out']),
      };
      self.trueBars = castValuesToNumber(formUnputsDup['true_bars']);
      self.sash_gaps = castValuesToNumber(formUnputsDup['sash_gaps']);
      self.glass_gap = Number(formUnputsDup['glass_gap']);
      self.frame_glass_gap = Number(formUnputsDup['frame_glass_gap']);
      self.hiddenInBrick = Number(formUnputsDup['hidden_in_brick']);
      self.draftSealGap = Number(formUnputsDup['draft_seal_gap']);
      self.isInternallyGlazed = formUnputsDup['is_internally_glazed'];
      self.isPartOfBay = formUnputsDup['part_of_bay'] === 'true';
      self.finalSize = formUnputsDup['is_fabrication']
        ? self.size
        : this.brickToFabricationSizes(self.size);
      self.internalSize = {
        width: self.finalSize.width - 2 * this.visibleJambWidth,
        height: self.finalSize.height - (self.visibleSillHeight + self.visibleHeadWidth),
      };
      self.arcRise = Number(formUnputsDup['arc_rise']);
      self.arcRadius =
        self.arcRise > 0 ? radiusFromSagittaAndChord(self.arcRise, self.finalSize.width) : 0;
      self.sections[0].setSectionSize(
        {
          width: self.visiualOpeningSizes.width,
          height: self.visiualOpeningSizes.height,
        },
        true
      );
      self.sections[0].setOrigin({
        x: 0,
        y: 0,
        applyType: true,
      });
      const transom = self.sections[0].findSectionType('transom');
      if (transom) transom.rebalanceSections();
      const mullion = self.sections[0].findSectionType('mullion');
      if (mullion) mullion.rebalanceSections();
    },

    getCurrentSection(sectionID) {
      return self.sections
        .map((section) => section.findSectionId(sectionID))
        .find((a) => a !== null);
    },

    findSection(sectionID) {
      return this.getCurrentSection(sectionID) || null;
    },

    setCurrentSection(sectionID) {
      self.currentSection = this.getCurrentSection(sectionID) || null;
      self.selectedSectionId = sectionID;
    },

    deselectCurrentSection() {
      self.currentSection = null;
      self.selectedSectionId = null;
    },

    setHeadStyle(style) {
      self.headStyle = style;
    },

    toggleSashesOnly() {
      self.sashesOnly = !self.sashesOnly;
      self.sections[0].setOrigin({ x: 0, y: 0 });
      self.sections[0].setSectionSize(self.finalSize);
    },

    updateDimensions(dimensions) {
      self.dimensions = {
        ...self.dimensions,
        ...dimensions,
      };
    },

    setHasHeadRainDrip(hasHeadRainDrip) {
      self.hasHeadRainDrip = hasHeadRainDrip;
    },

    setCurrentDimensionalLevel(level, axis = 'x') {
      if (level + 1 > self.currentDimensionLevels[axis]) {
        self.currentDimensionLevels = {
          ...self.currentDimensionLevels,
          [axis]: level + 1,
        };
      }
    },

    resetCasement() {
      self.sections[0].sections.forEach((section) => {
        section.deleteSection();
      });
    },
  }));

export interface ICasement extends Instance<typeof Casement> {}

export function createCasement(state = null) {
  const initialState = {
    id: uuid(),
    store: {},
    selectedSectionId: null,
    currentSection: null,
    arcRise: 900,
    hiddenInBrick: 0,
    origin: { x: 0, y: 0 },
    absoluteOrigin: { x: 0, y: 0 },
    parameters: {},
    headStyle: 'standard',
    // headStyle: 'full_arch',
    leafStyle: 'standard',
    isFalseTransom: false,
    hasMidRail: false,
    midRailDrop: 0,
    transomDrop: 0,
    splitVia: null,
    openDirection: null,
    dimensions: { x: false, y: false, levelX: -1, levelY: -1 },
    sectionType: 'rootAncestor',
    sections: [],
  };
  const casementStore = Casement.create(state || initialState);

  if (!state) {
    casementStore.addSection(
      Section.create({
        store: casementStore,
        sectionType: 'root',
        sections: [],
        isHeadNeighbour: true,
        relativePosition: { top: 'head', left: 'jamb', bottom: 'sill', right: 'jamb' },
        origin: {
          x: 0,
          y: 0,
        },
        absoluteOrigin: {
          x: casementStore.visibleJambWidth,
          y: casementStore.visibleSillHeight,
        },
        size: {
          width: casementStore.visiualOpeningSizes.width,
          height: casementStore.visiualOpeningSizes.height,
        },
        calculateAbsOrigin: (abs, newOrigin, oldOrigin, self) => ({
          x: self.store.visibleJambWidth,
          y: self.store.visibleSillHeight,
        }),
        dimensions: { x: false, y: false, levelX: -1, levelY: -1 },
        id: uuid(),
      })
    );
  }

  return casementStore;
}

export function sectionFactory(sectionType, attributes) {
  let sectionTypes = {
    mullion: (attributes) => new Section(attributes),
    transom: (attributes) => new Section(attributes),
    root: (attributes) => new Section(attributes),
  };

  return sectionTypes[sectionType](attributes);
}

export default createCasement;

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = createCasement;
}
