import React from 'react';

import { itemToInput } from '@components/forms/GridForm';

const TableInputs = ({ methods, columns = [], rows = [] }) => {
  // const columns = [{ name: 'Openable' }, { name: 'Fixed' }];
  // const rows = [
  //   {
  //     name: 'Upper',
  //     inputs: [{ name: 'glazing_bars[upper][openable]' }, { name: 'glazing_bars[upper][fixed]' }],
  //   },
  //   {
  //     name: 'Lower',
  //     inputs: [{ name: 'glazing_bars[lower][openable]' }, { name: 'glazing_bars[lower][fixed]' }],
  //   },
  // ];

  return (
    <table>
      <thead>
        <tr>
          <th className="py-2 px-4 border-solid border-b border-r"></th>
          {columns.map((column, i) => (
            <th
              className="py-2 px-4 border-solid border-b border-r"
              style={{ minWidth: '100px', maxWidth: '110px' }}
              key={column.name + i}
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((rowData, i) => {
          return (
            <tr key={rowData.name + i}>
              <td className="py-2 px-2 border-solid border-b border-r">{rowData.name}</td>
              {rowData.inputs.map((rowInput, j) => (
                <td
                  className={`py-2 px-2 border-solid border-b border-r ${
                    rowInput.disabled && 'bg-orange-M100'
                  }`}
                  style={{ minWidth: '100px', maxWidth: '110px', ...(rowInput.cellStyle || {}) }}
                  key={rowInput.key || rowInput.name + i}
                >
                  {rowInput.static && <span className="ml-1">{rowInput.value}</span>}
                  {!rowInput.disabled &&
                    !rowInput.static &&
                    itemToInput(
                      {
                        label: null,
                        name: rowInput.name,
                        type: 'checkbox',
                        options: [{ label: null, value: 'true' }],
                        defaultValue: '',
                        inputClass:
                          'w-full ml-auto' + (rowInput.inputClass ? ' ' + rowInput.inputClass : ''),
                        wrapperStyles: {
                          marginBottom: 0,
                        },
                        disabled: rowInput.itemDisabled,
                        ...rowInput,
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableInputs;
