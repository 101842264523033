import API from 'apiv1';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable, { TruncatedCell } from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import TimberItemsForm from '@components/forms/TimberItemsForm';
import MaterialsMenu from '@components/menus/materials';
import Modal from '@components/modals/modal';
// import SectionTitle from '@components/section-title';
import Widget from '@components/widget';

import { setSuppliers } from '../actions/formActions';
import { store } from '../store';

const Page = ({ records, timberTypes, suppliers }) => {
  const dispatch = useDispatch();
  const formId = 'timberItemsForm';

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, [records]);

  const columns = [
    {
      Header: 'Type',
      accessor: 'timber_type_name',
    },
    {
      Header: 'Dimensions',
      accessor: 'dimensions',
    },
    {
      Header: 'Density (kg/m3)',
      accessor: 'timber_density',
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
    {
      Header: (props) => <div className="text-right w-full">Unit Price</div>,
      accessor: 'price',
      maxWidth: 50,
      Cell: ({ value }) => <span className="whitespace-nowrap text-right w-full">{value}</span>,
    },
    {
      Header: 'Unit',
      accessor: 'unit',
      maxWidth: 50,
    },
    {
      Header: 'VAT',
      accessor: 'vat_rate',
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) =>
        RecordActions(value, TimberItemsForm, API.deleteTimberItem, { timberTypes }),
    },
  ];

  return (
    <>
      {/* <SectionTitle title="Materials & Pricing " subtitle="Timber" /> */}

      <MaterialsMenu />

      <Widget
        title="Timber"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Add Timber"
              body={<TimberItemsForm formId={formId} timberTypes={timberTypes} />}
              buttonTitle="Add Timber"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
              buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <Datatable columns={columns} data={records} />
      </Widget>
    </>
  );
};

const TimberItemsPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default TimberItemsPage;
