import API from 'apiv1';
import React, { useState } from 'react';
import { BiCloudDownload, BiDuplicate } from 'react-icons/bi';
import { FaPoundSign } from 'react-icons/fa';
import { MdDescription } from 'react-icons/md';
import { RiBillLine, RiDeleteBin5Line, RiErrorWarningLine, RiMore2Fill } from 'react-icons/ri';
import NewModal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { Provider } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import tw from 'twin.macro';

import BoxedLayout from '@appSrc/layouts/content/Boxed';
import { store } from '@appSrc/store';

import ButtonIcon from '@components/buttons/ButtonIcon';
import Button from '@components/buttons/ButtonLink';
import Datatable, { TruncatedCell } from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import CustomProductItemForm from '@components/forms/ProductItems/Custom';
import ProjectIcon from '@components/icons/ProjectIcon';
import ImageWithFallback from '@components/images/ImageWithFallback';
import Modal from '@components/modals/modal';
import SimpleTooltip from '@components/tooltips/SimpleTooltip';

import '../../css/react-notifications.css';
import ProductSelector from './ProductSelector';

function cpFirst(word) {
  let lcWord = word.toLowerCase();
  return lcWord.charAt(0).toUpperCase() + lcWord.slice(1);
}

// NewModal.setAppElement(document.getElementById('modal-container'));

const STATUS_OPTIONS = [
  { label: 'Quoting', value: 'quoting' },
  { label: 'In-Progress', value: 'in_progress' },
  { label: 'Closed', value: 'closed' },
  { label: 'Completed', value: 'completed' },
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Page = ({ project, rfqs, productItems }) => {
  const [data, setData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [greetings, setGreetings] = useState(rfqs[0].greetings || '');
  const [notes, setNotes] = useState(rfqs[0].notes || '');
  const [showInstallation, setShowInstallation] = useState(
    rfqs[0]['show_installation_price'] || false
  );
  const [installationPrice, setInstallationPrice] = useState(rfqs[0]['installation_price'] || '');
  const [installationQuantity, setInstallationQuantity] = useState(
    rfqs[0]['installation_item_quantity'] || ''
  );
  const [installationDescription, setInstallationDescription] = useState(
    rfqs[0]['installation_item_description'] || ''
  );

  const [showDelivery, setShowDelivery] = useState(rfqs[0]['show_delivery_price'] || false);
  const [deliveryPrice, setDeliveryPrice] = useState(rfqs[0]['delivery_price'] || '');
  const [deliveryQuantity, setDeliveryQuantity] = useState(rfqs[0]['delivery_item_quantity'] || '');
  const [deliveryDescription, setDeliveryDescription] = useState(
    rfqs[0]['delivery_item_description'] || ''
  );

  const [showServiceFee, setShowServiceFee] = useState(rfqs[0]['show_service_fee'] || false);
  const [serviceFee, setServiceFee] = useState(rfqs[0]['service_fee'] || '');
  const [serviceFeeQuantity, setServiceFeeQuantity] = useState(
    rfqs[0]['service_fee_item_quantity'] || ''
  );
  const [serviceFeeDescription, setServiceFeeDescription] = useState(
    rfqs[0]['service_fee_item_description'] || ''
  );
  const [customItemRequested, setCustomItemRequested] = useState(false);

  const columns = [
    {
      Header: 'Type',
      maxWidth: 50,
      accessor: 'product_category',
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 50 }),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 200 }),
    },
    {
      Header: 'Ref',
      accessor: 'reference',
      maxWidth: 100,
      Cell: (props) => {
        return (
          <SimpleTooltip
            usePortal={true}
            hideArrow={false}
            portalContainer={document.getElementById('portal-container')}
            trigger="click"
            placement="bottom"
            tooltip={
              props.row.original.type !== 'custom' && (
                <div className="w-full">
                  <div className="bg-white">
                    <ImageWithFallback
                      style={{ maxWidth: 300, maxHeight: 400, width: 'auto' }}
                      src={props.row.original.product['frame_diagram']}
                      fallback="/missing.png"
                    />
                  </div>
                  <table className="table w-full text-black bg-white">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Quantity</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.row.original.product['price_breakdown'] &&
                        Object.entries(props.row.original.product['price_breakdown']).map(
                          ([category, value]) => (
                            <tr key={category}>
                              <td>{cpFirst(category)}</td>
                              {/* @ts-ignore */}
                              <td>{value.quantity}</td>
                              {/* @ts-ignore */}
                              <td className="text-right">{value.value}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              )
            }
          >
            {props.value}
          </SimpleTooltip>
        );
      },
    },
    {
      Header: 'Site Ref',
      maxWidth: 50,
      accessor: 'site',
      Cell: ({ value }) => TruncatedCell({ value, maxWidth: 50 }),
    },
    {
      Header: (props) => <div className="text-right w-full">Unit Cost</div>,
      accessor: 'unit_price',
      maxWidth: 100,
      Cell: ({ value }) => <div className="text-right w-full">{value}</div>,
    },
    {
      Header: 'Qty.',
      accessor: 'quantity',
      maxWidth: 35,
      Cell: ({ value }) => <div className="text-center w-full">{value}</div>,
    },
    {
      Header: 'Discount',
      accessor: 'discount',
      maxWidth: 75,
      Cell: ({ value }) => <div className="text-center w-full">{value}%</div>,
    },
    {
      Header: (props) => <div className="text-right w-full">Total Cost</div>,
      accessor: 'item_total',
      maxWidth: 75,
      Cell: ({ value }) => <div className="text-right w-full">{value}</div>,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      minWidth: 200,
      maxWidth: 200,
      Cell: ({ value, ...props }) => (
        <div className="flex flex-row">
          <SimpleTooltip
            usePortal={true}
            hideArrow={false}
            portalContainer={document.getElementById('portal-container')}
            trigger="hover"
            placement="top"
            tooltip={
              <div className="flex flex-row">
                {props.row.original.product && props.row.original.product.category === 'Box Sash' && (
                  <>
                    <SimpleTooltip
                      usePortal={true}
                      hideArrow={false}
                      portalContainer={document.getElementById('portal-container')}
                      trigger="hover"
                      placement="top"
                      tooltip={<span>Cutting List</span>}
                    >
                      <ButtonIcon
                        as="a"
                        href={`/box_sash_configs/${
                          props.row.original.product['box_sash'] &&
                          props.row.original.product['box_sash']['id']
                        }/cutting_list?rfq_id=${props.row.original['rfq_id']}&product_item_id=${
                          props.row.original['id']
                        }`}
                        bgColor="pink-60"
                        textColor="pink-60"
                        className="m-2"
                      >
                        <RiBillLine size={18} color="#663259" />
                      </ButtonIcon>
                    </SimpleTooltip>
                    <SimpleTooltip
                      usePortal={true}
                      hideArrow={false}
                      portalContainer={document.getElementById('portal-container')}
                      trigger="hover"
                      placement="top"
                      tooltip={<span>Replicate</span>}
                    >
                      <ButtonIcon
                        as="button"
                        onClick={() => replicateBoxSashProductItem(props.row.original['id'])}
                        bgColor="teal-60"
                        textColor="teal-60"
                        className="m-2"
                      >
                        <BiDuplicate size={18} color="#1bc5bd" />
                      </ButtonIcon>
                    </SimpleTooltip>
                  </>
                )}
                {props.row.original.product && props.row.original.product.category === 'Casement' && (
                  <>
                    <SimpleTooltip
                      usePortal={true}
                      hideArrow={false}
                      portalContainer={document.getElementById('portal-container')}
                      trigger="hover"
                      placement="top"
                      tooltip={<span>Cutting List</span>}
                    >
                      <ButtonIcon
                        as="a"
                        href={`/casements/${
                          props.row.original.product['casement'] &&
                          props.row.original.product['casement']['id']
                        }/cutting_list?rfq_id=${props.row.original['rfq_id']}&product_item_id=${
                          props.row.original['id']
                        }`}
                        bgColor="pink-60"
                        textColor="pink-60"
                        className="m-2"
                      >
                        <RiBillLine size={18} color="#663259" />
                      </ButtonIcon>
                    </SimpleTooltip>
                    <SimpleTooltip
                      usePortal={true}
                      hideArrow={false}
                      portalContainer={document.getElementById('portal-container')}
                      trigger="hover"
                      placement="top"
                      tooltip={<span>Replicate</span>}
                    >
                      <ButtonIcon
                        as="button"
                        onClick={() => replicateProductItem(props.row.original['id'])}
                        bgColor="teal-60"
                        textColor="teal-60"
                        className="m-2"
                      >
                        <BiDuplicate size={18} color="#1bc5bd" />
                      </ButtonIcon>
                    </SimpleTooltip>
                  </>
                )}

                <SimpleTooltip
                  usePortal={true}
                  hideArrow={false}
                  portalContainer={document.getElementById('portal-container')}
                  trigger="hover"
                  placement="top"
                  tooltip={<span>Edit Project Item</span>}
                >
                  <ButtonIcon
                    as="button"
                    onClick={() => {
                      setSelectedProduct(props.row.original['id']);
                      setModalIsOpen(true);
                    }}
                    bgColor="teal-60"
                    textColor="teal-60"
                    className="m-2"
                  >
                    <MdDescription size={18} color="#1bc5bd" />
                  </ButtonIcon>
                </SimpleTooltip>
              </div>
            }
          >
            <ButtonIcon as="button" bgColor="pink-60" textColor="pink-60" className="m-2">
              <RiMore2Fill size={18} color="#663259" />
            </ButtonIcon>
          </SimpleTooltip>

          {props.row.original.product &&
            props.row.original.product.category === 'Box Sash' &&
            RecordActions(value, `/product_items/${value}/edit`, API.deleteProductItem, {
              redirectToURL: `/projects/${project.id}`,
            })}
          {props.row.original.product &&
            props.row.original.product.category === 'Casement' &&
            RecordActions(value, `/product_items/casement/${value}/edit`, API.deleteProductItem, {
              redirectToURL: `/projects/${project.id}`,
            })}
          {props.row.original.type === 'custom' &&
            RecordActions(value, null, API.deleteProductItem, {
              redirectToURL: `/projects/${project.id}`,
            })}
        </div>
      ),
    },
  ];

  function handleBomSync() {
    API.syncBOM(rfqs[0]['id'])
      .then((resp) =>
        NotificationManager.success(
          'The Bill Of Materials has been synced successfully.',
          'Synced Successful',
          40000
        )
      )
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to sync the Bill of Materials, our team has been informed of this error.',
          'Sync Error',
          40000
        )
      );
  }

  function handleSaveQuote() {
    API.saveQuote(rfqs[0]['id'])
      .then((resp) => {
        NotificationManager.success('Quote saved successfully.', 'Synced Successful', 40000);
        window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to save the quote, our team has been informed of this error.',
          'Save Error',
          40000
        )
      );
  }

  function handleSaveGlassSummary() {
    API.saveGlassSummary(rfqs[0]['id'])
      .then((resp) => {
        NotificationManager.success(
          'Glass Summary saved successfully.',
          'Synced Successful',
          40000
        );
        window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to save the glass summary, our team has been informed of this error.',
          'Save Error',
          40000
        )
      );
  }

  function replicateProductItem(id) {
    API.replicateProductItem(id)
      .then((resp) => {
        NotificationManager.success('Product Item replicated.', 'Replication Successful', 40000);
        window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to replicate the product item, our team has been informed of this error.',
          'Replication Error',
          40000
        )
      );
  }

  function replicateBoxSashProductItem(id) {
    API.replicateBoxSashProductItem(id)
      .then((resp) => {
        NotificationManager.success('Product Item replicated.', 'Replication Successful', 40000);
        window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to replicate the product item, our team has been informed of this error.',
          'Replication Error',
          40000
        )
      );
  }

  function deleteAttachment(id) {
    API.deleteAttachment(id)
      .then((resp) => {
        NotificationManager.success('Quote deleted successfully.', 'Delete Successful', 40000);
        window.location.reload();
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while trying to delete the quote, our team has been informed of this error.',
          'Delete Error',
          40000
        )
      );
  }

  const saveQuoteDetails = () => {
    API.updateQuoteDetails(
      API.toFormData({
        greetings,
        notes,
        installation_price: installationPrice || 0,
        delivery_price: deliveryPrice || 0,
        service_fee: serviceFee || 0,
        show_installation_price: showInstallation,
        show_delivery_price: showDelivery,
        show_service_fee: showServiceFee,
        installation_item_description: installationDescription,
        installation_item_quantity: installationQuantity || 0,
        delivery_item_description: deliveryDescription,
        delivery_item_quantity: deliveryQuantity || 0,
        service_fee_item_description: serviceFeeDescription,
        service_fee_item_quantity: serviceFeeQuantity || 0,
      }),
      rfqs[0]['id']
    )
      .then((resp) => {
        NotificationManager.success('Quote details saved.', 'Save Successful', 40000);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error trying to update the quote details.',
          'Submission Error',
          40000
        )
      );
  };

  return (
    <>
      <SummaryContainer>
        <div className="flex flex-row w-full">
          <div className="flex flex-col">
            <span className="text-black text-lg self-start">Project: {project.name}</span>
            <span className="font-semibold my-2">Overview</span>
            <div className="inline-grid grid-cols-2 gap-2">
              <span>Total price:</span>
              <span className="text-right">{rfqs[0].stats.total_price}</span>
              <span>Discount:</span>
              <span className="text-right">{rfqs[0].stats.total_discount}</span>
              <span>Total after discount:</span>
              <span className="text-right">{rfqs[0].stats.total_discounted_price}</span>
            </div>
          </div>

          {/* TODO: Implement saving of the project status */}
          {/* <div className="ml-auto" style={{ width: '16rem' }}>
            <div className="mb-2 text-sm">Status</div>
            <Select
              options={STATUS_OPTIONS}
              menuPortalTarget={document.body}
              value={data['status'] || STATUS_OPTIONS[0]}
              onChange={(option) => setData({ ...data, status: option })}
              styles={{
                control: (styles) => ({
                  ...styles,
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  appearance: 'none',
                  backgroundColor: '#fff',
                  borderColor: '#e2e8f0',
                  borderWidth: '1px',
                  borderRadius: '0.675rem',
                  lineHeight: 1.5,
                  fontSize: '0.825rem',
                  width: '100%',
                }),
                valueContainer: (styles) => ({
                  ...styles,
                  paddingTop: '0.25rem',
                  paddingRight: '0.5rem',
                  paddingBottom: '0.25rem',
                  paddingLeft: '0.5rem',
                  borderRadius: '0.675rem',
                }),
                menu: (styles) => ({
                  ...styles,
                  paddingTop: '0.25rem',
                  paddingRight: '0.5rem',
                  paddingBottom: '0.25rem',
                  paddingLeft: '0.5rem',
                  borderRadius: '0.675rem',
                  zIndex: 9999,
                }),
                menuPortal: (styles) => ({
                  ...styles,
                  zIndex: 9990,
                }),
              }}
            />
          </div> */}
        </div>
      </SummaryContainer>
      <SectionContainer>
        <SectionTitle>Products</SectionTitle>
        <div className="flex flex-row-reverse">
          <Modal
            body={
              <ProductSelector
                projectID={project.id}
                rfqID={rfqs[0]['id']}
                onCustomFormRequest={setCustomItemRequested}
              />
            }
            buttonHidden={true}
            title={''}
            modalButtonText={'Add New Product'}
          />
          <Button className="mr-2" as="button" onClick={handleBomSync}>
            Sync BOM
          </Button>
          <Button className="mr-2" as="button" onClick={handleSaveQuote}>
            Save Quote
          </Button>
          <Button className="mr-2" as="button" onClick={handleSaveGlassSummary}>
            Save Glass Summary
          </Button>
        </div>
        <div>
          <Datatable columns={columns} data={productItems} />
        </div>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>Saved Quotes</SectionTitle>
        <div className="flex flex-col">
          <table className="table">
            <thead>
              <tr>
                <th className="text-left">Name</th>
                <th className="text-left">Created On</th>
                <th className="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rfqs[0].attachments.map((attachment) => (
                <tr key={attachment.id}>
                  <td>{attachment.name}</td>
                  <td>{attachment.created_at}</td>
                  <td>
                    {attachment.url && attachment.url.length !== 0 && (
                      <SimpleTooltip
                        usePortal={true}
                        hideArrow={false}
                        portalContainer={document.getElementById('portal-container')}
                        trigger="hover"
                        placement="top"
                        tooltip={<span>Download</span>}
                      >
                        <ButtonIcon
                          as="a"
                          href={attachment.url}
                          bgColor="teal-60"
                          textColor="teal-60"
                          className="m-2"
                          target="_blank"
                        >
                          <BiCloudDownload size={18} color="#1bc5bd" />
                        </ButtonIcon>
                      </SimpleTooltip>
                    )}
                    <SimpleTooltip
                      usePortal={true}
                      hideArrow={false}
                      portalContainer={document.getElementById('portal-container')}
                      trigger="hover"
                      placement="top"
                      tooltip={<span>Delete</span>}
                    >
                      <Modal
                        title="Delete"
                        icon={<RiErrorWarningLine size={32} className={'text-red-500'} />}
                        body={
                          <p>
                            This action is irreversible.
                            <br />
                            Are you sure you want to continue?
                          </p>
                        }
                        handleButtonClick={() => deleteAttachment(attachment.id)}
                        buttonTitle="Delete"
                        buttonComponent={(showCallback, _) => (
                          <ButtonIcon onClick={(e) => showCallback(e)} as="button" className="m-2">
                            <RiDeleteBin5Line size={18} />
                          </ButtonIcon>
                        )}
                        buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
                      />
                    </SimpleTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>Extra services</SectionTitle>
        <div className="flex flex-col">
          <span className="font-medium pb-4">Installation</span>
          <div className={`form-element`}>
            <div className={`flex items-start justify-start flex-col`}>
              <label className="flex items-center justify-start space-x-2">
                <input
                  type="checkbox"
                  value={showInstallation}
                  className={`form-checkbox h-4 w-4`}
                  onChange={(e) => setShowInstallation(e.target.checked)}
                  defaultChecked={showInstallation}
                />
                <span>Show installation in quote</span>
              </label>
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Installation Price</div>
            <div className={`flex items-start justify-start flex-col`}>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FaPoundSign />
                </span>
                <input
                  type="number"
                  step={0.01}
                  min={0}
                  autoComplete="off"
                  value={installationPrice}
                  className={`rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900 `}
                  onChange={(e) => setInstallationPrice(e.target.value)}
                  defaultChecked={installationPrice}
                />
              </div>
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Quantity</div>
            <div className={`flex items-start justify-start flex-col`}>
              <input
                type="number"
                step={1}
                min={0}
                value={installationQuantity}
                className={`rounded-md pr-8 focus:outline-none focus:bg-white focus:text-gray-900`}
                onChange={(e) => setInstallationQuantity(e.target.value)}
                defaultChecked={installationQuantity}
              />
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Installation line item description</div>
            <div className={`flex items-start justify-start flex-col`}>
              <textarea
                rows={6}
                className="form-textarea md:w-full lg:w-1/2"
                value={installationDescription}
                onChange={(e) => setInstallationDescription(e.target.value)}
              />
            </div>
          </div>

          <span className="font-medium pb-4 mt-8">Delivery</span>
          <div className={`form-element`}>
            <div className={`flex items-start justify-start flex-col`}>
              <label className="flex items-center justify-start space-x-2">
                <input
                  type="checkbox"
                  value={showDelivery}
                  className={`form-checkbox h-4 w-4`}
                  onChange={(e) => setShowDelivery(e.target.checked)}
                  defaultChecked={showDelivery}
                />
                <span>Show delivery in quote</span>
              </label>
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Delivery Price</div>
            <div className={`flex items-start justify-start flex-col`}>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FaPoundSign />
                </span>
                <input
                  type="number"
                  step={0.01}
                  min={0}
                  autoComplete="off"
                  value={deliveryPrice}
                  className={`rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900 `}
                  onChange={(e) => setDeliveryPrice(e.target.value)}
                  defaultChecked={deliveryPrice}
                />
              </div>
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Quantity</div>
            <div className={`flex items-start justify-start flex-col`}>
              <input
                type="number"
                step={1}
                min={0}
                value={deliveryQuantity}
                className={`rounded-md pr-8 focus:outline-none focus:bg-white focus:text-gray-900`}
                onChange={(e) => setDeliveryQuantity(e.target.value)}
                defaultChecked={deliveryQuantity}
              />
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Delivery line item description</div>
            <div className={`flex items-start justify-start flex-col`}>
              <textarea
                rows={6}
                className="form-textarea md:w-full lg:w-1/2"
                value={deliveryDescription}
                onChange={(e) => setDeliveryDescription(e.target.value)}
              />
            </div>
          </div>

          <span className="font-medium pb-4 mt-8">Service fee</span>
          <div className={`form-element`}>
            <div className={`flex items-start justify-start flex-col`}>
              <label className="flex items-center justify-start space-x-2">
                <input
                  type="checkbox"
                  value={showServiceFee}
                  className={`form-checkbox h-4 w-4`}
                  onChange={(e) => setShowServiceFee(e.target.checked)}
                  defaultChecked={showServiceFee}
                />
                <span>Show service fee in quote</span>
              </label>
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Service Fee Price</div>
            <div className={`flex items-start justify-start flex-col`}>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <FaPoundSign />
                </span>
                <input
                  type="number"
                  step={0.01}
                  min={0}
                  autoComplete="off"
                  value={serviceFee}
                  className={`rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900 `}
                  onChange={(e) => setServiceFee(e.target.value)}
                  defaultChecked={serviceFee}
                />
              </div>
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Quantity</div>
            <div className={`flex items-start justify-start flex-col`}>
              <input
                type="number"
                step={1}
                min={0}
                value={serviceFeeQuantity}
                className={`rounded-md pr-8 focus:outline-none focus:bg-white focus:text-gray-900`}
                onChange={(e) => setServiceFeeQuantity(e.target.value)}
                defaultChecked={serviceFeeQuantity}
              />
            </div>
          </div>
          <div className={`form-element`}>
            <div className={'form-label'}>Service Fee line item description</div>
            <div className={`flex items-start justify-start flex-col`}>
              <textarea
                rows={6}
                className="form-textarea rounded-md focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent md:w-full lg:w-1/2"
                value={serviceFeeDescription}
                onChange={(e) => setServiceFeeDescription(e.target.value)}
              />
            </div>
          </div>

          <Button as="button" className="mt-8" style={{ maxWidth: 70 }} onClick={saveQuoteDetails}>
            Save
          </Button>
        </div>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>Quote details</SectionTitle>
        <div className="flex flex-col">
          <span className="font-medium pb-4">Descriptions & Greetings</span>
          <textarea
            className="form-textarea rounded-md focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent md:w-full lg:w-1/2"
            rows={6}
            value={greetings}
            onChange={(e) => setGreetings(e.target.value)}
          />

          <span className="font-medium pb-4 mt-8">Notes</span>
          <textarea
            className="md:w-full lg:w-1/2 form-textarea rounded-md focus:ring-2 focus:ring-blue-M500 focus:outline-none focus:border-transparent"
            rows={6}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Button as="button" className="mt-8" style={{ maxWidth: 70 }} onClick={saveQuoteDetails}>
            Save
          </Button>
        </div>
      </SectionContainer>
      <NewModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Update Product Item"
      >
        {/* @ts-ignore */}
        <CustomProductItemForm id={selectedProduct} onSubmit={() => setModalIsOpen(false)} />
      </NewModal>
      <NewModal
        isOpen={customItemRequested}
        onRequestClose={() => setCustomItemRequested(false)}
        style={customStyles}
        contentLabel="Custom Product Item"
      >
        <CustomProductItemForm id={selectedProduct} rfqID={rfqs[0].id} />
      </NewModal>
    </>
  );
};

const ProjectSidebar = ({ rfqs }) => {
  return (
    // sidebar-content flex-column-fluid pb-10 pt-9 px-5 px-lg-10
    <div className="flex flex-auto w-full pb-8 px-5 lg:px-5" style={{ paddingTop: '2.25rem' }}>
      <div
        className="card card-custom bg-white card-shadowless gutter-b rounded-metronic"
        style={{ minWidth: '100%' }}
      >
        <div className="card-header border-0 mt-5 px-5">
          <div className="flex flex-col">
            <span className="text-base font-semibold text-dark">Summaries</span>
            {/* <span className="text-muted mt-1 font-normal text-xsm">Pending 10 tasks</span> */}
          </div>
        </div>
        <div className="card-body">
          <div className="flex flex-row">
            <ButtonIcon
              as="a"
              href={`/rfqs/${rfqs[0]['id']}`}
              bgColor="pink-60"
              textColor="pink-60"
            >
              <ProjectIcon size={26} color="#663259" />
            </ButtonIcon>
            <a
              href={`/rfqs/${rfqs[0]['id']}`}
              className="text-black pl-2 self-center font-medium text-sm hover:text-deep-purple-M700 no-underline"
            >
              Quote preview
            </a>
          </div>
          <div className="flex flex-row mt-2">
            <ButtonIcon
              as="a"
              href={`/rfqs/${rfqs[0]['id']}/bill_of_materials`}
              bgColor="teal-60"
              textColor="teal-60"
            >
              <RiBillLine size={26} color="#1bc5bd" />
            </ButtonIcon>
            <a
              href={`/rfqs/${rfqs[0]['id']}/bill_of_materials`}
              className="text-black pl-2 self-center font-medium text-sm hover:text-deep-purple-M700 no-underline"
            >
              Bill of Materials
            </a>
          </div>
          <div className="flex flex-row mt-2">
            <ButtonIcon
              as="a"
              href={`/rfqs/${rfqs[0]['id']}/glass_summary`}
              bgColor="teal-60"
              textColor="teal-60"
            >
              <RiBillLine size={26} color="#1bc5bd" />
            </ButtonIcon>
            <a
              href={`/rfqs/${rfqs[0]['id']}/glass_summary`}
              className="text-black pl-2 self-center font-medium text-sm hover:text-deep-purple-M700 no-underline"
            >
              Glass Summary
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryContainer = styled.div`
  ${tw`flex flex-col w-full space-y-16 px-6 py-8`}
`;

const SectionContainer = styled.div`
  ${tw`flex flex-col w-full space-y-12 px-6 pb-8`}
`;

const SectionTitle = styled.div`
  ${tw`text-base font-semibold`}
`;

const ProjectPage = (...props) => {
  return (
    <Provider store={store}>
      {BoxedLayout(<Page {...props[0]} {...props[1]} />, [
        <ProjectSidebar key="proj-sidebar" {...props[0]} {...props[1]} />,
      ])}
    </Provider>
  );
};

export default ProjectPage;
