import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';
import BoxedLayout from '@appSrc/layouts/content/Boxed';

import Casement from '@components/configurators/Casement';
import Widget from '@components/widget';

import { store } from '../store';

const Page = ({
  snapshot,
  data,
  casementId,
  hardwareSet,
  finishSystem,
  sectionEditorData,
  productData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setConfigurationData('timber', productData['timber_types'] || []));
    dispatch(setConfigurationData('hardwareSets', productData['hardware_sets'] || []));
    dispatch(setConfigurationData('hardware', productData['hardware'] || []));
    dispatch(setConfigurationData('glazingUnits', productData['glazing_units'] || []));
    dispatch(setConfigurationData('finishSystems', productData['finish_systems'] || []));
    dispatch(setConfigurationData('timberFinishes', productData['timber_finishes'] || []));

    dispatch(setFormData('hardwareSet', hardwareSet));
    dispatch(setFormData('finishSystem', finishSystem));
    dispatch(setFormData('sectionEditors', sectionEditorData || {}));
  }, [productData]);

  return (
    <Widget title="Edit Casement">
      <Casement snapshot={snapshot} data={data} editMode={true} casementId={casementId} />
    </Widget>
  );
};

const EditCasementPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default EditCasementPage;
