import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import '../../css/react-notifications.css';

import { updateSelectionKeys, insertKey, update } from '@utils/formHelpers';
import ContextForm from './ContextForm';
import { Alert } from '../alerts';
import API from 'apiv1';
import DebugConsole from '../DebugOptions/DebugConsole';

const MODE_HANDLER = {
  edit: API.updateDrawingInstruction,
  // default: API.createClient,
};

const ClientsForm = ({ message = null, formId, recordId = null, mode = 'edit' }) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();

  useEffect(() => {
    if (recordId == null) return;

    API.getDrawingInstruction(recordId).then((data) => {
      setDefaultValues(data);
      const { availableConfigs, ...formData } = data;
      reset({ ...formData });
    });
  }, [reset, recordId]);

  const onSubmitFn = (data) => {
    // NotificationManager.success('Success message', 'Title here');
    // updateSelectionKeys(data, 'box_sash_config');
    MODE_HANDLER[mode || 'default'](API.toFormData({ ...data }), recordId)
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: [' w-64', 'full '],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Name',
          error: { required: 'Please enter a name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Box Sash Config',
          error: { required: 'Please select a config' },
          name: 'box_sash_config_id',
          type: 'select',
          options: defaultValues.availableConfigs || [],
          defaultValue: '',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row" style={{ minWidth: '600px' }}>
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            formId={formId}
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          />
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default ClientsForm;
