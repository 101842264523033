import React, { useState } from 'react';

import { Alert } from '../alerts';
import Validation from '../forms/validation';

const urlEncoder = function (obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? urlEncoder(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      );
    }
  }
  return str.join('&');
};

const loginCall = async (data) => {
  if (data && data.length !== 0) {
    await fetch('/users/sign_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // @ts-ignore
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      mode: 'no-cors',
      redirect: 'follow',
      // @ts-ignore
      body: urlEncoder({ user: data }),
    })
      .then((response) => {
        window.location.pathname = '/';
      })
      .catch((error) => console.log(error));
  }
};

const Login = ({ message = null }) => {
  const [data, onSubmit] = useState(null);

  loginCall(data);

  let items = [
    {
      label: 'Email',
      error: { required: 'Please enter a valid email' },
      name: 'email',
      type: 'email',
      placeholder: 'Enter you email',
    },
    {
      label: 'Password',
      error: {
        required: 'Password is required',
        minLength: {
          value: 8,
          message: 'Your password should have at least 4 characters',
        },
        maxLength: {
          value: 32,
          message: 'Your password should have no more than 8 characters',
        },
      },
      name: 'password',
      type: 'password',
      placeholder: 'Enter your password',
    },
  ];
  return (
    <>
      <div className="flex flex-col">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        {/* @ts-ignore */}
        <Validation items={items} onSubmit={onSubmit} />
      </div>
    </>
  );
};

export default Login;
