import React, { useState } from 'react';

import { pythagaros, bezierArcPoints } from '@utils/geometry';

import GlazingBeads from './GlazingBeads';

const Fixed = ({ section, ...shapeProps }) => {
  let radius = section.store.arcRadius;

  let topBead = {};

  if (section.isHeadNeighbour && section.store.headStyle !== 'standard') {
    const glazingBeadWidth = section.store['frame_glazing_bead'].width;

    const radiusReduction =
      section.store.finalSize.height - (section.absoluteOrigin.y + section.size.height);
    const upperArcRadius = radius - radiusReduction;

    const arcCenterY = section.size.height - upperArcRadius; // relative to section
    const arcCenterX = section.store.finalSize.width / 2 - section.absoluteOrigin.x; // relative to section
    const arcCenter = { x: arcCenterX, y: arcCenterY };

    const lowerArcRadius = upperArcRadius - glazingBeadWidth;
    const upperStartY = pythagaros(arcCenterX, upperArcRadius) + arcCenterY;
    const beadLowerY = pythagaros(arcCenterX - glazingBeadWidth, lowerArcRadius) + arcCenterY;

    topBead = {
      upperY: upperStartY,
      lowerY: beadLowerY,
      radius: lowerArcRadius,
      center: arcCenter,
    };
  }

  return (
    <>
      {/* Beads */}
      <GlazingBeads section={section} topBead={topBead} />
    </>
  );
};

export default Fixed;
