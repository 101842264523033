import { intersection } from 'lodash/array';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { setHardwareSet } from '@appSrc/actions/configuratorActions';
import { setHardwareSetItem } from '@appSrc/actions/configuratorActions';

import DataModal from '@components/DataModal';
import { itemToInput } from '@components/forms/GridForm';

import Card from '../Card';
import Section, { InputContainer } from '../Section';

export const HARDWARE_SET = 'hardwareSet';

const HARDWARE_SET_ITEMS = [
  'pulley',
  'lift',
  'handle',
  'fitch_fastener',
  'vent_lock',
  'cord',
  'seal',
  'weight_supplier',
];

const HARDWARE_FINSIH_ITEMS = ['pulley', 'lift', 'handle', 'fitch_fastener', 'vent_lock'];

const snakeCaseToTitleCase = (str) => {
  let tempStr = str.split('_');
  return tempStr.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};

const Hardware = ({
  hardwareSets = [],
  hardware = [],
  weightSuppliers = [],
  sealHardware = [],
}) => {
  const methods = useFormContext();
  const { hardwareSet, hardwareSetItems } = useSelector((state) => {
    return {
      // @ts-ignore
      hardwareSet: state.configuratorForms.hardwareSet,
      // @ts-ignore
      hardwareSetItems: state.configuratorForms.hardwareSetItems,
    };
  });
  const dispatch = useDispatch();

  const findAvailableHardwareFinish = () => {
    let hardwareFinishes = [];
    HARDWARE_FINSIH_ITEMS.forEach((itemSetName) => {
      let hardwareSetItem = hardwareSetItems[itemSetName];
      if (hardwareSetItem && hardwareFinishes.length === 0) {
        hardwareFinishes = hardwareSetItem['finish_full'].split(', ');
      }
      if (hardwareSetItem && hardwareFinishes.length > 0) {
        hardwareFinishes = intersection(
          hardwareFinishes,
          hardwareSetItem['finish_full'].split(', ')
        );
      }
    });
    return hardwareFinishes.map((hardwareFinish) => ({
      label: hardwareFinish,
      value: hardwareFinish,
    }));
  };

  const handleSelection = (item) => {
    dispatch(setHardwareSet(HARDWARE_SET, item));
    methods.setValue('hardware_finish_id', '');
    HARDWARE_FINSIH_ITEMS.forEach((itemSetName) => {
      dispatch(
        setHardwareSetItem(
          itemSetName,
          item[itemSetName]['hardware_variant'] || item[itemSetName]['hardware']
        )
      );
    });

    return true;
  };

  const handleSelectionChange = (setItemName, item) => {
    methods.setValue('hardware_finish_id', '');
    dispatch(setHardwareSetItem(setItemName, item));
    return true;
  };

  const buttons = [
    <DataModal data={hardwareSets} materialKey="hardwareSet" onItemSelect={handleSelection} />,
  ];

  const renderCard = (item) => {
    if (item && item.label) {
      return <Card defaultOpen={false} title={item['label']} narrowStyle />;
    } else if (item) {
      return (
        <Card
          defaultOpen={false}
          title={`${item['name']} ${!item['finish_full'] ? item['finish'] : ''}`}
          narrowStyle
        />
      );
    } else {
      return <Card defaultOpen={false} blank={true} narrowStyle />;
    }
  };

  return (
    <Section title={'Hardware'}>
      <InputContainer className="flex flex-row space-x-12 w-full">
        <InputContainer className="flex flex-col space-y-2 w-2/5">
          <span>Hardware Set</span>
          <InputContainer className="flex flex-row space-x-2 w-full">
            {renderCard(hardwareSet)}

            <div className="flex self-center">{buttons}</div>
          </InputContainer>
          {hardwareSet && (
            <InputContainer className="flex flex-row space-x-2 w-full">
              {itemToInput(
                {
                  label: 'Hardware Finish',
                  name: 'hardware_finish_id',
                  type: 'newSelect',
                  options: findAvailableHardwareFinish(),
                  defaultValue: '',
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </InputContainer>
          )}

          {hardwareSet &&
            HARDWARE_SET_ITEMS.map((setItemName) => (
              <React.Fragment key={setItemName}>
                <span className="mb-2">{snakeCaseToTitleCase(setItemName)}</span>
                <InputContainer key={setItemName} className="flex flex-row space-x-2 w-4/5">
                  {renderCard(hardwareSetItems[setItemName])}
                  <div className="flex flex-col space-y-2 self-center">
                    <DataModal
                      data={
                        setItemName === 'weight_supplier'
                          ? weightSuppliers
                          : setItemName === 'seal'
                          ? sealHardware
                          : hardware
                      }
                      materialKey={
                        setItemName === 'weight_supplier'
                          ? 'weightSupplier'
                          : setItemName === 'seal'
                          ? 'hardwareVariant'
                          : 'hardware'
                      }
                      onItemSelect={(hardware) => handleSelectionChange(setItemName, hardware)}
                      clearable
                      handleClear={() => handleSelectionChange(setItemName, null)}
                    />
                  </div>
                </InputContainer>
              </React.Fragment>
            ))}
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default Hardware;
