import { combineReducers } from 'redux';

import config from './config';
import leftSidebar from './left-sidebar';
import palettes from './palettes';
import navigation from './navigation';
import forms from './forms';
import debug from './debug';
import configuratorForms from './configuratorForms';
import configurationData from './configurationData';
import glazingUnitForms from './glazingUnitForms';
import selectedMaterials from './selectedMaterials';

const rootReducer = combineReducers({
  config,
  configuratorForms,
  debug,
  forms,
  glazingUnitForms,
  leftSidebar,
  navigation,
  palettes,
  selectedMaterials,
  configData: configurationData,
});

export default rootReducer;
