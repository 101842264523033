import _ from 'lodash';

export function omit(object, paths) {
  return _.omit(object, paths);
}

export function unpackSelection(entry) {
  if (_.isArray(entry)) {
    return entry.map(unpackSelection);
  } else {
    return entry.value;
  }
}

export function unpackSelectionKeys(object: any, keyPath: string) {
  if (_.has(object, keyPath) === false) {
    console.error(`Path does not exist in object: ${keyPath}`);
    return null;
  }

  return unpackSelection(_.get(object, keyPath));
}

export function updateSelectionKeys(object: any, keyPath: string) {
  if (_.has(object, keyPath) === false) {
    console.error(`Path does not exist in object: ${keyPath}`);
    return null;
  }

  return _.update(object, keyPath, unpackSelection);
}

export function insertKey(object, keyPath, value) {
  return _.set(object, keyPath, value);
}

export function update(object, keyPath: string, updater: (_) => any) {
  _.update(object, keyPath, updater);
}
