import API from 'apiv1';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FaPercentage } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';

import '@appSrc/css/react-notifications.css';

import Button from '@components/buttons/ButtonLink';
import { itemToInput } from '@components/forms/GridForm';
import SwitchInput from '@components/forms/Switch';

import UUID from '@utils/uuid';

import ContextForm from '../ContextForm';
import OvearheadItems, { Employees } from './OverheadItems';

type Item = {
  name: string;
  cost: number;
  system_record: boolean;
  enabled: boolean;
  itemID?: string;
};

const addItemIds = (items: Item[] | null): Array<Item | null> => {
  return (items || []).map((item) => ({ ...item, itemID: UUID() }));
};

const defaultEmployees = (modelData) => {
  if (modelData.length > 0) {
    return modelData;
  }

  return [{ name: '', hourly_rate: 0, weekly_work_hours: 0, gid: UUID() }];
};

const PricingSettingForm = ({ pricingSetting }) => {
  const [helpNeeded, setHelpNeeded] = useState(!pricingSetting['overhead_cost_provided']);
  const [detailed, setDetailed] = useState(pricingSetting['detailed_labour']);
  const { pricing_setting_items, ...restOfParams } = pricingSetting;
  const [costItems, setCostItems] = useState(addItemIds(pricingSetting['pricing_setting_items']));
  const [employees, setEmployees] = useState(
    defaultEmployees(pricingSetting['pricing_setting_employees'])
  );
  const { reset, ...methods } = useForm({ defaultValues: restOfParams });

  const handleChange = (event, itemID, type = null) => {
    if (type === 'enabled') {
      setCostItems(
        costItems.map((costItem) => {
          if (itemID === costItem.itemID) {
            return { ...costItem, enabled: !costItem.enabled };
          }
          return costItem;
        })
      );
      return;
    }

    if (type === 'name') {
      setCostItems(
        costItems.map((costItem) => {
          if (itemID === costItem.itemID) {
            return { ...costItem, name: event.target.value };
          }
          return costItem;
        })
      );
      return;
    }

    if (type === 'cost') {
      let costTotal = 0;
      const newCostItems = costItems.map((costItem) => {
        if (itemID === costItem.itemID) {
          costTotal += Number(event.target.value);
          return { ...costItem, cost: event.target.value };
        }
        costTotal += Number(costItem.cost);
        return costItem;
      });

      setCostItems(newCostItems);
      methods.setValue('overhead_cost', costTotal, { shouldDirty: true });
      return;
    }
  };

  const handleEmployeeUpdate = (gid, data) => {
    const newEmployees = employees.map((employee) => {
      if (employee.gid === gid) {
        return { ...employee, ...data };
      }
      return employee;
    });
    setEmployees(newEmployees);
  };

  const addNewEmployee = () => {
    setEmployees([...employees, { name: '', hourly_rate: 0, weekly_work_hours: 0, gid: UUID() }]);
  };

  const handleAddNew = () => {
    setCostItems([
      ...costItems,
      { itemID: UUID(), cost: 0, name: '', enabled: true, system_record: false },
    ]);
  };

  const handleRemoveItem = (itemID: string): void => {
    setCostItems(costItems.filter((item) => item.itemID !== itemID));
    // methods.unregister(`pricing_setting_items${itemID}[name]`);
  };

  const removeEmployee = (gid: string): void => {
    setEmployees(employees.filter((employee) => employee.gid !== gid));
  };

  const filterEmployees = (employees) => {
    return employees.filter((employee) => employee.name !== '');
  };

  const onSubmitFn = (data) => {
    API.updatePricingSetting(
      API.toFormData({
        ...data,
        pricing_setting_items: helpNeeded ? JSON.stringify(costItems) : null,
        overhead_cost_provided: !helpNeeded,
        detailed_labour: detailed,
        pricing_setting_employees: JSON.stringify(filterEmployees(employees)),
      }),
      pricingSetting.id
    )
      .then((resp) => {
        NotificationManager.success(
          'Pricing settings updated successfully',
          'Update Successful',
          40000
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const items = [
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: (
            <span>
              Overhead absorption <br /> cost per labour hour
            </span>
          ),
          error: {},
          disabled: true,
          name: 'hourly_overhead_cost',
          type: 'currency',
          inlineClass: 'w-1/3',
        },
      ],
    },
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Price per labour hour',
          error: {},
          disabled: true,
          name: 'price_per_labour_hour',
          type: 'currency',
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <FormProvider {...methods} reset={reset}>
        <div className="max-w-md">
          <SwitchInput
            initialState={detailed}
            leftLabel="Simplified labour cost breakdown"
            rightLabel="Detailed employee breakdown"
            onChange={setDetailed}
          />
          {detailed && (
            <div className="max-w-md">
              <table className="table-auto">
                <thead>
                  <tr>
                    <th style={{ maxWidth: '300px', width: '300px' }}>Name</th>
                    <th>Work hours per week</th>
                    <th>Rate</th>
                    <th></th>
                  </tr>
                </thead>
                <Employees
                  employees={employees}
                  onDelete={removeEmployee}
                  onAdd={addNewEmployee}
                  onChange={handleEmployeeUpdate}
                />
              </table>
            </div>
          )}
          {!detailed && (
            <>
              <div className="flex flex-row space-x-8 mt-4">
                {itemToInput(
                  {
                    label: 'Length of working day (hours)',
                    error: { required: 'Please provide the length of the working day' },
                    name: 'length_of_working_day',
                    type: 'number',
                    wrapperStyles: { width: '50%' },
                    inputClass: 'w-1/2',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Working days in a month',
                    error: { required: 'Please provide the number of working days in a month' },
                    name: 'working_days_in_a_month',
                    type: 'number',
                    inputClass: 'w-1/2',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </div>
              <div className="flex flex-row space-x-8 mt-4 mb-4">
                {itemToInput(
                  {
                    label: 'Number of workshop workers',
                    error: { required: 'Please provide the number of workshop workers' },
                    name: 'workshop_workers_count',
                    type: 'number',
                    wrapperStyles: { width: '50%' },
                    inputClass: 'w-1/2',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Labour cost per hour',
                    error: { required: 'Please provide your labour cost' },
                    name: 'labour_cost',
                    type: 'currency',
                    inputClass: 'w-1/2',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </div>
            </>
          )}
        </div>
        <ContextForm
          gridItems={items}
          formId={'business'}
          onSubmit={onSubmitFn}
          formStyle="flex flex-row"
        >
          <div className="max-w-md">
            <SwitchInput
              initialState={helpNeeded}
              leftLabel="I know my overheads"
              rightLabel="I need help calculating my overheads"
              onChange={setHelpNeeded}
            />
            <div className="max-w-xs">
              {itemToInput(
                {
                  label: 'Overhead cost per month',
                  error: { required: !helpNeeded },
                  disabled: helpNeeded,
                  name: 'overhead_cost',
                  type: 'currency',
                  inlineClass: 'w-1/3',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </div>

            {helpNeeded && (
              <div className="max-w-md">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th style={{ maxWidth: '300px', width: '300px' }}>Name</th>
                      <th>Cost</th>
                      <th>Use</th>
                      <th></th>
                    </tr>
                  </thead>
                  <OvearheadItems
                    items={costItems}
                    onAdd={handleAddNew}
                    onDelete={handleRemoveItem}
                    onChange={handleChange}
                  />
                </table>
              </div>
            )}

            <h5 className="mt-8">Profit & VAT</h5>

            <div className="flex flex-row max-w-xs mt-2">
              {itemToInput(
                {
                  label: 'Profit margin on labour',
                  error: { required: 'Please enter your profit margin for labour' },
                  name: 'profit_margin',
                  type: 'number',
                  icon: <FaPercentage />,
                  inputClass: 'w-3/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Profit margin on materials',
                  name: 'materials_profit_margin',
                  type: 'number',
                  icon: <FaPercentage />,
                  inputClass: 'w-3/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </div>
            {itemToInput(
              {
                label: 'VAT rate',
                name: 'vat_rate',
                type: 'number',
                icon: <FaPercentage />,
                inputClass: 'w-1/4',
              },
              null,
              methods.register,
              methods.errors,
              methods.control
            )}
          </div>

          <div style={{ maxWidth: '140px' }}>
            <Button as="button" type="submit" wrap={false}>
              Update Settings
            </Button>
          </div>
        </ContextForm>
      </FormProvider>
    </div>
  );
};

export default PricingSettingForm;
