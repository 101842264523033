import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin5Line, RiErrorWarningLine } from 'react-icons/ri';

import ButtonIcon from '@components/buttons/ButtonIcon';

import Modal from '../modals/modal';
import SimpleTooltip from '../tooltips/SimpleTooltip';

const RecordActions = (
  value,
  RecordFormComponent,
  recordDeleteFn,
  formParams = {},
  conditionals = {}
) => {
  const renderEditContent = () => {
    if (!RecordFormComponent) {
      return null;
    } else if (typeof RecordFormComponent === 'string') {
      return (
        <ButtonIcon href={RecordFormComponent} as="a" className="m-2">
          <FiEdit size={18} />
        </ButtonIcon>
      );
    } else {
      return (
        <Modal
          title="Update"
          body={
            <RecordFormComponent
              formId={'editRecordForm' + value}
              mode="edit"
              recordId={value}
              {...formParams}
            />
          }
          buttonTitle="Update"
          buttonComponent={(showCallback, _) => (
            <ButtonIcon onClick={(e) => showCallback(e)} as="button" className="m-2">
              <FiEdit size={18} />
            </ButtonIcon>
          )}
          buttonFormId={'editRecordForm' + value}
          // buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
        />
      );
    }
  };

  const deleteCondition = conditionals['delete'] ? conditionals['delete'].fn() : true;

  return (
    <div className="flex flex-row">
      <SimpleTooltip
        usePortal={true}
        hideArrow={false}
        portalContainer={document.getElementById('portal-container')}
        trigger="hover"
        placement="top"
        tooltip={<span>Edit</span>}
      >
        {renderEditContent()}
      </SimpleTooltip>

      {recordDeleteFn && deleteCondition && (
        <SimpleTooltip
          usePortal={true}
          hideArrow={false}
          portalContainer={document.getElementById('portal-container')}
          trigger="hover"
          placement="top"
          tooltip={<span>Delete</span>}
        >
          <Modal
            title="Delete"
            icon={<RiErrorWarningLine size={32} className={'text-red-500'} />}
            body={
              <p>
                This action is irreversible.
                <br />
                Are you sure you want to continue?
              </p>
            }
            handleButtonClick={() => {
              recordDeleteFn(value).then((_) => window.location.reload());
            }}
            buttonTitle="Delete"
            buttonComponent={(showCallback, _) => (
              <ButtonIcon onClick={(e) => showCallback(e)} as="button" className="m-2">
                <RiDeleteBin5Line size={18} />
              </ButtonIcon>
            )}
            buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
          />
        </SimpleTooltip>
      )}
      {recordDeleteFn && !deleteCondition && (
        <SimpleTooltip
          usePortal={true}
          hideArrow={false}
          portalContainer={document.getElementById('portal-container')}
          trigger="hover"
          placement="top"
          tooltip={conditionals['delete'].body}
        >
          <ButtonIcon as="button" className="m-2 cursor-not-allowed">
            <RiDeleteBin5Line size={18} />
          </ButtonIcon>
        </SimpleTooltip>
      )}
    </div>
  );
};

export default RecordActions;
