import React from 'react';
import { FiPlus } from 'react-icons/fi';

import Button from '@components/buttons/ButtonLink';

export const NewResourceModalButton = (showCallback, buttonText) => {
  return (
    <Button as="button" onClick={showCallback}>
      <FiPlus size={22} /> {buttonText}
    </Button>
  );
};
