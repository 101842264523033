import React from 'react';

import { FaPoundSign } from 'react-icons/fa';
// import PoundIcon from './PoundIcon';

type Props = {
  inline: boolean;
  label: string;
  name: string;
  type: string;
  style: string;
  placeholder: string;
  message: string;
  value: number | undefined;
  onChange: (e) => {};
};

export const CurrencyInput = React.forwardRef(
  (
    { name = 'name', style = '', placeholder = 'Enter something...', ...otherProps }: Props,
    ref
  ) => {
    const inputStyle =
      'rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900 ' + style;

    return (
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <FaPoundSign />
        </span>
        <input
          name={name}
          type="number"
          className={inputStyle}
          placeholder={placeholder}
          autoComplete="off"
          step={0.01}
          min={0}
          max={10000000}
          ref={ref}
          {...otherProps}
        />
      </div>
    );
  }
);
