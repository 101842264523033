import React from 'react';

const TimberSelector = ({ options, onSelect }) => {
  return (
    <div className="flex flex-row flex-wrap space-x-5" style={{ maxWidth: '900px' }}>
      {options.map((option, i) => {
        return (
          <div className="flex flex-col justify-center items-center mb-2" key={option.density + i}>
            <div
              onClick={(e) => onSelect(option)}
              className="flex flex-col justify-center items-center bg-green-300 border-green-600 text-black-500 mb-2 p-5 border-2 cursor-pointer"
            >
              <span>Lambda: {option.lambda}</span>
              <span>Density: {option.density}</span>
            </div>
            <span>{option.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TimberSelector;
