import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Provider, useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';
import Button from '@appSrc/components/buttons/ButtonLink';
import BoxedLayout from '@appSrc/layouts/content/Boxed';
import { store } from '@appSrc/store';

import createStore from '@stores/CasementStore';

import Casement from '@components/configurators/Casement';
import EditProductItemFields from '@components/configurators/Casement/EditProductItemFields';
import Widget from '@components/widget';

import { handleSubmit, sleep } from '@utils/casementFormHelpers';

export function migrateCasementStore(snapshot) {
  if (!snapshot) return createStore();

  const snapshotCopy = JSON.parse(JSON.stringify(snapshot));
  snapshotCopy.dimensions = {
    ...snapshotCopy.dimensions,
    levelX: -1,
    levelY: -1,
  };
  function upgradeSection(section) {
    if (section.sections.length > 0) {
      section.sections.map(upgradeSection);
    }

    section.dimensions = {
      ...section.dimensions,
      levelX: section.dimensions.levelX - 1,
      levelY: section.dimensions.levelY - 1,
    };
    return section;
  }

  // Apply relative position to existing casements
  snapshotCopy.sections[0].relativePosition = {
    top: 'head',
    left: 'jamb',
    bottom: 'sill',
    right: 'jamb',
  };

  if (snapshotCopy.sash_gap > 0) {
    snapshotCopy.sash_gaps = {
      top: snapshotCopy.sash_gap,
      left: snapshotCopy.sash_gap,
      bottom: snapshotCopy.sash_gap,
      right: snapshotCopy.sash_gap,
    };
  }

  snapshotCopy.sections = snapshotCopy.sections.map(upgradeSection);
  const casementStore = createStore(snapshotCopy);
  return casementStore;
}

const Page = ({
  recordId = null,
  productItemId,
  rfqId,
  projectId,
  snapshot,
  data,
  sites = [],
  store,
  hardwareSet,
  finishSystem,
  sectionEditorData,
  productItemData,
  productData,
}) => {
  const casementStore = migrateCasementStore(snapshot);
  const stageRef = React.useRef(null);
  const dispatch = useDispatch();
  const productItemMethods = useForm({ defaultValues: productItemData });

  useEffect(() => {
    dispatch(setConfigurationData('timber', productData['timber_types'] || []));
    dispatch(setConfigurationData('hardwareSets', productData['hardware_sets'] || []));
    dispatch(setConfigurationData('hardware', productData['hardware'] || []));
    dispatch(setConfigurationData('glazingUnits', productData['glazing_units'] || []));
    dispatch(setConfigurationData('finishSystems', productData['finish_systems'] || []));
    dispatch(setConfigurationData('timberFinishes', productData['timber_finishes'] || []));

    dispatch(setFormData('hardwareSet', hardwareSet));
    dispatch(setFormData('finishSystem', finishSystem));
    dispatch(setFormData('sectionEditors', sectionEditorData || {}));
  }, [productData]);

  return (
    <Widget title="New Casement">
      <div
        className="w-1/12 fixed top-0.5 right-0"
        style={{ zIndex: 100 }}
        onClick={() => {
          casementStore.clearCurrentSection();
          console.log('CasementStore', casementStore);
          casementStore.clearCurrentSection();
          sleep(800).then(() => {
            handleSubmit(
              casementStore,
              null,
              'updateItem',
              productItemId,
              stageRef,
              null,
              null,
              {
                rfqId,
                projectId,
              },
              [productItemMethods.trigger]
            );
          });
        }}
      >
        <div className="fixed bottom-0 py-4 pl-64 inset-x-0 bg-orange-M100">
          <Button as="button">Save</Button>
          <Button
            buttonStyle="light-secondary-outline"
            as="button"
            className="ml-2"
            onClick={() => (window.location.href = `/projects/${projectId}`)}
          >
            Back To Project
          </Button>
        </div>
      </div>
      <FormProvider {...productItemMethods}>
        <EditProductItemFields data={productItemData} availableSiteOptions={sites} />
      </FormProvider>
      {/* @ts-ignore */}
      <Casement
        stageRef={stageRef}
        id={recordId}
        snapshot={snapshot}
        store={casementStore}
        data={data}
      />
    </Widget>
  );
};

const EditProductItemCasementPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default EditProductItemCasementPage;
