import React, { useState, useRef } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

const Card = ({
  attributes = [],
  title = null,
  description = null,
  defaultOpen = true,
  narrowStyle = false,
  blank = false,
}) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const bodyEl = useRef(null);

  const handleOpenClose = (e) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  const bodyStyle = {
    maxHeight: bodyEl.current && isOpen ? bodyEl.current.scrollHeight : 0,
    opacity: isOpen ? 1 : 0,
  };

  if (!attributes && !blank) {
    attributes = [];
  }

  if (!title && !blank) {
    title = '';
  }

  const customStyle = narrowStyle
    ? {
        height: 'fit-content',
        minHeight: '2.5rem',
      }
    : {};

  const sections = attributes.length === 1 ? 1 : 2 * Math.round(attributes.length / 2);

  return (
    <div
      className={`${
        narrowStyle ? 'px-3 py-3' : 'px-5 py-5'
      } bg-gray-800 text-gray-500 border border-light-blue-500 rounded shadow-sm w-full`}
      style={customStyle}
    >
      <div className="flex w-full">
        <h4 className="text-sm font-semibold leading-tight flex-1 mr-2">{blank ? '' : title}</h4>
        {!blank ||
          (attributes.length !== 0 && (
            <div className="relative h-5 leading-none">
              <button
                className="text-xl text-gray-500 hover:text-gray-300 h-6 focus:outline-none"
                onClick={handleOpenClose}
              >
                {isOpen ? <FiChevronUp /> : <FiChevronDown />}
              </button>
            </div>
          ))}
      </div>

      <div
        className="relative overflow-hidden transition-all duration-500"
        ref={bodyEl}
        style={bodyStyle}
      >
        <div>
          {description && (
            <div className="pb-4 lg:pb-6">
              <h4
                className="text-2xl lg:text-3xl text-gray-500 font-semibold leading-tight inline-block"
                x-ref="total"
              >
                {description}
              </h4>
            </div>
          )}
          {attributes.length !== 0 && (
            <div className="flex -mx-4 pt-5">
              {attributes.map((attribute, index) => {
                return (
                  <div
                    className={`w-1/${sections} px-4 ${
                      index !== 0 ? 'border-l border-gray-700' : ''
                    }`}
                    key={attribute.label + index}
                  >
                    <div className="text-sm">
                      <span className="inline-block w-2 h-2 rounded-full mr-1 align-middle bg-blue-500">
                        &nbsp;
                      </span>
                      <span className="align-middle">{attribute.label}</span>
                    </div>
                    <div className="font-medium text-lg text-gray-500">
                      <span>{attribute.value}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
