import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';

import DebugConsole from '@components/DebugOptions/DebugConsole';

import { updateSelectionKeys, insertKey, update } from '@utils/formHelpers';

import '../../css/react-notifications.css';
import { Alert } from '../alerts';
import ContextForm from './ContextForm';

const MODE_HANDLER = {
  edit: API.updateClient,
  default: API.createClient,
};

const ClientsForm = ({ message = null, formId, recordId = null, mode = 'default' }) => {
  const [data, onSubmit] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();

  const businessEntity = methods.watch('business_entity');

  useEffect(() => {
    if (recordId == null) return;

    API.getClient(recordId).then((data) => {
      setDefaultValues(data);
      reset({ ...data });
    });
  }, [reset, recordId]);

  const onSubmitFn = (data) => {
    console.log('Data', data);
    console.log('Submit count', methods.formState.submitCount);
    // NotificationManager.success('Success message', 'Title here');
    update(data, 'address', (address) => JSON.stringify(address));
    MODE_HANDLER[mode || 'default'](API.toFormData({ ...data }), recordId)
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const businessDetails = [
    {
      label: 'Company No.',
      // error: { required: 'Please enter a point of contact' },
      error: {},
      name: 'company_registration_number',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'VAT No.',
      // error: { required: 'Please enter a point of contact' },
      error: {},
      name: 'vat_number',
      type: 'text',
      defaultValue: '',
    },
    {
      label: 'Website URL',
      // error: { required: 'Please enter a point of contact' },
      error: {},
      name: 'website_url',
      type: 'text',
      defaultValue: '',
    },
  ];

  const showBusinessDetails =
    businessEntity === undefined || businessEntity === 'true' ? businessDetails : [];

  let items = [
    {
      config: [' w-64', 'full', 'full', 'full', 'full', 'full', 'full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Client Name',
          error: { required: 'Please enter a client name' },
          name: 'name',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Point of contact',
          // error: { required: 'Please enter a point of contact' },
          error: {},
          name: 'point_of_contact',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Email',
          // error: { required: 'Please enter a point of contact' },
          error: {},
          name: 'email',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Contact number',
          // error: { required: 'Please enter a point of contact' },
          error: {},
          name: 'contact_number',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Entity',
          // error: { required: 'Please enter a point of contact' },
          error: {},
          name: 'business_entity',
          type: 'radio',
          options: [
            { label: 'Private', value: 'false' },
            { label: 'Business', value: 'true', checked: true },
          ],
        },
        ...showBusinessDetails,
      ],
    },
    {
      config: [' w-64', 'full', 'full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Address Line 1',
          error: { required: 'Please enter a first line of address' },
          name: 'address[line_1]',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Address Line 2',
          // error: { required: 'Please enter a client name' },
          error: {},
          name: 'address[line_2]',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'City',
          error: { required: 'Please enter a city' },
          name: 'address[city]',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Post Code',
          // error: { required: 'Please enter a client name' },
          error: { required: 'Please provide a postcode' },
          name: 'address[post_code]',
          type: 'text',
          defaultValue: '',
        },
        {
          label: 'Country',
          error: { required: 'Please enter a country' },
          name: 'address[country]',
          type: 'text',
          defaultValue: 'United Kingdom',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row" style={{ minWidth: '600px' }}>
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            formId={formId}
            gridItems={items}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          />
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default ClientsForm;
