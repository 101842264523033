import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setFormData } from '@appSrc/actions/configuratorActions';

import { itemToInput } from '@components/forms/GridForm';

import Section from '../Section';

export const handleVariants = (variants) => {
  return Object.entries(variants).map(([key, value]) => {
    return {
      // @ts-ignore
      ...value,
      site: value['site'].value,
    };
  });
};

type SelectOption = {
  value: string;
  label: string;
};

type Props = {
  availableSiteOptions: SelectOption[];
  data: any;
};

const EditProductItemFormFields = ({ data, availableSiteOptions }: Props) => {
  const [sites, setSites] = useState(availableSiteOptions);
  const { reset, ...methods } = useFormContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = methods.watch();
    dispatch(setFormData('casementProductItemData', data));
  }, [methods.watch]);

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={methods.handleSubmit(console.log)} id="casement-form">
        <Section title={'Project Item'}>
          <div className={'flex flex-col space-y-6'} style={{ maxWidth: '992px' }}>
            <div className={`flex flex-row`}>
              <div className="flex flex-row space-x-12">
                {itemToInput(
                  {
                    label: 'Reference',
                    name: `product_item[reference]`,
                    type: 'text',
                    error: { required: 'Please provide a reference' },
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Site',
                    name: `product_item[site]`,
                    type: 'combobox',
                    error: { required: 'Please select or enter a site' },
                    options: sites,
                    // defaultValue: '',
                    placeholder: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Quantity',
                    name: `product_item[quantity]`,
                    type: 'text',
                    error: { required: 'Please enter a quantity' },
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </div>
            </div>
          </div>
        </Section>
      </form>
    </div>
  );
};

export default EditProductItemFormFields;
