import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { getColor } from '../../functions/colors';

const SwitchInput = ({
  initialState = false,
  leftLabel,
  rightLabel = null,
  onChange,
  color = 'blue',
  state = null,
}) => {
  const [checked, handleChange] = useState(initialState);
  let onColor = `bg-${color}-200`;
  let onHandleColor = `bg-${color}-500`;
  let offColor = `bg-grey-200`;
  let offHandleColor = 'bg-white';

  useEffect(() => {
    onChange && onChange(checked);
  }, [checked]);

  return (
    <div className="w-full flex flex-row items-center justify-start space-x-2 mb-10 mt-2 text-md">
      {leftLabel && <span className="text-black-600">{leftLabel}</span>}
      <Switch
        onChange={() => handleChange(!checked)}
        checked={state || checked}
        onColor={getColor(onColor)}
        onHandleColor={getColor(onHandleColor)}
        offColor={getColor(offColor)}
        offHandleColor={getColor(offHandleColor)}
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
      />
      {rightLabel && <span className="text-black-600">{rightLabel}</span>}
    </div>
  );
};

export default SwitchInput;
