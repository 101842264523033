import Fuse from 'fuse.js';
import React, { useState } from 'react';

import { TruncatedCell } from '@components/datatable';
import Modal from '@components/modals/DataModal';

const MATERIAL_TABLE_CONFIG = {
  timberType: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Density',
        accessor: 'density',
      },
      {
        title: 'Type',
        accessor: 'type',
      },
    ],
    searchFields: ['name'],
    title: 'Select Timber',
  },
  timberFinish: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Code',
        accessor: 'code',
      },
      {
        title: 'Description',
        accessor: 'description',
        Cell: TruncatedCell,
      },
      {
        title: 'Supplier',
        accessor: 'supplier',
      },
    ],
    searchFields: ['name', 'code'],
    title: 'Select Product',
  },
  finishSystem: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
    ],
    searchFields: ['name'],
    title: 'Select Finish System',
  },
  glazingUnit: {
    columns: [
      {
        title: 'Configuration',
        accessor: 'product_name',
      },
      {
        title: 'Supplier',
        accessor: 'supplier',
      },
      {
        title: 'Price',
        accessor: 'price',
      },
    ],
    searchFields: ['product_name', 'supplier'],
    title: 'Select Glazing Unit',
  },
  hardwareSet: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Finishes',
        accessor: 'finish_names',
      },
    ],
    searchFields: ['name', 'finish_names'],
    title: 'Select Hardware Set',
  },
  casementHardwareSet: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
    ],
    searchFields: ['name'],
    title: 'Select Hardware Set',
  },
  hardware: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Finish',
        accessor: 'finish_full',
      },
    ],
    searchFields: ['name', 'finish_full'],
    title: 'Select Hardware',
  },
  hardwareVariant: {
    columns: [
      {
        title: 'Name',
        accessor: 'name',
      },
      {
        title: 'Finish',
        accessor: 'finish',
      },
    ],
    searchFields: ['name', 'finish'],
    title: 'Select Hardware',
  },
  weightSupplier: {
    columns: [
      {
        title: 'Name',
        accessor: 'label',
      },
    ],
    searchFields: ['label'],
    title: 'Select Supplier',
  },
};

const DataModal = ({
  data,
  materialKey,
  fuseOptions = {},
  onQueryChange = (_) => {},
  onItemSelect = (_) => true,
  handleClear = () => {},
  clearable = false,
  key = 'ms1',
}) => {
  const [query, setQuery] = useState('');
  const defaultOptions = {
    includeScore: true,
    distance: 200,
    threshold: 0.3,
    keys: MATERIAL_TABLE_CONFIG[materialKey].searchFields,
  };
  const options = { ...defaultOptions, ...fuseOptions };

  const fuse = new Fuse(data, options);

  const handleSearch = (q) => {
    onQueryChange(q);
    return fuse.search(q);
  };

  const handleSelection = (item) => {
    if (onItemSelect(item)) {
      setQuery('');
    }
  };

  return (
    <Modal
      key="ai1"
      modalButtonText="Select"
      buttonClass="whitespace-nowrap"
      clearable={clearable}
      title={MATERIAL_TABLE_CONFIG[materialKey].title}
      columns={MATERIAL_TABLE_CONFIG[materialKey].columns}
      data={query ? handleSearch(query).map((r) => r.item) : data}
      handleSearch={setQuery}
      onSelect={(item) => handleSelection(item)}
      handleClear={handleClear}
    />
  );
};

export default DataModal;
