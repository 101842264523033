import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { setTimber } from '@appSrc/actions/configuratorActions';

import { itemToInput } from '@components/forms/GridForm';

import Card from '../Card';
import MaterialSelector from '../MaterialSelector';
import Section, { InputContainer } from '../Section';
import TableInputs from '../TableInputs';
import { DoubleInputs } from '../layouts';

export const SASH_TIMBER_KEY = 'sash_timber';

const Sashes = ({ recordId, isTriple = false, borderBottom = true, timberItems = [] }) => {
  const methods = useFormContext();
  const timber = useSelector((state) => {
    // @ts-ignore
    return state.configuratorForms.timber[SASH_TIMBER_KEY];
  });
  const dispatch = useDispatch();

  const handleTimberSelection = (item) => {
    dispatch(setTimber(SASH_TIMBER_KEY, item));
  };

  const columnsRail = [{ name: 'Thickness' }, { name: 'Width' }];

  const rowsRails = [
    {
      name: 'Top Rail',
      inputs: [
        { name: 'sash[top_rail][thickness]', type: 'number', inputClass: 'w-full' },
        { name: 'sash[top_rail][width]', type: 'number', inputClass: 'w-full' },
      ],
    },
    {
      name: 'Meeting Rail',
      inputs: [
        { name: 'sash[meeting_rail][thickness]', type: 'number', inputClass: 'w-full' },
        { name: 'sash[meeting_rail][width]', type: 'number', inputClass: 'w-full' },
      ],
    },
    {
      name: 'Bottom Rail',
      inputs: [
        { name: 'sash[bottom_rail][thickness]', type: 'number', inputClass: 'w-full' },
        { name: 'sash[bottom_rail][width]', type: 'number', inputClass: 'w-full' },
      ],
    },
  ];

  const columnsHorns = [{ name: 'Height' }];

  const rowsHorns = [
    {
      name: 'Top',
      inputs: [{ name: 'sash[horns_top][height]', type: 'number', inputClass: 'w-full' }],
    },
    {
      name: 'Bottom',
      inputs: [{ name: 'sash[horns_bottom][height]', type: 'number', inputClass: 'w-full' }],
    },
  ];

  const columnsSashType = [{ name: 'Openable' }, { name: 'Fixed' }];
  const rowsSashType = [
    {
      name: 'Upper',
      inputs: [
        { name: 'glazing[middle_sash][upper][openable]' },
        { name: 'glazing[middle_sash][upper][fixed]' },
      ],
    },
    {
      name: 'Lower',
      inputs: [
        { name: 'glazing[middle_sash][lower][openable]' },
        { name: 'glazing[middle_sash][lower][fixed]' },
      ],
    },
  ];

  const rowsSideSashType = [
    {
      name: 'Upper',
      inputs: [
        { name: 'glazing[side_sash][upper][openable]' },
        { name: 'glazing[side_sash][upper][fixed]' },
      ],
    },
    {
      name: 'Lower',
      inputs: [
        { name: 'glazing[side_sash][lower][openable]' },
        { name: 'glazing[side_sash][lower][fixed]' },
      ],
    },
  ];

  const rowsSingleSashType = [
    {
      name: 'Upper',
      inputs: [{ name: 'glazing[sash][upper][openable]' }, { name: 'glazing[sash][upper][fixed]' }],
    },
    {
      name: 'Lower',
      inputs: [{ name: 'glazing[sash][lower][openable]' }, { name: 'glazing[sash][lower][fixed]' }],
    },
  ];

  return (
    <Section title={'Sashes'} borderBottom={borderBottom}>
      <InputContainer className="flex flex-row space-x-12 w-full">
        <InputContainer className="flex flex-col space-y-12 w-full">
          <InputContainer className="flex flex-row space-x-12">
            <InputContainer className="flex flex-col space-y-2">
              <span className="pl-16">General</span>
              {itemToInput(
                {
                  label: 'Sash thickness',
                  name: 'sash[sash][thickness]',
                  type: 'number',
                  defaultValue: '',
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {DoubleInputs({
                methods,
                first: {
                  label: 'Stiles thickness',
                  name: 'sash[stiles][thickness]',
                  type: 'number',
                  defaultValue: '',
                  inputClass: 'w-3/5',
                },
                second: {
                  label: 'Stiles width',
                  name: 'sash[stiles][width]',
                  type: 'number',
                  defaultValue: '',
                  inputClass: 'w-3/5',
                },
                distance: 12,
              })}
              {DoubleInputs({
                methods,
                first: {
                  label: 'Rebate width',
                  name: 'sash[rebate][width]',
                  type: 'number',
                  defaultValue: '',
                  inputClass: 'w-3/5',
                },
                second: {
                  label: 'Rebate depth',
                  error: { required: 'Rebate depth is required' },
                  name: 'sash[rebate][depth]',
                  type: 'number',
                  defaultValue: '',
                  inputClass: 'w-3/5',
                },
                third: {
                  label: 'Rebate depth reduction',
                  name: 'sash[rebate][depth_reduction]',
                  type: 'number',
                  defaultValue: '',
                  inputClass: 'w-3/5',
                },
                distance: 12,
              })}
            </InputContainer>
            {/* Upper Sash  */}
            <InputContainer className="flex flex-col space-y-2">
              <TableInputs methods={methods} columns={columnsRail} rows={rowsRails} />
            </InputContainer>
            {/* Horns  */}
            <InputContainer className="flex flex-col space-y-2">
              <span className="pl-16">Horns</span>
              <TableInputs methods={methods} columns={columnsHorns} rows={rowsHorns} />
            </InputContainer>
          </InputContainer>

          <InputContainer className="flex flex-col space-y-12">
            <InputContainer className="flex flex-col space-y-2">
              <span className="">Opening Configuration</span>
              <InputContainer className="flex flex-row space-x-12">
                {/* Side Sashes  */}
                {isTriple && (
                  <>
                    {/* Middle Sashes  */}
                    <InputContainer className="flex flex-col space-y-2">
                      {isTriple && <span className="pb-4 self-center">Middle Sashes</span>}
                      <TableInputs
                        methods={methods}
                        columns={columnsSashType}
                        rows={rowsSashType}
                      />
                    </InputContainer>

                    <InputContainer className="flex flex-col space-y-2">
                      <span className="pb-4 self-center">Side Sashes</span>
                      <TableInputs
                        methods={methods}
                        columns={columnsSashType}
                        rows={rowsSideSashType}
                      />
                    </InputContainer>
                  </>
                )}

                {!isTriple && (
                  <InputContainer className="flex flex-col space-y-2">
                    {/* <span className="pb-4 self-center">Side Sashes</span> */}
                    <TableInputs
                      methods={methods}
                      columns={columnsSashType}
                      rows={rowsSingleSashType}
                    />
                  </InputContainer>
                )}
              </InputContainer>
            </InputContainer>
            {/* Sash Timber */}
            <InputContainer className="flex space-x-4" style={{}}>
              <div className="self-center " style={{ minWidth: 215 }}>
                {timber ? (
                  <Card
                    narrowStyle
                    defaultOpen={false}
                    title={timber['name']}
                    attributes={[{ label: 'Type', value: timber['type'] }]}
                  />
                ) : (
                  <Card defaultOpen={false} blank={true} narrowStyle />
                )}
              </div>
              <div className="self-center w-full">
                {/* @ts-ignore */}
                <MaterialSelector
                  modalButtonText="Sash Timber"
                  title="Sash Timber"
                  items={timberItems}
                  onItemSelect={handleTimberSelection}
                  typeOfMaterial={'timberType'}
                />
              </div>
            </InputContainer>
            {timber && (
              <InputContainer className="flex flex-row space-x-2 w-full">
                {itemToInput(
                  {
                    label: 'Sash timber composition',
                    name: 'sash[timber_composition]',
                    error: { required: 'Please select timber composition for sash' },
                    orientation: 'vertical',
                    type: 'radio',
                    options: [
                      { label: 'Solid', value: 'solid' },
                      { label: 'Engineered', value: 'engineered' },
                    ],
                    defaultValue: '',
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </InputContainer>
            )}
          </InputContainer>
        </InputContainer>
      </InputContainer>
    </Section>
  );
};

export default Sashes;
