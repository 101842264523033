import React, { useState } from 'react';
import { Group, Line, Rect, Shape } from 'react-konva';

import { pythagaros, bezierArcPoints } from '@utils/geometry';

import SashMoulding from './SashMoulding';
import StandardSash from './Standard';

const Sash = ({ section, ...shapeProps }) => {
  if (!section.isHeadNeighbour || section.store.headStyle === 'standard') {
    return <StandardSash {...shapeProps} section={section} />;
  }
  let radius = section.store.arcRadius;

  if (section.store.finalSize.width / 2 === section.store.arcRadius) {
    // If it is a semi-circle we can no longer approximate the arc with bezier curves.
    radius = radius + 0.0001;
  }
  const stileExcMouldingWidth =
    section.store['stiles'].width - section.store['sash_moulding'].width;

  // section.store.arcRadius
  const radiusReduction =
    section.store.finalSize.height -
    (section.absoluteOrigin.y + section.size.height + section.store['sash_gap']);
  const upperArcRadius = radius - radiusReduction;

  const arcCenterY = section.size.height + section.sashGapBottom - upperArcRadius; // relative to section
  const arcCenterX =
    section.store.finalSize.width / 2 - section.sashGapLeft - section.absoluteOrigin.x; // relative to section
  const arcCenter = { x: arcCenterX, y: arcCenterY };

  const upperStartY = pythagaros(arcCenterX, upperArcRadius) + arcCenterY;
  const upperEndY = pythagaros(arcCenterX - section.size.width, upperArcRadius) + arcCenterY;

  const [upperArcPointsP1, upperArcPointsP2] = bezierArcPoints(
    { x: 0, y: upperStartY },
    { x: section.size.width, y: upperEndY },
    section.size.height,
    arcCenter,
    upperArcRadius,
    false
  );

  const lowerArcRadius =
    upperArcRadius - section.store['top_rail'].width + section.store['sash_moulding'].width;
  const lowerStartY = pythagaros(arcCenterX - stileExcMouldingWidth, lowerArcRadius) + arcCenterY;
  const lowerEndY =
    pythagaros(arcCenterX - (section.size.width - stileExcMouldingWidth), lowerArcRadius) +
    arcCenterY;
  const mouldingLowerY =
    pythagaros(
      arcCenterX - section.store['stiles'].width,
      lowerArcRadius - section.store['sash_moulding'].width
    ) + arcCenterY;

  const [lowerArcPointsP1, lowerArcPointsP2] = bezierArcPoints(
    { x: stileExcMouldingWidth, y: lowerStartY },
    { x: section.size.width - stileExcMouldingWidth, y: lowerEndY },
    section.size.height - section.store['top_rail'].width + section.store['sash_moulding'].width,
    arcCenter,
    lowerArcRadius
  );

  return (
    <>
      {/* Top Rail */}
      <Shape
        {...shapeProps}
        {...section.size}
        opacity={1}
        closed={false}
        sceneFunc={(ctx, shape) => {
          ctx.beginPath();
          ctx.moveTo(stileExcMouldingWidth, lowerStartY);
          // @ts-ignore
          ctx.bezierCurveTo(...lowerArcPointsP1);
          // @ts-ignore
          ctx.bezierCurveTo(...lowerArcPointsP2);
          ctx.lineTo(section.size.width, upperEndY);
          // @ts-ignore
          ctx.bezierCurveTo(...upperArcPointsP1);
          // @ts-ignore
          ctx.bezierCurveTo(...upperArcPointsP2);
          // ctx.lineTo(section.size.width, 0);
          // ctx.closePath();

          // Konva specific method
          ctx.fillStrokeShape(shape);
        }}
      />
      {/* Bottom Rail */}
      <Rect
        {...shapeProps}
        width={section.size.width}
        height={section.bottomRailWidth - section.store['sash_moulding'].width}
      />
      {/* Left Stile */}
      <Line
        {...shapeProps}
        points={[
          0,
          0,

          0,
          upperStartY,

          stileExcMouldingWidth,
          lowerStartY,

          stileExcMouldingWidth,
          0,
        ]}
      />
      {/* Right Stile */}
      <Line
        {...shapeProps}
        points={[
          section.size.width,
          0,

          section.size.width,
          upperEndY,

          section.size.width - stileExcMouldingWidth,
          lowerEndY,

          section.size.width - stileExcMouldingWidth,
          0,
        ]}
      />
      {/* Moulding */}
      <SashMoulding
        section={section}
        topMoulding={{
          upperY: lowerStartY,
          lowerY: mouldingLowerY,
          radius: lowerArcRadius,
          center: arcCenter,
        }}
      />
    </>
  );
};

export default Sash;
