import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  let { name } = { ...config };
  return (
    <div className="flex flex-row items-center justify-between w-full text-xs z-10">
      <div className="text-white">&copy; {name} 2022</div>
      <div className="flex flex-row ml-auto space-x-2">
        <a href="/privacy-policy">Privacy policy</a>
        <a href="/terms-of-service">Terms of service</a>
        <a href="/contact-us">Contact us</a>
      </div>
    </div>
  );
};

export default Footer;
