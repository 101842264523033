import React from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useBlockLayout,
  useFlexLayout,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import Widget from '@components/widget';

const Page = ({ data, box_sash_configs }) => {
  const columns = Object.keys(data[0]).map((columnName, i) => ({
    Header: columnName,
    accessor: columnName,
    sticky: i == 0 ? 'left' : '',
  }));

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useSticky
  );

  const footerGroups = headerGroups.slice().reverse();

  return (
    <Widget title="Box Sash Debugger">
      <div {...getTableProps()} className="table sticky" style={{ width: 1000, height: 500 }}>
        <div className="sticky top-0">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="">
              {headerGroup.headers.map((column, i) => {
                const headerProps = column.getHeaderProps();
                const thStyle = i == 0 ? { width: 50 } : {};
                headerProps.style = { ...headerProps.style, ...thStyle };
                return (
                  <div
                    {...headerProps}
                    className="px-3 py-2 border-r border-b text-xs text-center leading-4 font-medium uppercase tracking-wider bg-grey-200 border-grey-500"
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div
          {...getTableBodyProps()}
          // className="overflow-y-scroll"
          style={{ maxHeight: '90vh', maxWidth: '100vw' }}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr hover:bg-grey-100 hover:bg-opacity-50">
                {row.cells.map((cell, i) => {
                  const tdStyle = i == 0 ? { width: 50 } : {};
                  const cellProps = cell.getCellProps();
                  cellProps.style = { ...cellProps.style, ...tdStyle };
                  return (
                    <div
                      {...cellProps}
                      className="border-b border-r px-1 py-1 first:bg-grey-200 first:border-grey-500 first:max-w-0.5 text-right"
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="footer">
          {footerGroups.map((footerGroup) => (
            <div {...footerGroup.getHeaderGroupProps()} className="tr">
              {footerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="td px-3 py-2 first:(border-2)">
                  {column.render('Footer')}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Widget>
  );
};

const SheetDebugPage = (props) => {
  return <Page {...props} />;
};

export default SheetDebugPage;
