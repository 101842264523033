import { ADD_IMAGE, REMOVE_FILE, SET_SUPPLIERS, SET_CLIENTS } from '../actions/formActions';

export default function forms(state = {}, action) {
  switch (action.type) {
    case ADD_IMAGE:
      const { key, file } = action.payload;
      return {
        ...state,
        [`${key}`]: file,
      };
    case REMOVE_FILE:
      const { [action.payload.key]: _, ...newState } = state;
      return newState;
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    default:
      return state;
  }
}
