import QuoteUtilities from './app/PageUtilities/QuoteUtilities';
import appLayout from './app/appLayout';
import DefaultQuoteSettingsPage from './app/pages/Account/DefaultQuoteSettingsPage';
import AccountPage from './app/pages/AccountPage';
import BillOfMaterialsPage from './app/pages/BillOfMaterialsPage';
import BoxSashProductionPage from './app/pages/BoxSashProductionPage';
import BoxSashProductsPage from './app/pages/BoxSashProductsPage';
import CasementHardwareSetPage from './app/pages/CasementHardwareSetPage';
import CasementProductionPage from './app/pages/CasementProductionPage';
import CasementProductsPage from './app/pages/CasementProductsPage';
import ClientsPage from './app/pages/ClientsPage';
import ConfiguratorPage from './app/pages/ConfiguratorPage';
import DrawingEditorPage from './app/pages/DrawingEditorPage';
import DrawingInstructionsPage from './app/pages/DrawingInstructionsPage';
import EditCasementPage from './app/pages/EditCasementPage';
import EditProductItemsPage from './app/pages/EditProductItemsPage';
import GlazingUnitsPage from './app/pages/GlazingUnitsPage';
import GlobalProductionPage from './app/pages/GlobalProductionPage';
import HardwarePage from './app/pages/HardwarePage';
import HardwareSetPage from './app/pages/HardwareSetPage';
import MiscPage from './app/pages/MiscPage';
import MouldingPage from './app/pages/MouldingPage';
import NewCasementPage from './app/pages/NewCasementPage';
import NewProductItemsPage from './app/pages/NewProductItemsPage';
import PricingSettingPage from './app/pages/PricingSettingPage';
import ProjectPage from './app/pages/ProjectPage';
import ProjectsPage from './app/pages/ProjectsPage';
import SheetDebugPage from './app/pages/SheetDebugPage';
import SuppliersPage from './app/pages/SuppliersPage';
import TimberFinishItemsPage from './app/pages/TimberFinishItemsPage';
import TimberItemsPage from './app/pages/TimberItemsPage';
import TimberTypesPage from './app/pages/TimberTypesPage';
import RenderCasement from './app/pages/admin/RenderCasement';
import DashboardPage from './app/pages/dashboard';
import LoginPage from './app/pages/login';
import EditProductItemCasementPage from './app/pages/product_items/casements/EditProductItemCasementPage';
import NewProductItemCasementPage from './app/pages/product_items/casements/NewProductItemCasementPage';

require.context('../assets/images', true);

export {
  AccountPage,
  appLayout,
  BillOfMaterialsPage,
  BoxSashProductionPage,
  BoxSashProductsPage,
  CasementHardwareSetPage,
  CasementProductionPage,
  CasementProductsPage,
  ClientsPage,
  ConfiguratorPage,
  DashboardPage,
  DefaultQuoteSettingsPage,
  DrawingEditorPage,
  DrawingInstructionsPage,
  EditCasementPage,
  EditProductItemCasementPage,
  EditProductItemsPage,
  GlazingUnitsPage,
  GlobalProductionPage,
  HardwarePage,
  HardwareSetPage,
  LoginPage,
  MiscPage,
  MouldingPage,
  NewCasementPage,
  NewProductItemCasementPage,
  NewProductItemsPage,
  PricingSettingPage,
  ProjectPage,
  ProjectsPage,
  QuoteUtilities,
  RenderCasement,
  SheetDebugPage,
  SuppliersPage,
  TimberFinishItemsPage,
  TimberItemsPage,
  TimberTypesPage,
};
