import { observer, inject } from 'mobx-react';
import { isAlive, onSnapshot } from 'mobx-state-tree';
import React from 'react';
import { Group, Line, Arrow, Label, Text, Tag } from 'react-konva';

import { ICasement } from '@stores/CasementStore';
import { ISection } from '@stores/SectionStore';

import {
  round,
  LEVELS,
  isSmallDimension,
  getTextWidth,
  EXTENSION_LINE_LENGTH,
  DIMENSION_LINE_DISTANCE,
  BASE_FONTSIZE,
} from '@utils/dimensionHelpers';

export const METRIC_SIZE = 80;
const DEFAULT_LABEL_RATIO = 0.8; // base of 40px width
const LABEL_RATIO_STEP = 0.02;
const LABEL_RATIO_BASE = 40;

export function VerticalDimension({ x, y, height, scaleFactor = 1, extendForSillHorns = 0 }) {
  const setOutside = isSmallDimension(height);
  let labelOffset = EXTENSION_LINE_LENGTH / 10;
  let labelScale = 1;
  let letterSpacing = 2;
  if (setOutside) {
    const reducer = String(height).length - 2 > 1 && height < 60 ? -2 : 1;
    labelScale =
      DEFAULT_LABEL_RATIO - Math.min(Math.abs(LABEL_RATIO_BASE - height), 15) * LABEL_RATIO_STEP;
    letterSpacing = 6;
    labelOffset = -14 * reducer;
  }
  const labelWidth = getTextWidth(height, labelScale, letterSpacing);

  return (
    <Group x={x + extendForSillHorns} y={y}>
      {!setOutside && (
        <Arrow
          points={[DIMENSION_LINE_DISTANCE, 0, DIMENSION_LINE_DISTANCE, height]}
          stroke="black"
          fill="black"
          pointerAtBeginning={false}
          pointerAtEnding={false}
        />
      )}
      <Line points={[0, 0, EXTENSION_LINE_LENGTH, 0]} stroke="black" />
      <Line points={[0, height, EXTENSION_LINE_LENGTH, height]} stroke="black" />
      <Label
        x={DIMENSION_LINE_DISTANCE + (BASE_FONTSIZE + labelOffset) / 2}
        y={height / 2 + labelWidth / 2}
        scaleY={-1}
        rotation={-90}
      >
        {!setOutside && <Tag fill="white" stroke="white" />}
        <Text text={height} padding={2} align="center" fontSize={BASE_FONTSIZE * labelScale} />
      </Label>
      {/* <Line points={[0, height / 2, leaderLength, height / 2]} stroke="red" /> */}
    </Group>
  );
}

export function HorizontalDimension({ x, y, width, scaleFactor = 1 }) {
  const setOutside = isSmallDimension(width);
  let labelOffset = EXTENSION_LINE_LENGTH / 10;
  let labelScale = 1;
  let letterSpacing = 2;
  if (setOutside) {
    const reducer = String(width).length - 2 > 1 && width < 60 ? -2 : 1;
    labelScale =
      DEFAULT_LABEL_RATIO - Math.min(Math.abs(LABEL_RATIO_BASE - width), 15) * LABEL_RATIO_STEP;
    letterSpacing = 6;
    labelOffset = -14 * reducer;
  }
  const labelWidth = getTextWidth(width, labelScale, letterSpacing);

  return (
    <Group x={x} y={y}>
      {!setOutside && (
        <Arrow
          points={[0, DIMENSION_LINE_DISTANCE, width, DIMENSION_LINE_DISTANCE]}
          stroke="black"
          fill="black"
          pointerAtBeginning={false}
          pointerAtEnding={false}
          // pointerLength={15}
          // pointerWidth={5}
        />
      )}
      <Line points={[0, 0, 0, EXTENSION_LINE_LENGTH]} stroke="black" />
      <Line points={[width, 0, width, EXTENSION_LINE_LENGTH]} stroke="black" />
      <Label
        x={width / 2 - labelWidth / 2}
        y={DIMENSION_LINE_DISTANCE + (BASE_FONTSIZE + labelOffset) / 2}
        scaleY={-1}
      >
        {!setOutside && <Tag fill="white" stroke="white" />}
        <Text text={width} padding={2} align="center" fontSize={BASE_FONTSIZE * labelScale} />
      </Label>
      {/* <Line points={[width / 2, 0, width / 2, leaderLength]} stroke="red" /> */}
    </Group>
  );
}

type Props = {
  casementStore?: ICasement;
  scaleFactor?: number;
  mainDimensionsOnly: boolean;
};

type State = {
  transoms: ISection[];
  mullions: ISection[];
};

class Dimensions extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      transoms: [],
      mullions: [],
    };

    // @ts-ignore
    this.childSections = [];
    // @ts-ignore
    this.unsubscribe = null;
  }

  componentDidMount() {
    // @ts-ignore
    this.unsubscribe = onSnapshot(this.props.casementStore, (snapshot) => {
      this.setState({
        transoms: this.props.casementStore.sections[0].findSectionsType('transom'),
        mullions: this.props.casementStore.sections[0].findSectionsType('mullion'),
      });
    });
  }

  componentWillUnmount(): void {
    // @ts-ignore
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { width, height } = this.props.casementStore.finalSize;
    const scaleFactor = this.props.scaleFactor;
    const sill_horns = this.props.casementStore.sillHornsWidth;
    const mainDimensionsOnly = this.props.mainDimensionsOnly;

    let uniquenessCounter = 0;

    let verticalLevels = 0;
    let horizontalLevels = 0;

    const verticalComponents = [];
    const horizontalComponents = [];

    const deduplication = {
      transoms: {},
      mullions: {},
    };

    let transoms = this.state.transoms;
    transoms = transoms.filter((transom) => {
      const transomKey = `${transom.transomDrop}x${transom.parent.absoluteOrigin.y}`;
      if (!deduplication.transoms[transomKey]) {
        deduplication.transoms[transomKey] = true;
        return true;
      }

      return false;
    });
    let mullions = this.state.mullions;
    mullions = mullions.filter((mullion) => {
      const mullionKey = `${mullion.absoluteOrigin.x}x${mullion.parent.absoluteOrigin.x}`;
      if (!deduplication.mullions[mullionKey]) {
        deduplication.mullions[mullionKey] = true;
        return true;
      }

      return false;
    });

    // @ts-ignore
    transoms.forEach(processSections);
    // @ts-ignore
    mullions.forEach(processSections);

    function processSections(section: ISection, skipChildren = false) {
      if (!section) return;
      const { dimensions, originalSize, originalAbsOrigin } = section;

      if (section.annotateVertically && section.annotateVertically() && !mainDimensionsOnly) {
        if (verticalLevels < section.dimensions.levelX) {
          verticalLevels = section.dimensions.levelX;
        }
        verticalComponents.push(
          <VerticalDimension
            x={LEVELS[section.dimensions.levelX] || 0}
            y={originalAbsOrigin.y}
            height={round(originalSize.height, 2)}
            scaleFactor={scaleFactor}
            key={section.id + '-' + String(uniquenessCounter) + '-vertical'}
            extendForSillHorns={sill_horns}
          />
        );
      }
      if (section.annotateHorizontally && section.annotateHorizontally() && !mainDimensionsOnly) {
        if (horizontalLevels < section.dimensions.levelY) {
          horizontalLevels = section.dimensions.levelY;
        }
        horizontalComponents.push(
          <HorizontalDimension
            x={originalAbsOrigin.x}
            y={LEVELS[section.dimensions.levelY] || 0}
            width={round(originalSize.width)}
            scaleFactor={scaleFactor}
            key={section.id + '-' + String(uniquenessCounter) + '-horizontal'}
          />
        );
      }

      uniquenessCounter++;

      const childIsTransomOrMullion =
        section.sections.length > 0 &&
        ['mullion', 'transom'].includes(section.sections[0].sectionType);
      if (section.sections.length > 0 && (childIsTransomOrMullion || !skipChildren)) {
        const skipTraversingChildren = childIsTransomOrMullion ? false : true;

        section.sections.forEach((ss) => processSections(ss, skipTraversingChildren));
      }
    }

    function processRootAncestor(store) {
      if (!store) return;
      if (verticalLevels >= 0 && verticalComponents.length > 0) {
        verticalLevels += 1;
      }
      if (horizontalLevels >= 0 && horizontalComponents.length > 0) {
        horizontalLevels += 1;
      }

      verticalComponents.push(
        <VerticalDimension
          x={LEVELS[verticalLevels || 1] || 0}
          y={store.absoluteOrigin.y}
          height={round(store.finalSize ? store.finalSize.height : store.size.height)}
          scaleFactor={scaleFactor}
          key={store.id + '-vertical-full'}
          extendForSillHorns={sill_horns}
        />
      );
      // Head + Sill
      verticalComponents.push(
        <VerticalDimension
          x={0}
          y={store.absoluteOrigin.y}
          height={round(store.visibleSillHeight, 0)}
          scaleFactor={scaleFactor}
          key={store.id + '-vertical-sill'}
          extendForSillHorns={sill_horns}
        />,
        <VerticalDimension
          x={0}
          y={store.absoluteOrigin.y + store.visiualOpeningSizes.height + store.visibleSillHeight}
          height={round(store.visibleHeadWidth)}
          scaleFactor={scaleFactor}
          key={store.id + '-vertical-head'}
          extendForSillHorns={sill_horns}
        />
      );

      horizontalComponents.push(
        <HorizontalDimension
          x={store.absoluteOrigin.x}
          y={LEVELS[horizontalLevels || 1] || 0}
          width={round(store.finalSize ? store.finalSize.width : store.size.width)}
          scaleFactor={scaleFactor}
          key={store.id + '-horizontal-full'}
        />
      );

      // Jambs
      horizontalComponents.push(
        <HorizontalDimension
          x={store.absoluteOrigin.x}
          y={0}
          width={round(store.visibleJambWidth)}
          scaleFactor={scaleFactor}
          key={store.id + '-horizontal-jamb-left'}
        />,
        <HorizontalDimension
          x={store.absoluteOrigin.x + store.finalSize.width - store.visibleJambWidth}
          y={0}
          width={round(store.visibleJambWidth)}
          scaleFactor={scaleFactor}
          key={store.id + '-horizontal-jamb-right'}
        />
      );
    }

    if (!this.props.casementStore.sashesOnly) {
      processRootAncestor(this.props.casementStore);
    }

    return (
      <Group>
        <Group x={width}>{verticalComponents}</Group>
        <Group y={height}>{horizontalComponents}</Group>
      </Group>
    );
  }
}

export default inject('casementStore')(observer(Dimensions));
