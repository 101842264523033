import { startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';

import DataModal from '@components/DataModal';
import Card from '@components/configurators/Card';
import SwitchInput from '@components/forms/Switch';

import c from '@utils/constants';

import { InputContainer } from '../Section';

type State = {
  finishSystem: any;
};

const FinishSystem = ({ productData }) => {
  const dispatch = useDispatch();
  const state = useSelector((state: State) => {
    return {
      // @ts-ignore
      finishSystem: state.configuratorForms.finishSystem,
    };
  });

  const materialCard = (material) => {
    if (material) {
      return <Card defaultOpen={false} title={material['name']} narrowStyle={true} />;
    }

    return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
  };

  const handleKeyItemChange = (keyItem) => {
    dispatch(setFormData('finishSystem', keyItem));
  };

  const handleItemChange = (itemKey, item) => {
    dispatch(setFormData(`finishSystem.timber_finishes[${itemKey}]`, item));
    return true;
  };

  const handleOuterFinishChange = (item) => {
    dispatch(setFormData(`finishSystem.outer_frame.timber_finish`, item));
    return true;
  };

  const handleDualColorChange = (value) => {
    dispatch(setFormData(`finishSystem.dual_finish`, value));
  };

  return (
    <>
      <span className="my-2">Finish System</span>
      <InputContainer className="flex flex-row space-x-2 w-full">
        {materialCard(state.finishSystem)}
        <div className="flex flex-col space-y-2 self-center">
          <DataModal
            data={productData.finishSystems}
            materialKey="finishSystem"
            // @ts-ignore
            onItemSelect={(finishSystem) => handleKeyItemChange(finishSystem)}
          />
        </div>
      </InputContainer>
      {state.finishSystem && (
        <SwitchInput
          key="fin1"
          initialState={state.finishSystem && state.finishSystem.dual_finish}
          leftLabel="Dual color"
          onChange={handleDualColorChange}
        />
      )}
      {state.finishSystem &&
        state.finishSystem.data.map((layer, i) => {
          return (
            <React.Fragment key={layer.display_name + i}>
              <span className="my-2">{startCase(layer.display_name)}</span>
              <InputContainer className="flex flex-row space-x-2 w-full">
                {materialCard(state.finishSystem.timber_finishes[i])}
                <div className="flex flex-col space-y-2 self-center">
                  <DataModal
                    data={productData.timberFinishes}
                    materialKey="finishSystem"
                    // @ts-ignore
                    onItemSelect={(timberFinish) => handleItemChange(i, timberFinish)}
                  />
                </div>
              </InputContainer>
            </React.Fragment>
          );
        })}
      {state.finishSystem && state.finishSystem.dual_finish && (
        <React.Fragment>
          <span className="my-2">Outside Top Coat</span>
          <InputContainer className="flex flex-row space-x-2 w-full">
            {materialCard(state.finishSystem.outer_frame['timber_finish'])}
            <div className="flex flex-col space-y-2 self-center">
              <DataModal
                data={productData.timberFinishes}
                materialKey="finishSystem"
                // @ts-ignore
                onItemSelect={(timberFinish) => handleOuterFinishChange(timberFinish)}
              />
            </div>
          </InputContainer>
        </React.Fragment>
      )}
    </>
  );
};

export default FinishSystem;
