import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';

import '@appSrc/css/components/left-sidebar-1.css';

import Item from './item';
import Logo from './logo';

const LeftSidebarContainer = styled.div`
  ${tw`text-white font-normal`}
  max-height: 96vh;
  margin: 20px 0;
  border-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #653359;
`;

const VersionContainer = styled.div`
  ${tw`mt-2 px-4 font-semibold`}
  [data-collapsed='true'] & {
    display: none;
  }
`;

const Sidebar = () => {
  const { navigation, version } = useSelector(
    (state) => ({
      navigation: state.navigation,
      version: state.config.version,
    }),
    shallowEqual
  );
  return (
    <LeftSidebarContainer className="left-sidebar-1">
      <Logo />
      {navigation.map((menu, i) => (
        <div key={i}>
          <div className="left-sidebar-title">
            <span>{menu.title}</span>
          </div>
          <ul>
            {menu.items.map((l0, a) => (
              <li key={a} className="l0">
                <Item {...l0} />
                <ul>
                  {l0.items.map((l1, b) => (
                    <li key={b} className="l1">
                      <Item {...l1} />
                      <ul>
                        {l1.items.map((l2, c) => (
                          <li key={c} className="l2">
                            <Item {...l2} />
                            <ul>
                              {l2.items.map((l3, d) => (
                                <li key={d} className="l3">
                                  <Item {...l3} />
                                  <ul>
                                    {l3.items.map((l4, e) => (
                                      <li key={e} className="l4">
                                        <Item {...l4} />
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <VersionContainer>Version: {version}</VersionContainer>
    </LeftSidebarContainer>
  );
};

export default Sidebar;
