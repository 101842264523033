import API from 'apiv1';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

import DebugConsole from '@appSrc/components/DebugOptions/DebugConsole';

import Button from '@components/buttons/ButtonLink';
import { itemToInput } from '@components/forms/GridForm';

import { update } from '@utils/formHelpers';

import '../../../css/react-notifications.css';
import ContextForm from '../ContextForm';

const AccountForm = ({ business, businessID }) => {
  const [data, onSubmit] = useState(null);
  const { reset, ...methods } = useForm({ defaultValues: business });
  const state = useSelector((state) => {
    return {
      // @ts-ignore
      file: state.forms['company_logo' + businessID],
    };
  });

  const onSubmitFn = (data) => {
    const imageParams = state.file ? { company_logo: state.file } : {};

    // Serialize the address as the API expects at as JSON
    update(data, 'registered_address', (address) => JSON.stringify(address));

    API.updateBusiness(
      API.toFormData({
        ...data,
        ...imageParams,
      }),
      businessID
    )
      .then((resp) => {
        NotificationManager.success(
          'Account details have been updated successfully',
          'Update Successful',
          40000
        );
        // window.location.reload()
      })
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  const items = [
    {
      config: ['full'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Logo',
          error: {},
          name: 'company_logo',
          type: 'image',
          recordId: businessID,
          files: state.file,
          defaultValue: business['company_logo'] || '',
        },
      ],
    },
    {
      config: ['3/4', '3/4', '3/4'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Company name',
          error: { required: 'Company name cannot be blank.' },
          name: 'name',
          type: 'text',
          inlineClass: 'w-1/3',
        },
        {
          label: 'Company No.',
          error: {},
          name: 'company_number',
          type: 'text',
          inlineClass: 'w-1/3',
        },
        {
          label: 'VAT No.',
          error: {},
          name: 'vat_number',
          type: 'text',
          inlineClass: 'w-1/3',
        },
      ],
    },
    {
      config: ['3/4', '3/4'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Email',
          error: {},
          name: 'email',
          type: 'text',
          inlineClass: 'w-1/3',
        },
        {
          label: 'Contact Number',
          error: {},
          name: 'contact_number',
          type: 'text',
          inlineClass: 'w-1/3',
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row">
        <FormProvider {...methods} reset={reset}>
          <ContextForm
            gridItems={items}
            formId={'business'}
            onSubmit={(data) => {
              onSubmit(data);
              onSubmitFn(data);
            }}
            formStyle="flex flex-row"
          >
            <div className="max-w-sm">
              {itemToInput(
                {
                  label: 'Address Line 1',
                  name: 'registered_address[line_1]',
                  type: 'text',
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Address Line 2',
                  name: 'registered_address[line_2]',
                  type: 'text',
                  inputClass: 'w-2/4',
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              <div className="max-w-xs">
                {itemToInput(
                  {
                    label: 'City',
                    name: 'registered_address[city]',
                    type: 'text',
                    inputClass: 'w-1/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Post Code',
                    name: 'registered_address[post_code]',
                    type: 'text',
                    inputClass: 'w-1/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Country',
                    name: 'registered_address[country]',
                    type: 'text',
                    defaultValue: 'United Kingdom',
                    inputClass: 'w-1/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </div>
            </div>
            <div style={{ maxWidth: '140px' }}>
              <Button as="button" type="submit">
                Update Details
              </Button>
            </div>
          </ContextForm>
        </FormProvider>
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default AccountForm;
