import React from 'react';
import styled from 'styled-components';

const Section = ({
  title,
  children,
  borderTop = true,
  borderBottom = true,
  center = false,
  switches = null,
}) => {
  function styleChildren(elements) {
    if (Array.isArray(elements)) {
      return elements.map(
        (element, i) =>
          element &&
          React.cloneElement(element, {
            key: i,
            ...element.props,
            className: `${(element && element.props.className) || ''} ${
              center ? 'self-center' : ''
            }`,
          })
      );
    }

    return React.cloneElement(elements, {
      className: `${(elements && elements.props.className) || ''} ${center ? 'self-center' : ''}`,
    });
  }

  return (
    <div
      className={`flex flex-col border-solid ${borderBottom ? 'border-b-2' : ''} ${
        borderTop ? 'border-t-2' : ''
      } w-full space-y-12 pt-6 pb-6`}
      style={{ minHeight: '200px' }}
    >
      <span
        className="flex-initial max-w-xs align-start whitespace-normal font-semibold"
        style={{ minWidth: '160px', maxWidth: 160 }}
      >
        {title}
      </span>
      <div className="flex flex-col space-y-4 w-full">
        {switches && <div className="w-full">{switches}</div>}
        {styleChildren(children)}
      </div>
    </div>
  );
};

export const InputContainer = styled.div``;

export default Section;
