import API from 'apiv1';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, useWatch, useFormContext } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';

import { setFormData, setConfigurationData } from '@appSrc/actions/configuratorActions';
import Button from '@appSrc/components/buttons/ButtonLink';
import { store } from '@appSrc/store';
import {
  hardwareSetToFormParams,
  finishSystemToFormParams,
  sectionEditorsToFormParams,
} from '@appSrc/stores/sectionUtilities';

import { ICasement } from '@stores/CasementStore';

import DataModal from '@components/DataModal';
import Card from '@components/configurators/Card';
import { itemToInput } from '@components/forms/GridForm';
import SwitchInput from '@components/forms/Switch';

import { DEFAULT_VALUES, upgradeCasementFormData } from '@utils/casementFormHelpers';
import c from '@utils/constants';
import { insertKey } from '@utils/formHelpers';

import Section, { InputContainer } from '../Section';
import TableInputs from '../TableInputs';
import FinishSystem from './FinishSystem';
import HardwareSet from './HardwareSet';

const handleSubmit = async (casementStore, data, editMode, casementId, stageRef) => {
  const state = store.getState();
  const configForm = state.configuratorForms;
  const hardwareSet = configForm.hardwareSet;
  const finishSystem = configForm.finishSystem;
  const sectionEditors = configForm.sectionEditors;
  const { name, ...configData } = data;

  let glazingData = [];

  glazingData = casementStore.glassSummary.map((glass) => ({
    ...glass,
    glazing_unit_id:
      sectionEditors[glass.sectionID] && sectionEditors[glass.sectionID].glazingUnit.id,
  }));

  let hardwareData = casementStore.hardwareSummary;
  hardwareData.sections = hardwareData.sections.map((section) => {
    const { sectionID, ...rest } = section;

    const editorState = sectionEditors[sectionID];
    if (!editorState) return section;

    return {
      ...rest,
      sectionID: sectionID,
      restrictor_quantity: editorState.restrictorQuantity,
      has_shootbolt: editorState.hasShootbolt,
      has_trickle_vent: editorState.hasTrickleVent,
      has_brake: editorState.hasBrake,
      has_extension_set: editorState.hasExtensionSet,
      has_lockable_stay_pin: editorState.hasLockableStayPin,
      has_seal: editorState.hasSeal,
    };
  });

  const hardwareFinishes = {};
  if (configData.hardware_finish_id) {
    // @ts-ignore
    hardwareFinishes.hardware_finish_id = configData.hardware_finish_id.value;
  }
  if (configData.trickle_vent_finish_id) {
    // @ts-ignore
    hardwareFinishes.trickle_vent_finish_id = configData.trickle_vent_finish_id.value;
  }
  if (configData.shootbolt_finish_id) {
    // @ts-ignore
    hardwareFinishes.shootbolt_finish_id = configData.shootbolt_finish_id.value;
  }

  const labourStatistics = casementStore.statistics;

  labourStatistics.is_dual_colour = finishSystem && finishSystem.dual_finish;

  const formData = API.toFormData({
    data: JSON.stringify({
      name: name,
      snapshot: getSnapshot(casementStore),
      data: configData,
      section_editor_data: sectionEditors,
      hardware_set: { ...hardwareSetToFormParams(hardwareSet), ...hardwareFinishes },
      finish_system: finishSystemToFormParams(finishSystem),
      sections: sectionEditorsToFormParams(sectionEditors),

      timber_config: {
        timber: {
          frame_timber_id: configData.frame_timber_id,
          leaf_timber_id: configData.leaf_timber_id,
          sill_timber_id: configData.sill_timber_id,
          frame_engineered: configData.frame_engineered && configData.frame_engineered === 'true',
          sill_engineered: configData.sill_engineered && configData.sill_engineered === 'true',
          leaf_engineered: configData.leaf_engineered && configData.leaf_engineered === 'true',
        },
        timber_items: casementStore.allTimber,
      },

      glazing_config: {
        glass_summary: glazingData,
      },

      labour_config: labourStatistics,

      finish_config: {
        total_surface_area: casementStore.surfaceArea,
      },

      hardware_config: hardwareData,
    }),
  });

  function getSashDiagram(container, category = 'sash') {
    return new Promise((resolve) => {
      const key = container.id;
      container.querySelector('canvas').toBlob(async (blob) => {
        const file = new File([blob], `${category}_${key}.png`, { type: 'image/png' });

        formData.append('sash_diagrams[]', file);
        resolve(true);
      }, 'image/png');
    });
  }

  function getFrameDiagram(frame) {
    return new Promise((resolve) => {
      frame.content.childNodes[0].toBlob((blob) => {
        const file = new File([blob], 'casement.png', { type: 'image/png' });

        formData.append('full_diagram', file);
        resolve(true);
      }, 'image/png');
    });
  }

  try {
    casementStore.clearCurrentSection();
    const promises = [];
    promises.push(getFrameDiagram(stageRef.current));

    document.querySelectorAll('.js-sash-stage-container').forEach((container) => {
      promises.push(getSashDiagram(container, 'sash'));
    });

    document.querySelectorAll('.js-frame-stage-container').forEach((container) => {
      promises.push(getSashDiagram(container, 'frame'));
    });

    await Promise.all(promises);

    // console.log('FormData', formData.getAll('sash_diagrams'));

    if (editMode && casementId) {
      await API.updateCasement(formData, casementId);
    } else {
      await API.createCasement(formData);
    }
    NotificationManager.success('Success');
    window.location.href = '/casements';
  } catch (err) {
    NotificationManager.error(err.message);
  }
};

const thicknessParams = (data) => {
  const sashThickness = Number(data.stiles.thickness);
  const frameThickness = Number(data.jamb.thickness);

  return [
    ['top_rail.thickness', sashThickness],
    ['bottom_rail.thickness', sashThickness],
    ['mid_rail.thickness', sashThickness],
    ['muntin.thickness', sashThickness],
    ['top_sash_bottom_rail.thickness', sashThickness],
    ['mullion.thickness', frameThickness],
    ['transom.thickness', frameThickness],
    ['secondary_transom.thickness', frameThickness],
    ['head.thickness', frameThickness],
  ];
};

type Props = {
  data?: any;
  store?: ICasement;
  editMode?: boolean | string;
  casementId?: Number;
  stageRef: React.RefObject<any>;
  productData?: any;
};

const Updater = ({ control, store, localState }) => {
  const dispatch = useDispatch();
  const formData = useWatch({
    control,
  });

  function updateData() {
    let data = {
      ...formData,
      is_fabrication: localState.isFabricationSizes,
      is_internal_glazed: localState.isInternallyGlazed,
    };
    thicknessParams(data).forEach(([key, value]) => {
      insertKey(data, key, value);
    });
    dispatch(setFormData('casementData', { ...DEFAULT_VALUES, ...data }));
    store.loadFormInputs(data);
  }

  useEffect(() => {
    updateData();
  }, []);

  updateData();
  return <></>;
};

const Fields = observer(
  ({ store, data, editMode, casementId, stageRef, productData, localState, setLocalState }) => {
    const { reset, ...methods } = useFormContext();
    const [selectedMaterials, setSelectedMaterials] = useState({});

    useEffect(() => {
      if (data) {
        setSelectedMaterials({
          ...selectedMaterials,
          frame_timber: productData.timber.find((t) => t.id === Number(data['frame_timber_id'])),
          leaf_timber: productData.timber.find((t) => t.id === Number(data['leaf_timber_id'])),
          sill_timber: productData.timber.find((t) => t.id === Number(data['sill_timber_id'])),
        });
      }
    }, [productData]);

    const columnsLeaf = [{ name: 'Thickness' }, { name: 'Width' }];

    const rowsLeafs = [
      {
        name: 'Stiles',
        inputs: [
          { name: 'stiles[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'stiles[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Top Rail',
        inputs: [
          {
            name: 'top_rail[thickness]',
            static: true,
            value: store.topRailThickness,
          },
          { name: 'top_rail[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Bottom Rail',
        inputs: [
          {
            name: 'bottom_rail[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.bottom_rail.thickness,
          },
          { name: 'bottom_rail[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Mid Rail',
        inputs: [
          {
            name: 'mid_rail[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.mid_rail.thickness,
          },
          { name: 'mid_rail[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Muntin',
        inputs: [
          {
            name: 'muntin[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.muntin.thickness || 0,
          },
          { name: 'muntin[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Top Sash Bottom Rail',
        inputs: [
          {
            name: 'top_sash_bottom_rail[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.top_sash_bottom_rail.thickness,
          },
          { name: 'top_sash_bottom_rail[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Moulding',
        inputs: [
          { name: 'sash_moulding[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'sash_moulding[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Storm Proof Rebate',
        inputs: [
          { name: 'sash_rebate[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'sash_rebate[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Glazing Rebate',
        inputs: [
          {
            value: store.glazingRebateThickness,
            static: true,
          },
          { name: 'glazing_rebate[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Glazing Bead',
        inputs: [
          { name: 'sash_glazing_bead[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'sash_glazing_bead[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Glass gap',
        inputs: [{ disabled: true }, { name: 'glass_gap', type: 'number', inputClass: 'w-full' }],
      },
    ];

    const rowsStickOnBars = [
      {
        name: 'In',
        inputs: [
          { name: 'stick_on_bars[in][thickness]', type: 'number', inputClass: 'self-center w-3/4' },
          { name: 'stick_on_bars[in][width]', type: 'number', inputClass: 'self-center w-3/4' },
        ],
      },
      {
        name: 'Out',
        inputs: [
          {
            name: 'stick_on_bars[out][thickness]',
            type: 'number',
            inputClass: 'self-center w-3/4',
          },
          { name: 'stick_on_bars[out][width]', type: 'number', inputClass: 'self-center w-3/4' },
        ],
      },
    ];

    const rowsTrueBars = [
      {
        name: 'Bar',
        inputs: [
          { name: 'true_bars[thickness]', type: 'number', inputClass: 'self-center  w-3/4' },
          { name: 'true_bars[width]', type: 'number', inputClass: 'self-center  w-3/4' },
        ],
      },
      {
        name: 'Rebate',
        inputs: [
          { name: 'true_bars[rebate]', type: 'number', inputClass: 'self-center  w-3/4' },
          { disabled: true },
        ],
      },
    ];

    const columnsSashGaps = [
      { name: 'Top' },
      { name: 'Left' },
      { name: 'Bottom' },
      { name: 'Right' },
    ];

    const rowsSashGaps = [
      {
        name: '',
        inputs: [
          { name: 'sash_gaps[top]', type: 'number', inputClass: 'self-center  w-3/4' },
          { name: 'sash_gaps[left]', type: 'number', inputClass: 'self-center  w-3/4' },
          { name: 'sash_gaps[bottom]', type: 'number', inputClass: 'self-center  w-3/4' },
          { name: 'sash_gaps[right]', type: 'number', inputClass: 'self-center  w-3/4' },
        ],
      },
    ];

    const rowsRainDrips = [
      {
        name: 'Transom',
        inputs: [
          {
            name: 'transom_rain_drip[thickness]',
            type: 'number',
            inputClass: 'self-center  w-3/4',
          },
          { name: 'transom_rain_drip[width]', type: 'number', inputClass: 'self-center  w-3/4' },
        ],
      },
      {
        name: 'Head',
        inputs: [
          { name: 'head_rain_drip[thickness]', type: 'number', inputClass: 'self-center  w-3/4' },
          { name: 'head_rain_drip[width]', type: 'number', inputClass: 'self-center  w-3/4' },
        ],
      },
    ];

    const columnsFrame = [{ name: 'Thickness' }, { name: 'Width' }];

    const rowsFrame = [
      {
        name: 'Jamb',
        inputs: [
          { name: 'jamb[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'jamb[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Mullion',
        inputs: [
          {
            name: 'mullion[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.mullion.thickness,
          },
          { name: 'mullion[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Head',
        inputs: [
          {
            name: 'head[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.head.thickness,
          },
          { name: 'head[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Transom',
        inputs: [
          {
            name: 'transom[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.transom.thickness,
          },
          { name: 'transom[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Transom angle',
        inputs: [{ name: 'transom[angle]', type: 'number', inputClass: 'w-full' }],
      },
      {
        name: 'Secondary Transom',
        inputs: [
          {
            name: 'secondary_transom[thickness]',
            type: 'number',
            inputClass: 'w-full',
            static: true,
            value: store.secondary_transom.thickness,
          },
          { name: 'secondary_transom[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Sill',
        inputs: [
          { name: 'sill[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'sill[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Sill horns',
        inputs: [
          { disabled: true },
          { name: 'sill_horns[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Sill projection',
        inputs: [{ name: 'sill[projection]', type: 'number', inputClass: 'w-full' }],
      },
      {
        name: 'Sill angle',
        inputs: [{ name: 'sill[angle]', type: 'number', inputClass: 'w-full' }],
      },
      {
        name: 'Rebate',
        inputs: [
          { name: 'rebate[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'rebate[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Direct glazing glass gap',
        inputs: [
          { disabled: true },
          { name: 'frame_glass_gap', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Glazing Bead',
        inputs: [
          { name: 'frame_glazing_bead[thickness]', type: 'number', inputClass: 'w-full' },
          { name: 'frame_glazing_bead[width]', type: 'number', inputClass: 'w-full' },
        ],
      },
      {
        name: 'Draft seal gap',
        inputs: [
          { disabled: true },
          { name: 'draft_seal_gap', type: 'number', inputClass: 'w-full' },
        ],
      },
    ];

    const materialCard = (material) => {
      if (material) {
        return <Card defaultOpen={false} title={material['name']} narrowStyle={true} />;
      }

      return <Card defaultOpen={false} blank={true} narrowStyle={true} />;
    };

    const handleTimberChange = (key: string, timberType: any) => {
      methods.setValue(`${key}_id`, timberType.id);
      setSelectedMaterials({ ...selectedMaterials, [key]: timberType });
    };

    return (
      <>
        <Section title={'Product details'}>
          <InputContainer className="flex flex-row space-x-12 w-full">
            <InputContainer className="flex flex-row space-x-2">
              {itemToInput(
                {
                  label: 'Name',
                  name: 'name',
                  type: 'text',
                  defaultValue: '',
                  inputClass: 'w-3/4',
                  error: { required: 'Please write a product name' },
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Window style',
                  name: 'window_style',
                  type: 'newSelect',
                  options: [
                    { value: 'flush', label: 'Flush' },
                    { value: 'storm_proof', label: 'Storm proof' },
                  ],
                  defaultValue: '',
                  inputClass: 'w-1/4',
                  style: { minWidth: '150px', paddingRight: '12px', marginLeft: '18px' },
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
              {itemToInput(
                {
                  label: 'Sub-type',
                  name: 'sub_type',
                  type: 'newSelect',
                  options: [
                    { value: 'standard', label: 'Traditional' },
                    { value: 'performance', label: 'Performance' },
                  ],
                  defaultValue: '',
                  inputClass: 'w-1/4',
                  style: { minWidth: '150px' },
                },
                null,
                methods.register,
                methods.errors,
                methods.control
              )}
            </InputContainer>
          </InputContainer>
        </Section>
        <Section title={'Sizes'}>
          <InputContainer className="flex flex-row space-x-12 w-full">
            <InputContainer className="flex flex-col space-y-2">
              <SwitchInput
                initialState={localState.isFabricationSizes}
                leftLabel="Brick Opening"
                rightLabel="Fabrication"
                onChange={(value) => setLocalState({ ...localState, isFabricationSizes: value })}
              />
              <InputContainer className="flex flex-row">
                {itemToInput(
                  {
                    label: 'Width',
                    name: 'size[width]',
                    type: 'number',
                    defaultValue: '',
                    error: { required: 'Please provide window width' },
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Height',
                    name: 'size[height]',
                    type: 'number',
                    defaultValue: '',
                    error: { required: 'Please provide window height' },
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Hidden behind brick (mm)',
                    name: 'hidden_in_brick',
                    type: 'number',
                    defaultValue: 0,
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Arc rise',
                    name: 'arc_rise',
                    type: 'number',
                    defaultValue: 0,
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    label: 'Part of bay',
                    name: 'part_of_bay',
                    type: 'checkbox',
                    options: [{ name: '', value: true }],
                    inputClass: 'w-2/4',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}

                {/* Hidden fields */}
                {itemToInput(
                  {
                    name: 'frame_timber_id',
                    type: 'hidden',
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    name: 'leaf_timber_id',
                    type: 'hidden',
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
                {itemToInput(
                  {
                    name: 'sill_timber_id',
                    type: 'hidden',
                    defaultValue: '',
                  },
                  null,
                  methods.register,
                  methods.errors,
                  methods.control
                )}
              </InputContainer>
            </InputContainer>
          </InputContainer>
        </Section>
        <Section title={'Frame'}>
          {/* Upper Sash  */}
          <InputContainer className="flex flex-row space-x-12">
            <InputContainer className="flex flex-col space-y-2">
              <span className="pl-16">Leafs</span>
              <TableInputs methods={methods} columns={columnsLeaf} rows={rowsLeafs} />
              <SwitchInput
                initialState={localState.isInternallyGlazed}
                leftLabel="Externally"
                rightLabel="Internally Glazed"
                onChange={(value) => setLocalState({ ...localState, isInternallyGlazed: value })}
              />
              <span className="pt-6">Stick-on Georgian bars</span>
              <TableInputs methods={methods} columns={columnsLeaf} rows={rowsStickOnBars} />
              <span className="pt-4">True bars</span>
              <TableInputs methods={methods} columns={columnsLeaf} rows={rowsTrueBars} />
            </InputContainer>
            <InputContainer className="flex flex-col space-y-2">
              <span className="pl-16">Frame</span>
              <TableInputs methods={methods} columns={columnsFrame} rows={rowsFrame} />
              <span className="pt-6">Sash gaps</span>
              <TableInputs methods={methods} columns={columnsSashGaps} rows={rowsSashGaps} />
              <span className="pt-6">Rain drips</span>
              <TableInputs methods={methods} columns={columnsFrame} rows={rowsRainDrips} />
            </InputContainer>
            <InputContainer className="flex flex-col">
              <span className="mb-2">Frame timber</span>
              <InputContainer className="flex flex-row space-x-2 w-full">
                {materialCard(selectedMaterials['frame_timber'])}
                <div className="flex flex-col space-y-2 self-center">
                  <DataModal
                    data={productData.timber}
                    materialKey="timberType"
                    // @ts-ignore
                    onItemSelect={(timber) => handleTimberChange('frame_timber', timber)}
                  />
                </div>
              </InputContainer>
              {selectedMaterials['frame_timber'] &&
                !selectedMaterials['frame_timber']['has_solid'] &&
                !selectedMaterials['frame_timber']['has_engineered'] && (
                  <p className="text-red-A400">
                    You do not have any timber available of this type.
                  </p>
                )}
              {selectedMaterials['frame_timber'] &&
                (selectedMaterials['frame_timber']['has_solid'] ||
                  selectedMaterials['frame_timber']['has_engineered']) && (
                  <InputContainer className="flex flex-row space-x-2 w-full">
                    {itemToInput(
                      {
                        label: 'Frame timber composition',
                        name: 'frame_engineered',
                        error: { required: 'Please select timber composition for frame' },
                        orientation: 'vertical',
                        type: 'radio',
                        options: [
                          {
                            label: 'Solid',
                            value: 'false',
                            checked: selectedMaterials['frame_timber']['has_solid'],
                            disabled: !selectedMaterials['frame_timber']['has_solid'],
                          },
                          {
                            label: 'Engineered',
                            value: 'true',
                            checked:
                              selectedMaterials['frame_timber']['has_engineered'] &&
                              !selectedMaterials['frame_timber']['has_solid'],
                            disabled: !selectedMaterials['frame_timber']['has_engineered'],
                          },
                        ],
                        defaultValue: '',
                        inputClass: 'w-2/4',
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                  </InputContainer>
                )}
              <span className="my-2">Leaf timber</span>
              <InputContainer className="flex flex-row space-x-2 w-full">
                {materialCard(selectedMaterials['leaf_timber'])}
                <div className="flex flex-col space-y-2 self-center">
                  <DataModal
                    data={productData.timber}
                    materialKey="timberType"
                    // @ts-ignore
                    onItemSelect={(timber) => handleTimberChange('leaf_timber', timber)}
                  />
                </div>
              </InputContainer>
              {selectedMaterials['leaf_timber'] &&
                !selectedMaterials['leaf_timber']['has_solid'] &&
                !selectedMaterials['leaf_timber']['has_engineered'] && (
                  <p className="text-red-A400">
                    You do not have any timber available of this type.
                  </p>
                )}
              {selectedMaterials['leaf_timber'] &&
                (selectedMaterials['leaf_timber']['has_solid'] ||
                  selectedMaterials['leaf_timber']['has_engineered']) && (
                  <InputContainer className="flex flex-row space-x-2 w-full">
                    {itemToInput(
                      {
                        label: 'Leaf timber composition',
                        name: 'leaf_engineered',
                        error: { required: 'Please select timber composition for frame' },
                        orientation: 'vertical',
                        type: 'radio',
                        options: [
                          {
                            label: 'Solid',
                            value: 'false',
                            checked: selectedMaterials['leaf_timber']['has_solid'],
                            disabled: !selectedMaterials['leaf_timber']['has_solid'],
                          },
                          {
                            label: 'Engineered',
                            value: 'true',
                            checked:
                              selectedMaterials['leaf_timber']['has_engineered'] &&
                              !selectedMaterials['leaf_timber']['has_solid'],
                            disabled: !selectedMaterials['leaf_timber']['has_engineered'],
                          },
                        ],
                        defaultValue: '',
                        inputClass: 'w-2/4',
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                  </InputContainer>
                )}
              <span className="my-2">Sill timber</span>
              <InputContainer className="flex flex-row space-x-2 w-full">
                {materialCard(selectedMaterials['sill_timber'])}
                <div className="flex flex-col space-y-2 self-center">
                  <DataModal
                    data={productData.timber}
                    materialKey="timberType"
                    // @ts-ignore
                    onItemSelect={(timber) => handleTimberChange('sill_timber', timber)}
                  />
                </div>
              </InputContainer>
              {selectedMaterials['sill_timber'] &&
                !selectedMaterials['sill_timber']['has_solid'] &&
                !selectedMaterials['sill_timber']['has_engineered'] && (
                  <p className="text-red-A400">
                    You do not have any timber available of this type.
                  </p>
                )}
              {selectedMaterials['sill_timber'] &&
                (selectedMaterials['sill_timber']['has_solid'] ||
                  selectedMaterials['sill_timber']['has_engineered']) && (
                  <InputContainer className="flex flex-row space-x-2 w-full">
                    {itemToInput(
                      {
                        label: 'Sill timber composition',
                        name: 'sill_engineered',
                        error: { required: 'Please select timber composition for frame' },
                        orientation: 'vertical',
                        type: 'radio',
                        options: [
                          {
                            label: 'Solid',
                            value: 'false',
                            checked: selectedMaterials['sill_timber']['has_solid'],
                            disabled: !selectedMaterials['sill_timber']['has_solid'],
                          },
                          {
                            label: 'Engineered',
                            value: 'true',
                            checked:
                              selectedMaterials['sill_timber']['has_engineered'] &&
                              !selectedMaterials['sill_timber']['has_solid'],
                            disabled: !selectedMaterials['sill_timber']['has_engineered'],
                          },
                        ],
                        defaultValue: '',
                        inputClass: 'w-2/4',
                      },
                      null,
                      methods.register,
                      methods.errors,
                      methods.control
                    )}
                  </InputContainer>
                )}
              <HardwareSet productData={productData} />
              <FinishSystem productData={productData} />
            </InputContainer>
          </InputContainer>

          {typeof editMode !== 'undefined' && (
            <div
              className="fixed bottom-0 py-4 pl-64 inset-x-0 bg-orange-M100"
              style={{ zIndex: 100 }}
            >
              <Button
                as="button"
                type="button"
                onClick={() =>
                  handleSubmit(store, methods.getValues(), editMode, casementId, stageRef)
                }
              >
                Save
              </Button>
              <Button
                buttonStyle="light-secondary-outline"
                as="button"
                className="ml-2"
                onClick={() => (window.location.href = '/casements')}
              >
                Back To Products
              </Button>
            </div>
          )}
        </Section>
      </>
    );
  }
);

const FormFields = ({ store, data, editMode, casementId, stageRef, productData }: Props) => {
  const [localState, setLocalState] = useState({
    isFabricationSizes: true,
    isInternallyGlazed: true,
  });
  const { reset, ...methods } = useForm({
    defaultValues: upgradeCasementFormData(data),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  return (
    <div className="flex flex-col w-full">
      <FormProvider {...methods} reset={reset}>
        <form
          onSubmit={methods.handleSubmit(async (data) => {
            // Trigger validations
            const result = await methods.trigger();
            if (!result) return;

            handleSubmit(store, data, editMode, casementId, stageRef);
          })}
          id="casement-form"
        >
          <Updater control={methods.control} store={store} localState={localState} />
          <Fields
            store={store}
            editMode={editMode}
            casementId={casementId}
            stageRef={stageRef}
            productData={productData}
            data={data}
            localState={localState}
            setLocalState={setLocalState}
          />
        </form>
      </FormProvider>
    </div>
  );
};
export default FormFields;
