import API from 'apiv1';
import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable, { TruncatedCell } from '@components/datatable';

import { setSuppliers } from '../actions/formActions';
import RecordActions from '../components/datatable/RecordActions';
import MiscForm from '../components/forms/MiscForm';
import MaterialsMenu from '../components/menus/materials';
import Modal from '../components/modals/modal';
// import SectionTitle from '../components/section-title';
import Widget from '../components/widget';
import { store } from '../store';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Supplier',
    accessor: 'supplier',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
  },
  {
    Header: 'VAT',
    accessor: 'vat_rate',
  },
  {
    Header: 'Actions',
    accessor: 'id',
    Cell: ({ value }) => RecordActions(value, MiscForm, API.deleteMiscItem),
  },
];

const Page = ({ miscRecords, suppliers }) => {
  const dispatch = useDispatch();
  const formId = 'misc';

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, [miscRecords]);

  return (
    <>
      {/* <SectionTitle title="Materials & Pricing " subtitle="Miscellenous" /> */}

      <MaterialsMenu />

      <Widget
        title="Miscellenous"
        borderless={true}
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Miscellenous"
              body={<MiscForm formId={formId} />}
              buttonTitle="Add Miscellenous"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
              buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <Datatable columns={columns} data={miscRecords} />
      </Widget>
    </>
  );
};

const MiscPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default MiscPage;
