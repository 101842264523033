import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../store';

import Datatable, { TruncatedCell } from '@components/datatable';
import RecordActions from '../components/datatable/RecordActions';
// import SectionTitle from '../components/section-title';
import Widget from '../components/widget';
import SupplierForm from '../components/forms/SupplierForm';
import BoxedLayout from '@appSrc/layouts/content/Boxed';

import Modal from '../components/modals/modal';
import API from 'apiv1';

const Page = ({ records }) => {
  const formId = 'clientsForm';

  const columns = [
    {
      Header: 'Name',
      accessor: 'label',
    },
    {
      Header: 'Actions',
      accessor: 'value',
      Cell: ({ value }) => RecordActions(value, SupplierForm, API.deleteSupplier),
    },
  ];

  return (
    <>
      {/* <SectionTitle title="Suppliers" /> */}

      <Widget
        title="Suppliers"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Add Supplier"
              body={<SupplierForm formId={formId} />}
              buttonTitle="Add Supplier"
              modalButtonText="Add new"
              buttonFormId={formId}
              buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <Datatable columns={columns} data={records} />
      </Widget>
    </>
  );
};

const SuppliersPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default SuppliersPage;
