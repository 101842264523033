import React from 'react';
import { BsPeopleFill } from 'react-icons/bs';
import { FaLayerGroup } from 'react-icons/fa';
import { FaCogs, FaUserCog } from 'react-icons/fa';
import { FiActivity, FiHelpCircle, FiClipboard, FiGrid } from 'react-icons/fi';

const initialState = [
  {
    title: 'Menu',
    items: [
      {
        url: '/projects',
        icon: <FiClipboard size={20} />,
        title: 'Projects',
        items: [],
      },
      {
        url: '/hardware',
        icon: <FiGrid size={20} />,
        title: 'Materials',
        items: [],
      },
      {
        url: '/box_sash_configs',
        icon: <FaCogs size={20} />,
        title: 'Products',
        items: [
          {
            url: '/box_sash_configs',
            title: 'Box Sash',
            items: [],
          },
          {
            url: '/casements',
            title: 'Casement',
            items: [],
          },
        ],
      },
      {
        url: '/clients',
        icon: <BsPeopleFill size={20} />,
        title: 'Clients',
        items: [],
      },
      {
        url: '/account',
        icon: <FaUserCog size={20} />,
        title: 'Account',
        items: [],
      },
      {
        url: '/',
        icon: <FiActivity size={20} />,
        title: 'Workshop',
        items: [
          {
            url: '/pricing_setting',
            title: 'Pricing Settings',
            items: [],
          },
          {
            url: '/product_production/global',
            title: 'General Production',
            items: [],
          },
          {
            url: '/product_production/box_sash',
            title: 'Box Sash Production',
            items: [],
          },
          {
            url: '/product_production/casement',
            title: 'Casement Production',
            items: [],
          },
          {
            url: '/suppliers',
            title: 'Suppliers',
            items: [],
          },
          {
            url: '/timber_type',
            title: 'Timber Types',
            items: [],
          },
          {
            url: '/account/default_quote_settings',
            title: 'Quote Settings',
            items: [],
          },
          // {
          //   url: '/drawing_instructions',
          //   title: 'Drawings',
          //   items: [],
          // },
        ],
      },
    ],
  },
  {
    title: 'Section title',
    items: [
      {
        url: '/',
        icon: <FiHelpCircle size={20} />,
        title: 'Menu',
        items: [],
      },
    ],
  },
];

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
