import API from 'apiv1';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import Datatable from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import ProjectsForm from '@components/forms/ProjectsForm';
import Modal from '@components/modals/modal';
import Widget from '@components/widget';

import { setClients } from '../actions/formActions';
import { store } from '../store';

const Page = ({ records, clients }) => {
  const dispatch = useDispatch();
  const formId = 'projectsForm';

  useEffect(() => {
    dispatch(setClients(clients));
  }, [records]);

  const columns = [
    {
      Header: 'Project Name',
      accessor: 'name',
      Cell: ({ value, row }) => (
        <a className="text-blue-M900" href={`/projects/${row.original.id}`}>
          {value}
        </a>
      ),
    },
    {
      Header: 'Client',
      accessor: 'client',
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value }) => moment(value).format('MMM DD HH:mm'),
    },
    {
      Header: 'Modified',
      accessor: 'updated_at',
      Cell: ({ value }) => moment(value).format('MMM DD HH:mm'),
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => RecordActions(value, ProjectsForm, API.deleteProject),
    },
  ];

  const handleRowClick = (item) => {
    window.location.pathname = `projects/${item.id}`;
  };

  return (
    <>
      <Widget
        title="Projects"
        borderless={true}
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Start New Project"
              body={<ProjectsForm formId={formId} />}
              buttonTitle="Create"
              modalButtonText="New Project"
              buttonFormId={formId}
              // buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            />
          </div>
        }
      >
        <Datatable columns={columns} data={records} />
      </Widget>
    </>
  );
};

const ProjectsPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default ProjectsPage;
