import * as Components from '../';
import '../app/css/_components.css';
import '../app/css/main.css';
import '../app/css/tailwind.css';
import {
  initialize,
  uninitialize,
  unmountComponents,
  unmountComponent,
} from '../utils/mountComponents';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

// const getComponents = async () => {
//   const { Components } = await import('../components');
//   return Components;
// };

document.addEventListener('turbolinks:load', () => {
  // console.log(getComponents());
  // getComponents().then((components) => initialize(document, components));
  // unmountComponents(document.body);
  initialize(document, Components);
});

// document.addEventListener('turbolinks:before-render', () => {
//   console.log('Visit Started');
//   // getComponents().then((components) => initialize(document, components));
//   unmountComponent(document.getElementById('app'));
//   unmountComponents(document.body);
//   // uninitialize();
//   // initialize(document, Components);
// });

// Ignore onwheel effect for number inputs while focused to avoid incrementing or decrementing the value
document.addEventListener('wheel', function (event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
