import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const BoxedLayout = (main: JSX.Element, sidebar = []): JSX.Element => {
  const [hide, setVisibility] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('turbolinks:load', () => {
      setVisibility(true);
    });
  }, []);

  return (
    <MainConentWrapper>
      <ContentWrapper>{!hide && main}</ContentWrapper>
      {sidebar.length !== 0 && <SidebarWrapper>{sidebar}</SidebarWrapper>}
    </MainConentWrapper>
  );
};

const MainConentWrapper = styled.div`
  ${tw`flex flex-1 flex-row mb-24`}
`;

const ContentWrapper = styled.div`
  ${tw`flex flex-auto flex-col mr-4 w-full overflow-x-auto lg:px-2 lg:py-4`}
  background-color: #fff;
  border-radius: 1.25rem;
`;

const SidebarWrapper = styled.div`
  ${tw`flex flex-auto flex-col`}
  width: 400px;
  max-width: 400px;
  height: 400px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f5ebdf;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
`;

export default BoxedLayout;
