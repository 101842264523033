import API from 'apiv1';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable from '@components/datatable';
import RecordActions from '@components/datatable/RecordActions';
import FinishSystemForm from '@components/forms/FinishSystemForm';
import TimberFinishItemsForm from '@components/forms/TimberFinishItemsForm';
import MaterialsMenu from '@components/menus/materials';
import Modal from '@components/modals/modal';
import Widget from '@components/widget';

import { setSuppliers } from '../actions/formActions';
import { store } from '../store';

const columns = [
  {
    Header: 'Product Code',
    accessor: 'product_reference',
  },
  {
    Header: 'Colour Name',
    accessor: 'name',
  },
  {
    Header: 'Colour Code',
    accessor: 'code',
  },
  {
    Header: 'Supplier',
    accessor: 'supplier',
  },
  {
    Header: 'Price / l',
    accessor: 'price',
    Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
  },
  {
    Header: 'VAT',
    accessor: 'vat_rate',
  },
  {
    Header: 'Actions',
    accessor: 'id',
    Cell: ({ value }) => RecordActions(value, TimberFinishItemsForm, API.deleteTimberFinishItem),
  },
];

const Page = ({ records, finishSystems, timberFinishItems, suppliers }) => {
  const dispatch = useDispatch();
  const formId = 'timberFinishes';
  const formFinishSystemId = 'finishSystem';

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, [records]);

  const finishSytemColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) =>
        RecordActions(value, FinishSystemForm, API.deleteFinishSystem, {
          timberFinishItems,
        }),
    },
  ];

  return (
    <>
      <MaterialsMenu />

      <Widget
        title="Finish Systems"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Finish System"
              minWidth={500}
              body={
                <FinishSystemForm
                  formId={formFinishSystemId}
                  timberFinishItems={timberFinishItems}
                />
              }
              buttonTitle="Create"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New System"
              buttonFormId={formFinishSystemId}
            />
          </div>
        }
      >
        <Datatable columns={finishSytemColumns} data={finishSystems} />
      </Widget>

      <Widget
        title="Finishes"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Finish"
              body={<TimberFinishItemsForm formId={formId} />}
              buttonTitle="Add finish"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
            />
          </div>
        }
      >
        <Datatable columns={columns} data={records} />
      </Widget>
    </>
  );
};

const TimberFinishItemsPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default TimberFinishItemsPage;
