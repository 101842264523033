import React from 'react';
import { css } from 'styled-components';
import tw from 'twin.macro';

import ButtonLink from '@components/buttons/ButtonLink';

const MaterialsMenu = (_props) => {
  return (
    <div className="flex flex-col lg:flex-row lg:flex-wrap items-start lg:items-center content-between justify-center space-x-2 my-6">
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/hardware">
        Hardware
      </ButtonLink>
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/hardware_sets">
        Sets
      </ButtonLink>
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/hardware_sets/casements">
        Casement Sets
      </ButtonLink>
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/timber">
        Timber
      </ButtonLink>
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/glazing_unit">
        Glazing Units
      </ButtonLink>
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/timber_finish">
        Finishes
      </ButtonLink>
      {/* <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/miscelleneous">
        Miscellenous
      </ButtonLink> */}
      <ButtonLink buttonStyle="light-primary" css={css(tw`mb-2`)} href="/moulding">
        Mouldings & Trimmings
      </ButtonLink>
    </div>
  );
};

export default MaterialsMenu;
