import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers';

export var store;

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // trace: true,
        // traceLimit: 25,
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware()
  // other store enhancers if any
);
export const initStore = (initialState) => {
  store = createStore(rootReducer, initialState, enhancer);
};
