import API from 'apiv1';
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FaPercentage } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

import { itemToInput } from '@components/forms/GridForm';

import '../../css/react-notifications.css';
import DebugConsole from '../DebugOptions/DebugConsole';
import { Alert } from '../alerts';
import ContextForm from './ContextForm';
import TextInput from './TextInput';
import TimberSelector from './TimberSelector';
import { CurrencyInput } from './currencyInput';
import { NumberInput } from './numberInput';

const dimensionsComponent = (item, alerts, register, errors, control, timberType) => {
  return (
    <div
      className="form-element flex items-center justify-start flex-row space-x-2"
      style={{ flexDirection: 'row' }}
      key={'dimensionsComponent'}
    >
      <div className="form-element">
        <div className="form-label required">Thickness mm</div>
        <div className="flex items-start justify-start flex-col">
          {/* @ts-ignore */}
          <Controller
            as={NumberInput}
            name="thickness"
            style={`form-textarea ${errors['thickness'] ? 'border border-red-500' : ''} w-32`}
            icon={null}
            control={control}
            rules={{ required: 'Thickness is required' }}
            min={0}
            defaultValue={''}
          />
        </div>
      </div>
      <div className="form-element">
        <div className={`form-label ${timberType ? 'required' : ''}`}>Width mm</div>
        <div className="flex items-start justify-start flex-col">
          {/* @ts-ignore */}
          <Controller
            as={NumberInput}
            name="width"
            style={`form-textarea ${errors['width'] ? 'border border-red-500' : ''} w-32`}
            icon={null}
            control={control}
            rules={{ required: timberType && 'Width is required' }}
            min={0}
            defaultValue={''}
          />
        </div>
      </div>
      <div className="form-element">
        <div className={`form-label ${timberType ? 'required' : ''}`}>Length mm</div>
        <div className="flex items-start justify-start flex-col">
          {/* @ts-ignore */}
          <Controller
            as={NumberInput}
            name="length"
            style={`form-textarea ${errors['length'] ? 'border border-red-500' : ''} w-32`}
            icon={null}
            control={control}
            rules={{ required: timberType && 'Length is required' }}
            min={0}
            defaultValue={''}
          />
        </div>
      </div>
      {errors['thickness'] && <div className="form-error">{errors['thickness'].message}</div>}
      {errors['width'] && <div className="form-error">{errors['width'].message}</div>}
      {errors['length'] && <div className="form-error">{errors['length'].message}</div>}
    </div>
  );
};

const timberDetails = (item, alerts, register, errors, control, timber, density) => {
  return (
    <div
      className="form-element flex items-center justify-start flex-row space-x-2"
      style={{ flexDirection: 'row' }}
      key={'timberDetails'}
    >
      <div className="form-element flex items-center justify-start flex-col">
        <div className="form-element">
          <div className="form-label">Timber type</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={TextInput}
              name="timber[type]"
              style={`form-textarea w-64`}
              icon={item.icon || null}
              control={control}
              disabled={true}
              defaultValue={timber.type || ''}
            />
          </div>
        </div>
        <div className="form-element">
          <div className="form-label">Timber name</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={TextInput}
              name="timber[name]"
              style={`form-textarea w-64`}
              icon={item.icon || null}
              control={control}
              disabled={true}
              defaultValue={timber.name || ''}
            />
          </div>
        </div>
      </div>

      <div className="form-element flex items-center justify-start flex-col">
        <div className="form-element">
          <div className="form-label required">Density kg/m3</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={NumberInput}
              name="timber_density"
              style={`form-textarea ${errors['density'] ? 'border border-red-500' : ''} w-32`}
              icon={item.icon || null}
              control={control}
              rules={{ required: 'Density is required' }}
              defaultValue={density || timber.density}
              min={item.min || 0}
            />
          </div>
        </div>
        <div className="form-element">
          <div className="form-label">Lambda</div>
          <div className="flex items-start justify-start flex-col">
            {/* @ts-ignore */}
            <Controller
              as={NumberInput}
              name="length"
              style={`form-textarea w-32`}
              icon={item.icon || null}
              control={control}
              disabled={true}
              min={item.min || 0}
              defaultValue={timber.lambda}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const priceAndVAT = (item, alerts, register, errors, control, timberType) => {
  return (
    <div
      className="form-element flex items-start justify-start space-y-2"
      style={{ flexDirection: 'column' }}
      key={item.name}
    >
      <div className="flex flex-row items-center justify-start space-x-2">
        <div className="form-element">
          <div className="form-label required">
            {timberType ? (
              'Price'
            ) : (
              <>
                Price m
                <span className="text-2xl" style={{ lineHeight: '0.5rem' }}>
                  {' '}
                  &#179;
                </span>
              </>
            )}
          </div>
          {/* @ts-ignore */}
          <Controller
            as={CurrencyInput}
            name="price"
            style={`form-textarea ${errors['price'] ? 'border border-red-500' : ''} w-40`}
            placeholder={'3.02'}
            control={control}
            rules={{ required: 'Please enter price' }}
            defaultValue={''}
          />
        </div>
        {!alerts && errors['price'] && <div className="form-error">{errors['price'].message}</div>}
        <div style={{ maxWidth: 100 }}>
          {itemToInput(
            {
              label: 'Unit',
              error: { required: 'Please select Pricing Unit.' },
              name: 'unit',
              type: 'newSelect',
              options: [
                { label: 'm', value: 'm' },
                { label: 'm2', value: 'm2' },
                { label: 'm3', value: 'm3' },
              ],
              style: {
                minWidth: 100,
                maxWidth: 100,
              },
              defaultValue: { label: 'm3', value: 'm3' },
              inputClass: 'w-2/4',
            },
            null,
            register,
            errors,
            control
          )}
        </div>
      </div>
      <div className="form-element">
        <div className="form-label required">VAT</div>
        {/* @ts-ignore */}
        <Controller
          as={NumberInput}
          name="vat_rate"
          style={`form-textarea ${errors['vat_rate'] ? 'border border-red-500' : ''} w-24`}
          placeholder=""
          icon={<FaPercentage />}
          control={control}
          rules={{ required: 'Please a valid percentage value.' }}
          defaultValue={20}
          min={0}
        />
      </div>
      {!alerts && errors['vat_rate'] && (
        <div className="form-error">{errors['vat_rate'].message}</div>
      )}
    </div>
  );
};

const MODE_HANDLER = {
  edit: API.updateTimberItem,
  default: API.createTimberItem,
};

const certificateOptions = [
  { label: 'FSC', value: 'fsc' },
  { label: 'PEFC', value: 'pfc' },
];

const TimberItemsForm = ({
  message = null,
  formId,
  timberTypes,
  recordId = null,
  mode = 'default',
}) => {
  const [data, onSubmit] = useState(null);
  const [timber, setTimber] = useState(null);
  const [timberType, setTimberType] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const { reset, ...methods } = useForm();
  const state = useSelector((state) => {
    return {
      supplierOptions: state.forms.suppliers,
    };
  });

  useEffect(() => {
    if (recordId == null) return;
    if (typeof state.supplierOptions === 'undefined') return;

    API.getTimberItem(recordId).then((data) => {
      const supplier = state.supplierOptions.find(
        (option) => Number(option.value) === data['supplier_id']
      );
      let certifications = [];
      if (data['pfc']) {
        certifications.push('pfc');
      }
      if (data['fsc']) {
        certifications.push('fsc');
      }
      let selectedTimberType = timberTypes.find((option) => option.id === data['timber_type_id']);
      setTimber(selectedTimberType);
      setTimberType(data['timber_composition'] === 'engineered');
      setDefaultValues(data);
      reset({ ...data, supplier, certifications });
    });
  }, [reset, recordId, state.supplierOptions]);

  const onSubmitFn = (data) => {
    // console.log('Data', data);
    // console.log('Submit count', methods.formState.submitCount);
    // console.log('Timber', timber);

    let { ...filteredData } = data;
    MODE_HANDLER[mode || 'default'](
      API.toFormData({
        ...filteredData,
        supplier: filteredData.supplier.value,
        certifications: filteredData.certifications,
        timber_type_id: timber.id,
        timber_composition: timberType ? 'engineered' : 'solid',
        unit: filteredData.unit.value,
      }),
      recordId
    )
      .then((resp) => window.location.reload())
      .catch(() =>
        NotificationManager.error(
          'There was an error while submitting your form, our team has been informed of this error.',
          'Submission Error',
          40000
        )
      );
  };

  let items = [
    {
      config: ['3/4', '3/4'],
      style: { orientation: 'column' },
      items: [
        {
          label: 'Supplier',
          error: { required: 'Please enter a supplier' },
          name: 'supplier',
          options: state.supplierOptions,
          type: 'combobox',
          inlineClass: 'w-1/4',
        },
        {
          label: 'Certifications',
          error: {},
          name: 'certifications',
          type: 'checkbox',
          options: certificateOptions,
          inlineClass: 'w-1/3',
          orientation: 'vertical',
        },
      ],
    },
    {
      config: ['full', 'full'],
      style: { orientation: 'column' },
      items: [
        {
          error: { required: 'You must select at least one option' },
          type: 'custom',
          /* @ts-ignore */
          component: (...props) => dimensionsComponent(...props, timberType),
        },
        {
          error: {},
          type: 'custom',
          /* @ts-ignore */
          component: (...props) => timberDetails(...props, timber, defaultValues['timber_density']),
        },
      ],
    },
    {
      config: ['full'],
      style: { orientation: 'column-reverse' },
      items: [
        {
          error: { required: 'Please enter quantity and price' },
          name: '',
          type: 'custom',
          /* @ts-ignore */
          component: (...props) => priceAndVAT(...props, timberType),
        },
      ],
    },
  ];
  return (
    <>
      <div className="flex flex-row">
        {data && message && (
          <div className="w-full mb-4">
            <Alert color="bg-transparent border-green-500 text-green-500" borderLeft raised>
              {message}
            </Alert>
          </div>
        )}
        {timber ? (
          <FormProvider {...methods} reset={reset}>
            <ContextForm
              gridItems={items}
              onSubmit={(data) => {
                onSubmit(data);
                onSubmitFn(data);
              }}
              formId={formId}
              formStyle="flex flex-row"
              switchOptions={{
                initialState: timberType,
                state: timberType,
                leftLabel: 'Solid',
                rightLabel: 'Engineered',
                onChange: setTimberType,
              }}
            />
          </FormProvider>
        ) : (
          <TimberSelector options={timberTypes} onSelect={setTimber} />
        )}
      </div>
      <DebugConsole data={data} />
    </>
  );
};

export default TimberItemsForm;
