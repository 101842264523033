import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../store';

import BoxedLayout from '@appSrc/layouts/content/Boxed';
import Widget from '@components/widget';

import PricingSettingForm from '@components/forms/PricingSettingForm';
import TermsAndConditionsForm from '@components/forms/AccountForm/TermsAndConditionsForm';

const Page = ({ pricingSetting }) => {
  return (
    <>
      <Widget title="Description">
        <p>
          Overhead costs are indirect business expenses including items like rent, taxes, utilities,
          office equipment, machine maintenance etc. These are your overhead costs. Direct expenses
          related to the production of goods and services, such as labour and raw materials, are not
          included in overhead costs.
          <br />
          <br />
          Knowing your overheads is a key to running a well-balanced business, it allows you to
          optimally price your products and be aware of the expenses you are accumulating and
          thereby avoiding any surprises.
          <br />
          <br />
          We use machine learning algorithms that evolve the longer you are with us and help you
          calculate how much you can optimally and minimally load your products to cover operational
          costs. Therefore the algorithm lets your prices be competitive and precise.
          <br />
          <br />
        </p>
        {/* <AccountForm business={business} businessID={business.id} /> */}
      </Widget>
      <Widget title="Overheads">
        <PricingSettingForm pricingSetting={pricingSetting} />
      </Widget>
    </>
  );
};

const PricingSettingPage = (props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props} />)}</Provider>;
};

export default PricingSettingPage;
