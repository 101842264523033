import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { addImage } from '../../actions/formActions';
import { FaImage } from 'react-icons/fa';

const UploadFile = ({ item }) => {
  const dispatch = useDispatch();

  const handleFileUploadChange = (event) => {
    dispatch(addImage(item.name + item.recordId, event.target.files[0]));
    event.target.value = null;
  };

  return (
    <div className="m-4">
      <input
        name="images[]"
        type="file"
        multiple={true}
        accept="image/*"
        style={{
          width: 0.1,
          height: 0.1,
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
        id={'post_medias' + item.recordId}
        onChange={handleFileUploadChange}
      />
      <label htmlFor={'post_medias' + item.recordId}>
        <Attachment className="flex flex-row items-center justify-center space-x-2">
          <span>Add image</span>
          <FaImage />
        </Attachment>
      </label>
    </div>
  );
};

const Attachment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 40px;
  height: 40px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  background-color: #f0f2f6;
  border-radius: 18px;
  cursor: pointer;
`;

export default UploadFile;
