import API from 'apiv1';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import BoxedLayout from '@appSrc/layouts/content/Boxed';

import { NewResourceModalButton } from '@components/buttons/SharedButtons';
import Datatable, { TruncatedCell } from '@components/datatable';

import { setSuppliers } from '../actions/formActions';
import RecordActions from '../components/datatable/RecordActions';
import GlazingUnitsForm from '../components/forms/GlazingUnitsForm';
import MaterialsMenu from '../components/menus/materials';
import Modal from '../components/modals/modal';
// import SectionTitle from '../components/section-title';
import Widget from '../components/widget';
import { store } from '../store';

const Page = ({ records, suppliers }) => {
  const dispatch = useDispatch();
  const formId = 'glazingUnitsForm';

  useEffect(() => {
    dispatch(setSuppliers(suppliers));
  }, [records]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'product_name',
    },
    {
      Header: 'Thickness',
      accessor: 'thickness',
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
    {
      Header: 'Price /m2',
      accessor: 'price',
      Cell: ({ value }) => <span className="whitespace-nowrap">{value}</span>,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ value }) => RecordActions(value, GlazingUnitsForm, API.deleteGlazingUnitItem),
    },
  ];

  return (
    <>
      <MaterialsMenu />

      <Widget
        title="Glazing Units"
        right={
          <div className="flex flex-col lg:flex-row lg:flex-wrap items-end lg:items-center justify-end space-y-2 lg:space-y-0 lg:space-x-2">
            <Modal
              title="Add Glazing Unit"
              body={<GlazingUnitsForm formId={formId} />}
              buttonTitle="Add Glazing Unit"
              buttonComponent={NewResourceModalButton}
              modalButtonText="New"
              buttonFormId={formId}
            />
          </div>
        }
      >
        <Datatable columns={columns} data={records} />
      </Widget>
    </>
  );
};

const GlazingUnitsPage = (...props) => {
  return <Provider store={store}>{BoxedLayout(<Page {...props[0]} {...props[1]} />)}</Provider>;
};

export default GlazingUnitsPage;
