import { Provider } from 'mobx-react';
import { getSnapshot, applySnapshot, onSnapshot } from 'mobx-state-tree';
import React from 'react';
import { connect } from 'react-redux';

import { setDiagramSnapshot } from '@appSrc/actions/configuratorActions';

import createStore, { ICasement } from '@stores/CasementStore';

import Canvas from './Canvas';
import DiagramsToSave from './DiagramsToSave';
import FormFields from './FormFields';
import SectionControls from './SectionControls';
import SectionEditors from './SectionEditors';

type Props = {
  snapshot?: any;
  data?: any;
  store?: ICasement;
  editMode?: boolean | string;
  casementId?: Number;
  stageRef?: React.RefObject<any>;
  id?: Number;
  setDiagramSnapshot?: Function;
  productData?: any;
  dispatch?: Function;
};

type State = {
  store: ICasement;
  stageRef: React.RefObject<any>;
};

class Casement extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      store: this.props.store || createStore(this.props.snapshot),
      stageRef: this.props.stageRef || React.createRef(),
    };

    // @ts-ignore
    this.unsubscribe = null;
  }

  componentDidMount(): void {
    console.log('Casement did mount');
    onSnapshot(this.state.store, (snapshot) => {
      this.props.dispatch(setDiagramSnapshot('casementSnapshot', snapshot));
    });
  }

  componentWillUnmount(): void {
    console.log('Casement will unmount');
    // @ts-ignore
    if (this.unsubscribe) {
      // @ts-ignore
      this.unsubscribe();
    }
  }

  render() {
    return (
      <div className="flex flex-col w-full">
        <span className="font-semibold pb-2">Casement configuration</span>
        <Provider casementStore={this.state.store}>
          <FormFields
            store={this.state.store}
            data={this.props.data}
            editMode={this.props.editMode}
            casementId={this.props.casementId}
            stageRef={this.state.stageRef}
            productData={this.props.productData}
          />
          <SectionControls store={this.state.store} />
          <div className="flex flex-row">
            <Canvas stageRef={this.state.stageRef} />
            <SectionEditors store={this.state.store} />
          </div>
          <DiagramsToSave casementStore={this.state.store} />
        </Provider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productData: state.configData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    setDiagramSnapshot,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Casement);
