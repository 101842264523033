import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const ButtonIcon = ({
  as = 'a',
  bgColor = 'grey-M80',
  textColor = 'blue-M900',
  className = '',
  ...linkProps
}) => {
  let Component;
  let componentDefaultProps = {};
  switch (as) {
    case 'a':
      Component = LightLink;
      break;

    case 'button':
      Component = LightButton;
      componentDefaultProps = { type: 'button' };
      break;

    default:
      break;
  }

  return (
    <Component
      className={`bg-${bgColor} border-${bgColor} hover:text-${bgColor} hover:bg-${textColor} text-${textColor} focus:ring-${textColor} ${className}`}
      {...componentDefaultProps}
      {...linkProps}
    ></Component>
  );
};

const LightLink = styled.a`
  ${tw`inline-flex items-center px-2 py-2 border-solid border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`}
`;

const LightButton = styled.button`
  ${tw`inline-flex items-center px-2 py-2 border-solid border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`}
`;

// ${({ color }) =>
// color &&
// tw`text-${color}-M900 bg-${color}-M100 hover:bg-${color}-M500 focus:ring-${color}-M900`}

export default ButtonIcon;
